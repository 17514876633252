import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { getUserDetailsApi } from "./api";
import * as type from "../types";

function* fetchUserDetails(action) {
  try {
    const userDetails = yield call(getUserDetailsApi, action);
    yield put({
      type: type.GET_USER_DETAILS_SUCCESS,
      payload: userDetails,
    });
  } catch (e) {
    yield put({ type: type.GET_USER_DETAILS_FAILED, message: e.message });
  }
}

function* userDetailsSaga() {
  yield takeLatest("GET_USER_DETAILS", fetchUserDetails);
}

export default userDetailsSaga;
