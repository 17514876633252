import * as React from "react";
import * as StyledDOM from "./style.jsx";
import * as IconGallery from "@icongallery";
import * as Utils from "@utils";
import * as ComponentsLib from "@componentsLib";
import { Auth } from "aws-amplify";
import Stack from "@mui/material/Stack";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../Header";
import Footer from "../../Footer";
import { useDispatch, useSelector } from "react-redux";
import placeholder from "../../../../assets/images/personplaceholder.png";
import { clearReduxSession, getUserDetails } from "../../../../redux/actions";

const MyAccount = () => {
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const userDetails = useSelector((state) =>
    state ? state.userDetails.userDetails : ""
  );
  const [firstUserElement = []] = userDetails || [];
  const { response = [], status = "Loading" } = firstUserElement;
  const {
    name = "Placeholder Name",
    email = "",
    reputation = "",
    awards = "",
    isVerified = "",
    phone_number = "",
    username = "",
    id = "",
    profile_image: profileImage = "",
  } = response || {};
  let locationUrl = window.location.href.split("/").reverse();
  const [firstElement = ""] = locationUrl;

  const [open, setOpen] = React.useState(false);
  const [isBtnLoading, setIsBtnLoading] = React.useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const onLogout = () => {
    handleOpen();
  };

  React.useEffect(() => {
    window.scroll(0, 0);
    dispatch(getUserDetails());
  }, []);

  const triggerLogOut = async () => {
    setIsBtnLoading(true);
    setIsBtnDisabled(true);
    try {
      Utils.resetStorage();
      setTimeout(async () => {
        await Auth.signOut();
        dispatch(clearReduxSession());
        setIsBtnLoading(false);
        setIsBtnDisabled(false);
        handleClose();
      }, 1000);
    } catch (error) {
      setIsBtnLoading(false);
      setIsBtnDisabled(false);
    }
  };

  const confirmationPopupProps = {
    open,
    setOpen,
    handleOpen,
    handleClose,
    modalTitle: "Hey, Wait!!",
    subTitle: `Are you sure, you want to logout from "Curator"? We'll miss you.`,
    cancelBtn: "I want to stay",
    confirmBtn: "Yes, Logout",
    triggerConfirmBtn: triggerLogOut,
    triggerCancelBtn: handleClose,
    isBtnLoading,
    isBtnDisabled,
    showIcon: true,
    showCloseIcon: false,
  };

  const headerProps = {
    showLogo: false,
    title: firstElement.replaceAll("-", " ")
      ? firstElement.replaceAll("-", " ")
      : "My Account",
    showBackIcon: true,
    showMenu: false,
    from: "myaccount",
  };

  function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
    return color;
  }

  function stringAvatar(name: string) {
    let splitName = name.split(" ");
    let childrenName =
      splitName.length > 1
        ? `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
        : `${splitName[0][0]}`;
    return {
      // sx: {
      //   bgcolor: stringToColor(name),
      // },
      children: `${childrenName.toUpperCase()}`,
    };
  }

  return (
    <>
      <Header {...headerProps} />
      <StyledDOM.MainDivContainer container>
        <StyledDOM.StyledOuterGrid item xs={12} lg={12} sm={12}>
          <Stack direction="row" spacing={2}>
            <StyledDOM.Styledimgborder>
              {profileImage ? (
                <StyledDOM.Styledavatar
                  alt={name}
                  src={profileImage}
                ></StyledDOM.Styledavatar>
              ) : (
                <StyledDOM.Styledavatar {...stringAvatar(name)} />
              )}
            </StyledDOM.Styledimgborder>
          </Stack>
          <StyledDOM.StyledSecondTypography>
            {name}
            {/* <StyledDOM.StyledHeaderh6>
              Product Designer
            </StyledDOM.StyledHeaderh6> */}
          </StyledDOM.StyledSecondTypography>
          <StyledDOM.StyledBoxContainer>
            <StyledDOM.StyledAppBar position="static">
              <StyledDOM.StyledToolbar variant="regular">
                <StyledDOM.StyledTypography
                  variant="h5"
                  onClick={() => {
                    navigateTo("/my-account/edit-profile");
                  }}
                >
                  <StyledDOM.StyledDiv>
                    <IconGallery.EditIcon />
                  </StyledDOM.StyledDiv>
                  <StyledDOM.StyledButton>
                    {Utils?.staticText?.myAccount?.editProfile}
                  </StyledDOM.StyledButton>
                  <StyledDOM.StyledGrid>
                    <IconGallery.RightArrow />
                  </StyledDOM.StyledGrid>
                </StyledDOM.StyledTypography>
              </StyledDOM.StyledToolbar>
            </StyledDOM.StyledAppBar>
          </StyledDOM.StyledBoxContainer>
          <StyledDOM.StyledHr />
          <StyledDOM.StyledBoxContainer>
            <StyledDOM.StyledAppBar position="static">
              <StyledDOM.StyledToolbar variant="regular">
                <StyledDOM.StyledTypography
                  variant="h5"
                  onClick={() => {
                    navigateTo("/my-account/faq");
                  }}
                >
                  <StyledDOM.StyledDiv>
                    <IconGallery.FaqIcon />
                  </StyledDOM.StyledDiv>
                  <StyledDOM.StyledButton
                  // onClick={() => {
                  //   navigateTo("/Faq");
                  // }}
                  >
                    {Utils?.staticText?.myAccount?.faq}
                  </StyledDOM.StyledButton>
                  <StyledDOM.StyledGrid>
                    <IconGallery.RightArrow />
                  </StyledDOM.StyledGrid>
                </StyledDOM.StyledTypography>
              </StyledDOM.StyledToolbar>
            </StyledDOM.StyledAppBar>
          </StyledDOM.StyledBoxContainer>
          <StyledDOM.StyledHr />
          <StyledDOM.StyledBoxContainer>
            <StyledDOM.StyledAppBar position="static">
              <StyledDOM.StyledToolbar variant="regular">
                <StyledDOM.StyledTypography
                  variant="h5"
                  onClick={() => {
                    navigateTo("/my-account/referral");
                  }}
                >
                  <StyledDOM.StyledDiv>
                    <IconGallery.ReferralIcon />
                  </StyledDOM.StyledDiv>
                  <StyledDOM.StyledButton>
                    {Utils?.staticText?.myAccount?.referral}
                  </StyledDOM.StyledButton>
                  <StyledDOM.StyledGrid>
                    <IconGallery.RightArrow />
                  </StyledDOM.StyledGrid>
                </StyledDOM.StyledTypography>
              </StyledDOM.StyledToolbar>
            </StyledDOM.StyledAppBar>
          </StyledDOM.StyledBoxContainer>
          <StyledDOM.StyledHr />

          <StyledDOM.StyledBoxContainer>
            <StyledDOM.StyledAppBar position="static">
              <StyledDOM.StyledToolbar variant="regular">
                <StyledDOM.StyledTypography
                  variant="h5"
                  onClick={() => {
                    navigateTo("/my-account/settings");
                  }}
                >
                  <StyledDOM.StyledDiv>
                    <IconGallery.SettingsIcon />
                  </StyledDOM.StyledDiv>
                  <StyledDOM.StyledButton>
                    {Utils?.staticText?.myAccount?.settings}
                  </StyledDOM.StyledButton>
                  <StyledDOM.StyledGrid>
                    <IconGallery.RightArrow />
                  </StyledDOM.StyledGrid>
                </StyledDOM.StyledTypography>
              </StyledDOM.StyledToolbar>
            </StyledDOM.StyledAppBar>
          </StyledDOM.StyledBoxContainer>
          <StyledDOM.StyledHr />
          <StyledDOM.StyledBoxContainer>
            <StyledDOM.StyledAppBar position="static">
              <StyledDOM.StyledToolbar variant="regular">
                <StyledDOM.StyledTypography variant="h5" onClick={onLogout}>
                  <StyledDOM.StyledDiv>
                    <IconGallery.LogOutIcon />
                  </StyledDOM.StyledDiv>
                  <StyledDOM.StyledButton>
                    {Utils?.staticText?.myAccount?.logout}
                  </StyledDOM.StyledButton>
                  <StyledDOM.StyledGrid>
                    <IconGallery.RightArrow />
                  </StyledDOM.StyledGrid>
                </StyledDOM.StyledTypography>
              </StyledDOM.StyledToolbar>
            </StyledDOM.StyledAppBar>
          </StyledDOM.StyledBoxContainer>
          <StyledDOM.Styledh6>
            <Link target="_blank" to="/privacy-policy">
              {Utils?.staticText?.myAccount?.policies}
            </Link>
          </StyledDOM.Styledh6>
        </StyledDOM.StyledOuterGrid>
      </StyledDOM.MainDivContainer>
      <ComponentsLib.ConfirmationPopup {...confirmationPopupProps} />
    </>
  );
};

export { MyAccount };
export default MyAccount;
