import { styled } from "@mui/material/styles";
import { FormLabel } from "@mui/material";

const StyledLabel = styled("label")`font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: #4f4f4f;
  margin-bottom: 8px;
`;
export { StyledLabel };
