import React from "react";
import * as StyledDOM from "./styles";
import * as Utils from "@utils";
import * as ComponentsLib from "@componentsLib";
import { Menu, MenuItem, Button, Grid } from "@mui/material";
import { Auth } from "aws-amplify";
import { Link, useNavigate } from "react-router-dom";
import * as IconGallery from "@icongallery";
import { useDispatch, useSelector } from "react-redux";
import { clearReduxSession } from "../../../redux/actions";

function BasicMenu(props) {
  const { triggerLogOut, navigateTo, handleOpen, name, profile_image } =
    props || {};
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onMyAccount = () => {
    navigateTo("/my-account");
    setAnchorEl(null);
  };
  const onLogout = () => {
    setAnchorEl(null);
    handleOpen();
  };

  function stringAvatar(name: string) {
    let splitName = name.split(" ");
    let childrenName =
      splitName.length > 1
        ? `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
        : `${splitName[0][0]}`;
    return {
      // sx: {
      //   bgcolor: stringToColor(name),
      // },
      children: `${childrenName.toUpperCase()}`,
    };
  }

  return (
    <Grid style={{ textAlign: "center" }}>
      {profile_image ? (
        <StyledDOM.StyledAvatar
          alt={name}
          src={profile_image}
        ></StyledDOM.StyledAvatar>
      ) : (
        <StyledDOM.StyledAvatar {...stringAvatar(name)} />
      )}
      {/* <Button
        style={{ padding: 0 }}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <IconGallery.ProfileCircleIcon sx={{ fontSize: "50px" }} />
      </Button> */}
      {/* <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={onLogout}>
          <IconGallery.LogoutIconIcon sx={{ color: "#FF5C3E" }} /> Logout
        </MenuItem>
      </Menu> */}
    </Grid>
  );
}

const UserDetails = (props) => {
  const { userDetails, handleBackdropOpen, handleBackdropClose } = props || {};
  // const userDetails = useSelector((state) => state.userDetails.userDetails);
  const [firstElement = []] = userDetails;
  const { response = [], status = "Loading" } = firstElement;
  const {
    name = "",
    email = "",
    reputation = "",
    awards = "",
    isVerified = "",
    phone_number = "",
    username = "",
    id = "",
    profile_image = "",
  } = response || {};
  const [open, setOpen] = React.useState(false);
  const [isBtnLoading, setIsBtnLoading] = React.useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const triggerLogOut = async () => {
    setIsBtnLoading(true);
    setIsBtnDisabled(true);
    try {
      handleBackdropOpen();
      await Auth.signOut();
      setIsBtnLoading(false);
      setIsBtnDisabled(false);
      Utils.resetStorage();
      dispatch(clearReduxSession());
      handleClose();
      navigateTo("/login");
      handleBackdropClose();
    } catch (error) {
      console.log("error signing out: ", error);
      setIsBtnLoading(false);
      setIsBtnDisabled(false);
    }
  };

  const confirmationPopupProps = {
    open,
    setOpen,
    handleOpen,
    handleClose,
    modalTitle: "Hey, Wait!!",
    subTitle: `Are you sure, you want to logout from "Phenowise"? We'll miss you.`,
    cancelBtn: "I want to stay",
    confirmBtn: "Yes, Logout",
    triggerConfirmBtn: triggerLogOut,
    triggerCancelBtn: handleClose,
    isBtnLoading,
    isBtnDisabled,
    showIcon: true,
  };
  const basicMenuProps = {
    triggerLogOut,
    navigateTo,
    handleOpen,
    name,
    profile_image,
  };
  const textLoaderProps = {
    variant: "text",
    height: 30,
    width: "80%",
  };
  const imageLoaderProps = {
    variant: "circular",
    height: 60,
  };
  const skeletonLoaderProps = {
    variant: "rounded",
    height: 60,
    width: "100%",
  };
  const errorProps = {
    content: "User Details Not found",
    width: "100%",
    height: 60,
  };
  return (
    <StyledDOM.StyledBanners container item xs={12}>
      <>
        {status == "Error" ? (
          <StyledDOM.StyledDetails item xs={12}>
            <ComponentsLib.ErrorSection {...errorProps} />
          </StyledDOM.StyledDetails>
        ) : (
          <>
            <StyledDOM.StyledDetails item xs={10}>
              {status == "Loading" ? (
                <ComponentsLib.SkeletonLoader {...textLoaderProps} />
              ) : (
                <StyledDOM.StyledTypography className="large">
                  {name}
                </StyledDOM.StyledTypography>
              )}
              {status == "Loading" ? (
                <ComponentsLib.SkeletonLoader {...textLoaderProps} />
              ) : (
                <StyledDOM.StyledTypography className="small">
                  {reputation ? reputation + " POINTS" : ""}
                </StyledDOM.StyledTypography>
              )}
            </StyledDOM.StyledDetails>

            <StyledDOM.StyledDetails item xs={2}>
              {status == "Loading" ? (
                <ComponentsLib.SkeletonLoader {...imageLoaderProps} />
              ) : (
                <BasicMenu {...basicMenuProps} />
              )}
            </StyledDOM.StyledDetails>
          </>
        )}
      </>
      <ComponentsLib.ConfirmationPopup {...confirmationPopupProps} />
    </StyledDOM.StyledBanners>
  );
};

export { UserDetails };
export default UserDetails;
