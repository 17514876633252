import React from "react";
import * as yup from "yup";

export const getValid = (value, optionalFieldItm = "") => {
  const { ...balanceProps } = value;
  let values = Object.keys(balanceProps).map((itm) => {
    let obj = "";
    if (balanceProps[itm].length === 0) {
      obj = itm;
    }
    return obj;
  });

  return values.filter((itm) => itm !== "");
};

export const FormFieldValues = (props) => {
  const initialValues = {
    // email: "",
    password: "",
    confirmPassword: "",
    rememberMe: false,
  };
  return {
    initialValues,
  };
};
let max = 64;
export const validationSchema = yup.object().shape({
  // pass: yup
  //   .string()
  //   .min(8, "Password must be 8 characters long")
  //   .matches(/[0-9]/, "Password requires a number")
  //   .matches(/[a-z]/, "Password requires a lowercase letter")
  //   .matches(/[A-Z]/, "Password requires an uppercase letter")
  //   .matches(/[^\w]/, "Password requires a symbol"),
  password: yup
    .string()
    .required("Old Password is required")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    ),
  confirmPassword: yup
    .string()
    .required("New Password is required")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    )
    .notOneOf(
      [yup.ref("password")],
      "New Passwords do not match with old password."
    ),
  rememberMe: yup.boolean().notRequired(),
});

export const Forminputprops = (props) => {
  const {
    formik,
    passwordType = "password",
    confirmPasswordType = "password",
  } = props;

  const passwordProps = {
    note: "",
    inputprops: {
      style: {
        height: "20px",
        backgroundPosition: "10px center",
        backgroundRepeat: "no-repeat",
        border: "1px solid #ccc",
        transition: "all 0.2s",
        borderRadius: "4px",
        fontSize: "16px",
        backgroundColor: "#fff",
      },
    },
    className: "cls-textfield cls-password",
    placeholder: "",
    labelname: "Old Password",
    id: "password",
    type: `${passwordType === "password" ? "password" : "text"}`,
    name: "password",
    onChange: (e) => {
      formik?.setFieldTouched("password");
      formik?.handleChange(e);
    },
    defaultValue: formik?.values.password,
    helpertext:
      formik?.errors.password &&
      formik?.touched.password &&
      `${formik?.errors.password}`,
    error: formik?.errors.password && formik?.touched.password,
    variant: "filled",
    formik: formik,
  };

  const confirmPasswordProps = {
    note: "",
    inputprops: {
      style: {
        height: "20px",
        backgroundPosition: "10px center",
        backgroundRepeat: "no-repeat",
        border: "1px solid #ccc",
        transition: "all 0.2s",
        borderRadius: "4px",
        fontSize: "16px",
        backgroundColor: "#fff",
      },
    },
    className: "cls-textfield cls-confirmPassword",
    placeholder: "",
    labelname: "New Password",
    id: "confirmPassword",
    type: `${confirmPasswordType === "password" ? "password" : "text"}`,
    name: "confirmPassword",
    onChange: (e) => {
      formik?.setFieldTouched("confirmPassword");
      formik?.handleChange(e);
    },
    defaultValue: formik?.values.confirmPassword,
    helpertext:
      formik?.errors.confirmPassword &&
      formik?.touched.confirmPassword &&
      `${formik?.errors.confirmPassword}`,
    error: formik?.errors.confirmPassword && formik?.touched.confirmPassword,
    variant: "filled",
    formik: formik,
  };

  const rememberMeProps = {
    note: "",
    formik: formik,
    checked: formik?.values.rememberMe,
    onChange: (e) => {
      formik?.setFieldTouched("rememberMe");
      formik?.setFieldValue("rememberMe", !formik?.values.rememberMe);
      formik?.handleChange(e);
    },
    type: "checkbox",
  };

  return {
    passwordProps,
    confirmPasswordProps,
    rememberMeProps,
  };
};
