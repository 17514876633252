import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { getTermDetails } from "./api";
import * as type from "../types";

function* fetchTermDetailsSaga(action) {
  try {
    const categoryList = yield call(getTermDetails, action);
    yield put({
      type: type.GET_TERM_DETAILS_SUCCESS,
      payload: categoryList,
    });
  } catch (e) {
    yield put({ type: type.GET_TERM_DETAILS_FAILED, message: e.message });
  }
}

function* termDetailsSaga() {
  yield takeLatest("GET_TERM_DETAILS", fetchTermDetailsSaga);
}

export default termDetailsSaga;
