import { styled } from "@mui/material/styles";
import { Grid, Button, Typography, Chip, Box } from "@mui/material";

export const StyledOuterThreadGrid = styled(Grid)`
  font-family: "Manrope", sans-serif;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 0px 15px;
  &.child {
  }
`;
export const StyledIconGrid = styled(Grid)`
  font-family: "Manrope", sans-serif;
  align-items: center;
  justify-content: left;
  display: flex;
`;
export const StyledContentGrid = styled(Grid)`
  font-family: "Manrope", sans-serif;
  display: table;
`;
export const StyledTypography = styled(Typography)`
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-size: 900;
  &.small {
    font-size: 11px;
    color: #9a999a;
    font-size: 500;
  }
  @media (max-width: 300px) {
    padding-left: 15px !important;
    &.small {
      padding-left: 15px !important;
    }
  }

  @media (max-width: 991px) {
    padding-left: 15px;
    &.small {
      padding-left: 15px;
    }
  }

  @media (max-width: 600px) {
    padding-left: 0px;
    position: relative;
    left: -10px;
    &.small {
      padding-left: 0px;
    }
  }

  @media (max-width: 450px) {
    padding-left: 0px;
    position: relative;
    left: 0px;
    &.small {
      padding-left: 0px;
    }
  }
`;

export const StyledContentTextGrid = styled(Grid)`
  font-family: "Manrope", sans-serif;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.87);
  line-height: 18px;
  font-weight: 500;
  word-wrap: break-word;
  white-space: break-spaces;
}
`;
export const StyledTagsGrid = styled(Grid)`
  font-family: "Manrope", sans-serif;
  display: flex;
  align-items: flex-start;
  justify-content: start;
  gap: 5px;
  span.symp {
    font-size: 11px;
    font-weight: 600;
    margin-top: 7px;
  }
  span.symptomsCount {
    font-size: 11px;
    color: #26867d;
    font-weight: 900;
    text-transform: uppercase;
    margin-top: 7px;
  }
  &.sympList {
    gap: 5px;
  }
`;
export const StyledTags = styled(Chip)`
  font-family: "Manrope", sans-serif;
  color: #26867d;
  background: #d3e7e5;
  font-size: 11px;
  font-weight: 900;
  text-transform: uppercase;
  border-radius: 4px;
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  &.MuiChip-root.MuiChip-filled.MuiChip-sizeMedium {
    height: auto;
  }
  span {
    padding: 4px 6px;
    font-size: 10px;
    font-weight: 900;
    white-space: break-spaces;
    border-radius: 4px;
  }
`;

export const StyledReplyGrid = styled(Grid)`
  font-family: "Manrope", sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0px 10px 0px;
  font-size: 14px;
`;
export const StyledVote = styled(Grid)`
  font-family: "Manrope", sans-serif;
  display: flex;
  padding: 5px 10px;
  background: rgb(176 176 176 / 0.2);
  border-radius: 4px;
`;
export const StyledComments = styled(Grid)`
  font-family: "Manrope", sans-serif;
  display: flex;
`;
export const StyledChildCommentBox = styled(Grid)`
  font-family: "Manrope", sans-serif;
  display: table;
  margin: 0 15px;
  border-left: 3px solid #dedede;
  width: 93%;
`;
export const StyledHr = styled("hr")`
  font-family: "Manrope", sans-serif;
  border: none;
  border-top: 1px solid #ccc;
  color: #fff;
  background-color: #fff;
  width: 100%;
  margin: 0px 0px 20px;
`;

export const StyledGrid = styled(Grid)`
  font-family: "Manrope", sans-serif;
  @media only screen and (max-width: 820px) {
  }
  @media only screen and (max-width: 420px) {
  }
`;
export const StyledUpVoteImg = styled("img")`
  width: 24px;
  height: 24px;
  margin-right: 7px;
  display: inline-block;
  overflow: hidden;
  &:active {
    transform: scale(1.5);
    -ms-transform: scale(1.5); /* IE 9 */
    -moz-transform: scale(1.5); /* Firefox */
    -webkit-transform: scale(1.5); /* Safari and Chrome */
    -o-transform: scale(1.5); /* Opera */
  }
`;

export const StyledDownVoteImg = styled("img")`
  width: 24px;
  height: 24px;
  margin-left: 7px;
  display: inline-block;
  overflow: hidden;
  &:active {
    transform: scale(1.5);
    -ms-transform: scale(1.5); /* IE 9 */
    -moz-transform: scale(1.5); /* Firefox */
    -webkit-transform: scale(1.5); /* Safari and Chrome */
    -o-transform: scale(1.5); /* Opera */
  }
`;
export const StyledSpan = styled("span")`
  color: #26867d;
  cursor: pointer;
`;
