import { styled } from "@mui/material/styles";
import { Grid, Button, Typography } from "@mui/material";

export const StyledMainHeading = styled("h1")`
  font-family: "Manrope", sans-serif;
  margin-bottom: 10px;
  margin-top: 0;
  text-align: left;
  font-size: 20px;
  font-weight: 900;

  @media only screen and (max-width: 700px) {
    margin-bottom: 0px;
    margin-top: 0;
  }
`;
export const StyledSubHeading = styled("h5")`
  font-family: "Manrope", sans-serif;
  font-size: 11px;
  color: #7c797a;
  margin: 5px 0;
  text-align: left;
`;

export const StyledMainFieldsWrapper = styled(Grid)`
  font-family: "Manrope", sans-serif;
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  label {
    font-size: 12px;
  }
  @media only screen and (min-width: 768px) {
    width: 60%;
    margin: 0 auto;
    padding: 10px 15px;
  }
`;
