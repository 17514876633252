import React from "react";
import Login from "../components/Pages/Login";
import Register from "../components/Pages/Register";
import NoPage from "../components/Pages/404";
import Home from "../components/Pages/Home";
import ComingSoon from "../components/Pages/ComingSoon";
import ForgotPassword from "../components/Pages/ForgotPassword";
import ResetPassword from "../components/Pages/ResetPassword";
import Explore from "../components/Pages/Explore";
import * as Utils from "@utils";
import { Auth } from "aws-amplify";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import SelectUserCategory from "../components/Pages/SelectUserCategory";
import Notifications from "../components/Pages/Notifications";
import CategoryDetails from "../components/Pages/CategoryDetails";
import TermDetails from "../components/Pages/TermDetails";
import MyAccount from "../components/Pages/MyAccount";
import EditProfile from "../components/Pages/MyAccount/EditProfile";
import CreatePassword from "../components/Pages/MyAccount/CreatePassword";
import AddComments from "../components/Pages/TermDetails/TermForum/AddComments";
import TermsAndConditions from "../components/Pages/TermsAndConditions";
import SearchResult from "../components/Pages/SearchResult";
import LeaderBoard from "../components/Pages/LeaderBoard";
import PrivacyPolicy from "../components/Pages/PrivacyPolicy";

export const authPageRouting = [
  {
    name: "Login",
    path: "login",
    index: false,
    element: <Login />,
    icon: "",
    isLoginRequired: false,
  },
  {
    name: "Register",
    path: "register",
    index: false,
    element: <Register />,
    icon: "",
    isLoginRequired: false,
  },
  {
    name: "ForgotPassword",
    path: "forgot-password",
    index: false,
    element: <ForgotPassword />,
    icon: "",
    isLoginRequired: false,
  },
  {
    name: "ResetPassword",
    // path: "reset-password/:emailId/:verificationId",
    path: "reset-password/:emailId/",
    index: false,
    element: <ResetPassword />,
    icon: "",
    isLoginRequired: false,
  },
  {
    name: "Register",
    path: "register/:type/:uuid/:userSub/verify",
    index: false,
    element: <Register />,
    icon: "",
    isLoginRequired: false,
  },
  {
    name: "PageNotFound",
    path: "*",
    index: false,
    element: <NoPage />,
    icon: "",
    isLoginRequired: false,
  },
  {
    name: "TermsAndConditions",
    path: "terms-and-conditions",
    index: false,
    element: <TermsAndConditions />,
    icon: "",
    isLoginRequired: false,
  },
  // {
  //   name: "Term Details",
  //   path: "explore/:categoryName/:termId/:termName",
  //   index: false,
  //   element: <TermDetails />,
  //   icon: "",
  //   isLoginRequired: false,
  // },
  // {
  //   name: "MyAccount",
  //   path: "my-account",
  //   index: false,
  //   element: <MyAccount />,
  //   icon: "",
  //   isLoginRequired: false,
  // },
  // {
  //   name: "EditProfile",
  //   path: "edit-profile",
  //   index: false,
  //   element: <EditProfile />,
  //   icon: "",
  //   isLoginRequired: false,
  // },
  // {
  //   name: "Create New Password",
  //   path: "settings",
  //   index: false,
  //   element: <CreatePassword />,
  //   icon: "",
  //   isLoginRequired: false,
  // },
  // {
  //   name: "Explore",
  //   path: "explore",
  //   index: false,
  //   element: <Explore />,
  //   icon: "",
  //   isLoginRequired: false,
  // },
  // {
  //   name: "Category Details",
  //   path: "explore/:categoryId/:categoryName",
  //   index: false,
  //   element: <CategoryDetails />,
  //   icon: "",
  //   isLoginRequired: false,
  // },
  // {
  //   name: "Home",
  //   path: "/",
  //   index: true,
  //   element: <Home />,
  //   icon: "",
  //   isLoginRequired: false,
  // },
  // {
  //   name: "Notifications",
  //   path: "notifications",
  //   index: false,
  //   element: <Notifications />,
  //   icon: "",
  //   isLoginRequired: false,
  // },
];
export const privatePageRoutings = [
  {
    name: "Home",
    path: "/",
    index: true,
    element: <Home />,
    icon: "",
    isLoginRequired: true,
  },
  {
    name: "Explore",
    path: "explore",
    index: false,
    element: <Explore />,
    icon: "",
    isLoginRequired: true,
  },
  {
    name: "SearchResult",
    path: "common/search/:catName/:searchString?",
    index: false,
    element: <SearchResult />,
    icon: "",
    isLoginRequired: true,
  },
  {
    name: "Category Details",
    path: "explore/:categoryId/:categoryName",
    index: false,
    element: <CategoryDetails />,
    icon: "",
    isLoginRequired: true,
  },
  {
    name: "Term Details",
    // path: "explore/:categoryId/:categoryName/:termId/:termName",
    path: "topicdetails/:termId/:termName",
    index: false,
    element: <TermDetails />,
    icon: "",
    isLoginRequired: true,
  },
  {
    name: "Add Comments",
    // path: "explore/:categoryId/:categoryName/:termId/:termName/add-comments",
    path: "topicdetails/:termId/:termName/add-comments",
    index: false,
    element: <AddComments />,
    icon: "",
    isLoginRequired: true,
  },
  {
    name: "MyAccount",
    path: "my-account",
    index: false,
    element: <MyAccount />,
    icon: "",
    isLoginRequired: true,
  },
  {
    name: "EditProfile",
    path: "my-account/edit-profile",
    index: false,
    element: <EditProfile />,
    icon: "",
    isLoginRequired: true,
  },
  {
    name: "Faq",
    path: "my-account/faq",
    index: false,
    element: <ComingSoon />,
    icon: "",
    isLoginRequired: true,
  },
  {
    name: "Referral",
    path: "my-account/referral",
    index: false,
    element: <ComingSoon />,
    icon: "",
    isLoginRequired: true,
  },
  {
    name: "Create New Password",
    path: "my-account/settings",
    index: false,
    element: <CreatePassword />,
    icon: "",
    isLoginRequired: false,
  },
  {
    name: "LeaderBoard",
    path: "leaderboard",
    index: false,
    element: <LeaderBoard />,
    icon: "",
    isLoginRequired: true,
  },
  {
    name: "Notifications",
    path: "notifications",
    index: false,
    element: <Notifications />,
    icon: "",
    isLoginRequired: true,
  },

  {
    name: "PageNotFound",
    path: "*",
    index: false,
    element: <NoPage />,
    icon: "",
    isLoginRequired: false,
  },
  {
    name: "PrivacyPolicy",
    path: "privacy-policy",
    index: false,
    element: <PrivacyPolicy />,
    icon: "",
    isLoginRequired: true,
  },
  // {
  //   name: "SelectUserCategory",
  //   path: "selectusercategory",
  //   index: false,
  //   element: (
  //       <SelectUserCategory />
  //   ),
  //   icon: "",
  //   isLoginRequired: true,
  // },
];
