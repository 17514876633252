import { styled } from "@mui/material/styles";
import {
  Grid,
  Typography,
  IconButton,
  MenuItem,
  AvatarGroup,
  Avatar,
  Box
} from "@mui/material";

export const StyledTopicsSlider = styled(Grid)`
  font-family: "Manrope", sans-serif;
  margin: 0px 0px 15px 0px;
  .sc-dkrFOg.biYdFZ.rec.rec-slider-container {
    margin: 0px;
  }
  @media only screen and (max-width: 820px) {
  }
`;

export const StyledMainHeading = styled("h1")`
  font-family: "Manrope", sans-serif;
  margin: 0px;
  text-align: left;
  font-size: 36px;
  font-weight: 900;

  @media only screen and (max-width: 700px) {
    margin: 0;
  }
`;
export const StyledSubHeadingOne = styled("h2")`
  font-family: "Manrope", sans-serif;
  font-size: 20px;
  color: #0f0f0f;
  margin: 0px;
  text-align: left;
  margin-bottom: 0px;
  font-weight: 900;
  margin-top: 15px;
`;
export const StyledSubHeadingTwo = styled("h3")`
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  color: #000000;
  text-align: left;
  margin: 10px 0 0px;
`;
export const StyledSubHeadingThree = styled("h4")`
  font-family: "Manrope", sans-serif;
  font-size: 11px;
  color: #9a999a;
  margin-top: 0px;
  margin-bottom: 12px;
  text-align: left;
  width: 80%;
  @media only screen and (max-width: 820px) {
    width: 80%;
  }
  @media only screen and (max-width: 420px) {
    width: 60%;
  }
  @media (max-width: 300px) {
   font-size: 9px;
  }
`;
export const StyledGrid = styled(Grid)`
  font-family: "Manrope", sans-serif;
  position: relative;
`;
export const StyledCarousel = styled(Grid)`
  font-family: "Manrope", sans-serif;
  padding: 0;
  margin: 0;
`;
export const StyledCarouselItem = styled(Grid)`
  font-family: "Manrope", sans-serif;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 15px 10px 10px 10px;
  margin: 0px 0px 15px 0px;
  box-shadow: 0pt 3pt 7pt #0d332f12;
  width: 100%;

  // &.listView {
  //   margin-bottom: 10px;
  // }
  @media (max-width: 300px) {
   overflow-x : hidden; 
 }
`;
export const StyledItemTitle = styled("h5")`
  font-family: "Manrope", sans-serif;
  margin: 10px 0;
  font-size: 14px;
  font-weight: 900;
  color: #272727;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 300px;
  height: auto;
  white-space: nowrap;
   @media (max-width: 300px) {
   font-size: 10px;
  }
`;
export const StyledItemContent = styled(Typography)`
  font-family: "Manrope", sans-serif;
  min-height: 36px;
  font-size: 12px;
  font-weight: 900;
  color: #7c797a;
  
`;
export const StyledArrowIconBtn = styled(IconButton)`
  font-family: "Manrope", sans-serif;
  position: absolute;
  right: 0;
  bottom: 0;
`;
export const StyledHr = styled("hr")`
  font-family: "Manrope", sans-serif;
  border: none;
  border-top: 3px dotted #ccc;
  color: #fff;
  background-color: #fff;
  height: 0px;
  width: 100%;
  margin: 5px 0px 3px 0;
`;
export const StyledInfo = styled(Grid)`
  font-family: "Manrope", sans-serif;
  position: relative;
  margin-bottom: 0px;
  height: 20px;
  span.count {
    margin-top: 10px;
    position: absolute;
    right: 0;
    top: -6px;
    font-size: 12px;
    color: #7c797a;
    font-weight: 900;
  }
`;
export const StyledInfoText = styled("span")`
  font-family: "Manrope", sans-serif;
  position: absolute;
  margin-left: 5px;
  font-size: 11px;
  font-weight: 900;
  color: #7c797a;
  width: 80%;
  display: flex;
  align-items: center;
  gap: 3px;
`;
export const StyledProfileView = styled(Grid)`
  font-family: "Manrope", sans-serif;
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: 97%;
  // svg {
  //   position: absolute;
  //   right: 15px;
  //   z-index: 999;
  //   top: -4px;
  // }
  img {
    border-radius: 20px;
  }
  img.profileIcon_1 {
    position: absolute;
    right: 10px;
    z-index: 99;
    top: -2px;
  }
  img.profileIcon_2 {
    position: absolute;
    right: 8px;
    z-index: 9;
    top: 2px;
  }
`;

export const StyledSkeletonGrid = styled(Grid)`
  font-family: "Manrope", sans-serif;
  position: relative;
  margin-bottom: 10px;
`;
export const StyledFilterDropDown = styled(IconButton)`
  font-family: "Manrope", sans-serif;
  position: absolute;
  top: 10px;
  right: 0;
  bottom: 0;
  padding: 0px;
  .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
    padding: 5px;
  }
  .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input {
    font-family: "Manrope", sans-serif;
    top: -7px;
    font-size: 11px;
    color: #0f0f0f;
    font-weight: 500;
  }
  svg {
    margin-top: 5px;
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  font-size: 11px;
  font-family: "Manrope", sans-serif;
  color: #0f0f0f;
  font-weight: 500;
  min-height: 30px;
  &.Mui-selected {
    background-color: #b0f4ed;
  }
  
`;

export const StyledProfileViewImageGrid = styled(Grid)`
  position: absolute;

  // &.profileIcon_0 {
  //   right: 0px;
  // }
  // &.profileIcon_1 {
  //   right: 10px;
  // }
  // &.profileIcon_2 {
  //   right: 20px;
  // }
`;
export const StyledAvatarGroup = styled(AvatarGroup)`
  font-family: "Manrope", sans-serif;
  height: 20px;
  width: 20px;
`;

export const StyledAvatar = styled(Avatar)`
  font-family: "Manrope", sans-serif;
  height: 20px;
  width: 20px;
`;
export const StyledCarouselItemDiv = styled(Grid)`
  font-family: "Manrope", sans-serif;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 15px 10px 10px 10px;
  margin: 0px;
  box-shadow: 0pt 3pt 7pt #0d332f12;
  width: 100%;

  // &.listView {
  //   margin-bottom: 10px;
  // }
  
`;
export const StyledBox = styled(Box)`
display:flex;
gap:5px;
@media (max-width: 300px) {
font-size:9px

  } 
`



