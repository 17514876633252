/* Login and Register */
export const USER_SIGNUP = "/registration";
export const USER_LOGIN = "/loginUser";
export const USERS_DETAILS = "/userDetail";
export const USERS_EMAIL_VERIFY = "/verify";
export const USERS_PHONE_VERIFY = "/generate-otp";
export const USERS_PHONE_OTP_VERIFY = "/verify-otp";
export const RESEND_VERIFICATION_LINK = "/resendlink";

/* My account */
export const UPDATE_USER_DETAILS = "/user";

/* User Category */
export const GET_USER_CATEGORY = "/usercategory";
export const POST_USER_CATEGORY = "/usercategory";

/* Trending Terms */
export const TRENDING_TOPICS = "/treadingQuestion";

/*Categories */
export const CATEGORY_DETAILS_VIEW = "/question";
export const CATEGORY_LIST = "/getCategories";

/* Global Search */
export const GET_GLOBAL_SEARCH = "/search";
export const GET_GLOBAL_SEARCH_BOT = "/new_search";

/*Term/Topics Details */
export const GET_TERM_DETAILS = "/question";
export const POST_VOTE = "/vote";
export const GET_COMMENTS = "/get-answers"; // get Terms contribution list // /get-answers/{id}
export const POST_COMMENTS = "/answer"; // add Terms comments
export const GET_REPLY = "/comments"; // get contribution replies list // /comments/{id}
export const POST_REPLY = "/comments"; // add contribution's reply
export const POST_HELPFUL = "/saveQuestionHelpful";

/*Notifications */
export const CONTRIBUTE_NOTIFICATION_LIST = "/userNotification";
