import React from "react";
import * as ComponentsLib from "@componentsLib";
import * as IconGallery from "@icongallery";
import * as Utils from "@utils";
import * as StyledDOM from "./styles";
import _ from "lodash";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTermDetailsStates } from "./useTermDetailsStates";
import { Grid, Chip, Box, Button } from "@mui/material";
import Header from "../../Header";
import Footer from "../../Footer";
import { useDispatch, useSelector } from "react-redux";
import {
  getComments,
  clearTermsReduxSession,
  getUserDetails,
} from "../../../../redux/actions";
import InfoIcon from "@mui/icons-material/Info";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import Divider from "@mui/material/Divider";
import TermForum from "./TermForum";
import IKnowSomeOne from "./TermForum/IKnowSomeOne";
import CommonIllness from "./TermForum/CommonIllness";
import { postHelpfulApi } from "./api";

// const symptoms = [
//   "Headache",
//   "Chills and shivering",
//   "Muscle aches",
//   "Loss of appetite",
//   "Sweating",
// ];

function TermDetails(props) {
  const {} = props || {};
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const {
    // categoryId = "",
    categoryName = "",
    termName = "",
    termId = "",
  } = params || {};
  const commentsList = useSelector((state) => state.commentsList.commentsList);
  const [firstElement = []] = commentsList || [];
  const { response = [], status = "Loading" } = firstElement;
  const [firstCommentArray = []] = response || [];
  const { Answer = [], question = [] } = firstCommentArray;

  let reStructureAnswer = [];
  reStructureAnswer = Answer.map((item, index) => {
    let reOrderValue = {
      ...item,
    };
    return reOrderValue;
  });

  const [questionElement = []] = question;
  const {
    categories_id: categoryId = "",
    comment = "",
    definition = "",
    description = "",
    name = "",
    number_of_children = "",
    ontology_id = "",
    symptoms = [],
    synonyms: synonym = [],
    isCompleted = "",
    is_obsolete = "",
    question: questionName = "",
    helful = [],
  } = questionElement;
  const userDetails = useSelector((state) => state.userDetails.userDetails);
  const [firstUserElement = []] = userDetails;
  const { response: userResponse = [], status: userApiStatus = "Loading" } =
    firstUserElement;
  const {
    name: userFirstName = "",
    email = "",
    reputation = "",
    awards = "",
    isVerified = "",
    isRoleVerified = "",
    phone_number = "",
    username = "",
    id: userUUID = "",
    usr_cat_id: userCatId = "",
  } = userResponse || {};

  let currentUserVoted = helful.reduce(
    (currentUserVotedSoFar, { helpful, user_id }) => {
      if (!currentUserVotedSoFar[helpful]) currentUserVotedSoFar[helpful] = [];
      currentUserVotedSoFar[helpful].push(user_id);
      return currentUserVotedSoFar;
    },
    {}
  );
  let currentUserUpVoted =
    currentUserVoted?.Yes?.length > 0 &&
    currentUserVoted.Yes.filter((item) => item === userUUID);
  let currentUserDownVoted =
    currentUserVoted?.No?.length > 0 &&
    currentUserVoted.No.filter((item) => item === userUUID);

  const {
    openBackdrop,
    setOpenBackdrop,
    handleBackdropOpen,
    handleBackdropClose,
    openModelPopup,
    setOpenModelPopup,
    handleModelPopupClose,
    handleModelPopupOpen,
    openToast,
    setOpenToast,
    handleOpenToast,
    handleCloseToast,
    vertical,
    setVertical,
    horizontal,
    setHorizontal,
    message,
    setMessage,
    severityNote,
    setSeverityNote,
    showSymptom,
    triggerShowSymptom,
    screenRerender,
    setScreenRerender,
    showSynonym,
    triggerShowSynonym,
    showSymptomAndSynonyms,
  } = useTermDetailsStates();

  const [showIKnowSomeOne, setShowIKnowSomeOne] = React.useState(false);
  const [showCommonIllness, setShowCommonIllness] = React.useState(false);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(clearTermsReduxSession());
    dispatch(getUserDetails());
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(getComments(termId));
  }, [screenRerender]);

  const snackBarProps = {
    openToast,
    handleOpenToast,
    handleCloseToast,
    vertical,
    horizontal,
    message,
    severityNote,
  };
  const headerProps = {
    showLogo: false,
    // title: termName.replaceAll("-", " "),
    title: "Discourse on this topic",
    showBackIcon: true,
    floatLeft: true,
    showMenu: false,
    showBookmark: true,
    showShare: true,
    backTo: "",
  };

  const sendPostHelpful = (value) => {
    const postHelpfulProps = {
      termId: termId,
      categoryId: categoryId,
      userUUID,
      value: value,
      screenRerender,
      setScreenRerender,
      setMessage,
      setSeverityNote,
      handleOpenToast,
    };
    postHelpfulApi({ ...props, ...postHelpfulProps });
  };

  const triggerHelpful = (value) => {
    if (isRoleVerified) {
      sendPostHelpful(value);
    } else {
      handleOpenToast();
      setSeverityNote("warning");
      setMessage(
        "Your profile category is not verified yet. Please verify it to like/dislike."
      );
    }
  };

  const termForumProps = {
    setMessage,
    setSeverityNote,
    handleOpenToast,
    handleCloseToast,
    reStructureAnswer,
    status,
    userUUID,
    userCatId,
    screenRerender,
    setScreenRerender,
    isRoleVerified,
    categoryId,
  };
  const skeletonLoaderProps = {
    variant: "rectangular",
    width: "100%",
    height: 100,
  };
  const skeletonTextLoaderProps = {
    variant: "text",
    width: "60%",
    height: 60,
  };

  const triggerNewPopUp = () => {
    setShowIKnowSomeOne(true);
  };
  const handleTriggerNewPopUpClose = () => {
    setShowIKnowSomeOne(false);
  };

  const triggerIllnessPopUp = () => {
    setShowCommonIllness(true);
  };
  const handleTriggerIllnessPopUpClose = () => {
    setShowCommonIllness(false);
  };

  const iKnowSomeOneProps = {
    options: symptoms,
    handleTriggerNewPopUpClose,
    setMessage,
    setSeverityNote,
    handleOpenToast,
    handleCloseToast,
    screenRerender,
    setScreenRerender,
    categoryId,
    userCatId,
  };

  const componentPopupProps = {
    openModelPopup: showIKnowSomeOne,
    handleModelPopupClose: handleTriggerNewPopUpClose,
    handleModelPopupOpen: triggerNewPopUp,
    modalTitle: "",
    subTitle: "",
    renderComponent: <IKnowSomeOne {...iKnowSomeOneProps} />,
    showCloseIcon: true,
    dynamicClassName: "iknowsomeone",
  };

  const commonIllnessProps = {
    options: symptoms,
    handleTriggerIllnessPopUpClose,
    setMessage,
    setSeverityNote,
    handleOpenToast,
    handleCloseToast,
    screenRerender,
    setScreenRerender,
    categoryId,
    userCatId,
  };

  const commonIllnessPopupProps = {
    openModelPopup: showCommonIllness,
    handleModelPopupClose: handleTriggerIllnessPopUpClose,
    handleModelPopupOpen: triggerIllnessPopUp,
    modalTitle: "",
    subTitle: "",
    renderComponent: <CommonIllness {...commonIllnessProps} />,
    showCloseIcon: true,
    dynamicClassName: "iknowsomeone",
  };

  const showSymptomProps = {
    sympSynText: "Symptoms:",
    sympSyn: symptoms,
    showAll: showSymptom,
    triggerShowSymptomAndSynonyms: triggerShowSymptom,
  };
  const showSynonymsProps = {
    sympSynText: "Synonyms:",
    sympSyn: synonym,
    showAll: showSynonym,
    triggerShowSymptomAndSynonyms: triggerShowSynonym,
  };
  return (
    <>
      <Header {...headerProps} />
      <ComponentsLib.ComponentPopup {...componentPopupProps} />
      <ComponentsLib.ComponentPopup {...commonIllnessPopupProps} />
      <StyledDOM.StyledMainFieldsWrapper>
        {status == "Loading" ? (
          <StyledDOM.StyledGridLoader>
            <ComponentsLib.SkeletonLoader {...skeletonLoaderProps} />
          </StyledDOM.StyledGridLoader>
        ) : (
          <StyledDOM.StyledGrid>
            <StyledDOM.StyledTitle variant="h4">
              <span>
                {name
                  ? name
                  : questionName
                  ? questionName
                  : "No title available"}
              </span>
              {/* <span>
                <InfoIcon sx={{ fontStyle: "italic", color: "#575757" }} />
              </span> */}
            </StyledDOM.StyledTitle>
            {/* <StyledDOM.StyledSubTitle variant="h6">
              {Utils.getCurrentDate()}
            </StyledDOM.StyledSubTitle> */}
            <StyledDOM.StyledContentGrid>
              <p>
                {description
                  ? description
                  : definition
                  ? definition
                  : "No Description Available"}
              </p>
            </StyledDOM.StyledContentGrid>

            {symptoms?.length == 0 && synonym?.length > 0 && (
              <>{showSymptomAndSynonyms({ ...showSynonymsProps })}</>
            )}
            {symptoms?.length > 0 && (
              <>{showSymptomAndSynonyms({ ...showSymptomProps })}</>
            )}
            {symptoms?.length > 0 && (
              <StyledDOM.StyledIKnowSomeoneGrid>
                <Button onClick={triggerNewPopUp}>I Know Someone</Button>
                <Button onClick={triggerIllnessPopUp}>Common Symptoms</Button>
              </StyledDOM.StyledIKnowSomeoneGrid>
            )}
            <StyledDOM.StyledBox>
              Helpful?
              {currentUserUpVoted?.length > 0 ? (
                <span>
                  <ThumbUpIcon sx={{ fontSize: "20px", color: "green" }} />
                </span>
              ) : (
                <span onClick={() => triggerHelpful("Yes")}>
                  <ThumbUpOutlinedIcon sx={{ fontSize: "20px" }} />
                </span>
              )}
              <Divider orientation="vertical" variant="middle" flexItem />
              {currentUserDownVoted?.length > 0 ? (
                <span>
                  <ThumbDownIcon sx={{ fontSize: "20px", color: "#FF5C3E" }} />
                </span>
              ) : (
                <span onClick={() => triggerHelpful("No")}>
                  <ThumbDownOutlinedIcon sx={{ fontSize: "20px" }} />
                </span>
              )}
            </StyledDOM.StyledBox>
          </StyledDOM.StyledGrid>
        )}
        <StyledDOM.StyledContributionGrid>
          {status == "Loading" ? (
            <StyledDOM.StyledGridLoader>
              <ComponentsLib.SkeletonLoader {...skeletonTextLoaderProps} />
            </StyledDOM.StyledGridLoader>
          ) : (
            <StyledDOM.StyledContributionTitle>
              Contributions({Answer?.length ? Answer?.length : 0})
            </StyledDOM.StyledContributionTitle>
          )}
          <StyledDOM.StyledForumGrid>
            <TermForum {...termForumProps} />
          </StyledDOM.StyledForumGrid>
        </StyledDOM.StyledContributionGrid>
        <ComponentsLib.ToastSnackbar {...snackBarProps} />
      </StyledDOM.StyledMainFieldsWrapper>
    </>
  );
}

export { TermDetails };
export default TermDetails;
