import React from "react";
import * as StyledDOM from "../styles";
import * as ComponentsLib from "@componentsLib";
import * as Utils from "@utils";
import CircularProgress from "@mui/material/CircularProgress";
import { Link } from "react-router-dom";

const SignUpInfo = (props) => {
  const { navigateTo = () => "" } = props || {};
  const [timer, setTimer] = React.useState(5);
  const id = React.useRef(null);
  const clear = () => {
    window.clearInterval(id.current);
  };
  React.useEffect(() => {
    id.current = window.setInterval(() => {
      setTimer((time) => time - 1);
    }, 1000);
    return () => clear();
  }, []);

  React.useEffect(() => {
    if (timer === 0) {
      clear();
    }
  }, [timer]);

  React.useEffect(() => {
    setTimeout(() => {
      navigateTo("/");
    }, 5000);
  }, []);

  return (
    <>
      <StyledDOM.StyledSubHeadingOne>{`You have successfully signed up for curator app!`}</StyledDOM.StyledSubHeadingOne>
      <StyledDOM.StyledSubHeadingSuccessTwo>
        Please login to continue.
        <br />
        {timer > 0 ? (
          `You will redirect to login page in ${timer} seconds.`
        ) : (
          <>
            Click here to{" "}
            <Link to="/login">{Utils?.staticText?.login?.button}</Link>
          </>
        )}
      </StyledDOM.StyledSubHeadingSuccessTwo>
    </>
  );
};

export { SignUpInfo };
export default SignUpInfo;
