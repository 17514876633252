/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import * as IconGallery from "@icongallery";
import * as ComponentsLib from "@componentsLib";
import * as StyledDOM from "./styles";
import * as Utils from "@utils";

import { Amplify, Auth, Hub } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { Navigate, useNavigate } from "react-router-dom";

//redux
import { connect, useDispatch, useSelector } from "react-redux";
import { Button, Grid } from "@mui/material";

import awsconfig from "../../../aws-exports";
// You can get the current config object
import {
  registerUser,
  loginUser,
  getUserDetails,
  clearReduxSession,
} from "../../../redux/actions";
Auth.configure(awsconfig);

const SocialLogin = (props) => {
  const { dottedText = "Or Login with" } = props || {};
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const [user, setUser] = useState(null);

  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          Utils.initialStorage(data);
          setUser(data);
          dispatch(
            loginUser({
              username: data?.username,
              email: data?.attributes?.email,
              navigateTo,
            })
          );
          dispatch(getUserDetails());
          navigateTo("/");
          break;
        case "cognitoHostedUI":
          getUser().then((userData) => {
            setUser(userData);
            const { signInUserSession = {}, username = "" } = userData || {};
            const { idToken = {} } = signInUserSession || {};
            const { payload = {} } = idToken || {};
            const {
              email = "",
              family_name = "",
              given_name = "",
              name = "",
              picture = "",
              sub = "",
              phone_number = "+917708121581",
            } = payload || {};
            const usersSignupProps = {
              fullName: given_name + " " + family_name,
              phoneNumber: phone_number,
              email,
              userSub: sub,
              name: name,
              username: username,
              profile_url: picture,
              loginFrom: "sso",
            };
            dispatch(registerUser(usersSignupProps));
          });

          break;
        case "signOut":
          setUser(null);
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          console.log("Sign in failure", data);
          break;
      }
    });

    getUser().then((userData) => setUser(userData));
  }, []);

  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then((userData) => userData)
      .catch(() => console.log("Not signed in"));
  }

  const onSingleSignOn = () => {
    Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
  };

  return (
    <>
      <StyledDOM.StyledGrid className="social-media-login">
        <StyledDOM.StyledButton onClick={onSingleSignOn}>
          <IconGallery.GoogleIcon width="18px" height="18px" />
          <StyledDOM.StyledSpan>Continue with Google</StyledDOM.StyledSpan>
        </StyledDOM.StyledButton>
        {/* <StyledDOM.StyledDottedWrapper>
          {dottedText}
        </StyledDOM.StyledDottedWrapper> */}
        <StyledDOM.StyledDottedWrapper>
          <span className="dot"></span>
          <span className="text">{dottedText}</span>
          <span className="dot"></span>
        </StyledDOM.StyledDottedWrapper>
      </StyledDOM.StyledGrid>
    </>
  );
};

export { SocialLogin };
export default SocialLogin;
