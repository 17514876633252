import { styled } from "@mui/material/styles";
import { Tabs, Tab, Typography, Box, Grid, Avatar } from "@mui/material";
import { Link } from "react-router-dom";

export const StyledTabs = styled(Tabs)`
  font-family: "Manrope", sans-serif;
  .MuiTabs-indicator {
    background-color: #26867d;
  }
`;
export const StyledTab = styled(Tab)`
  font-family: "Manrope", sans-serif;
  text-transform: capitalize;
  color: #9a999a;
  font-size: 16px;
  font-weight: 700;
  &.MuiButtonBase-root.MuiTab-root.MuiTab-fullWidth.Mui-selected {
    color: #26867d;
    font-size: 14px;
  }
  @media (max-width: 300px) {
   font-size: 14px;
  }
   &.MuiButtonBase-root.MuiTab-root.MuiTab-fullWidth.Mui-selected {
    color: #26867d;
    font-size: 12px;
  }
`;
export const StyledTypography = styled(Typography)`
  font-family: "Manrope", sans-serif;
`;
export const StyledBox = styled(Box)`
  font-family: "Manrope", sans-serif;
`;
export const StyledCount = styled("span")`
  font-family: "Manrope", sans-serif;
  margin-left: 8px;
  background: rgb(255, 208, 22);
  color: rgb(15, 15, 15);
  height: 24px;
  width: 24px;
  border-radius: 4px;
  padding-top: 4px;
  font-size: 14px;  
  font-weight: 900;
}
`;

export const StyledOuterGrid = styled(Grid)`
  font-family: "Manrope", sans-serif;
  font-size: 12px;
  padding: 10px;
  &.active {
    background-color: #b0f4ed2e;
  }
  em {
    font-weight: 900;
  }
`;
export const StyledLayerOneGrid = styled(Grid)``;
export const StyledLayerTwoGrid = styled(Grid)``;
export const StyledLink = styled(Link)`
  padding: 10px 20px;
  border: 1px solid;
  border-radius: 4px;
  font-weight: 900;
  text-decoration: none;
  margin: 0 auto;
  font-size: 14px;
`;
export const StyledTabPanelGrid = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const StyledTabPanelGridSpan = styled("span")`
  padding: 15px 0px 10px 15px;
  font-size: 12px;
  font-weight: 900;
  color: #7c797a;
  text-transform: uppercase;
`;
export const StyledHr = styled("hr")`
  font-family: "Manrope", sans-serif;
  border: none;
  border-top: 1px solid #ccc;
  color: #fff;
  background-color: #fff;
  width: 100%;
  margin: 0;
`;
export const StyledGrid = styled(Grid)`
  font-family: "Manrope", sans-serif;
  @media only screen and (max-width: 820px) {
    .categoryImage {
      width: auto;
      height: auto;
    }
  }
  @media only screen and (max-width: 420px) {
    .categoryImage {
      width: 42px;
      height: 42px;
    }
  }
`;
export const StyledAvatar = styled(Avatar)`
  font-family: "Manrope", sans-serif;
  height: 50px;
  width: 50px;
`;
