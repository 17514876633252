import React from "react";
import { Outlet, Link } from "react-router-dom";
import * as ComponentsLib from "@componentsLib";
import * as IconGallery from "@icongallery";
import * as Utils from "@utils";
import {
  Forminputprops,
  FormFieldValues,
  validationSchema,
  getValid,
} from "../fixture";
import * as StyledDOM from "../styles";
import { useRegisterStates } from "../useRegisterStates";
import { Grid } from "@mui/material";
import { getUsersListAPI } from "../api";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";

const RegisterForm = (props) => {
  const {
    handleNext,
    formik,
    isBtnLoading,
    setIsBtnLoading,
    reLoadForm,
    formValuesObj,
  } = props || {};

  const { initialValues } = FormFieldValues({ ...formValuesObj });
  const {
    // formik,
    passwordType,
    setPasswordType,
    togglePassword,
    handleBack,
    handleSkip,
    handleReset,
    open,
    setOpen,
    vertical,
    setVertical,
    horizontal,
    setHorizontal,
    message,
    setMessage,
    handleClick,
    handleClose,
  } = useRegisterStates({
    initialValues,
    validationSchema,
    handleNext,
  });

  const { nameProps, phoneNumberProps, emailProps, passwordProps } =
    Forminputprops({
      formik,
      passwordType,
    });

  return (
    <>
      <StyledDOM.StyledSubMainWrapperForm
        key={!reLoadForm}
        onSubmit={formik.handleSubmit}
      >
        <StyledDOM.StyledFieldsWrapper>
          <ComponentsLib.TextField {...nameProps} />
        </StyledDOM.StyledFieldsWrapper>
        <StyledDOM.StyledFieldsWrapper>
          <ComponentsLib.TextField {...phoneNumberProps} />
        </StyledDOM.StyledFieldsWrapper>
        <StyledDOM.StyledFieldsWrapper>
          <ComponentsLib.TextField {...emailProps} />
        </StyledDOM.StyledFieldsWrapper>
        <StyledDOM.StyledFieldsWrapper>
          <ComponentsLib.TextField {...passwordProps} />
          <StyledDOM.StyledViewIcon
            onClick={() => togglePassword()}
            className={!formik.isValid ? `rightMargin` : ``}
          >
            {passwordType === "password" ? (
              <IconGallery.VisibilityOff />
            ) : (
              <IconGallery.VisibilityOn />
            )}
          </StyledDOM.StyledViewIcon>
        </StyledDOM.StyledFieldsWrapper>
        <StyledDOM.StyledButtonWrapper
          // disabled={isBtnLoading || !(formik.dirty && formik.isValid)}
          disabled={isBtnLoading}
          id={"login-button"}
          type={`submit`}
        >
          {isBtnLoading && <CircularProgress disableShrink />}{" "}
          {Utils?.staticText?.register?.signUp}
        </StyledDOM.StyledButtonWrapper>
        <StyledDOM.StyledLoginGrid>
          <StyledDOM.StyledLoginInnerGrid>
            By registering you agree to the <br />{" "}
            <Link target="_blank" to="/terms-and-conditions">
              terms & conditions
            </Link>
          </StyledDOM.StyledLoginInnerGrid>
          <StyledDOM.StyledLoginInnerGridLevelTwo>
            Already a member?{" "}
            <Link to="/login">{Utils?.staticText?.login?.button}</Link>
          </StyledDOM.StyledLoginInnerGridLevelTwo>
        </StyledDOM.StyledLoginGrid>
        {/* <StyledDOM.StyledHrWrapper /> */}
      </StyledDOM.StyledSubMainWrapperForm>
    </>
  );
};

export { RegisterForm };
export default RegisterForm;
