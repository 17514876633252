import { styled } from "@mui/material/styles";
import { Grid, Button, Typography } from "@mui/material";
import { style } from "@mui/system";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

export const StyledMainFieldsWrapper = styled(Grid)`
  font-family: "Manrope", sans-serif;
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;

  label {
    font-size: 12px;
  }

  @media only screen and (max-width: 700px) {
    padding: 0px;
  }
`;
export const StyledSocialIcons = styled(Grid)`
  display: flex;
  align-items: center;
  // margin-top: 15px;
  margin-top: 50px;
  justify-content: center;
  column-gap: 20px;
`;

export const StyledTypography = styled(Typography)`
  font-family: "Manrope", sans-serif;
  font-size: 18px;
  color: #000;
  margin: 0px;
  width: 100%;
  text-align: center;
  font-weight: 900;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const StyledTypographyCopy = styled(Typography)`
  font-family: "Manrope", sans-serif;
  font-size: 11px;
  color: #fff;
  margin: 0px;
  width: 100%;
  text-align: center;
  font-weight: 900;
  display: flex;
  justify-content: space-between;
  background: #000;
  position: absolute;
  bottom: 10px;
  margin: 0 10px;
  padding: 10px;
  width: 88%;
  align-items: center;
  span {
    color: blue;
  }
`;
