// import { Autocomplete } from "@material-ui/lab";
import {
  Grid,
  Typography,
  Button,
  TextField,
  AppBar,
  Autocomplete,
  Popper,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { autocompleteClasses } from "@mui/material/Autocomplete";

export const StyledATag = styled("a")`
  font-family: "Manrope", sans-serif;
  background: #dedede;
  padding: 5px 12px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  border-radius: 6px;
  top: 500px;
  margin: 0 10px 0 0;
`;
export const StyledContentGrid = styled(Grid)`
  font-family: "Manrope", sans-serif;
  padding: 0px;
  &.iknowsomeone {
    margin-top: 50px;
  }
`;
export const StyledTextarea = styled("textarea")`
  font-family: "Manrope", sans-serif;
  width: 100%;
  height: 81px;
  padding: 10px;
  font-family: inherit;
  font-weight: 500;
  background-color: #fbfbfb;
  border-radius: 4px;
  border: 1px solid #dededeb3;
  color: #0f0f0fcc;
  font-size: 14px;
  :focus {
    border-color: #66aaa2;
    outline: none; /* remove default outline */
  }
`;
export const Styledh3 = styled("h3")`
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  color: #0f0f0f;
  font-size: 14px;
  span {
    color: #999393;
  }
`;

export const StyledTextareaDiv = styled("div")`
  font-family: "Manrope", sans-serif;
  display: flex;
  flex-wrap: wrap;
`;
export const SuggetionsDivTag = styled("div")`
  font-family: "Manrope", sans-serif;
  top: 420px;
  margin-left: 0px;
`;
export const SuggetionsnameTag = styled("h4")`
  font-family: "Manrope", sans-serif;
  font-family: "Poppins", sans-serif;
  top: 354px;
  font-size: 15px;
`;
export const StyledPublishButton = styled(Button)`
  font-family: "Manrope", sans-serif;
  color: white;
  background-color: #26867d;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  :hover {
    color: white;
    background-color: #26867d;
  }
  &.Mui-disabled {
    background: #ccc;
  }
  &.MuiButtonBase-root.MuiButton-root:hover {
    background: #ccc;
  }
  .MuiCircularProgress-indeterminate {
    width: 20px !important;
    height: 20px !important;
    margin-right: 10px;
  }
  :disabled {
    background-color: #cccccc !important;
    color: #666666;
  }
`;
export const StyledCancelButton = styled(Button)`
  font-family: "Manrope", sans-serif;
  color: #26867d;
  border: 1px solid #26867d;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  :disabled {
    background-color: #cccccc !important;
    color: #666666;
    border: none !important;
  }
`;
export const StyledAutocomplete = styled(Autocomplete)`
  font-family: "Manrope", sans-serif;
  height: 100%;
  width: 100%;
  background-color: #fbfbfb;
  .MuiChip-root {
    margin: 3px;
    max-width: calc(100% - 6px);
    background: #d4e7e5;
    color: #66aaa2;
    font-weight: 800;
    text-transform: uppercase;
    border-radius: 5px;
  }

  .MuiAutocomplete-endAdornment.css-1q60rmi-MuiAutocomplete-endAdornment
    svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
    opacity: 0;
  }
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-fullWidth {
    padding: 5px;
  }
  svg.MuiSvgIcon-root.MuiChip-deleteIcon {
    color: rgb(0 0 0 / 71%) !important;
  }
  fieldset {
    border-radius: 4px;
  }
`;
export const StyledButtonsDiv = styled("div")`
  font-family: "Manrope", sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 50px;
  width: 96%;
  column-gap: 11px;
    @media (max-width: 300px) {
width: 90%;
  }
`;
export const StyledMainDiv = styled(Grid)`
  font-family: "Manrope", sans-serif;
  height: fit-content !important;
  postion: relative;
  padding: 0 15px;
`;
export const StyledTextfield = styled(TextField)`
  font-family: "Manrope", sans-serif;
  width: 100%;
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: #66aaa2;
    }
  }
`;
export const StyledErrorMsg = styled("div")`
  color: red;
  margin-top: 3px;
  font-size: 13px;
`;
