import React from "react";
import ReactDOM from "react-dom/client";
import * as Utils from "@utils";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PageNavigation from "../components/PageNavigation";
import Login from "../components/Pages/Login";
import Register from "../components/Pages/Register";
import NoPage from "../components/Pages/404";
import Home from "../components/Pages/Home";
import ComingSoon from "../components/Pages/ComingSoon";
import ForgotPassword from "../components/Pages/ForgotPassword";
import ResetPassword from "../components/Pages/ResetPassword";
import SelectUserCategory from "../components/Pages/SelectUserCategory";
import { authPageRouting, privatePageRoutings } from "./fixture";
import _ from "lodash";
import { PrivateRoute } from "./PrivateRoute";
import { AuthRoute } from "./AuthRoute";

const CustomRoutes = () => {
  return (
    <Routes>
      <Route element={<PrivateRoute />}>
        {!_.isEmpty(privatePageRoutings) &&
          privatePageRoutings.map((page, i) => {
            return (
              <Route
                key={i}
                index={page.index}
                path={page.path}
                element={page.element}
              />
            );
          })}
      </Route>
      <Route element={<AuthRoute />}>
        {!_.isEmpty(authPageRouting) &&
          authPageRouting.map((page, i) => {
            return (
              <Route
                key={i}
                index={page.index}
                path={page.path}
                element={page.element}
              />
            );
          })}
      </Route>
    </Routes>
  );
};

export { CustomRoutes };
export default CustomRoutes;
