import { styled } from "@mui/material/styles";
import {
  TextField,
  Grid,
  Autocomplete,
  Typography,
  TextareaAutosize,
} from "@mui/material";
export const StyledTextWrapper = styled(Grid)`
  font-family: "Manrope", sans-serif;
  display: flex;
  .showHide {
    display: block;
    position: absolute;
    right: 5px;
    top: 15px;
    color: #eb5757 !important;
    font-size: 16px;
  }
`;
export const StyledLabelWrapper = styled(Grid)`
  font-family: "Manrope", sans-serif;
  display: flex;
  justify-content: space-between;
`;
export const StyledNoteText = styled(
  Typography
)`font-family: "Manrope", sans-serif;
    font-size: 12px;
    margin-bottom: 8px;
}
`;
export const StyledAutocomplete = styled(Autocomplete)`
  font-family: "Manrope", sans-serif;
  &.cls-error-field {
    fieldset {
      border-color: red !important;
    }
  }

  .MuiAutocomplete-inputRoot {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }
`;
const StyledTextField = styled(TextField)`
  font-family: "Manrope", sans-serif;
  width: 100%;
  // margin-bottom: 10px;
  color: #4f4f4f;

  &.cls-email
    label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl,
  &.cls-fullName
    label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl {
    top: 3px;
  }
  .cls-password-textfield-wrapper
    label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl {
    top: -1px;
  }
  &.cls-password
    .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl {
    top: -1px;
  }
  input {
    color: #4f4f4f !important;
    padding-top: 21px !important;
    padding-right: 12px !important;
    padding-bottom: 4px !important;
    padding-left: 12px !important;
    // padding: 12px 9px;
  }
  .Mui-focused fieldset {
    border-width: 1px !important;
    border-color: #bfbfbf !important;
  }
  fieldset {
    border-width: 1px;
    border-color: #bfbfbf;
  }

  &.disabled {
    input {
      background: #e4e4e4;
    }
  }
  .Mui-error {
    color: #eb5757 !important;
    text-align: left;
    margin-left: 0;
  }

  p.Mui-error {
    position: absolute;
    bottom: -22px;
    left: 0;
  }
`;
const StyledTextAreaField = styled(TextareaAutosize)`
  font-family: "Manrope", sans-serif;
  width: 100%;
  margin-bottom: 10px;
  color: #4f4f4f;
  border: 1px solid grey;
  border-radius: 5px;
  padding: 5px;
  height: 81px !important;
`;
const StyledTextFieldWrapper = styled(Grid)`
  font-family: "Manrope", sans-serif;
  width: 100%;
  margin-bottom: 10px;
  color: #4f4f4f;
  flex-direction: column;
  display: flex;
  border-radius: 4px;
  // padding: 0 10px;

  .react-tel-input .invalid-number-message {
    // font-size: 12px;
    left: 0;
    top: 43px;
    background: #fff;
    padding: 0 2px;
    color: #eb5757 !important;
    width: 100%;
    font-family: Inter, Roboto, "Helvetica", Arial, sans-serif;
    font-weight: 400;
    font-size: 1.1142857142857143rem;
  }

  .Mui-disabled {
    background: #efefef !important;
  }
  p.Mui-disabled {
    background: #fff !important;
  }

  .react-tel-input {
    height: 48px;
    padding-bottom: 0px;
    .flag-dropdown {
      z-index: 999 !important;
      height: 48px;
    }
    &.cls-error-field {
      input,
      .flag-dropdown {
        border-color: red;
      }
    }

    .form-control {
      height: 48px;
      width: 100%;
      background: rgba(0, 0, 0, 0.06);
      border-radius: 4px;
    }
  }

  .cls-error {
    color: #eb5757 !important;
    font-size: 11px;
    margin-top: 3px;
    margin-right: 14px;
    margin-bottom: 0px;
  }
`;
export { StyledTextAreaField, StyledTextField, StyledTextFieldWrapper };
