import React from "react";
import * as StyledDOM from "./styles";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import * as IconGallery from "@icongallery";
import * as ComponentsLib from "@componentsLib";
import _ from "lodash";
import { Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getCategoriesList } from "../../../redux/actions/categoriesList";

import HeartIcon from "../../../assets/images/categories/heart-rate@3x.png";
import HealthIcon from "../../../assets/images/categories/health@3x.png";
import WoundIcon from "../../../assets/images/categories/plaster@3x.png";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "0pt 0pt 3pt 3pt #05100f12",
  borderRadius: "4px",
}));

const skeletonLoaderProps = {
  variant: "text",
  width: "30%",
  height: 30,
};

const rectLoaderProps = {
  variant: "rectangular",
  width: "100%",
  height: 100,
};

const GridListCategory = (props) => {
  const {
    categoryList = [],
    dispatch = () => "",
    navigateTo = () => "",
    showUrl = false,
    home = true,
  } = props || {};
  const objects = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const getListCat = useSelector(
    (state) => state.categoriesList.categoriesList
  );
  const [firstElement = []] = getListCat;
  const { response = [], status = "Loading" } = firstElement;
  React.useEffect(() => {
    dispatch(getCategoriesList());
  }, []);
  const redirectUrl = (item = "") => {
    if (item) {
      navigateTo(
        `/explore/${item.id}/${item.details.name
          .replaceAll(" ", "-")
          .replaceAll("/", "-")
          .toLowerCase()}`
      );
    }
  };
  const errorProps = {
    content: "Categories Not found",
    width: "100%",
    height: 60,
  };
  return (
    <StyledDOM.StyledGridList style={{ marginBottom: home ? "100px" : "30px" }}>
      <StyledDOM.StyledMainHeading>
        {status == "Loading" ? (
          <ComponentsLib.SkeletonLoader {...skeletonLoaderProps} />
        ) : (
          <>{`Categories`}</>
        )}
      </StyledDOM.StyledMainHeading>
      <StyledDOM.StyledSubHeading>
        {status == "Loading" ? (
          <ComponentsLib.SkeletonLoader {...skeletonLoaderProps} />
        ) : (
          <>{`Explore all categories`}</>
        )}
      </StyledDOM.StyledSubHeading>
      <Box sx={{ width: "100%" }}>
        <StyledDOM.StyledGrid
          container
          rowSpacing={2}
          columnSpacing={{ xs: 2, sm: 2, md: 3 }}
        >
          {status == "Loading" ? (
            <>
              {objects.map(function (object, i) {
                return (
                  <StyledDOM.StyledGrid key={i} item xs={4}>
                    <ComponentsLib.SkeletonLoader {...rectLoaderProps} />
                  </StyledDOM.StyledGrid>
                );
              })}
            </>
          ) : (
            <>
              {status == "Error" ? (
                <Grid item xs={12}>
                  <ComponentsLib.ErrorSection {...errorProps} />
                </Grid>
              ) : (
                <>
                  {!_.isEmpty(response) &&
                    response?.map((item, i) => {
                      if (item.details.name) {
                        return (
                          <StyledDOM.StyledGridItem key={i} item xs={4}>
                            <Item
                              onClick={() => {
                                redirectUrl(item);
                              }}
                            >
                              {item.details.image_url ? (
                                <Typography>
                                  <img
                                    className="categoryImage"
                                    src={item.details.image_url}
                                    alt=""
                                  />
                                </Typography>
                              ) : (
                                ""
                              )}
                              <StyledDOM.StyledTypography>
                                {item.details.name.replaceAll("/", "/ ")}
                              </StyledDOM.StyledTypography>
                            </Item>
                          </StyledDOM.StyledGridItem>
                        );
                      }
                    })}
                </>
              )}
            </>
          )}
        </StyledDOM.StyledGrid>
      </Box>
    </StyledDOM.StyledGridList>
  );
};

export { GridListCategory };
export default GridListCategory;
