import React from "react";
import Header from "../../Header";
import * as StyledDom from "./style";
import * as Utils from "@utils";
import { useNavigate, Link } from "react-router-dom";

const TermsAndConditions = () => {
  const navigateTo = useNavigate();
  return (
    <>
      <Header />
      <StyledDom.StyledBox>
        <StyledDom.StyledTypography variant="h4">
          {Utils.capitalizeText("Terms And Conditions")}
        </StyledDom.StyledTypography>
        <StyledDom.StyledTypography variant="h6">
          Page Coming Soon...
        </StyledDom.StyledTypography>
        <Link onClick={() => navigateTo(-1)}>Go Back</Link>
      </StyledDom.StyledBox>
    </>
  );
};

export { TermsAndConditions };
export default TermsAndConditions;
