import { styled } from "@mui/material/styles";
import { Grid, Button, Typography } from "@mui/material";

export const StyledMainHeading = styled("h1")`
  margin-bottom: 10px;
  margin-top: 0;
  text-align: center;
  font-size: 28px;
  font-weight: 600;

  @media only screen and (max-width: 700px) {
    margin-bottom: 0px;
    margin-top: 0;
  }
`;

export const StyledMainFieldsWrapper = styled(Grid)`
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  margin-top: 100px;
  label {
    font-size: 12px;
  }
  @media only screen and (min-width: 768px) {
    width: 60%;
    margin: 0 auto;
    padding: 10px 15px;
  }
`;
