import { get, post } from "../../../../helpers/api_helper";
import * as url from "../../../../helpers/url_helper";
import * as Utils from "@utils";
import { getUserDetails } from "../../../../redux/actions";

export const updateUserCategory = (props) => {
  const { type = "", payload = {}, userDetails = {} } = props || {};
  const { id = "" } = userDetails || {};
  const {
    userCategory = "",
    handleOpenToast = () => "",
    setMessage = () => "",
    setSeverityNote = () => "",
    handleModelPopupClose = () => "",
    setIsBtnLoading = () => "",
    dispatch = () => "",
  } = payload || {};

  post(url.POST_USER_CATEGORY, {
    category_id: userCategory,
    user_id: id,
  })
    .then((response) => {
      const {
        code = "",
        message = "User Category updated successfully!",
        result = [],
      } = response || {};
      dispatch(getUserDetails());
      handleModelPopupClose();
      handleOpenToast();
      setSeverityNote("success");
      setMessage("User Category updated successfully!");
      setIsBtnLoading(false);
    })
    .catch((error) => {
      const { response = {} } = error || {};
      const { data = {} } = response || {};
      const { code = "", message = Utils?.staticText?.apiResponseMsg?.error } =
        data || {};
      handleOpenToast();
      setSeverityNote("error");
      setMessage(message);
      setIsBtnLoading(false);
    });
};
