import { styled } from "@mui/material/styles";
import { Grid, Button, Typography } from "@mui/material";

export const StyledMainHeading = styled("h1")`
  font-family: "Manrope", sans-serif;
  margin-bottom: 10px;
  margin-top: 0;
  text-align: center;
  font-size: 28px;
  font-weight: 900;

  @media only screen and (max-width: 700px) {
    margin-bottom: 0px;
    margin-top: 0;
  }
`;
export const StyledSubHeading = styled("h5")`
  font-family: "Manrope", sans-serif;
  font-size: 12px;
  color: #9a999a;
  margin: 10px 0 30px;
  text-align: center;
`;
export const StyledViewIcon = styled(Grid)`
  font-family: "Manrope", sans-serif;
  position: absolute;
  right: 12px;
  top: 12px;
  &.rightMargin {
    right: 22px !important;
  }
`;

export const StyledFieldsWrapper = styled(Grid)`
  font-family: "Manrope", sans-serif;
  display: flex;
  position: relative;

  .MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline:before,
  .MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline:after {
    border-bottom: 0px;
  }
  .MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.Mui-focused {
    border: 1px solid #26867d;
  }
  .MuiTextField-root p.Mui-error {
    position: relative;
    bottom: 0;
  }
  .cls-tenant fieldset {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0px;
  }
  .cls-cls-firstname-textfield-wrapper {
    width: 48% !important;
  }
  .cls-cls-lastname-textfield-wrapper {
    width: 48% !important;
    margin-right: 20px;
  }

  @media only screen and (max-width: 700px) {
    flex-direction: column;
    .cls-cls-firstname-textfield-wrapper,
    .cls-cls-lastname-textfield-wrapper {
      width: 100% !important;
    }
    .cls-cls-zipcode-textfield-wrapper {
      margin-top: 20px;
    }
  }
`;

export const StyledMainFieldsWrapper = styled(Grid)`
  font-family: "Manrope", sans-serif;
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;

  label {
    font-size: 12px;
  }

  @media only screen and (max-width: 700px) {
    padding: 10px 15px;
  }
`;

export const StyledSubMainWrapperForm = styled("form")`
  font-family: "Manrope", sans-serif;
  @media only screen and (max-width: 700px) {
    height: calc(100% - 95px);
  }
`;

export const StyledButtonWrapper = styled(Button)`
  font-family: "Manrope", sans-serif;
  width: 100%;
  text-transform: capitalize;
  background: #26867d;
  border-radius: 4px;
  color: #ffffff;
  padding: 7px;
  font-size: 18px;
  font-weight: 900;
  &.Mui-disabled {
    background: #ccc;
  }
  &:hover {
    background-color: #1b2330;
  }
  &.MuiButtonBase-root.MuiButton-root:hover {
    background: #ccc;
  }
  .MuiCircularProgress-indeterminate {
    width: 20px !important;
    height: 20px !important;
    margin-right: 10px;
  }
`;

export const StyledHrWrapper = styled("hr")`
  margin: 20px 0;
`;

export const StyledRegisterGrid = styled(Grid)`
  font-family: "Manrope", sans-serif;
  text-align: center;
  margin: 30px 0 0;
  font-size: 12px;
  font-weight: 500;
  color: #7c797a;
  a {
    font-size: 14px;
    font-weight: 900;
  }
  span {
    font-size: 14px;
    font-weight: 900;
  }
`;

export const StyledRegisterResendGrid = styled(Grid)`
  font-family: "Manrope", sans-serif;
  text-align: center;
  span {
    font-size: 14px;
    font-weight: 900;
  }
`;

export const StyledForgotGrid = styled(Grid)`
  font-family: "Manrope", sans-serif;
  text-align: right;
  margin: -10px 10px 15px 10px;
  font-size: 12px;
`;

export const StyledGridOuter = styled(Grid)`
  font-family: "Manrope", sans-serif;
  margin-top: 20px;
  @media only screen and (min-width: 768px) {
    width: 60%;
    margin: 0 auto;
  }

  @media only screen and (max-width: 420px) {
    width: 100%;
    margin: 0 auto;
  }
`;
