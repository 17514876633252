import { styled } from "@mui/material/styles";
import { Grid, Button, Typography } from "@mui/material";

export const StyledHeader = styled(Grid)`
  font-family: "Manrope", sans-serif;
  display: flex;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  box-shadow: 0px 3px 3px #ccc;
  padding: 15px 15px 15px 10px;
  justify-content: space-between;
  position: relative;
  height: 70px;
  align-items: center;
  &.inner {
    padding: 20px 15px 20px 10px;
  }
  @media only screen and (max-width: 820px) {
  }
`;

export const StyledTypography = styled(Typography)`
  font-family: "Manrope", sans-serif;
  font-size: 18px;
  color: #000;
  margin: 0px;
  width: 100%;
  text-align: center;
  font-weight: 900;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
`;

export const StyledTypographyLeft = styled(Typography)`
  font-family: "Manrope", sans-serif;
  font-size: 18px;
  color: #000;
  margin: 0px;
  width: 100%;
  text-align: left;
  font-weight: 900;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: capitalize;
    @media (max-width: 300px) {
    font-size: 16px;
  }
`;

export const StyledSpanIcon = styled("span")`
  display: flex;
  align-items: center;
  &.absolute {
    position: absolute;
  }
`;
export const StyledSpanMyAccountAbsolute = styled("span")`
  position: absolute;
  right: 15px;
`;
