import * as React from "react";
import * as StyledDOM from "./styles";
import * as IconGallery from "@icongallery";
import { Typography, Modal, IconButton, Grid } from "@mui/material";

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: "620px",
//   backgroundColor: "#fff",
//   boxShadow: 24,
//   padding: 4,
//   textAlign: "center",
//   height: "800px",
// };

function ComponentPopup(props) {
  const {
    openModelPopup = true,
    handleModelPopupClose,
    handleModelPopupOpen,
    modalTitle = "",
    subTitle = "",
    renderComponent = () => "",
    showCloseIcon = true,
    dynamicClassName = "",
  } = props || {};

  return (
    <StyledDOM.StyledOuterGrid>
      <Modal
        open={openModelPopup}
        onClose={handleModelPopupClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <StyledDOM.StyledBox className={dynamicClassName}>
          <StyledDOM.StyledTitleTypography
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            {modalTitle}
            {showCloseIcon && (
              <IconButton onClick={handleModelPopupClose}>
                <IconGallery.ClosePopUpIcon />
              </IconButton>
            )}
          </StyledDOM.StyledTitleTypography>
          <Grid id="modal-modal-description" sx={{ mt: 2, color: "#9a999a" }}>
            {renderComponent}
          </Grid>
        </StyledDOM.StyledBox>
      </Modal>
    </StyledDOM.StyledOuterGrid>
  );
}

export { ComponentPopup };
export default ComponentPopup;
