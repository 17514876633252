import * as React from "react";
import {
  StyledTextField,
  StyledTextFieldWrapper,
  StyledLabelWrapper,
  StyledNoteText,
  StyledTextWrapper,
  StyledTextAreaField,
} from "./styles";
import * as ComponentsLib from "@componentsLib";
import PhoneInput from "react-phone-input-2";
import TextDropdown from "./TextDropdown";
import "react-phone-input-2/lib/style.css";
import InputAdornment from "@mui/material/InputAdornment";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { Grid } from "@mui/material";

function TextField(props) {
  const [countryCode, setCountryCode] = React.useState("us");
  const {
    isEnableLabel = false,
    labelname = "",
    className = "",
    isRequired = false,
    type = "",
    note = "",
    disabled = false,
    renderLabelChildren = () => "",
    disableDropdown = false,
    disableCountryCode = false,
    validatePhoneNumber = () => "",
    rows = "20",
    innTextAreastyle = {},
    variant = "filled",
    formik = () => "",
  } = props;

  const StyledTextFieldProps = {
    ...props,
  };

  const { name, country, onChange, helpertext, error, defaultValue } =
    StyledTextFieldProps;

  React.useEffect(() => {
    if (defaultValue == "") {
      fetch("https://ipapi.co/json/")
        .then((res) => res.json())
        .then((response) => {
          const { country_code = "us" } = response || {};
          setCountryCode(country_code.toLowerCase());
        })
        .catch((data, status) => {
          console.log("Request failed:", data);
        });
    }
  }, [defaultValue]);
  return (
    <StyledTextFieldWrapper
      className={`cls-textfield-wrapper cls-${className}-textfield-wrapper`}
    >
      {isEnableLabel && (
        <StyledLabelWrapper className="cls-StyledLabelWrapper-text">
          <ComponentsLib.Label
            className={"cls-sub-label"}
            Labelname={labelname}
            isrequired={isRequired}
          />
          {note.length > 0 && <StyledNoteText>{note}</StyledNoteText>}
          {renderLabelChildren()}
        </StyledLabelWrapper>
      )}
      {type === "phone" ? (
        <>
          <PhoneInput
            disableCountryCode={disableCountryCode}
            disableDropdown={disableDropdown}
            className={`${error ? "cls-error-field" : ""}`}
            name={name}
            country={countryCode}
            onChange={onChange}
            value={defaultValue}
            disabled={disabled ? true : false}
            // onlyCountries={["us"]}
            // isValid={(value, country) => {
            //   if (!value.match(/1/)) {
            //     validatePhoneNumber(true);
            //     return "Please enter valid US phone number";
            //   } else if (!value.match(/1/)) {
            //     validatePhoneNumber(false);
            //     return false;
            //   } else {
            //     validatePhoneNumber(false);
            //     return true;
            //   }
            // }}
          />
          {error && <p className={"cls-error"}>{helpertext}</p>}
        </>
      ) : type === "textdropdown" ? (
        <>
          <TextDropdown
            {...StyledTextFieldProps}
            className={`${error ? "cls-error-field" : ""}`}
            disabled={disabled}
          />
          {error && <p className={"cls-error"}>{helpertext}</p>}
        </>
      ) : type === "textarea" ? (
        <Grid className={`cls-${className}`}>
          <StyledTextWrapper>
            <StyledTextAreaField
              {...StyledTextFieldProps}
              {...innTextAreastyle}
              rows={rows}
            />
          </StyledTextWrapper>
          {error && <p className={"cls-error"}>{helpertext}</p>}
        </Grid>
      ) : (
        <Grid className={`cls-${className}`}>
          <StyledTextWrapper>
            <StyledTextField
              {...StyledTextFieldProps}
              label={labelname}
              variant={variant}
            />
            {!formik?.isValid && !formik?.values[name] ? (
              <PriorityHighIcon className="showHide" />
            ) : (
              ""
            )}
          </StyledTextWrapper>
          {error && <p className={"cls-error"}>{helpertext}</p>}
        </Grid>
      )}
    </StyledTextFieldWrapper>
  );
}

export { TextField };
export default TextField;
