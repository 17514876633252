import React from "react";
import { Auth } from "aws-amplify";
import { generateOtp, verifyOtp, verifyEmailApi } from "./api";
import * as Utils from "@utils";

function useRegisterStates(props) {
  const {
    navigateTo = () => "",
    emailId = "",
    uuid,
    userSub,
    // setActiveStep = () => "",
  } = props || {};

  const [isBtnLoading, setIsBtnLoading] = React.useState(false);
  const [passwordType, setPasswordType] = React.useState("password");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [emailVerifyText, setEmailVerifyText] =
    React.useState("Verify Your Email");
  const [emailVerifySubText, setEmailVerifySubText] = React.useState(
    "Please check your email and follow the instructions to verify your account. If you didn't receive an email or if it expired, you can resend one."
  );
  const [disableResendBtn, setDisableResendBtn] = React.useState(false);

  const [activeStep, setActiveStep] = React.useState(0);
  const [activeStepArray, setActiveStepArray] = React.useState(0);
  const [formValuesObj, setFormValuesObj] = React.useState({});
  const [userDynSub, setUserDynSub] = React.useState("");

  /* Snackbar functions */
  const [openToast, setOpenToast] = React.useState(false);
  const [vertical, setVertical] = React.useState("top");
  const [horizontal, setHorizontal] = React.useState("right");
  const [severityNote, setSeverityNote] = React.useState("info");
  const [message, setMessage] = React.useState("");
  const [otpCode, setOtpCode] = React.useState("");

  const handleOpenToast = () => {
    setOpenToast(true);
  };

  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
  };
  /*snackbar end */

  const togglePassword = () => {
    passwordType === "password"
      ? setPasswordType("text")
      : setPasswordType("password");
  };

  const handleBackdropClose = () => {
    setOpenBackdrop(false);
  };
  const handleBackdropOpen = () => {
    setOpenBackdrop(true);
  };

  const verifyEmailCode = async (props) => {
    const {
      setSeverityNote = () => "",
      setMessage = () => "",
      setEmailVerifyText = () => "",
      setEmailVerifySubText = () => "",
      setDisableBtn = () => "",
      formValuesObj = {},
      code = "",
      handleOpenToast = () => "",
      setActiveStep = () => "",
      setDisableBtnLoading = () => "",
    } = props || {};
    const {
      email = "",
      fullName = "",
      password = "",
      phoneNumber = "",
    } = formValuesObj;
    try {
      setDisableBtn(true);
      setDisableBtnLoading(false);
      setEmailVerifyText("Verifying email...");
      setEmailVerifySubText("Please wait while verifying email.");
      // verifyEmailApi(props);
      await Auth.confirmSignUp(email, code);
      setActiveStep(2);
    } catch (error) {
      const { message = Utils?.staticText?.apiResponseMsg?.error, name = "" } =
        error || {};
      setEmailVerifyText("Failed to verify email.");
      setEmailVerifySubText("Please try again later.");
      handleOpenToast();
      setSeverityNote("error");
      setMessage(message);
      // handleBackdropClose();
      setDisableBtn(false);
      setDisableBtnLoading(true);
      // setDisableResendBtn(false);
      // if (name === "LimitExceededException") {
      //   setDisableResendBtn(true);
      // }
    }
  };

  const resendTrigger = async (props) => {
    const {
      handleOpenToast = () => "",
      setSeverityNote = () => "",
      setMessage = () => "",
      setIsBtnLoading = () => "",
      formValuesObj = {},
      setOpenBackdrop = () => "",
    } = props || {};
    const {
      email = "",
      fullName = "",
      password = "",
      phoneNumber = "",
    } = formValuesObj || {};
    setOpenBackdrop(true);
    setIsBtnLoading(true);
    let localValues = JSON.parse(localStorage.getItem("formValues"));
    try {
      const resendVerification = await Auth.resendSignUp(email);
      if (resendVerification) {
        handleOpenToast();
        setSeverityNote("success");
        setMessage("Code resent successfully");
        setIsBtnLoading(false);
      }
      setOpenBackdrop(false);
    } catch (error) {
      const { message = "Something went wrong. Please try again later" } =
        error || {};
      handleOpenToast();
      setSeverityNote("error");
      setMessage(message);
      setIsBtnLoading(false);
      setOpenBackdrop(false);
    }
  };

  const sendOtp = (props) => {
    const { userDynSub = "" } = props || {};
    const generateOtpProps = {
      phoneNumber,
      emailId,
      userSub: userDynSub,
      setActiveStep,
      handleOpenToast,
      setMessage,
      setSeverityNote,
      otpCode,
      setOtpCode,
      setIsBtnLoading,
    };
    generateOtp(generateOtpProps);
  };

  const verifyPhoneNumber = (props) => {
    handleBackdropOpen();
    const { code = "", phoneNumber = "", userDynSub = "" } = props || {};
    const verifyOtpProps = {
      code,
      phoneNumber,
      emailId,
      userSub: userDynSub,
      navigateTo,
      handleOpenToast,
      setMessage,
      setSeverityNote,
      handleBackdropClose,
      setActiveStep,
    };
    verifyOtp(verifyOtpProps);
  };

  return {
    togglePassword,
    passwordType,
    setPasswordType,
    phoneNumber,
    setPhoneNumber,
    openToast,
    setOpenToast,
    severityNote,
    setSeverityNote,
    vertical,
    setVertical,
    horizontal,
    setHorizontal,
    message,
    setMessage,
    handleOpenToast,
    handleCloseToast,
    openBackdrop,
    setOpenBackdrop,
    handleBackdropOpen,
    handleBackdropClose,
    emailVerifyText,
    setEmailVerifyText,
    emailVerifySubText,
    setEmailVerifySubText,
    verifyEmailCode,
    resendTrigger,
    disableResendBtn,
    setDisableResendBtn,
    sendOtp,
    verifyPhoneNumber,
    otpCode,
    setOtpCode,
    isBtnLoading,
    setIsBtnLoading,
    activeStep,
    setActiveStep,
    activeStepArray,
    setActiveStepArray,
    formValuesObj,
    setFormValuesObj,
    userDynSub,
    setUserDynSub,
  };
}
export { useRegisterStates };
export default useRegisterStates;
