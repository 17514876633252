import { styled } from "@mui/material/styles";
import {
  Grid,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
  Alert,
} from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";

export const StyledViewIcon = styled(Grid)`
  font-family: "Manrope", sans-serif;
  position: absolute;
  right: 12px;
  top: 12px;
  &.rightMargin {
    right: 22px !important;
  }
`;

export const StyledFieldsWrapper = styled(Grid)`
  font-family: "Manrope", sans-serif;
  display: flex;
  position: relative;
  .MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline:before,
  .MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline:after {
    border-bottom: 0px;
  }
  .MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.Mui-focused {
    border: 1px solid #26867d;
  }
  .MuiTextField-root p.Mui-error {
    position: relative;
    bottom: 0;
  }
  .cls-tenant fieldset {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0px;
  }
  .cls-cls-firstname-textfield-wrapper {
    width: 48% !important;
  }
  .cls-cls-lastname-textfield-wrapper {
    width: 48% !important;
    margin-right: 20px;
  }
  @media only screen and (max-width: 700px) {
    flex-direction: column;
    .cls-cls-firstname-textfield-wrapper,
    .cls-cls-lastname-textfield-wrapper {
      width: 100% !important;
    }
    .cls-cls-zipcode-textfield-wrapper {
      margin-top: 20px;
    }
  }
`;

export const StyledSubMainWrapperForm = styled("form")`
  font-family: "Manrope", sans-serif;
  @media only screen and (max-width: 700px) {
    height: calc(100% - 95px);
  }
`;

export const StyledButtonWrapper = styled(Button)`
  font-family: "Manrope", sans-serif;
  width: 100%;
  text-transform: capitalize;
  background: #26867d;
  border-radius: 4px;
  color: #ffffff;
  padding: 10px;
  font-size: 18px;
  font-weight: 900;
  &.Mui-disabled {
    background: #ccc;
  }
  &:hover {
    background-color: #1b2330;
  }
  &.MuiButtonBase-root.MuiButton-root:hover {
    background: #ccc;
  }
  .MuiCircularProgress-indeterminate {
    width: 20px !important;
    height: 20px !important;
    margin-right: 10px;
  }
`;

export const StyledGridOuter = styled(Grid)`
  font-family: "Manrope", sans-serif;
  margin-top: 20px;
  @media only screen and (max-width: 820px) {
  }

  @media only screen and (max-width: 420px) {
    padding: 0px;
  }
`;

export const StyledInfoOuter = styled(Grid)`
  font-family: "Manrope", sans-serif;
  font-size: 11px;
  color: #7c797a;
  line-height: 20px;
  @media only screen and (max-width: 820px) {
    margin-bottom: 100px;
  }

  @media only screen and (max-width: 912px) {
    margin-bottom: 100px;
  }
  @media only screen and (max-width: 430px) {
    margin-bottom: 100px;
  }
`;

export const StyledNote = styled(Typography)`
  font-family: "Manrope", sans-serif;
  font-size: 11px;
`;

export const StyledOl = styled("ol")`
  font-family: "Manrope", sans-serif;
  padding-inline-start: 12px;
  margin-bottom: 0;
  margin-top: 0;
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  font-family: "Manrope", sans-serif;
  span {
    font-family: "Manrope", sans-serif;
    font-size: 11px;
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  .MuiSvgIcon-root {
    font-size: 35px;
  }
`;

export const StyledOtpField = styled(MuiOtpInput)`
  font-family: "Manrope", sans-serif;
  margin: 0 auto 15px auto;
  gap: 15px;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
  .MuiInputBase-input.MuiOutlinedInput-input {
    padding: 11px 14px;
  }
    @media (max-width: 300px) {
   gap: 5px;
  }
`;
export const StyledAlert = styled(Alert)`
  padding: 5px;
  border: 0px;
  background: #0288d11a;
  margin: 0 0 15px auto;
  .MuiAlert-message {
  }
`;

export const StyledResendBtn = styled("span")`
  display: flex;
  align-items: center;
  justify-content: end;
`;

export const StyledButtonLink = styled(Button)`
  font-family: "Manrope", sans-serif;
  color: #26867d;
  font-weight: 700;
  background: none;
  margin-top: -2px;
`;
