import React from "react";
import * as ComponentsLib from "@componentsLib";
import * as IconGallery from "@icongallery";
import * as Utils from "@utils";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as StyledDOM from "./styles";
import { useCategoryDetailsStates } from "./useCategoryDetailsStates";
import { Grid } from "@mui/material";
import Header from "../../Header";
import Footer from "../../Footer";
import { useDispatch, useSelector } from "react-redux";
import {
  getTrendingTopicsListById,
  getTopicsListById,
  getCategoriesList,
  clearCategoryReduxSession,
  clearCategoryTrendingReduxSession,
} from "../../../../redux/actions";
// import { SearchResult } from "../../../common/SearchResult";

function CategoryDetails(props) {
  const {} = props || {};
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const { categoryName = "", categoryId = "" } = params || {};

  React.useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(clearCategoryReduxSession());
    dispatch(clearCategoryTrendingReduxSession());
    dispatch(getTrendingTopicsListById(categoryId));
    dispatch(getTopicsListById({ id: categoryId }));
    dispatch(getCategoriesList());
  }, []);

  const trendingTopicsListById = useSelector(
    (state) => state.trendingTopicsListById.trendingTopicsListById[0]
  );
  const topicsListById = useSelector(
    (state) => state.topicsListById.topicsListById
  );
  const getListCat = useSelector(
    (state) => state.categoriesList.categoriesList
  );
  const [firstElement = []] = topicsListById;
  const { response = [], status: apiStatus = "Loading" } = firstElement;
  const [firstResponseElement = []] = response;
  const { question = [], categories = [] } = firstResponseElement;
  const {
    openBackdrop,
    setOpenBackdrop,
    handleBackdropOpen,
    handleBackdropClose,
    openModelPopup,
    setOpenModelPopup,
    handleModelPopupClose,
    handleModelPopupOpen,
    openToast,
    setOpenToast,
    handleOpenToast,
    handleCloseToast,
    vertical,
    setVertical,
    horizontal,
    setHorizontal,
    message,
    setMessage,
    severityNote,
    setSeverityNote,
    isTrendingLoading,
    setIsTrendingLoading,
    isTopicLoading,
    setIsTopicLoading,
    openSearchPopup,
    setOpenSearchPopup,
    handleSearchPopupClose,
    handleSearchPopupOpen,
  } = useCategoryDetailsStates({ navigateTo, categoryName });

  const triggerTopicsFilter = (value) => {
    dispatch(getTopicsListById({ id: categoryId, searchTxt: value }));
  };

  // const backDropProps = {
  //   openBackdrop,
  //   setOpenBackdrop,
  //   handleBackdropClose,
  // };
  const snackBarProps = {
    openToast,
    handleOpenToast,
    handleCloseToast,
    vertical,
    horizontal,
    message,
    severityNote,
  };
  const headerProps = {
    showLogo: false,
    title: categoryName.replaceAll("-", " "),
    showBackIcon: true,
    floatLeft: true,
    // showMenu: false,
    // showBookmark: true,
    // showShare: true,
    backTo: "/explore",
  };

  const searchinputprops = {
    placeholder: "Search for topics and categories...",
    // onChange: onHandleSearch,
    disableSearchBar: true,
    handleSearchPopupClose,
    handleSearchPopupOpen,
  };
  const trendingTopicsSliderProps = {
    topicsItems: trendingTopicsListById,
    navigateTo,
    title: "Topic Suggestions",
    subTitle: "Curated picks in this topic",
    getListCat,
  };
  const topicsSliderProps = {
    openBackdrop,
    topicsItems: question,
    navigateTo,
    showCarousel: false,
    title: "Topics",
    subTitle: "All topics in",
    status: apiStatus,
    showFilter: true,
    triggerTopicsFilter,
  };
  // const searchResultProps = {
  //   handleSearchPopupClose,
  //   handleSearchPopupOpen,
  //   showCategoryList: false,
  // };
  // const componentPopupProps = {
  //   openModelPopup: openSearchPopup,
  //   handleModelPopupClose,
  //   handleModelPopupOpen,
  //   modalTitle: "",
  //   subTitle: "",
  //   renderComponent: <SearchResult {...searchResultProps} />,
  //   showCloseIcon: false,
  //   dynamicClassName: "searchResult",
  // };
  return (
    <StyledDOM.StyledMainDiv>
      <Header {...headerProps} />
      {/* <ComponentsLib.BackDrop {...backDropProps} /> */}
      <StyledDOM.StyledMainFieldsWrapper>
        <ComponentsLib.Search {...searchinputprops} />
        {/* {openSearchPopup && (
          <ComponentsLib.ComponentPopup {...componentPopupProps} />
        )} */}
        <ComponentsLib.TrendingTopicsSlider {...trendingTopicsSliderProps} />
        <ComponentsLib.TopicsSlider {...topicsSliderProps} />
        <ComponentsLib.ToastSnackbar {...snackBarProps} />
      </StyledDOM.StyledMainFieldsWrapper>
      <Footer />
    </StyledDOM.StyledMainDiv>
  );
}

export { CategoryDetails };
export default CategoryDetails;
