import React from "react";
import * as StyledDOM from "../styles";

const OtpForm = (props) => {
  const {
    customClass = "",
    value = "",
    handleChange = () => "",
    handleComplete = () => "",
  } = props || {};

  return (
    <StyledDOM.StyledOtpField
      className={customClass}
      value={value}
      onChange={handleChange}
      onComplete={handleComplete}
      length={6}
      TextFieldsProps={{
        type: "number",
      }}
      onKeyDown={(evt) =>
        (evt.key === "e" || evt.key === "E") && evt.preventDefault()
      }
    />
  );
};

export { OtpForm };
export default OtpForm;
