import React from "react";
import * as StyledDOM from "./styles";
import * as IconGallery from "@icongallery";
import * as Utils from "@utils";
import * as ComponentsLib from "@componentsLib";
import _ from "lodash";
// import Carousel from "react-grid-carousel";
import Carousel from "react-elastic-carousel";
import InfoIcon from "../../../assets/images/infoicon.png";
import { useParams } from "react-router-dom";
import TopicsFilter from "./TopicsFilter";
import { useSelector } from "react-redux";

import AvatarGroup from "@mui/material/AvatarGroup";
import { Grid } from "@mui/material";
import { Fade } from "react-reveal";

const TopicsSlider = (props) => {
  const {
    topicsItems = [],
    navigateTo = () => "",
    title = "For You",
    subTitle = "Recommended Topics",
    showCarousel = true,
    status = "Loading",
    showFilter = false,
    triggerTopicsFilter = () => {},
  } = props || {};
  const params = useParams();
  const { categoryId = "", categoryName = "" } = params || {};
  const objects = [1, 2, 3, 4];

  const [trendingTopicsArrayList, setTrendingTopicsArrayList] = React.useState(
    []
  );
  const [screenRerender, setScreenRerender] = React.useState(false);
  const [topicsLoading, setTopicsLoading] = React.useState(false);

  const getListCat = useSelector(
    (state) => state.categoriesList.categoriesList
  );
  const [firstElement = []] = getListCat;
  const { response = [] } = firstElement;

  let trendingTopicsArray = [];
  trendingTopicsArray = topicsItems.map((item, index) => {
    const {
      id = "",
      definition = "",
      description = "",
      synonym = [],
      category_id = "",
      categories_id = "",
      frequency = "",
      isCompleted = "",
      ontologyId = "",
      onset = "",
      sources = "",
      name = "",
      question: questionName = "",
      question_id = "",
      users_contributions = [],
    } = item;

    let reOrderValue = {
      id,
      question: name
        ? name
        : questionName
        ? questionName
        : "No title available",
      definition: definition
        ? definition
        : description
        ? description
        : "No Description Available.",
      infoTxt: "",
      profileList: {
        profileViewIcons: users_contributions.slice(0, 3),
        profileViewCount: users_contributions?.length,
      },
      symptoms: synonym,
      category_id,
      frequency,
      isCompleted,
      ontologyId,
      onset,
      sources,
      categories_id,
      question_id,
      users_contributions,
    };
    return reOrderValue;
  });
  // const redirectToCategoryExplore = () => {
  //   navigateTo("/explore");
  // };
  const redirectToTermDetailsUrlTrendingTopics = (item) => {
    let catIdArray =
      response?.length > 0 &&
      response?.filter(
        (catItem) => catItem.id === (item.category_id || item.categories_id)
      );
    const [firstCatItem = []] = catIdArray;
    const { id = "", details = {} } = firstCatItem;
    const { name = "" } = details;
    // if (!_.isEmpty(item.category_id || item.categories_id)) {
    // navigateTo(
    //   `/explore/${id}/${name
    //     .replaceAll(" ", "-")
    //     .replaceAll("/", "-")
    //     .toLowerCase()}/${item.question_id}/${item.question
    //     .replaceAll(" ", "-")
    //     .replaceAll("/", "-")
    //     .toLowerCase()}`
    // );
    // }
    navigateTo(
      `/topicdetails/${item?.question_id}/${item?.question
        .replaceAll(" ", "-")
        .replaceAll("/", "-")
        .toLowerCase()}`
    );
  };
  const redirectToTermDetailsUrl = (item) => {
    // navigateTo(
    //   `/explore/${categoryId}/${categoryName}/${item.id}/${item.question
    //     .replaceAll(" ", "-")
    //     .replaceAll("/", "-")
    //     .toLowerCase()}`
    // );
    navigateTo(
      `/topicdetails/${item?.id}/${item?.question
        .replaceAll(" ", "-")
        .replaceAll("/", "-")
        .toLowerCase()}`
    );
  };
  const skeletonLoaderProps = {
    variant: "text",
    width: "30%",
    height: 30,
  };
  const roundLoaderProps = {
    variant: "rounded",
    width: "100%",
    height: 60,
  };
  React.useEffect(() => {
    if (
      trendingTopicsArray.length > 0 &&
      !_.isEqual(trendingTopicsArray, trendingTopicsArrayList)
    ) {
      setTrendingTopicsArrayList(trendingTopicsArray);
    }
  }, [trendingTopicsArray]);
  const errorProps = {
    content: "No Topic(s) Available",
    width: "100%",
    height: 60,
  };
  const callBackFunc = (value) => {
    setTopicsLoading(true);
    setScreenRerender(!screenRerender);
    setTimeout(() => {
      setTopicsLoading(false);
    }, 1000);
  };
  const topicsFilterProps = {
    triggerTopicsFilter,
    trendingTopicsArrayList,
    callBackFunc,
  };
  const breakPoints = [
    { width: 1, itemsToShow: 1.1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 2, itemToScroll: 2 },
    { width: 1200, itemsToShow: 2, itemToScroll: 2 },
  ];

  return (
    <StyledDOM.StyledTopicsSlider
      style={{ marginBottom: showCarousel === false ? "80px" : "" }}
    >
      <StyledDOM.StyledGrid>
        <StyledDOM.StyledGrid>
          <StyledDOM.StyledSubHeadingOne>
            {status === "Loading" ? (
              <ComponentsLib.SkeletonLoader {...skeletonLoaderProps} />
            ) : (
              <>{title}</>
            )}
          </StyledDOM.StyledSubHeadingOne>
          <StyledDOM.StyledSubHeadingThree>
            {status === "Loading" ? (
              <ComponentsLib.SkeletonLoader {...skeletonLoaderProps} />
            ) : (
              <>
                {subTitle}{" "}
                {Utils.capitalizeText(categoryName).replaceAll("-", " ")}
              </>
            )}
          </StyledDOM.StyledSubHeadingThree>
        </StyledDOM.StyledGrid>
        {/* <StyledDOM.StyledArrowIconBtn onClick={redirectToCategoryExplore}>
            <IconGallery.RightArrowIcon style={{ color: "#67AAA4" }} />
          </StyledDOM.StyledArrowIconBtn> */}
        {showFilter && <TopicsFilter {...topicsFilterProps} />}
      </StyledDOM.StyledGrid>
      {showCarousel && (
        <>
          {status === "Loading" ? (
            <ComponentsLib.SkeletonLoader {...roundLoaderProps} />
          ) : (
            <>
              {status === "Error" ? (
                <ComponentsLib.ErrorSection {...errorProps} />
              ) : (
                // <Carousel
                //   cols={1}
                //   rows={1}
                //   gap={10}
                //   loop
                //   containerStyle={{ margin: "0px 0px 15px -10px" }}
                //   hideArrow={true}
                //   responsiveLayout={[
                //     {
                //       breakpoint: 820,
                //       cols: 2,
                //       rows: 1,
                //       gap: 10,
                //       loop: false,
                //     },
                //     {
                //       breakpoint: 420,
                //       cols: 1,
                //       rows: 1,
                //       gap: 10,
                //       loop: false,
                //     },
                //   ]}
                // >
                <Carousel
                  breakPoints={breakPoints}
                  showArrows={false}
                  isInfiniteLoop={false}
                  enableMouseSwipe={true}
                  pagination={false}
                  itemPadding={[0, 10, 0, 0]}
                  // style={{ margin: "0px 0px 15px -10px" }}
                >
                  {!_.isEmpty(trendingTopicsArrayList) &&
                    trendingTopicsArrayList.map((item, i) => {
                      return (
                        <StyledDOM.StyledCarouselItemDiv
                          key={i}
                          onClick={() =>
                            redirectToTermDetailsUrlTrendingTopics(item)
                          }
                        >
                          <StyledDOM.StyledItemTitle>
                            {Utils.contentLimitByCharacter(item.question, 30)}
                          </StyledDOM.StyledItemTitle>
                          <StyledDOM.StyledItemContent>
                            {Utils.contentLimitByCharacter(item.definition, 65)}
                          </StyledDOM.StyledItemContent>
                          <StyledDOM.StyledHr />
                          <StyledDOM.StyledInfo>
                            {/* <StyledDOM.StyledInfoText>
                                {item.symptoms?.length > 0 ? (
                                  <img
                                    src={InfoIcon}
                                    alt="info"
                                    width="24"
                                    height="24"
                                  />
                                ) : (
                                  ""
                                )}
                                <span>
                                  {item.symptoms?.length > 0
                                    ? item.symptoms[0]
                                    : ""}
                                </span>
                              </StyledDOM.StyledInfoText> */}
                            <StyledDOM.StyledProfileView>
                              <StyledDOM.StyledAvatarGroup max={3}>
                                {item.profileList?.profileViewIcons?.length >
                                  0 &&
                                  item.profileList?.profileViewIcons?.map(
                                    (profileIcon, index) => {
                                      return (
                                        <StyledDOM.StyledAvatar
                                          key={index}
                                          alt={profileIcon.name}
                                          src={profileIcon.profile_image}
                                        />
                                      );
                                    }
                                  )}
                              </StyledDOM.StyledAvatarGroup>
                            </StyledDOM.StyledProfileView>
                            <span className="count">
                              {item.profileList.profileViewCount}
                            </span>
                          </StyledDOM.StyledInfo>
                        </StyledDOM.StyledCarouselItemDiv>
                      );
                    })}
                </Carousel>
              )}
            </>
          )}
        </>
      )}
      {!showCarousel && (
        <>
          {!topicsLoading ? (
            <>
              {status === "Loading" ? (
                <>
                  {objects.map(function (object, i) {
                    return (
                      <StyledDOM.StyledSkeletonGrid key={i} item xs={1}>
                        <ComponentsLib.SkeletonLoader {...roundLoaderProps} />
                      </StyledDOM.StyledSkeletonGrid>
                    );
                  })}
                </>
              ) : (
                <>
                  {status === "Error" ? (
                    <ComponentsLib.ErrorSection {...errorProps} />
                  ) : (
                    <>
                      {!_.isEmpty(trendingTopicsArrayList) &&
                        trendingTopicsArrayList.map((item, i) => {
                          return (
                            <Fade>
                              <StyledDOM.StyledCarouselItem
                                className="listView"
                                key={i}
                                onClick={() => redirectToTermDetailsUrl(item)}
                              >
                                <StyledDOM.StyledItemTitle>
                                  {item.question}
                                </StyledDOM.StyledItemTitle>
                                <StyledDOM.StyledItemContent>
                                  {Utils.contentLimitByCharacter(
                                    item.definition,
                                    75
                                  )}
                                </StyledDOM.StyledItemContent>
                                <StyledDOM.StyledHr />
                                <StyledDOM.StyledInfo>
                                  {/* <StyledDOM.StyledInfoText>
                              {item.symptoms?.length > 0 ? (
                                <img
                                  src={InfoIcon}
                                  alt="info"
                                  width="24"
                                  height="24"
                                />
                              ) : (
                                ""
                              )}
                              <span>
                                {item.symptoms?.length > 0
                                  ? Utils.contentLimitByCharacter(
                                      item.symptoms[0],
                                      45
                                    )
                                  : ""}
                              </span>
                            </StyledDOM.StyledInfoText> */}
                                  <StyledDOM.StyledProfileView>
                                    <StyledDOM.StyledAvatarGroup max={3}>
                                      {item.profileList?.profileViewIcons
                                        ?.length > 0 &&
                                        item.profileList?.profileViewIcons?.map(
                                          (profileIcon, index) => {
                                            return (
                                              <StyledDOM.StyledAvatar
                                                key={index}
                                                alt={profileIcon.name}
                                                src={profileIcon.profile_image}
                                              />
                                            );
                                          }
                                        )}
                                    </StyledDOM.StyledAvatarGroup>
                                  </StyledDOM.StyledProfileView>
                                  <span className="count">
                                    {item.profileList.profileViewCount}
                                  </span>
                                </StyledDOM.StyledInfo>
                              </StyledDOM.StyledCarouselItem>
                            </Fade>
                          );
                        })}
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              {objects.map(function (object, i) {
                return (
                  <StyledDOM.StyledSkeletonGrid key={i} item xs={1}>
                    <ComponentsLib.SkeletonLoader {...roundLoaderProps} />
                  </StyledDOM.StyledSkeletonGrid>
                );
              })}
            </>
          )}
        </>
      )}
    </StyledDOM.StyledTopicsSlider>
  );
};

export { TopicsSlider };
export default TopicsSlider;
