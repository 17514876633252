import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
// import { height } from "@mui/system";

function SkeletonLoader(props) {
  const { variant = "rectangular", width = "100%", height = 60 } = props || {};
  return (
    <Stack spacing={1}>
      {variant == "text" && (
        <Skeleton
          variant="text"
          sx={{ width: { width }, height: { height } }}
        />
      )}
      {variant == "circular" && (
        <Skeleton variant="circular" width={width} height={height} />
      )}
      {variant == "rectangular" && (
        <Skeleton variant="rectangular" width={width} height={height} />
      )}
      {variant == "rounded" && (
        <Skeleton variant="rounded" width={width} height={height} />
      )}
    </Stack>
  );
}
export { SkeletonLoader };
export default SkeletonLoader;
