import React from "react";
import _ from "lodash";
import * as Utils from "@utils";
import * as IconGallery from "@icongallery";

function useFooterStates(props) {
  const menuList = [
    {
      name: "Home",
      active: true,
      url: "/",
      icon: <IconGallery.MenuHomeIcon />,
    },
    {
      name: "Explore",
      active: false,
      url: "/explore",
      icon: <IconGallery.MenuSearchIcon />,
    },
    {
      name: "Leaderboard",
      active: false,
      url: "/leaderboard",
      icon: <IconGallery.MenuLeaderBoardIcon />,
    },
    {
      name: "Notifications",
      active: false,
      url: "/notifications",
      icon: <IconGallery.MenuNotificationIcon />,
    },
  ];
  const [activeMenu, setActiveMenu] = React.useState("Home");
  return { menuList, activeMenu, setActiveMenu };
}

export { useFooterStates };
export default useFooterStates;
