import * as React from "react";
import * as StyledDom from "./Styles";
import * as IconGallery from "@icongallery";
import * as Utils from "@utils";
import * as ComponentsLib from "@componentsLib";
import { Grid, Box, Toolbar, IconButton, Typography } from "@mui/material";
import axios from "axios";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { createFilterOptions } from "@mui/material/Autocomplete";
import Header from "../../../../Header";
import { postCommentsApi } from "../../api";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getUserDetails, getComments } from "../../../../../../redux/actions";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";
import CircularProgress from "@mui/material/CircularProgress";
// import AutoComplete from "./AutoComplete";

const filterOptions = createFilterOptions({
  matchFrom: "start",
  limit: 5,
  trim: true,
});

const data = [];
const url = "https://comments.free.beeceptor.com/my/api/path";

function AddComments() {
  const navigateTo = useNavigate();
  const [selectlang, setselectlang] = React.useState([]);
  const [typeVal, setTypeVal] = React.useState();
  const [value, setvalue] = React.useState();
  const dispatch = useDispatch();
  const params = useParams();
  const {
    // categoryId = "",
    categoryName = "",
    termName = "",
    termId = "",
  } = params || {};
  const resetForm = () => {
    setselectlang([]);
    setvalue("");
  };
  const commentsList = useSelector((state) => state.commentsList.commentsList);
  const [firstElementComment = []] = commentsList || [];
  const { response: commentResponse = [], status: commentStatus = "Loading" } =
    firstElementComment;
  const [firstCommentArray = []] = commentResponse || [];
  const { Answer = [], question = [] } = firstCommentArray;

  let reStructureAnswer = [];
  reStructureAnswer = Answer.map((item, index) => {
    let reOrderValue = {
      ...item,
    };
    return reOrderValue;
  });

  const [questionElement = []] = question;
  const { categories_id: categoryId = "" } = questionElement;
  const userDetails = useSelector((state) => state.userDetails.userDetails);
  const [firstElement = []] = userDetails;
  const { response = [], status = "Loading" } = firstElement;
  const {
    name = "",
    email = "",
    reputation = "",
    awards = "",
    isVerified = "",
    phone_number = "",
    username = "",
    id: userUUID = "",
    usr_cat_id = "",
  } = response || {};

  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  /* Snackbar functions */
  const [openToast, setOpenToast] = React.useState(false);
  const [vertical, setVertical] = React.useState("top");
  const [horizontal, setHorizontal] = React.useState("right");
  const [severityNote, setSeverityNote] = React.useState("info");
  const [message, setMessage] = React.useState("");

  const [showVerifyEmail, setShowVerifyEmail] = React.useState(false);

  const handleOpenToast = () => {
    setOpenToast(true);
  };

  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
  };
  /*snackbar end */

  const validationSchema = yup.object({
    TextboxValue: yup.string().required("This field is Required"),
  });

  const formik = useFormik({
    initialValues: {
      TextboxValue: "",
      CommentTags: [],
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      // for (var key in selectlang) {
      //   const text = selectlang[key].label;
      //   array.push(text);
      // }
      setIsSubmitted(true);
      try {
        dispatch(
          postCommentsApi({
            termId,
            categoryId,
            userUUID,
            comment: values.TextboxValue,
            SelectedId: values.CommentTags,
            handleOpenToast,
            setMessage,
            setSeverityNote,
            navigateTo,
            usr_cat_id,
          })
        );
        resetForm();
        resetForm(false);
      } catch (error) {
        resetForm(false);
        console.log(error.response);
      }
    },
  });

  const array = [];
  const Push = async (e) => {
    e.preventDefault();
    for (var key in selectlang) {
      const text = selectlang[key].label;
      array.push(text);
    }
    try {
      dispatch(
        postCommentsApi({
          termId,
          categoryId,
          userUUID,
          comment: value,
          SelectedId: array,
          handleOpenToast,
          setMessage,
          setSeverityNote,
          navigateTo,
          usr_cat_id,
        })
      );
      resetForm();
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleOnInputChange = (e, val) => {
    if (e.target.value.length === 0) {
      if (open) setOpen(false);
    } else {
      if (!open) setOpen(true);
    }
    setTypeVal(e.target.value);
  };

  let categoryNameFilter = termName.replaceAll("-", " ");

  const headerProps = {
    showLogo: false,
    title: `Your Contribution to ${categoryNameFilter}`,
    showBackIcon: true,
    floatLeft: true,
    showMenu: false,
    showBookmark: false,
    showShare: false,
    backTo: "",
  };

  const snackBarProps = {
    openToast,
    handleOpenToast,
    handleCloseToast,
    vertical,
    horizontal,
    message,
    severityNote,
  };

  return (
    <>
      <Header {...headerProps} />
      <StyledDom.StyledMainDiv container>
        <Grid item xs={12} lg={12} sm={12}>
          <form onSubmit={formik.handleSubmit} id="clear">
            <StyledDom.StyledContentGrid
              className="maincontent"
              item
              xs={12}
              lg={12}
              sm={12}
            >
              <StyledDom.Styledh3>My contribution</StyledDom.Styledh3>

              <StyledDom.StyledTextareaDiv>
                <StyledDom.StyledTextarea
                  name="TextboxValue"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                ></StyledDom.StyledTextarea>
                <br></br>

                {formik.touched.TextboxValue && formik.errors.TextboxValue ? (
                  <StyledDom.StyledErrorMsg>
                    {formik.errors.TextboxValue}
                  </StyledDom.StyledErrorMsg>
                ) : null}
              </StyledDom.StyledTextareaDiv>

              <StyledDom.Styledh3 style={{ marginTop: "0px" }}>
                Symptoms <span>(maximum 10)</span>
              </StyledDom.Styledh3>

              {/* <AutoComplete /> */}

              <StyledDom.StyledAutocomplete
                open={open}
                onClose={() => setOpen(false)}
                multiple
                autoHighlight
                id="multiple-limit-tags"
                filterOptions={(options, params) => {
                  const filtered = filterOptions(options, params);
                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  const isExisting = options.some(
                    (option) => inputValue === option.label
                  );
                  if (inputValue !== "" && !isExisting) {
                    filtered.push({
                      inputValue,
                      label: `${inputValue}`,
                    });
                  }

                  return filtered;
                }}
                options={data}
                getOptionLabel={(options) => options.label}
                disableClearable
                freeSolo={selectlang.length >= 10 ? true : false}
                limitTags={5}
                filterSelectedOptions={true}
                getOptionDisabled={(option) =>
                  formik.values.CommentTags.length === 10 ||
                  formik.values.CommentTags.includes(option)
                    ? true
                    : false
                }
                onInputChange={handleOnInputChange}
                name="CommentTags"
                onChange={(event, value) =>
                  formik.setFieldValue(
                    "CommentTags",
                    value.map((val) => {
                      return val.label;
                    })
                  )
                }
                renderInput={(params) => (
                  <StyledDom.StyledTextfield
                    id="autocompleteBtn"
                    {...params}
                    value={formik.values.CommentTags}
                    className="text"
                    placeholder="Add tags..."
                  />
                )}
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    {option.label}
                  </Box>
                )}
              />
            </StyledDom.StyledContentGrid>
            <StyledDom.StyledButtonsDiv>
              <StyledDom.StyledCancelButton
                variant="outlined "
                onClick={() => navigateTo(-1)}
                // disabled={isSubmitted}
              >
                Go Back
              </StyledDom.StyledCancelButton>
              <StyledDom.StyledPublishButton
                variant="outlined"
                type="submit"
                disabled={isSubmitted}
              >
                {isSubmitted && <CircularProgress disableShrink />} Publish
              </StyledDom.StyledPublishButton>
            </StyledDom.StyledButtonsDiv>
          </form>
        </Grid>
      </StyledDom.StyledMainDiv>
      <ComponentsLib.ToastSnackbar {...snackBarProps} />
    </>
  );
}
export { AddComments };
export default AddComments;
