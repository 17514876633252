import React from "react";
import Footer from "../../Footer";
import Header from "../../Header";
import * as StyledDom from "./style";
import * as Utils from "@utils";
import { useNavigate, useParams, Link } from "react-router-dom";

function ComingSoon(props) {
  const navigateTo = useNavigate();
  let params = useParams();
  let locationUrl = window.location.href.split("/").reverse();
  const [firstElement = ""] = locationUrl;
  const headerProps = {
    showLogo: false,
    title: Utils.capitalizeText(firstElement),
  };
  return (
    <>
      <Header {...headerProps} />
      <StyledDom.StyledBox>
        <StyledDom.StyledTypography variant="h4">
          {Utils.capitalizeText(firstElement)}
        </StyledDom.StyledTypography>
        <StyledDom.StyledTypography variant="h6">
          Page Coming Soon...
        </StyledDom.StyledTypography>
        <Link to={"/"}>Go to Home</Link>
      </StyledDom.StyledBox>
      <Footer />
    </>
  );
}

export { ComingSoon };
export default ComingSoon;
