import React from "react";
import _ from "lodash";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { Auth } from "aws-amplify";
import * as Utils from "@utils";
import * as IconGallery from "@icongallery";

function useSearchStates(props) {
  const { dispatch = () => "", navigate = () => "" } = props || {};

  return {};
}

export { useSearchStates };
export default useSearchStates;
