import React from "react";
import _ from "lodash";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { Auth } from "aws-amplify";
import * as Utils from "@utils";
import * as IconGallery from "@icongallery";
import * as StyledDOM from "./styles";
import { Grid } from "@mui/material";

function useTermDetailsStates(props) {
  const { dispatch = () => "", navigate = () => "" } = props || {};

  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [openModelPopup, setOpenModelPopup] = React.useState(false);
  const [showSymptom, setShowSymptom] = React.useState(false);
  const [showSynonym, setShowSynonym] = React.useState(false);

  const [screenRerender, setScreenRerender] = React.useState(false);

  const triggerShowSymptom = () => {
    setShowSymptom(true);
  };

  const triggerShowSynonym = () => {
    setShowSynonym(true);
  };

  const handleBackdropClose = () => {
    setOpenBackdrop(false);
  };
  const handleBackdropOpen = () => {
    setOpenBackdrop(true);
  };

  const handleModelPopupClose = () => {
    setOpenModelPopup(false);
  };
  const handleModelPopupOpen = () => {
    setOpenModelPopup(true);
  };

  /* Snackbar functions */
  const [openToast, setOpenToast] = React.useState(false);
  const [vertical, setVertical] = React.useState("top");
  const [horizontal, setHorizontal] = React.useState("right");
  const [severityNote, setSeverityNote] = React.useState("info");
  const [message, setMessage] = React.useState("");

  const [showVerifyEmail, setShowVerifyEmail] = React.useState(false);

  const handleOpenToast = () => {
    setOpenToast(true);
  };

  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
  };
  /*snackbar end */

  const showSymptomAndSynonyms = (props) => {
    const {
      sympSynText = "Symptoms:",
      sympSyn = [],
      showAll = false,
      triggerShowSymptomAndSynonyms = () => "",
    } = props || {};
    const [option1 = "", option2 = "", option3 = "", option4 = ""] =
      sympSyn || [];
    return (
      <StyledDOM.StyledTagsGrid className={showAll == true ? "sympList" : ""}>
        <span className="symp">{sympSynText}</span>
        {!showAll ? (
          <>
            <Grid>
              {option1 && (
                <StyledDOM.StyledTags
                  label={Utils.replaceSpecialCharactersValue(option1, "")}
                />
              )}
              {option2 && (
                <StyledDOM.StyledTags
                  label={Utils.replaceSpecialCharactersValue(option2, "")}
                />
              )}
              {option3 && (
                <StyledDOM.StyledTags
                  label={Utils.replaceSpecialCharactersValue(option3, "")}
                />
              )}
              {option4 && (
                <StyledDOM.StyledTags
                  label={Utils.replaceSpecialCharactersValue(option4, "")}
                />
              )}
            </Grid>
            <span
              className="symptomsCount"
              onClick={triggerShowSymptomAndSynonyms}
            >
              {sympSyn.length - 4 > 0 && <>+{sympSyn.length - 4} more</>}
            </span>
          </>
        ) : (
          <Grid>
            {sympSyn?.map((sympItem, index) => {
              return <StyledDOM.StyledTags key={index} label={sympItem} />;
            })}
          </Grid>
        )}
      </StyledDOM.StyledTagsGrid>
    );
  };

  return {
    openBackdrop,
    setOpenBackdrop,
    handleBackdropOpen,
    handleBackdropClose,
    openModelPopup,
    setOpenModelPopup,
    handleModelPopupClose,
    handleModelPopupOpen,
    openToast,
    setOpenToast,
    handleOpenToast,
    handleCloseToast,
    vertical,
    setVertical,
    horizontal,
    setHorizontal,
    message,
    setMessage,
    severityNote,
    setSeverityNote,
    showSymptom,
    triggerShowSymptom,
    screenRerender,
    setScreenRerender,
    showSynonym,
    triggerShowSynonym,
    showSymptomAndSynonyms,
  };
}

export { useTermDetailsStates };
export default useTermDetailsStates;
