import { get, post } from "../../../../helpers/api_helper";
import * as url from "../../../../helpers/url_helper";
import * as Utils from "@utils";

export const getCommentsListApi = (props) => {
  const { payload = {} } = props || {};
  return get(`${url.GET_COMMENTS}/${payload}`);
};

export const postVoteApi = (props) => {
  const {
    id = "",
    userUUID = "",
    value = "",
    termId = "",
    categoryId = "",
    userCatId = "",
    handleOpenToast = () => "",
    setSeverityNote = () => "",
    setMessage = () => "",
    screenRerender,
    setScreenRerender = () => "",
  } = props || {};
  const payload = {
    user_id: userUUID,
    category_id: categoryId,
    question_id: termId,
    user_category_id: userCatId,
    answer_id: id,
    action: value,
  };

  // setIsBtnLoading(true);
  post(url.POST_VOTE, payload)
    .then((response) => {
      const { data = {} } = response || {};
      let { message = "" } = data || {};
      let severityNoteTxt = "success";
      if (value === "up") {
        message = "Up Voted";
        severityNoteTxt = "success";
      } else {
        message = "Down Voted";
        severityNoteTxt = "warning";
      }
      handleOpenToast();
      setSeverityNote(severityNoteTxt);
      setMessage(message);
      getCommentsListApi({ payload: termId });
      setScreenRerender(!screenRerender);
      // setIsBtnLoading(false);
    })
    .catch((error) => {
      const { response = {} } = error || {};
      const { data = {} } = response || {};
      const { code = "", message = Utils?.staticText?.apiResponseMsg?.error } =
        data || {};
      handleOpenToast();
      setSeverityNote("error");
      setMessage(message);
      // setIsBtnLoading(false);
    });
};

export const postCommentsApi = (props) => {
  // reply to the contributions
  const {
    termId = "",
    categoryId = "",
    userUUID = "",
    handleOpenToast = () => "",
    setSeverityNote = () => "",
    setMessage = () => "",
    comment = "",
    SelectedId = "",
    navigateTo = () => "",
    usr_cat_id = "",
    handleTriggerNewPopUpClose = () => "",
    setSymptomArray = () => "",
    symptomArray = "",
    screenRerender = "",
    setScreenRerender = () => "",
  } = props || {};

  const payLoad = {
    definition: comment,
    question_id: termId,
    user_id: userUUID,
    category_id: categoryId,
    symptom: SelectedId,
    user_category_id: usr_cat_id,
  };
  post(`${url.POST_COMMENTS}`, payLoad)
    .then((response) => {
      const { data = {} } = response || {};
      const { code = "", message = "Published your contributions" } =
        data || {};
      handleOpenToast();
      setSeverityNote("success");
      setMessage(message);
      if (symptomArray) {
        setSymptomArray([]);
        handleTriggerNewPopUpClose();
        setScreenRerender(!screenRerender);
      }
      setTimeout(() => {
        navigateTo(-1);
      }, 2000);
    })
    .catch((error) => {
      const { response = {} } = error || {};
      const { data = {} } = response || {};
      const { code = "", message = Utils?.staticText?.apiResponseMsg?.error } =
        data || {};
      handleOpenToast();
      setSeverityNote("error");
      setMessage(message);
    });
};
export const postReplyApi = (props) => {
  // reply to the contributions
  const {
    termId = "",
    handleOpenToast = () => "",
    setSeverityNote = () => "",
    setMessage = () => "",
    comment = "",
    userUUID = "",
    answer_id = "",
    setReplyComment = () => "",
    setShowReplyArea = () => "",
    screenRerender,
    setScreenRerender = () => "",
  } = props || {};

  const payLoad = {
    comment,
    question_id: termId,
    user_id: userUUID,
    answer_id,
  };
  post(`${url.POST_REPLY}`, payLoad)
    .then((response) => {
      const { data = {} } = response || {};
      const { code = "", message = "Replied" } = data || {};
      handleOpenToast();
      setSeverityNote("success");
      setMessage(message);
      setReplyComment("");
      setShowReplyArea(false);
      // getCommentsListApi({ payload: termId });
      setScreenRerender(!screenRerender);
    })
    .catch((error) => {
      const { response = {} } = error || {};
      const { data = {} } = response || {};
      const { code = "", message = Utils?.staticText?.apiResponseMsg?.error } =
        data || {};
      handleOpenToast();
      setSeverityNote("error");
      setMessage(message);
    });
};

export const getReplyApi = (props) => {
  // reply to the contributions
  const { answer_id = "" } = props || {};

  get(`${url.GET_REPLY}/${answer_id}`)
    .then((response) => {
      const { data = {} } = response || {};
      const { code = "", message = "" } = data || {};
      return response;
    })
    .catch((error) => {
      const { response = {} } = error || {};
      const { data = {} } = response || {};
      const { code = "", message = Utils?.staticText?.apiResponseMsg?.error } =
        data || {};
    });
};

export const postHelpfulApi = (props) => {
  const {
    userUUID = "",
    value = "",
    termId = "",
    categoryId = "",
    handleOpenToast = () => "",
    setSeverityNote = () => "",
    setMessage = () => "",
    screenRerender,
    setScreenRerender = () => "",
  } = props || {};
  const payload = {
    user_id: userUUID,
    category_id: categoryId,
    question_id: termId,
    helpful: value,
  };

  post(url.POST_HELPFUL, payload)
    .then((response) => {
      const { data = {} } = response || {};
      let { message = "" } = data || {};
      let severityNoteTxt = "success";
      if (value === "Yes") {
        message = "You liked this topic";
        severityNoteTxt = "success";
      } else {
        message = "You disliked this topic";
        severityNoteTxt = "warning";
      }
      handleOpenToast();
      setSeverityNote(severityNoteTxt);
      setMessage(message);
      setScreenRerender(!screenRerender);
    })
    .catch((error) => {
      const { response = {} } = error || {};
      const { data = {} } = response || {};
      const { message = Utils?.staticText?.apiResponseMsg?.error } = data || {};
      handleOpenToast();
      setSeverityNote("error");
      setMessage(message);
    });
};
