import React from "react";
import Footer from "../../Footer";
import Header from "../../Header";
import * as StyledDom from "./style";
import * as Utils from "@utils";
import { useNavigate, useParams, Link } from "react-router-dom";
import leaderboard from "../../../../assets/images/leaderboard.png";

function LeaderBoard(props) {
  const navigateTo = useNavigate();
  let params = useParams();
  let locationUrl = window.location.href.split("/").reverse();
  const [firstElement = ""] = locationUrl;
  const headerProps = {
    showLogo: false,
    title: Utils.capitalizeText(firstElement),
  };
  return (
    <>
      <Header {...headerProps} />
      <StyledDom.StyledBox>
        {/* <StyledDom.StyledTypography variant="h4">
          {Utils.capitalizeText(firstElement)}
        </StyledDom.StyledTypography> */}
        <img
          src={leaderboard}
          alt={Utils.capitalizeText(firstElement)}
          width="150px"
        />
        <StyledDom.StyledTypographyH5>
          Leaders, in progress!
        </StyledDom.StyledTypographyH5>
        <StyledDom.StyledTypographyH6>
          Leaderboard will appear here once this app has a higher density of
          contributions. This is made possible, only by people like you & our
          community!
        </StyledDom.StyledTypographyH6>
        <StyledDom.StyledTypographyH6>
          Until then, keep contributing as you continue to earn points & badges
          for all contributions
        </StyledDom.StyledTypographyH6>
        {/* <Link to={"/"}>Go to Home</Link> */}
      </StyledDom.StyledBox>
      <Footer />
    </>
  );
}

export { LeaderBoard };
export default LeaderBoard;
