import React from "react";
import * as ComponentsLib from "@componentsLib";
import * as IconGallery from "@icongallery";
import * as Utils from "@utils";
import { Link, useNavigate } from "react-router-dom";
import {
  Forminputprops,
  FormFieldValues,
  validationSchema,
  getValid,
} from "./fixture";
import * as StyledDOM from "./styles";
import { useSelectUserCategoryStates } from "./useSelectUserCategoryStates";
import { Grid } from "@mui/material";
import Header from "../../Header";
import Footer from "../../Footer";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { getUserCategory } from "../../../../redux/actions";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";

const SelectUserCategory = (props) => {
  const {
    handleOpenToast = () => "",
    setMessage = () => "",
    setSeverityNote = () => "",
    handleModelPopupClose = () => "",
  } = props || {};
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const userDetails = useSelector((state) => state.userDetails.userDetails);
  const [firstElement = []] = userDetails;
  const { response: userDetailsResponse = [], status: apiStatus = "Loading" } =
    firstElement;
  const getUserCategoryList = useSelector(
    (state) => state.userCategory.userCategory
  );
  const { initialValues } = FormFieldValues();
  const {
    formik,
    isBtnDisabled,
    setIsBtnDisabled,
    isBtnLoading,
    setIsBtnLoading,
  } = useSelectUserCategoryStates({
    initialValues,
    validationSchema,
    dispatch,
    navigateTo,
    handleOpenToast,
    setMessage,
    setSeverityNote,
    handleModelPopupClose,
    userDetails: userDetailsResponse,
  });

  React.useEffect(() => {
    dispatch(getUserCategory());
  }, []);

  const { dropDownProps } = Forminputprops({
    formik,
    getUserCategoryList,
  });

  return (
    <>
      {/* <Header /> */}
      <StyledDOM.StyledMainSelectFieldsWrapper>
        <StyledDOM.StyledMainHeading>
          {`Please select category`}
        </StyledDOM.StyledMainHeading>
        <StyledDOM.StyledSubMainWrapperForm onSubmit={formik.handleSubmit}>
          <StyledDOM.StyledGridOuter>
            <StyledDOM.StyledFieldsWrapper>
              <ComponentsLib.TextField {...dropDownProps} />
            </StyledDOM.StyledFieldsWrapper>
            <StyledDOM.StyledButtonOuterWrapper>
              <StyledDOM.StyledButtonWrapper
                type={`button`}
                onClick={handleModelPopupClose}
              >
                Cancel
              </StyledDOM.StyledButtonWrapper>
              <StyledDOM.StyledButtonWrapper
                disabled={!(formik.dirty && formik.isValid) || isBtnDisabled}
                id={"login-button"}
                type={`submit`}
              >
                {isBtnLoading && <CircularProgress disableShrink />} Submit
              </StyledDOM.StyledButtonWrapper>
            </StyledDOM.StyledButtonOuterWrapper>
          </StyledDOM.StyledGridOuter>
        </StyledDOM.StyledSubMainWrapperForm>
      </StyledDOM.StyledMainSelectFieldsWrapper>
      {/* <Footer /> */}
    </>
  );
};

export { SelectUserCategory };
export default SelectUserCategory;
