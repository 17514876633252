import * as type from "../types";

export function getComments(addedComments) {
  return {
    type: type.GET_COMMENTS_LIST,
    payload: addedComments,
  };
}

// export function getAddedComments(trendingTopicsListById) {
//   return {
//     type: type.GET_TRENDING_TOPICS_BY_ID,
//     payload: trendingTopicsListById,
//   };
// }
