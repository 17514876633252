import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { height } from "@mui/system";
import { styled } from "@mui/material/styles";
import { colors, Grid } from "@mui/material";

function ErrorSection(props) {
  const {
    content = "",
    width = "100%",
    height = 60,
    bgColor = "#f1f1f1",
  } = props || {};
  return (
    <Grid
      style={{
        fontFamily: "Manrope, sans-serif",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "30px 10px",
        backgroundColor: bgColor,
        borderRadius: "8px",
        color: "#9a9a9a",
      }}
    >
      {content}
    </Grid>
  );
}
export { ErrorSection };
export default ErrorSection;
