import { styled } from "@mui/material/styles";
import { Typography, Avatar, Grid, Button } from "@mui/material";

export const OuterGrid = styled("div")`
  font-family: "Manrope", sans-serif;
  padding: 0px 15px;
  margin-top: 40px;
  @media only screen and (min-width: 768px) {
    width: 60%;
    margin: 40px auto 0px auto;
  }
`;
export const StyledImg = styled("div")`
  font-family: "Manrope", sans-serif;
  margin: 0 auto;
  display: table;
  padding: 3px;
  border: 3px dashed hsl(195deg 6% 87%);
  border-radius: 80px;
  z-index: -2;
`;
export const StyledAvatar = styled(Avatar)`
  height: 100px;
  width: 100px;
`;

export const StyledImgIcon = styled(Avatar)`
  height: 54px;
  width: 54px;
`;
export const StyledHeaderTypography = styled(Typography)`
  font-family: "Manrope", sans-serif;
  margin: auto;
  font-size: 25px;
  text-align: center;
  color: black;
`;

export const StyledQuotes = styled(Grid)`
  font-family: "Manrope", sans-serif;
  text-align: center;
`;

export const StyledCameraIcon = styled(Grid)`
  font-family: "Manrope", sans-serif;
  margin: -24px auto 0;
  display: table;
`;

export const StyledSubMainWrapperForm = styled("form")`
  font-family: "Manrope", sans-serif;
  @media only screen and (max-width: 700px) {
    height: calc(100% - 95px);
  }
`;

export const StyledFieldsWrapper = styled(Grid)`
  font-family: "Manrope", sans-serif;
  .MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline:before,
  .MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline:after {
    border-bottom: 0px;
  }
  .MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.Mui-focused {
    border: 1px solid #26867d;
  }
  .MuiTextField-root p.Mui-error {
    position: relative;
    bottom: 0;
  }
  display: flex;
  position: relative;

  .cls-tenant fieldset {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0px;
  }

  .cls-cls-firstname-textfield-wrapper {
    width: 48% !important;
  }
  .cls-cls-lastname-textfield-wrapper {
    width: 48% !important;
    margin-right: 20px;
  }

  @media only screen and (max-width: 700px) {
    flex-direction: column;
    .cls-cls-firstname-textfield-wrapper,
    .cls-cls-lastname-textfield-wrapper {
      width: 100% !important;
    }

    .cls-cls-zipcode-textfield-wrapper {
      margin-top: 20px;
    }
  }
`;

export const StyledButtonWrapper = styled(Button)`
  font-family: "Manrope", sans-serif;
  text-transform: capitalize;
  background: #26867d;
  border-radius: 4px;
  color: #ffffff;
  padding: 0px 28px;
  height: 50px;
  font-size: 18px;
  display: flex;
  width: 100%;
  margin: 10px auto 20px auto;
  &.Mui-disabled {
    background: #ccc;
  }
  &:hover {
    background-color: #1b2330;
  }
  &.MuiButtonBase-root.MuiButton-root:hover {
    background: #ccc;
  }
  .MuiCircularProgress-indeterminate {
    width: 20px !important;
    height: 20px !important;
    margin-right: 10px;
  }
  @media only screen and (max-width: 821px) {
    margin-top: 20%;
  }
  @media only screen and (max-width: 421px) {
    margin-top: 60%;
  }
`;
export const StyledPlaceholderGrid = styled(Grid)`
  width: 30%;
  margin: 0 auto;
`;

export const StyledLoaderGrid = styled(Grid)`
  width: 80%;
  margin: 0 auto;
`;
export const StyledResendSpan = styled("span")`
  font-size: 11px;
  font-weight: 700;
  position: absolute;
  bottom: -10px;
  right: 2px;
  span {
    font-weight: 900;
    color: #26867d;
    text-decoration: underline;
  }
`;
