import { styled } from "@mui/material/styles";
import { Grid, Button, Typography, Stepper, Box } from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";

export const StyledOtpField = styled(MuiOtpInput)`
  font-family: "Manrope", sans-serif;
  width: 80%;
  margin: 0 auto 40px auto;
  gap: 10px;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
  .MuiInputBase-input.MuiOutlinedInput-input {
    padding: 11px 14px;
  }
  &.verifyEmail {
    margin: 0 auto;
    @media (max-width: 300px) {
      width: 95%;
      gap: 4px;
      font-size: 8px;
    }
  }
`;

export const StyledTypography = styled(Typography)`
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  &:focus-visible {
    outline: 0px;
  }
`;
export const StyledButtonLink = styled(Button)`
  font-family: "Manrope", sans-serif;
  color: #26867d;
  font-weight: 700;
  background: none;
  margin-top: -2px;
`;

export const StyledStepper = styled(Stepper)`
  font-family: "Manrope", sans-serif;
  margin: 40px 0;
  svg {
    color: #fff;
    font-size: 26px;
    border: 1px solid rgba(0, 0, 0, 0.26);
    border-radius: 15px;
    text {
      fill: #000;
      font-size: 14px;
      font-weight: 600;
      font-family: "Manrope", sans-serif;
    }
  }
  .MuiStepLabel-iconContainer {
    padding-right: 0;
  }
  .MuiStepConnector-horizontal.Mui-active {
    span {
      border-color: #00ab66;
    }
  }
  .MuiStepConnector-root .MuiStepConnector-line {
    border-top-style: dotted;
    border-top-width: 3px;
  }
  .MuiStep-horizontal {
    padding: 0;
  }
  .MuiStepIcon-root.Mui-active {
    color: #c3e5d7;
    border: 1px solid #26867d;
  }
  .MuiStepIcon-root.Mui-completed {
    color: #00ab66;
  }
`;

export const StyledMainHeading = styled("h1")`
  font-family: "Manrope", sans-serif;
  margin: 0px;
  text-align: center;
  font-size: 28px;
  font-weight: 900;

  @media only screen and (max-width: 700px) {
    margin: 0;
  }
`;
export const StyledSubHeadingOne = styled("h2")`
  font-family: "Manrope", sans-serif;
  font-size: 24px;
  color: #000;
  margin: 0px;
  text-align: center;
  margin-bottom: 10px;
  @media (max-width: 300px) {
    font-size: 17px;
  }
`;
export const StyledSubHeadingTwo = styled("h3")`
  font-family: "Manrope", sans-serif;
  font-size: 12px;
  color: #9a999a;
  margin: 0px;
  text-align: center;
  margin: 10px 0 30px;
`;
export const StyledSubHeadingSuccessTwo = styled("h3")`
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  color: #9a999a;
  margin: 0px;
  text-align: center;
  margin: 10px 0 30px;
  font-weight: 600;
`;
export const StyledSubHeadingThree = styled("h4")`
  font-family: "Manrope", sans-serif;
  font-size: 12px;
  color: #9a999a;
  margin: 0px;
  text-align: center;
`;
export const StyledViewIcon = styled(Grid)`
  font-family: "Manrope", sans-serif;
  position: absolute;
  right: 12px;
  top: 12px;
  &.rightMargin {
    right: 22px !important;
  }
`;

export const StyledFieldsWrapper = styled(Grid)`
  font-family: "Manrope", sans-serif;
  .MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline:before,
  .MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline:after {
    border-bottom: 0px;
  }
  .MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.Mui-focused {
    border: 1px solid #26867d;
  }
  .MuiTextField-root p.Mui-error {
    position: relative;
    bottom: 0;
  }
  display: flex;
  position: relative;

  .cls-tenant fieldset {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0px;
  }

  .cls-cls-firstname-textfield-wrapper {
    width: 48% !important;
  }
  .cls-cls-lastname-textfield-wrapper {
    width: 48% !important;
    margin-right: 20px;
  }

  @media only screen and (max-width: 700px) {
    flex-direction: column;
    .cls-cls-firstname-textfield-wrapper,
    .cls-cls-lastname-textfield-wrapper {
      width: 100% !important;
    }

    .cls-cls-zipcode-textfield-wrapper {
      margin-top: 20px;
    }
  }
  .react-tel-input {
    padding-bottom: 10px;
  }
`;

export const StyledMainFieldsWrapper = styled(Grid)`
  font-family: "Manrope", sans-serif;
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;

  label {
    font-size: 12px;
  }

  @media only screen and (max-width: 700px) {
    padding: 10px 15px;
  }
`;

export const StyledSubMainWrapperForm = styled("form")`
  font-family: "Manrope", sans-serif;
  // .MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline:before {
  //   border-bottom: 0px;
  // }
  @media only screen and (max-width: 700px) {
    height: calc(100% - 95px);
  }
`;

export const StyledLoginGrid = styled(Grid)`
  font-family: "Manrope", sans-serif;
  text-align: center;
  color: #7c797a;
`;

export const StyledLoginInnerGrid = styled(Grid)`
  font-family: "Manrope", sans-serif;
  margin-top: 20px;
  margin-bottom: 30px;
  font-weight: 500;
  font-size: 14px;
  a {
    font-weight: 900;
  }
`;

export const StyledLoginInnerGridLevelTwo = styled(Grid)`
  font-family: "Manrope", sans-serif;
  margin-top: 20px;
  margin-bottom: 40px;
  font-weight: 500;
  font-size: 12px;
  a {
    font-size: 14px;
    font-weight: 900;
  }
`;

export const StyledButtonWrapper = styled(Button)`
  font-family: "Manrope", sans-serif;
  text-transform: capitalize;
  background: #26867d;
  border-radius: 4px;
  color: #ffffff;
  padding: 0px 28px;
  height: 40px;
  display: flex;
  width: 100%;
  margin: 10px auto 20px auto;
  font-size: 18px;
  font-weight: 900;

  &.Mui-disabled {
    background: #ccc;
  }
  &:hover {
    background-color: #1b2330;
  }
  &.MuiButtonBase-root.MuiButton-root:hover {
    background: #ccc;
  }
  .MuiCircularProgress-indeterminate {
    width: 20px !important;
    height: 20px !important;
    margin-right: 10px;
  }
  &.phoneNumber {
    width: auto;
    font-size: 16px;
  }
`;

export const StyledVerifyButtonWrapper = styled(Button)`
  font-family: "Manrope", sans-serif;
  text-transform: capitalize;
  background: #26867d;
  border-radius: 4px;
  color: #ffffff;
  padding: 0px 28px;
  height: 40px;
  font-size: 18px;
  display: flex;
  width: 177px;
  margin: 10px auto 20px auto;
  &.Mui-disabled {
    background: #ccc;
  }
  &:hover {
    background-color: #1b2330;
  }
  &.MuiButtonBase-root.MuiButton-root:hover {
    background: #ccc;
  }
  .MuiCircularProgress-indeterminate {
    width: 20px !important;
    height: 20px !important;
    margin-right: 10px;
  }
`;

export const StyledCancelButtonWrapper = styled(Button)`
  font-family: "Manrope", sans-serif;
  text-transform: capitalize;
  background: #ffffff;
  border-radius: 4px;
  color: #26867d;
  padding: 0px 27px;
  height: 40px;
  font-size: 18px;
  display: flex;
  width: auto;
  margin: 10px auto;
  border: 1px solid #26867d;

  &.Mui-disabled {
    background: #ccc;
    border: 0px solid #ccc;
  }

  &:hover {
    background-color: #1b2330;
  }
`;

export const StyledHrWrapper = styled("hr")`
  margin: 20px 0;
`;

export const StyledGoogleWrapper = styled(Grid)`
  font-family: "Manrope", sans-serif;
`;

export const StyledBox = styled(Box)`
  font-family: "Manrope", sans-serif;
  width: auto;

  @media only screen and (min-width: 768px) {
    width: 60%;
    margin: 0 auto;
  }

  @media only screen and (max-width: 420px) {
    width: 100%;
    margin: 0 auto;
  }
`;

export const StyledVerifySpanWrapper = styled("span")`
  font-family: "Manrope", sans-serif;
  text-transform: capitalize;
  font-size: 14px;
  display: flex;
  width: auto;
  margin: 0px auto;
  cursor: pointer;
  color: #26867d;
  font-weight: 700;
  &:focus-visible {
    outline: 0px;
  }
`;
