import * as Utils from "@utils";
/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "us-east-1",
  aws_cognito_region: "us-east-1",
  // aws_user_pools_id: "us-east-1_YPpMRYe3r",
  // aws_user_pools_web_client_id: "4fcv5vnmfjsesq94jqv7neger5",
  aws_user_pools_id: Utils.awsUserPoolsId(),
  aws_user_pools_web_client_id: Utils.awsUserPoolsWebId(),
  oauth: {
    domain: "curator-dev.auth.us-east-1.amazoncognito.com",
    scope: ["phone", "email", "openid", "profile"],
    redirectSignIn: Utils.assignRedirectSignInUrlSSO(),
    redirectSignOut: Utils.assignRedirectSignOutUrlSSO(),
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_POOLS",
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_social_providers: ["GOOGLE"],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
};

export default awsmobile;
