import { styled } from "@mui/material/styles";
import { Grid, Card, CardContent, MenuItem } from "@mui/material";

export const StyledGridWrapper = styled(Grid)`
  position: relative;
  font-family: "Manrope", sans-serif;
  .MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-colorPrimary.Mui-focused {
    border: 1px solid #26867d !important;
  }
  .MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-colorPrimary {
    border: 1px solid #cccccc !important;
  }
  .MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline:before,
  .MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline:after {
    border-bottom: 0px;
  }
  width: 100%;
  .search {
    margin-top: -7px;
    border-radius: 4px;
    width: 100%;
    padding: 10px 0px 0px 10px;
    height: 40px;
    position: relative;
    top: 5px;
  }
  .search div {
    border-radius: 4px;
    background-color: rgb(0 0 0 / 0%);
  }
  .search input {
    font-weight: bold;
  }
  margin-bottom: 18px;
  &.searchResult {
    margin-top: 9px;
    margin-bottom: 9px;
  }
  @media only screen and (max-width: 1200px) {
    .search {
      width: 100%;
      padding: 10px 0;
      margin-top: -5px;
    }
  }
  @media only screen and (max-width: 420px) {
    .search {
      width: 100%;
    }
    &.extendWidth {
      width: 330px;
    }
  }
`;

export const StyledGrid = styled(Grid)`
  z-index: 9999999999;
  height: 40px;
  position: absolute;
  width: 100%;
  border-radius: 4px;
  top: 10px;
`;
