import React from "react";
import * as yup from "yup";

export const getValid = (value, optionalFieldItm = "") => {
  const { ...balanceProps } = value;
  let values = Object.keys(balanceProps).map((itm) => {
    let obj = "";
    if (balanceProps[itm].length === 0) {
      obj = itm;
    }
    return obj;
  });

  return values.filter((itm) => itm !== "");
};

export const FormFieldValues = (props) => {
  // let localValues = JSON.parse(localStorage.getItem("formValues"));
  const {
    fullName = "",
    phoneNumber = "",
    email = "",
    password = "",
  } = props || {};

  const initialValues = {
    fullName: fullName,
    phoneNumber: phoneNumber,
    email: email,
    password: password,
  };
  return {
    initialValues,
  };
};

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

// phoneNumber: yup
//   .string()
//   .required("Phone Number is required")
//   .matches(phoneRegExp, "Phone number is not valid")
//   .min(10, "Phone number must be at least 10 characters")
//   .max(10, "Phone number must be at most 10 characters"),
export const validationSchema = yup.object().shape({
  fullName: yup
    .string()
    .required("Full Name is required")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  phoneNumber: yup.string().required("Phone Number is required"),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email Address is required"),
  password: yup
    .string()
    .required("Password is required")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must contain 8 characters, one uppercase, one lowercase, one number and one special case character"
    ),
});

export const Forminputprops = (props) => {
  const { formik = () => {}, passwordType = "password" } = props;

  const nameProps = {
    note: "",
    inputprops: {
      style: {
        height: "20px",
        backgroundPosition: "10px center",
        backgroundRepeat: "no-repeat",
        border: "1px solid #ccc",
        transition: "all 0.2s",
        borderRadius: "4px",
        fontSize: "16px",
        backgroundColor: "#fff",
      },
    },
    className: "cls-textfield cls-fullName",
    placeholder: "",
    labelname: "Full Name",
    id: "fullName",
    type: "text",
    name: "fullName",
    onChange: (e) => {
      formik?.setFieldTouched("fullName");
      formik?.handleChange(e);
    },
    defaultValue: formik?.values.fullName,
    helpertext:
      formik?.errors.fullName &&
      formik?.touched.fullName &&
      `${formik?.errors.fullName}`,
    error: formik?.errors.fullName && formik?.touched.fullName,
    variant: "filled",
    size: "small",
    formik,
  };

  const phoneNumberProps = {
    note: "",
    country: "in",
    inputprops: {
      style: {
        height: "20px",
        backgroundPosition: "10px center",
        backgroundRepeat: "no-repeat",
        border: "1px solid #ccc",
        transition: "all 0.2s",
        borderRadius: "4px",
        fontSize: "16px",
        backgroundColor: "#fff",
      },
    },
    className: "cls-textfield cls-phoneNumber",
    placeholder: "",
    labelName: "Phone Number",
    id: "phoneNumber",
    type: "phone",
    name: "phoneNumber",
    onChange: (value, data, event, formattedValue) => {
      let phoneValue = "+" + value;
      formik.setFieldValue("phoneNumber", phoneValue);
    },
    defaultValue: formik?.values.phoneNumber,
    helpertext:
      formik?.errors.phoneNumber &&
      formik?.touched.phoneNumber &&
      `${formik?.errors.phoneNumber}`,
    error: formik?.errors.phoneNumber && formik?.touched.phoneNumber,
    variant: "filled",
    formik,
  };

  const emailProps = {
    note: "",
    inputprops: {
      style: {
        height: "20px",
        backgroundPosition: "10px center",
        backgroundRepeat: "no-repeat",
        border: "1px solid #ccc",
        transition: "all 0.2s",
        borderRadius: "4px",
        fontSize: "16px",
        backgroundColor: "#fff",
      },
    },
    className: "cls-textfield cls-email",
    placeholder: "",
    labelname: "Email Address",
    id: "email",
    type: "text",
    name: "email",
    onChange: (e) => {
      formik?.setFieldTouched("email");
      formik?.handleChange(e);
    },
    defaultValue: formik?.values.email,
    helpertext:
      formik?.errors.email &&
      formik?.touched.email &&
      `${formik?.errors.email}`,
    error: formik?.errors.email && formik?.touched.email,
    variant: "filled",
    size: "small",
    formik,
  };

  const passwordProps = {
    note: "",
    inputprops: {
      style: {
        height: "20px",
        backgroundPosition: "10px center",
        backgroundRepeat: "no-repeat",
        border: "1px solid #ccc",
        transition: "all 0.2s",
        borderRadius: "4px",
        fontSize: "16px",
        backgroundColor: "#fff",
      },
    },
    className: "cls-textfield cls-password",
    placeholder: "",
    labelname: "Password",
    id: "password",
    type: `${passwordType === "password" ? "password" : "text"}`,
    name: "password",
    onChange: (e) => {
      formik?.setFieldTouched("password");
      formik?.handleChange(e);
    },
    defaultValue: formik?.values.password,
    helpertext:
      formik?.errors.password &&
      formik?.touched.password &&
      `${formik?.errors.password}`,
    error: formik?.errors.password && formik?.touched.password,
    variant: "filled",
    formik,
  };

  return {
    nameProps,
    phoneNumberProps,
    emailProps,
    passwordProps,
  };
};

export const steps = ["1", "2", "3", "4"];
