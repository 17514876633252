import React from "react";
import * as ComponentsLib from "@componentsLib";
import * as StyledDOM from "./styles";
import * as IconGallery from "@icongallery";
import * as Utils from "@utils";
import { Link, useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import OtpForm from "./otpForm";
import CircularProgress from "@mui/material/CircularProgress";

const UpdatePassword = (props) => {
  const {
    formik = () => "",
    togglePassword = () => "",
    toggleConfirmPassword = () => "",
    passwordProps = {},
    confirmPasswordProps = {},
    rememberMeProps = {},
    passwordType = "",
    showLogin = false,
    confirmPasswordType = "",
    showInfo = false,
    btnTxt = "Reset Password",
    otpCode = "",
    setOtpCode = () => "",
    componentFrom = "",
    setSeverityNote = () => "",
    setMessage = () => "",
    handleOpenToast = () => "",
    isBtnLoading = false,
    setIsBtnLoading = () => "",
    disableBtn = false,
    setDisableBtn = () => "",
  } = props || {};

  const otpFormProps = {
    otpCode,
    setOtpCode,
    setSeverityNote,
    setMessage,
    handleOpenToast,
  };

  return (
    <>
      <StyledDOM.StyledSubMainWrapperForm onSubmit={formik.handleSubmit}>
        <StyledDOM.StyledGridOuter>
          {componentFrom == "" && (
            <>
              <StyledDOM.StyledAlert variant="outlined" severity="info">
                Please enter the six digit code sent to the registered mail id
              </StyledDOM.StyledAlert>
              <OtpForm {...otpFormProps} />
            </>
          )}
          <StyledDOM.StyledFieldsWrapper>
            <ComponentsLib.TextField {...passwordProps} />
            <StyledDOM.StyledViewIcon
              onClick={() => togglePassword()}
              className={!formik.isValid ? `rightMargin` : ``}
            >
              {passwordType === "password" ? (
                <IconGallery.VisibilityOff />
              ) : (
                <IconGallery.VisibilityOn />
              )}
            </StyledDOM.StyledViewIcon>
          </StyledDOM.StyledFieldsWrapper>
          <StyledDOM.StyledFieldsWrapper>
            <ComponentsLib.TextField {...confirmPasswordProps} />
            <StyledDOM.StyledViewIcon
              onClick={() => toggleConfirmPassword()}
              className={!formik.isValid ? `rightMargin` : ``}
            >
              {confirmPasswordType === "password" ? (
                <IconGallery.VisibilityOff />
              ) : (
                <IconGallery.VisibilityOn />
              )}
            </StyledDOM.StyledViewIcon>
          </StyledDOM.StyledFieldsWrapper>
          {showInfo && (
            <StyledDOM.StyledInfoOuter>
              <StyledDOM.StyledNote>
                {Utils?.staticText?.createPassword?.importNote}
              </StyledDOM.StyledNote>
              <StyledDOM.StyledOl>
                {Utils?.staticText?.createPassword?.conditions.map(
                  (condition, idx) => (
                    <li key={idx}>{condition}</li>
                  )
                )}
              </StyledDOM.StyledOl>
              <StyledDOM.StyledFormControlLabel
                control={<StyledDOM.StyledCheckbox />}
                label="Remember me"
                {...rememberMeProps}
              />
            </StyledDOM.StyledInfoOuter>
          )}
          <StyledDOM.StyledButtonWrapper
            // disabled={!(formik.dirty && formik.isValid) || disableBtn}
            disabled={disableBtn}
            id={"login-button"}
            type={`submit`}
          >
            {isBtnLoading && <CircularProgress disableShrink />}
            {btnTxt}
          </StyledDOM.StyledButtonWrapper>
          {showLogin && (
            <Link to="/login">{Utils?.staticText?.login?.button}</Link>
          )}
        </StyledDOM.StyledGridOuter>
      </StyledDOM.StyledSubMainWrapperForm>
    </>
  );
};

export { UpdatePassword };
export default UpdatePassword;
