import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

import { registerUserApi } from "../../app/components/Pages/Register/api";
import * as type from "../types";

function* registerUser(action) {
  try {
    const register = yield call(registerUserApi, action);
    yield put({ type: type.REGISTER_USER_SUCCESS, payload: register });
  } catch (e) {
    yield put({ type: type.REGISTER_USER_FAILED, message: e.message });
  }
}

function* registerUserSaga() {
  yield takeLatest("REGISTER_USER", registerUser);
}

export default registerUserSaga;
