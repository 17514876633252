import axios from "axios";
import * as Utils from "@utils";

//apply base url for axios
// const REACT_APP_API_URL =
//   "https://6d9agu86aa.execute-api.us-east-1.amazonaws.com/dev/";

// const REACT_APP_API_URL =
//   "https://ptuev33k53.execute-api.us-east-2.amazonaws.com/";
// const REACT_APP_API_URL =
//   "https://i0nf3vtix1.execute-api.us-east-2.amazonaws.com/dev";

// const REACT_APP_API_URL =
//   "https://s84oclvcgk.execute-api.us-east-2.amazonaws.com/dev";

const axiosApi = axios.create({
  baseURL: Utils.assignDomainUrl(),
});

const headers = {
  Authorization: Utils.getStorage().token,
};

axiosApi.interceptors.request.use(function (config) {
  const token = Utils.getStorage()?.token.split(" ").reverse();
  if (token) {
    config.headers["Authorization"] = token[0];
  }
  config.headers["Content-Type"] = "application/json";
  return config;
});

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export async function get(url, config) {
  return await axiosApi
    .get(url, {
      ...config,
    })
    .then((response) => response.data);
}

export async function post(url, config) {
  return await axiosApi
    .post(url, {
      ...config,
    })
    .then((response) => response.data);
}

export async function put(url, config) {
  return await axiosApi
    .put(url, {
      ...config,
    })
    .then((response) => response.data);
}

/** Search Bot API GET call */

const REACT_APP_API_URL_SEARCH = "https://bot.phenowise.com/";

const axiosApiSearch = axios.create({
  baseURL: REACT_APP_API_URL_SEARCH,
});

axiosApiSearch.interceptors.request.use(function (config) {
  const token = Utils.getStorage()?.token.split(" ").reverse();
  if (token) {
    config.headers["Authorization"] = token[0];
  }
  config.headers["Content-Type"] = "application/json";
  return config;
});

axiosApiSearch.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export async function getSearchResult(url, config) {
  return await axiosApiSearch
    .get(url, {
      ...config,
    })
    .then((response) => response.data);
}
