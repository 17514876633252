import { styled } from "@mui/material/styles";
import { Grid, Button, Typography } from "@mui/material";

export const StyledMainHeading = styled("h1")`
  font-family: "Manrope", sans-serif;
  margin-bottom: 10px;
  margin-top: 0;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  color: #000;

  // @media only screen and (max-width: 700px) {
  //   margin-bottom: 0px;
  //   margin-top: 0;
  // }
`;
export const StyledSubHeading = styled("h5")`
  font-size: 12px;
  color: #9a999a;
  margin: 10px 0 30px;
  text-align: center;
`;
export const StyledViewIcon = styled(Grid)`
  position: absolute;
  right: 12px;
  top: 12px;
  &.rightMargin {
    right: 22px !important;
  }
`;

export const StyledFieldsWrapper = styled(Grid)`
  display: flex;
  position: relative;

  .MuiOutlinedInput-root.MuiAutocomplete-input {
    font-family: "Manrope", sans-serif;
    padding: 4px;
    font-size: 14px;
  }
  .MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline:before,
  .MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline:after {
    border-bottom: 0px;
  }
  .MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.Mui-focused {
    border: 1px solid #26867d;
  }
  .MuiTextField-root p.Mui-error {
    position: relative;
    bottom: 0;
  }
  .cls-tenant fieldset {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0px;
  }
  .cls-cls-firstname-textfield-wrapper {
    width: 48% !important;
  }
  .cls-cls-lastname-textfield-wrapper {
    width: 48% !important;
    margin-right: 20px;
  }

  @media only screen and (max-width: 700px) {
    flex-direction: column;
    .cls-cls-firstname-textfield-wrapper,
    .cls-cls-lastname-textfield-wrapper {
      width: 100% !important;
    }
    .cls-cls-zipcode-textfield-wrapper {
      margin-top: 20px;
    }
  }
`;

export const StyledMainSelectFieldsWrapper = styled(Grid)`
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  margin-top: 80px;
  label {
    font-size: 12px;
  }

  @media only screen and (max-width: 700px) {
    padding: 10px 15px;
  }
`;

export const StyledSubMainWrapperForm = styled("form")`
  @media only screen and (max-width: 700px) {
    height: calc(100% - 95px);
  }
`;

export const StyledButtonWrapper = styled(Button)`
  font-family: "Manrope", sans-serif;
  width: 100%;
  text-transform: capitalize;
  background: #26867d;
  border-radius: 4px;
  color: #ffffff;
  padding: 4px;
  font-size: 14px;
  &.Mui-disabled {
    background: #ccc;
  }

  &:hover {
    background-color: #1b2330;
  }
  .MuiCircularProgress-indeterminate {
    width: 20px !important;
    height: 20px !important;
    margin-right: 10px;
  }
  &.MuiButtonBase-root.MuiButton-root:hover {
    background: #ccc;
  },
`;

export const StyledRegisterGrid = styled(Grid)`
  text-align: center;
  margin: 40px 0;
`;

export const StyledForgotGrid = styled(Grid)`
  text-align: right;
  margin: -10px 10px 20px 10px;
`;

export const StyledGridOuter = styled(Grid)`
  margin-top: 20px;
  @media only screen and (min-width: 768px) {
    width: 60%;
    margin: 0 auto;
  }

  @media only screen and (max-width: 420px) {
    width: 100%;
    margin: 0 auto;
  }
`;

export const StyledButtonOuterWrapper = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
`;
