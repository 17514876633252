import React from "react";
import _ from "lodash";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { Auth } from "aws-amplify";
import * as Utils from "@utils";
import { updateUserCategory } from "./api";

function useSelectUserCategoryStates(props) {
  const {
    initialValues = {},
    validationSchema = {},
    dispatch = () => "",
    navigateTo = () => "",
    handleOpenToast = () => "",
    setSeverityNote = () => "",
    setMessage = () => "",
    handleModelPopupClose = () => "",
    userDetails = () => "",
  } = props || {};

  const [isBtnDisabled, setIsBtnDisabled] = React.useState(false);
  const [isBtnLoading, setIsBtnLoading] = React.useState(false);

  const defaultFormik = {
    initialValues,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setIsBtnDisabled(true);
      setIsBtnLoading(true);
      const { userCategory = "" } = values || {};
      const payLoad = {
        userCategory: userCategory,
        handleOpenToast,
        setMessage,
        setSeverityNote,
        handleModelPopupClose,
        setIsBtnLoading,
        dispatch
      }
      try {
        const updateuser = updateUserCategory({ payload: payLoad, userDetails });
      } catch (error) {
        console.log(error);
      }
    },
  };

  const formik = useFormik(defaultFormik);

  return {
    formik,
    isBtnDisabled,
    setIsBtnDisabled,
    isBtnLoading,
    setIsBtnLoading,
  };
}
export { useSelectUserCategoryStates };
export default useSelectUserCategoryStates;
