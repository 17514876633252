import { combineReducers } from "redux";
import * as Types from "../types";
import userDetails from "./userDetails";
import registerUser from "./registerUser";
import loginUser from "./loginUser";
import categoriesList from "./categoriesList";
import {
  trendingTopicsList,
  trendingTopicsListById,
  topicsListById,
} from "./topicsList";
import userCategory from "./userCategory";
import globalSearch from "./globalSearch";
import notificationsList from "./notificationsList";
import termDetails from "./termDetails";
import { commentsList } from "./contributions";

const appReducer = combineReducers({
  userDetails: userDetails,
  registerUser: registerUser,
  loginUser: loginUser,
  categoriesList: categoriesList,
  trendingTopicsList: trendingTopicsList,
  userCategory: userCategory,
  globalSearch: globalSearch,
  notificationsList: notificationsList,
  termDetails: termDetails,
  topicsListById: topicsListById,
  trendingTopicsListById: trendingTopicsListById,
  commentsList: commentsList,
});

const rootReducer = (state, action) => {
  switch (action.type) {
    case Types.DESTROY_MASTER_SESSION:
      return {
        state: undefined,
      };
    case Types.DESTROY_TERMS_SESSION:
      return {
        ...state,
        termDetails: undefined,
        commentsList: undefined,
      };
    case Types.DESTROY_SEARCH_SESSION:
      return {
        ...state,
        globalSearch: undefined,
      };
    case Types.DESTROY_CATEGORY_TRENDING_SESSION:
      return {
        ...state,
        trendingTopicsListById: undefined,
      };
    case Types.DESTROY_CATEGORY_SESSION:
      return {
        ...state,
        topicsListById: undefined,
      };
    default:
      return appReducer(state, action);
  }
};
export default rootReducer;
