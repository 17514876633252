import * as React from "react";
import * as StyledDOM from "./styles";
import * as IconGallery from "@icongallery";
import {
  Box,
  Button,
  Typography,
  Modal,
  Grid,
  IconButton,
} from "@mui/material";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import styled from "styled-components";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import logoutGif from "../../../assets/json/logoutGif.json";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "300px",
  backgroundColor: "#fff",
  boxShadow: 24,
  padding: "30px",
  textAlign: "center",
  borderRadius: "8px",
};

function ConfirmationPopup(props) {
  const {
    open = false,
    handleClose = () => "",
    handleOpen = () => "",
    modalTitle = "Hey, Wait!!",
    subTitle = `Are you sure, you want to logout from "Phenowise"? We'll miss you.`,
    cancelBtn = "I want to stay",
    confirmBtn = "Yes, Logout",
    triggerConfirmBtn = () => "",
    triggerCancelBtn = () => "",
    isBtnLoading,
    isBtnDisabled,
    showIcon = false,
    showCloseIcon = false,
  } = props || {};

  return (
    <Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <StyledDOM.StyledPopupBox>
          {showIcon && (
            <Player
              autoplay
              loop
              src={logoutGif}
              style={{ height: "100px", width: "100px" }}
            >
              <Controls
                visible={false}
                buttons={["play", "repeat", "frame", "debug"]}
              />
            </Player>
          )}
          {showCloseIcon && (
            <StyledDOM.StyledTitleTypography
              id="modal-modal-title"
              variant="h6"
              component="h6"
            >
              <IconButton onClick={handleClose}>
                <IconGallery.ClosePopUpIcon />
              </IconButton>
            </StyledDOM.StyledTitleTypography>
          )}
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {modalTitle}
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, color: "#9a999a" }}
          >
            {subTitle}
          </Typography>
          <Typography
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
              gap: "20px",
            }}
          >
            <StyledDOM.StyledConfirmButton
              type="button"
              onClick={triggerConfirmBtn}
              disabled={isBtnDisabled ? true : false}
            >
              {isBtnLoading && <CircularProgress disableShrink />} {confirmBtn}
            </StyledDOM.StyledConfirmButton>
            <StyledDOM.StyledCancelButton
              type="button"
              onClick={triggerCancelBtn}
            >
              {cancelBtn}
            </StyledDOM.StyledCancelButton>
          </Typography>
        </StyledDOM.StyledPopupBox>
      </Modal>
    </Grid>
  );
}

export { ConfirmationPopup };
export default ConfirmationPopup;
