import * as type from "../types";

export const clearReduxSession = () => {
  return { type: type.DESTROY_MASTER_SESSION };
};

export const clearTermsReduxSession = () => {
  return { type: type.DESTROY_TERMS_SESSION };
};
export const clearSearchSession = () => {
  return { type: type.DESTROY_SEARCH_SESSION };
};
export const clearCategoryReduxSession = () => {
  return { type: type.DESTROY_CATEGORY_SESSION };
};
export const clearCategoryTrendingReduxSession = () => {
  return { type: type.DESTROY_CATEGORY_TRENDING_SESSION };
};
