import { styled } from "@mui/material/styles";
import { Grid, Button, Typography, Box } from "@mui/material";

export const StyledFooter = styled(Grid)`
  font-family: "Manrope", sans-serif;
  margin-bottom: 18px;
  display: block;
  position: fixed;
  bottom: -1px;
  text-align: center;
  width: 100%;
  margin: 0px auto;
  background: #ffffff;
  z-index: 9999;
  height: 80px;
  @media (max-width: 300px) {
    height: 70px;
  }
`;

export const StyledTypography = styled(Typography)`
  font-family: "Manrope", sans-serif;
  font-size: 12px;
  @media (max-width: 300px) {
   font-size: 9px;
  }
`;

export const StyledOuterBox = styled(Box)`
  font-family: "Manrope", sans-serif;
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(1, 50px);
  gap: 0;
  padding: 10px 0;
  box-shadow: 0px -5px 5px #ccc;
 
`;

export const StyledInnerBox = styled(Box)`
  font-family: "Manrope", sans-serif;
  background: #fff;
  color: #9a999a;
  border-color: grey;
  p: 1;
  text-align: center;
  font-size: 0.875rem;
  font-weight: 700;
  display: table;

  &.active {
    color: #399d94;
  }
`;
