import { InputAdornment, TextField, Box } from "@mui/material";
import * as IconGallery from "@icongallery";
import * as StyledDOM from "./styles";

const SearchTextField = (props) => {
  const {
    onPressEnter = () => "",
    placeholder = "Search for topics and categories...",
    height = "40px",
    disableSearchBar = false,
    defaultValue = "",
  } = props || {};
  const keyPress = (e) => {
    if (e.keyCode === 13) {
      onPressEnter(e);
    }
  };
  return (
    <TextField
      key={defaultValue ? true : false}
      type="text"
      autoFocus
      disabled={disableSearchBar}
      placeholder={placeholder}
      className="search"
      onKeyDown={keyPress}
      variant="filled"
      defaultValue={defaultValue}
      InputProps={{
        endAdornment: (
          <InputAdornment variant="standard" position="start" padding="40px">
            <Box as="span" style={{ marginTop: "18px", marginRight: "-10px" }}>
              <IconGallery.InputSearchIcon />
            </Box>
          </InputAdornment>
        ),
        style: {
          fontSize: 11,
          paddingBottom: "15px",
          height,
          border: "1px solid #3FC2D4",
          fontFamily: "Manrope, sans-serif",
          width: "100%",
        },
      }}
    />
  );
};
const Search = (props) => {
  const {
    onPressEnter = () => "",
    placeholder = "Search for topics and categories...",
    height = "40px",
    disableSearchBar = false,
    handleSearchPopupOpen = () => "",
    clsNam = "",
  } = props || {};
  return (
    <StyledDOM.StyledGridWrapper
      className={disableSearchBar ? clsNam + " " : clsNam + " extendWidth"}
    >
      {disableSearchBar ? (
        <>
          <StyledDOM.StyledGrid
            className="check"
            onClick={handleSearchPopupOpen}
          ></StyledDOM.StyledGrid>
          <SearchTextField {...props} />
        </>
      ) : (
        <SearchTextField {...props} />
      )}
    </StyledDOM.StyledGridWrapper>
  );
};

export { Search };
export default Search;
