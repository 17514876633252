import { styled } from "@mui/material/styles";
import {
  Avatar,
  Button,
  Typography,
  Grid,
  Box,
  AppBar,
  Toolbar,
} from "@mui/material";

export const StyledToolbar = styled(Toolbar)`
  font-family: "Manrope", sans-serif;
  min-height: 46px;
`;
export const StyledOuterGrid = styled(Grid)``;
export const StyledTypography = styled(Typography)``;
export const StyledSecondTypography = styled(Typography)`
  font-family: "Manrope", sans-serif;
  color: black;
  fontweight: bolder;
  margin: 20px;
  text-align: center;
  font-size: 20px;
  font-weight: 900;
`;
export const Styledh6 = styled("h6")`
  font-family: "Manrope", sans-serif;
  margin: 100px 0 0;
  color: darkgray;
  text-align: center;
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
`;
export const StyledButton = styled(Button)`
  font-family: "Manrope", sans-serif;
  border: none;
  font-weight: 900;
  background-color: transparent;
  padding-left: 32px;
  text-transform: capitalize !important;
  font-size: 14px;
  cursor: pointer;
  color: black;
  position: absolute;
  left: 60px;
  top: 2px;
  padding: 10px;
`;
export const StyledGrid = styled(Grid)`
  font-family: "Manrope", sans-serif;
  svg#Group_11026 {
    position: absolute;
    right: 42px;
    top: 9px;
  }
`;
export const StyledDiv = styled("div")`
  font-family: "Manrope", sans-serif;
  width: 10px;
  position: absolute;
  top: 10px;
  img {
    height: 20px;
    position: absolute;
    left: 3px;
    top: 2px;
  }
`;
export const Styledimgborder = styled("div")`
  font-family: "Manrope", sans-serif;
  margin: 0 auto;
  display: table;
  padding: 3px;
  border: 3px dashed hsl(195deg 6% 87%);
  border-radius: 80px;
  z-index: -2;
  margin-top: 30px;
`;
export const Styledavatar = styled(Avatar)`
  font-family: "Manrope", sans-serif;
  height: 100px;
  width: 100px;
`;

export const MainDivContainer = styled(Grid)`
  font-family: "Manrope", sans-serif;
  height: 100vh;
  width: 100%;
`;
export const StyledBoxContainer = styled(Box)`
  font-family: "Manrope", sans-serif;
  flexgrow: 1;
`;
export const StyledAppBar = styled(AppBar)`
  font-family: "Manrope", sans-serif;
  background-color: white;
  color: black;
  box-shadow: none;
`;
export const StyledHeaderh6 = styled("h6")`
  font-family: "Manrope", sans-serif;
  margin: 0;
  color: darkgray;
  text-align: center;
  font-weight: 700;
  font-size: 11px;
  color: #9a999a;
`;
export const StyledHr = styled("hr")`
  font-family: "Manrope", sans-serif;
  border: none;
  border-top: 1px solid #ccc;
  color: #fff;
  background-color: #fff;
  width: 100%;
`;
