import * as React from "react";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ImageIcon from "@mui/icons-material/Image";
import WorkIcon from "@mui/icons-material/Work";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import { Typography } from "@mui/material";
import * as StyledDOM from "./styles";
import _ from "lodash";
import { Fade } from "react-reveal";
const SearchCategoryListView = (props) => {
  const {
    title = "",
    searchTxt = "",
    showIcon = true,
    dataList = [],
    emptyTxt = "",
    triggerList = () => "",
  } = props || {};
  return (
    <StyledDOM.StyledCategoryList>
      {_.isEmpty(dataList) && (
        <StyledDOM.StyledGridNoData>{emptyTxt}</StyledDOM.StyledGridNoData>
      )}
      {!_.isEmpty(dataList) && (
        <StyledDOM.StyledCategoryListTypography variant="h4">
          {title}
        </StyledDOM.StyledCategoryListTypography>
      )}
      {!_.isEmpty(dataList) &&
        dataList.map((item, index) => {
          return (
            <StyledDOM.StyledCategoryListUl key={index}>
              <StyledDOM.StyledCategoryListItem
                onClick={() => triggerList(item)}
              >
                {showIcon && (
                  <ListItemAvatar>
                    <Avatar>
                      <ImageIcon />
                    </Avatar>
                  </ListItemAvatar>
                )}
                <Fade>
                  <StyledDOM.StyledCategoryListItemText
                    primary={item.name}
                    secondary={item.description}
                  />
                </Fade>
              </StyledDOM.StyledCategoryListItem>
            </StyledDOM.StyledCategoryListUl>
          );
        })}
    </StyledDOM.StyledCategoryList>
  );
};
export { SearchCategoryListView };
export default SearchCategoryListView;
