import { styled } from "@mui/material/styles";
import { Box, Grid, Button, Typography } from "@mui/material";

export const StyledOuterGrid = styled(Grid)`
  font-family: "Manrope", sans-serif;
`;
export const StyledBox = styled(Box)`
  font-family: "Manrope", sans-serif;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 24;
  padding: 4;
  text-align: center;
  border: 0px;
  outline: none;
  border-radius: 8px;
  &.searchResult {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
  }
  @media only screen and (max-width: 820px) {
    width: 80%;
    height: 95%;
    &.searchResult {
      width: 100%;
      height: 100%;
    }
    &.socialShare {
      width: 80%;
      height: 30%;
    }
    &.verifyOTPForm {
      width: 90%;
      height: 40%;
    }
    &.iknowsomeon {
      width: 90%;
      height: 70%;
    }
  }

  @media only screen and (max-width: 420px) {
    width: 90%;
    height: 95%;
    &.searchResult {
      width: 100%;
      height: 100%;
    }
    &.socialShare {
      width: 80%;
      height: 30%;
    }
    &.verifyOTPForm {
      width: 90%;
      height: 40%;
    }
    &.iknowsomeon {
      width: 90%;
      height: 70%;
    }
  }
`;

export const StyledTitleTypography = styled(Typography)`
  font-family: "Manrope", sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  float: right;
  position: absolute;
  right: 0;
  top: 0;
  @media (max-width: 300px) {
   font-size: 12px;
  }
`;

export const StyledConfirmButton = styled(Button)`
  border: 1px solid;
  color: #26867d;
  .MuiCircularProgress-indeterminate {
    width: 16px !important;
    height: 16px !important;
    margin-right: 10px;
  }
  @media (max-width: 300px) {
  font-size: 10px;
  }
`;
export const StyledCancelButton = styled(Button)`
  border: 1px solid;
  color: #fff;
  background: #26867d;
  @media (max-width: 300px) {
  font-size: 10px;
  }
`;
export const StyledPopupBox = styled(Box)`
  
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  background-color : white !important;
  boxShadow: 24;
  padding: 30px;
  text-Align: center;
  border-radius: 8px;
  
  @media (max-width: 300px) {
  width: 244px;

  padding: 10px;

  } 
  @media (max-width: 360px) {

  padding: 10px;

  } 
`