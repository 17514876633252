import * as type from "../types";

const initialState = {
  globalSearch: [
    {
      response: [],
      status: "Loading",
    },
  ],
};

export default function globalSearch(state = initialState, action) {
  switch (action.type) {
    case type.GET_GLOBAL_SEARCH_SUCCESS:
      return {
        ...state,
        globalSearch: [
          {
            response: action.payload.hits,
            status: "Success",
          },
        ],
      };
    case type.GET_GLOBAL_SEARCH_FAILED:
      return {
        ...state,
        globalSearch: [
          {
            response: [],
            status: "Error",
          },
        ],
      };
    default:
      return state;
  }
}
