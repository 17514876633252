import React from "react";
import * as ComponentsLib from "@componentsLib";
import * as IconGallery from "@icongallery";
import * as Utils from "@utils";
import * as StyledDOM from "./styles";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSearchStates } from "./useSearchStates";
import { useDispatch, useSelector } from "react-redux";
import {
  getGlobalSearch,
  clearSearchSession,
  getCategoriesList,
} from "../../../../redux/actions";
import SearchCategoryListView from "./SearchCategoryListView";
import _ from "lodash";

function SearchResult(props) {
  const { handleSearchPopupClose = () => "", showCategoryList = true } =
    props || {};
  const params = useParams();
  const { catName = "", searchString = "" } = params || {};

  const objects = [1, 2, 3, 4, 5];
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const getListCat = useSelector(
    (state) => state.categoriesList.categoriesList
  );
  const [firstCatElement = []] = getListCat;
  const { response: responseCat = [], status: statusCat = "Loading" } =
    firstCatElement;
  const filterCatByName = responseCat.filter(
    (item, index) =>
      item?.details?.name
        .replaceAll(" ", "-")
        .replaceAll("/", "-")
        .toLowerCase() === catName
  );
  const [firstElementFilterCatByName = []] = filterCatByName;
  const { id: catId = "", details: catDetails = {} } =
    firstElementFilterCatByName;
  const { title = "all" } = catDetails;
  const globalSearchResult = useSelector(
    (state) => state?.globalSearch?.globalSearch
  );
  const [firstSearchElement = []] = globalSearchResult || [];
  const { response: searchResultResponse = [], status = "Load" } =
    firstSearchElement;
  let categories = [];
  let question = [];
  if (catName === "all") {
    question = searchResultResponse;
  } else if (catName !== "all") {
    question = searchResultResponse;
  }
  // const [firstResultElement = {}] = searchResultResponse;
  // const { categories, question } = firstResultElement;
  const {} = useSearchStates({ dispatch, navigateTo });
  const [searchTxt, setSearchTxt] = React.useState("");
  React.useEffect(() => {
    dispatch(clearSearchSession());
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    if (!_.isEmpty(searchString)) {
      setSearchTxt(searchString);
      dispatch(clearSearchSession());
      dispatch(getGlobalSearch({ searchString: searchString, catName: title }));
    }
  }, [searchString]);

  const onHandleSearch = (e) => {
    e.preventDefault();
    let searchValue = e.target.value.trim();
    navigateTo(`/common/search/${catName}/${searchValue}`);
    if (searchValue.length > 0) {
      dispatch(clearSearchSession());
      dispatch(getCategoriesList());
    }
  };
  const searchInputProps = {
    placeholder: "Search for topics and categories...",
    onPressEnter: onHandleSearch,
    disableSearchBar: false,
    defaultValue: searchTxt,
    clsNam: "searchResult",
  };
  const triggerToCategoryDetails = (item) => {
    navigateTo(
      `/topicdetails/${item.id}/${item.details.name
        .replaceAll(" ", "-")
        .replaceAll("/", "-")
        .toLowerCase()}`
    );
  };
  const triggerToTopicsDetails = (item) => {
    // navigateTo(
    //   `/explore/${item?.categories_id}/category/${item?.id}/${item?.name
    //     .replaceAll(" ", "-")
    //     .replaceAll("/", "-")
    //     .toLowerCase()}`
    // );
    navigateTo(
      `/topicdetails/${item?.id}/${item?.name
        .replaceAll(" ", "-")
        .replaceAll("/", "-")
        .toLowerCase()}`
    );
  };
  const SearchCategoryListViewProps = {
    title: `Categories related to ${searchTxt}`,
    searchTxt,
    showIcon: true,
    dataList: categories,
    emptyTxt: "No Categories found",
    triggerList: triggerToCategoryDetails,
  };
  const SearchTopicsListViewProps = {
    title: `Topics related to ${searchTxt}`,
    searchTxt,
    showIcon: false,
    dataList: question,
    emptyTxt: `No topics found for "${searchTxt}"`,
    triggerList: triggerToTopicsDetails,
  };
  const rectLoaderProps = {
    variant: "rectangular",
    width: "100%",
    height: 100,
  };
  return (
    <>
      <StyledDOM.StyledMainFieldsWrapper>
        <StyledDOM.StyledHeader>
          <StyledDOM.StyledSpanIcon
            onClick={() => {
              dispatch(clearSearchSession());
              // handleSearchPopupClose();
              navigateTo(-1);
            }}
          >
            <IconGallery.BackNavigateIcon />
          </StyledDOM.StyledSpanIcon>
          <ComponentsLib.Search {...searchInputProps} />
        </StyledDOM.StyledHeader>
        <StyledDOM.StyledSearchResult>
          {status == "Load" && (
            <StyledDOM.StyledGridNoData>
              Type to search for topics and categories
            </StyledDOM.StyledGridNoData>
          )}
          {status == "Loading" && (
            <>
              {objects.map(function (object, i) {
                return (
                  <StyledDOM.StyledGrid key={i}>
                    <ComponentsLib.SkeletonLoader {...rectLoaderProps} />
                  </StyledDOM.StyledGrid>
                );
              })}
            </>
          )}
          {/* {status == "Success" && !_.isEmpty(searchResultResponse) && ( */}
          {status == "Success" && (
            <>
              {/* {showCategoryList && ( */}
              {/* <SearchCategoryListView {...SearchCategoryListViewProps} /> */}
              {/* )} */}
              <SearchCategoryListView {...SearchTopicsListViewProps} />
            </>
          )}
          {status == "Error" && (
            <StyledDOM.StyledGridNoData>
              No data found for search <b>"{searchTxt}"</b>
            </StyledDOM.StyledGridNoData>
          )}
        </StyledDOM.StyledSearchResult>
      </StyledDOM.StyledMainFieldsWrapper>
    </>
  );
}

export { SearchResult };
export default SearchResult;
