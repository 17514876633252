import React from "react";
import * as StyledDOM from "../styles";
import * as ComponentsLib from "@componentsLib";
import * as Utils from "@utils";
import CircularProgress from "@mui/material/CircularProgress";
import _ from "lodash";

const EnterPhoneNumber = (props) => {
  const {
    setPhoneNumber = () => "",
    sendOtp = () => "",
    isBtnLoading,
    formValuesObj = {},
    userDynSub = "",
  } = props || {};
  // const { phoneNumber = "" } = formValuesObj;
  let localValues = JSON.parse(localStorage.getItem("formValues"));
  const { phoneNumber = "" } = localValues || {};
  const [disableBtn, setDisableBtn] = React.useState(
    phoneNumber != "" ? false : true
  );
  React.useEffect(() => {
    if (!_.isEmpty(phoneNumber)) {
      setPhoneNumber(phoneNumber);
    }
  }, [setPhoneNumber, phoneNumber]);

  const phoneNumberProps = {
    note: "",
    country: "in",
    inputprops: {
      style: {
        height: "20px",
        backgroundPosition: "10px center",
        backgroundRepeat: "no-repeat",
        border: "1px solid #ccc",
        transition: "all 0.2s",
        borderRadius: "4px",
        fontSize: "16px",
        backgroundColor: "#fff",
      },
    },
    className: "cls-textfield cls-phoneNumber",
    placeholder: "",
    labelname: "Phone Number",
    id: "phoneNumber",
    type: "phone",
    name: "phoneNumber",
    onChange: (value, data, event, formattedValue) => {
      setDisableBtn(false);
      let phoneValue = "+" + value;
      setPhoneNumber(phoneValue);
    },
    defaultValue: phoneNumber,
    helpertext: "",
    variant: "filled",
    disabled: phoneNumber ? true : false,
  };

  const getOTP = () => {
    sendOtp({ userDynSub });
  };

  return (
    <>
      <StyledDOM.StyledSubHeadingOne>{`Almost Done!`}</StyledDOM.StyledSubHeadingOne>
      <StyledDOM.StyledSubHeadingTwo>{`Enter your mobile number`}</StyledDOM.StyledSubHeadingTwo>
      <StyledDOM.StyledFieldsWrapper>
        <ComponentsLib.TextField {...phoneNumberProps} />
      </StyledDOM.StyledFieldsWrapper>
      <StyledDOM.StyledButtonWrapper
        disabled={disableBtn}
        id={"login-button"}
        type={`button`}
        onClick={getOTP}
        className="phoneNumber"
      >
        {isBtnLoading && <CircularProgress disableShrink />}{" "}
        {Utils?.staticText?.register?.otp}
      </StyledDOM.StyledButtonWrapper>
    </>
  );
};

export { EnterPhoneNumber };
export default EnterPhoneNumber;
