import * as React from "react";
import * as IconGallery from "@icongallery";
import * as Utils from "@utils";
import * as ComponentsLib from "@componentsLib";
import {
  Grid,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Button,
} from "@mui/material";
import axios from "axios";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { createFilterOptions } from "@mui/material/Autocomplete";
import Header from "../../../Header";
import { postCommentsApi } from "../api";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getUserDetails } from "../../../../../redux/actions";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";
import CircularProgress from "@mui/material/CircularProgress";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
// import AutoComplete from "./AutoComplete";
const StyledMainDiv = styled(Grid)`
  font-family: "Manrope", sans-serif;
  height: fit-content !important;
  postion: relative;
  padding: 0 15px;
`;
const StyledMainHead = styled("h3")`
  font-family: "Manrope", sans-serif;
  text-align: left;
  font-size: 24px;
  color: #000;
  margin-bottom: 10px;
`;
const StyledSubHead = styled("span")`
  font-family: "Manrope", sans-serif;
  text-align: left;
  float: left;
  font-size: 16px;
`;
const StyledSpanChipGrid = styled(Grid)`
  text-align: left;
  float: left;
  margin-top: 20px;
  height: calc(100vh - 400px);
  overflow-y: scroll;
  font-size: 14px;

  @media only screen and (max-width: 280px) {
    height: calc(100vh - 400px);
  }
`;
const StyledSpanChip = styled("span")`
  font-family: "Manrope", sans-serif;
  margin: 5px;
  color: #000;
  border-color: #000;
  border-radius: 8px;
  padding: 5px;
  border: 1px solid;
  float: left;
  &.filledChip {
    background-color: #9a9a9a;
    color: #fff;
  }
  &.outlinedChip {
    background-color: #ffffff;
  }
`;

const StyledPublishGrid = styled(Grid)`
  font-family: "Manrope", sans-serif;
  position: absolute;
  bottom: 20px;
  right: 15px;
  left: 15px;
  p {
    font-family: "Manrope", sans-serif;
    margin-top: 10px;
  }
`;

const StyledPublishButton = styled(Button)`
  font-family: "Manrope", sans-serif;
  padding: 10px;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid #000;
  padding: 5px 20px;
  text-transform: capitalize;
  color: #000;

  &.Mui-disabled {
    background: rgba(0, 0, 0, 0.08);
    color: #9a9a9a;
    border-color: rgba(0, 0, 0, 0.08);
    cursor: no-drop;
  }
  .MuiCircularProgress-indeterminate {
    width: 20px !important;
    height: 20px !important;
    margin-right: 10px;
  }
`;

const filterOptions = createFilterOptions({
  matchFrom: "start",
  limit: 5,
  trim: true,
});

// const data = [];
const url = "https://comments.free.beeceptor.com/my/api/path";

function IKnowSomeOne(props) {
  const {
    options: data = [],
    handleTriggerNewPopUpClose = () => "",
    setMessage,
    setSeverityNote,
    handleOpenToast,
    handleCloseToast,
    screenRerender,
    setScreenRerender,
    categoryId,
    userCatId,
  } = props || {};
  const dispatch = useDispatch();
  const navigateTo = useNavigate();

  const params = useParams();
  const {
    // categoryId = "",
    // categoryName = "",
    // termName = "",
    termId = "",
  } = params || {};
  const userDetails = useSelector((state) => state.userDetails.userDetails);
  const [firstElement = []] = userDetails;
  const { response = [], status = "Loading" } = firstElement;
  const {
    name = "",
    email = "",
    reputation = "",
    awards = "",
    isVerified = "",
    phone_number = "",
    username = "",
    id: userUUID = "",
    // usr_cat_id = "",
  } = response || {};

  const [submitDisable, setSubmitDisable] = React.useState(true);
  const [symptomArray, setSymptomArray] = React.useState([]);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [isSubmitted, setIsSubmitted] = React.useState(false);

  // React.useEffect(() => {
  //   dispatch(getUserDetails());
  // }, []);

  const removeItem = (item) => {
    setSymptomArray(
      symptomArray.filter((itemVal) => {
        return itemVal !== item;
      })
    );
    if (symptomArray.length == 0) {
      setSubmitDisable(true);
    }
  };

  const handleClick = (item) => {
    setSymptomArray((oldArray) => [...oldArray, item]);
    setSubmitDisable(false);
  };

  const pushSelectedData = () => {
    const uniqueTags = [];
    symptomArray.map((itm) => {
      if (uniqueTags.indexOf(itm) === -1) {
        uniqueTags.push(itm);
      }
    });
    if (symptomArray.length > 0) {
      setErrorMsg("");
      setSubmitDisable(true);
      setIsSubmitted(true);
      try {
        dispatch(
          postCommentsApi({
            termId,
            categoryId,
            userUUID,
            SelectedId: symptomArray,
            usr_cat_id: userCatId,
            handleTriggerNewPopUpClose,
            setSymptomArray,
            setMessage,
            setSeverityNote,
            handleOpenToast,
            handleCloseToast,
            symptomArray,
            screenRerender,
            setScreenRerender,
          })
        );
      } catch (error) {
        console.log(error.response);
      }
    } else {
      setErrorMsg("Please Select any of the symptom to publish");
    }
  };
  return (
    <>
      <StyledMainDiv container>
        <Grid item xs={12} lg={12} sm={12}>
          <StyledMainHead>Know someone with this condition?</StyledMainHead>
          <StyledSubHead>
            Please choose symptoms that they are facing from the list.
          </StyledSubHead>
          <StyledSpanChipGrid>
            {data?.length > 0 &&
              data.map((item, index) => {
                let classChip = symptomArray.find((itm) => itm === item);
                return (
                  <StyledSpanChip
                    key={index}
                    onMouseUp={
                      classChip
                        ? () => removeItem(item)
                        : () => handleClick(item)
                    }
                    className={classChip ? "filledChip" : "outlinedChip"}
                  >
                    {Utils.replaceSpecialCharactersValue(
                      Utils.capitalizeText(item),
                      ""
                    )}
                  </StyledSpanChip>
                );
              })}
          </StyledSpanChipGrid>
          <StyledPublishGrid>
            {errorMsg && (
              <Typography style={{ color: "red" }}>{errorMsg}</Typography>
            )}
            <StyledPublishButton
              onClick={
                submitDisable || symptomArray == 0 ? () => "" : pushSelectedData
              }
              disabled={submitDisable || symptomArray == 0 ? true : false}
              // className={
              //   submitDisable || symptomArray == 0 ? "disabled" : "enabled"
              // }
            >
              {isSubmitted && <CircularProgress disableShrink />}
              Publish
            </StyledPublishButton>
            <Typography>
              Your inputs will be published & will go a long in helping the
              community!
            </Typography>
          </StyledPublishGrid>
        </Grid>
      </StyledMainDiv>
    </>
  );
}
export { IKnowSomeOne };
export default IKnowSomeOne;
