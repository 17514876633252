import React from "react";
import _ from "lodash";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { Auth } from "aws-amplify";
import * as Utils from "@utils";
import * as IconGallery from "@icongallery";

function useNotificationsStates(props) {
  const { dispatch = () => "", navigate = () => "" } = props || {};

  const tabsList = [
    { title: "Contributions", count: "0" },
    { title: "Others", count: "0" },
  ];

  const contributionList = [
    {
      title: "",
      profileUrl: "",
      definition:
        "Arun added contributions on <em>#Headache</em> Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      date: "2023-01-20 12:12:12",
      status: "unread",
      isPromote: false,
    },
    {
      title: "Congratulations!",
      profileUrl: "",
      definition:
        "You have passed first step in <em>Contribution</em> to get your first badge.",
      date: "2023-01-20 12:12:12",
      status: "unread",
      isPromote: true,
    },
    {
      title: "",
      profileUrl: "",
      definition:
        "Arun added contributions on <em>#Headache</em> Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      date: "2023-01-20 12:12:12",
      status: "read",
      isPromote: false,
    },
    {
      title: "",
      profileUrl: "",
      definition:
        "Arun added contributions on <em>#Headache</em> Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      date: "2023-01-20 12:12:12",
      status: "unread",
      isPromote: false,
    },
  ];

  const otherNotificationsList = [
    {
      title: "",
      profileUrl: "",
      definition:
        "Arun added contributions on <em>#Headache</em> Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      date: "2023-01-20 12:12:12",
      status: "read",
      isPromote: false,
    },
    {
      title: "Congratulations!",
      profileUrl: "",
      definition:
        "You have passed first step in <em>Contribution</em> to get your first badge.",
      date: "2023-01-20 12:12:12",
      status: "read",
      isPromote: true,
    },
    {
      title: "",
      profileUrl: "",
      definition:
        "Arun added contributions on <em>#Headache</em> Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      date: "2023-01-20 12:12:12",
      status: "read",
      isPromote: false,
    },
    {
      title: "",
      profileUrl: "",
      definition:
        "Arun added contributions on <em>#Headache</em> Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      date: "2023-01-20 12:12:12",
      status: "unread",
      isPromote: false,
    },
  ];

  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [openModelPopup, setOpenModelPopup] = React.useState(false);

  const handleBackdropClose = () => {
    setOpenBackdrop(false);
  };
  const handleBackdropOpen = () => {
    setOpenBackdrop(true);
  };

  const handleModelPopupClose = () => {
    setOpenModelPopup(false);
  };
  const handleModelPopupOpen = () => {
    setOpenModelPopup(true);
  };

  /* Snackbar functions */
  const [openToast, setOpenToast] = React.useState(false);
  const [vertical, setVertical] = React.useState("top");
  const [horizontal, setHorizontal] = React.useState("right");
  const [severityNote, setSeverityNote] = React.useState("info");
  const [message, setMessage] = React.useState("");

  const [showVerifyEmail, setShowVerifyEmail] = React.useState(false);

  const handleOpenToast = () => {
    setOpenToast(true);
  };

  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
  };
  /*snackbar end */

  return {
    openBackdrop,
    setOpenBackdrop,
    handleBackdropOpen,
    handleBackdropClose,
    openModelPopup,
    setOpenModelPopup,
    handleModelPopupClose,
    handleModelPopupOpen,
    openToast,
    setOpenToast,
    handleOpenToast,
    handleCloseToast,
    vertical,
    setVertical,
    horizontal,
    setHorizontal,
    message,
    setMessage,
    severityNote,
    setSeverityNote,
    tabsList,
    contributionList,
    otherNotificationsList,
  };
}

export { useNotificationsStates };
export default useNotificationsStates;
