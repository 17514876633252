import React from "react";
import { MuiOtpInput } from "mui-one-time-password-input";
import * as StyledDOM from "./styles";
import _ from "lodash";
import { Auth } from "aws-amplify";
import { useParams } from "react-router-dom";

const OtpForm = (props) => {
  const {
    otpCode = "",
    setOtpCode = () => "",
    setSeverityNote = () => "",
    setMessage = () => "",
    handleOpenToast = () => "",
  } = props || {};
  const params = useParams();
  const { emailId = "" } = params || {};
  const [value, setValue] = React.useState("");
  // React.useEffect(() => {
  //   if (!_.isEmpty(otpCode)) {
  //     setOtpCode(otpCode);
  //   }
  // }, [otpCode]);
  const handleChange = (newValue: number) => {
    // setOtpCode(newValue);
    setValue(newValue);
  };

  const handleComplete = (finalValue: number) => {
    setOtpCode(finalValue);
  };

  const [timer, setTimer] = React.useState(30);
  const id = React.useRef(null);
  const clear = () => {
    window.clearInterval(id.current);
  };
  const triggerTimer = () => {
    id.current = window.setInterval(() => {
      setTimer((time) => time - 1);
    }, 1000);
  };
  React.useEffect(() => {
    triggerTimer();
    return () => clear();
  }, []);

  React.useEffect(() => {
    if (timer === 0) {
      clear();
    }
  }, [timer]);

  const resendOTP = async () => {
    await Auth.forgotPassword(emailId)
      .then((data) => {
        const { message = "Code sent to email to reset password" } = data || {};
        handleOpenToast();
        setSeverityNote("success");
        setMessage(message);
      })
      .catch((error) => {
        const { message = "Something went wrong. Please try again later" } =
          error || {};
        handleOpenToast();
        setSeverityNote("error");
        setMessage(message);
      });
  };

  return (
    <>
      <StyledDOM.StyledOtpField
        TextFieldsProps={{
          type: "number",
        }}
        value={value}
        onChange={handleChange}
        onComplete={handleComplete}
        length={6}
        onKeyDown={(evt) =>
          (evt.key === "e" || evt.key === "E") && evt.preventDefault()
        }
      />
      <StyledDOM.StyledResendBtn>
        {timer > 0 ? (
          `Resend OTP in ${timer} seconds.`
        ) : (
          <StyledDOM.StyledButtonLink
            variant="text"
            disabled={timer > 0 ? true : false}
            onClick={() => {
              setTimer(30);
              triggerTimer();
              resendOTP();
            }}
          >
            Resend
          </StyledDOM.StyledButtonLink>
        )}
      </StyledDOM.StyledResendBtn>
    </>
  );
};

export { OtpForm };
export default OtpForm;
