import React from "react";
import _ from "lodash";
import * as Utils from "@utils";
import { Auth } from "aws-amplify";
import {
  loginUser,
  getUserDetails,
  getCategoriesList,
} from "../../../../../redux/actions";
import { usersUpdate, generateOtp, verifyOtp } from "./api";

function useEditProfileStates(props) {
  const {
    initialValues = {},
    validationSchema = {},
    dispatch = () => "",
    navigateTo = () => "",
    response,
    formik,
  } = props || {};

  const [passwordType, setPasswordType] = React.useState("password");
  const [isBtnLoading, setIsBtnLoading] = React.useState(false);
  const [selectedImageFile, setselectedImageFile] = React.useState("");
  const [selectedImage, setselectedImage] = React.useState("");
  const [disableBtn, setDisableBtn] = React.useState(false);
  const [showOTPVerifyForm, setShowOTPVerifyForm] = React.useState(false);
  const [rerenderOnSuccess, setRerenderOnSuccess] = React.useState(false);

  /* BackDrop */

  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const handleBackdropOpen = () => {
    setOpenBackdrop(true);
  };
  const handleBackdropClose = () => {
    setOpenBackdrop(false);
  };

  /* Snackbar functions */
  const [openToast, setOpenToast] = React.useState(false);
  const [vertical, setVertical] = React.useState("top");
  const [horizontal, setHorizontal] = React.useState("right");
  const [severityNote, setSeverityNote] = React.useState("info");
  const [message, setMessage] = React.useState("");

  const handleOpenToast = () => {
    setOpenToast(true);
  };

  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
  };
  /*snackbar end */

  const logoutCallback = async (props) => {
    const { isLogout = false } = props || {};
    if (isLogout) {
      try {
        await Auth.signOut();
        Utils.resetStorage();
      } catch (error) {
        console.log("error signing out: ", error);
      }
    }
  };

  const sendOtp = (props) => {
    const {
      phoneNumber,
      userSub,
      setShowOTPVerifyForm = () => "",
      handleBackdropClose = () => "",
    } = props || {};
    const generateOtpProps = {
      phoneNumber,
      userSub,
      handleOpenToast,
      setMessage,
      setSeverityNote,
      setShowOTPVerifyForm,
      handleBackdropClose,
    };
    generateOtp(generateOtpProps);
  };

  const verifyPhoneNumber = (props) => {
    handleBackdropOpen();
    const {
      code = "",
      phoneNumber = "",
      handleSocialSharePopupClose = () => "",
      userSub = "",
    } = props || {};
    const verifyOtpProps = {
      code,
      phoneNumber,
      handleOpenToast,
      setMessage,
      setSeverityNote,
      openBackdrop,
      setOpenBackdrop,
      handleBackdropClose,
      handleSocialSharePopupClose,
      userSub,
      setRerenderOnSuccess,
    };
    verifyOtp(verifyOtpProps);
  };

  return {
    openToast,
    setOpenToast,
    vertical,
    setVertical,
    horizontal,
    setHorizontal,
    severityNote,
    setSeverityNote,
    message,
    setMessage,
    handleOpenToast,
    handleCloseToast,
    passwordType,
    setPasswordType,
    isBtnLoading,
    setIsBtnLoading,
    selectedImage,
    setselectedImage,
    disableBtn,
    setDisableBtn,
    verifyPhoneNumber,
    sendOtp,
    openBackdrop,
    setOpenBackdrop,
    handleBackdropClose,
    showOTPVerifyForm,
    setShowOTPVerifyForm,
    rerenderOnSuccess,
    setRerenderOnSuccess,
    selectedImageFile,
    setselectedImageFile,
  };
}
export { useEditProfileStates };
export default useEditProfileStates;
