import { styled } from "@mui/material/styles";
import { Grid, Button, Typography } from "@mui/material";
import maskgroup from "../../../assets/images/home/maskgroup.png";

export const StyledBanners = styled(Grid)`
  font-family: "Manrope", sans-serif;
  display: flex;
  padding: 10px 20px;
  text-align: center;
  background-image: url(${maskgroup});
  background-color: #26867d;
  color: #ffffff;
  border-radius: 8px;
  position: relative;
  background-position: left;
  background-attachment: fixed;
`;
export const StyledGrid = styled(Grid)`
font-family: "Manrope", sans-serif;
`;
export const StyledTypography = styled(Typography)`
  font-family: "Manrope", sans-serif;
  float: left;
  font-size: 18px;
  font-weight: 900;
  &.large {
    font-size: 28px;
    font-weight: 900;
  }
  &.small {
    font-size: 16px;
    font-weight: 900;
  }
  &.marginTop {
    margin-top: 10px;
  }
`;
export const StyledInfoText = styled("span")`
  font-family: "Manrope", sans-serif;
  position: relative;
  font-size: 11px;
  bottom: 8px;
  font-weight: 700;
  @media (max-width: 300px) {
  font-size: 8px;
  }
`;
