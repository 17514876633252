import * as type from "../types";

export function getTrendingTopicsList(trendingTopicsList) {
  return {
    type: type.GET_TRENDING_TOPICS,
    payload: trendingTopicsList,
  };
}

export function getTrendingTopicsListById(trendingTopicsListById) {
  return {
    type: type.GET_TRENDING_TOPICS_BY_ID,
    payload: trendingTopicsListById,
  };
}

export function getTopicsListById(topicsListById) {
  return {
    type: type.GET_TOPICS_BY_ID,
    payload: topicsListById,
  };
}
