import React from "react";
import * as StyledDOM from "../styles";
import * as ComponentsLib from "@componentsLib";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import OtpForm from "./otpForm";
import _ from "lodash";

const VerifyPhoneNumber = (props) => {
  const {
    handleNext = () => "",
    handleBack = () => "",
    phoneNumber = "",
    verifyPhoneNumber = () => "",
    otpCode,
    setOtpCode = () => "",
    sendOtp = () => "",
    handleSocialSharePopupClose = () => "",
    userDynSub = "",
    userSub = "",
  } = props || {};
  const [disableBtn, setDisableBtn] = React.useState(true);
  const [timer, setTimer] = React.useState(30);
  const id = React.useRef(null);
  const clear = () => {
    window.clearInterval(id.current);
  };
  const triggerTimer = () => {
    id.current = window.setInterval(() => {
      setTimer((time) => time - 1);
    }, 1000);
  };
  React.useEffect(() => {
    triggerTimer();
    return () => clear();
  }, []);

  React.useEffect(() => {
    if (timer === 0) {
      clear();
    }
  }, [timer]);
  React.useEffect(() => {
    if ("OTPCredential" in window) {
      const ac = new AbortController();

      navigator.credentials
        .get({
          otp: { transport: ["sms"] },
          signal: ac.signal,
        })
        .then((otp) => {
          setOtpCode({ otp: otp.code });
          ac.abort();
        })
        .catch((err) => {
          ac.abort();
          console.log(err);
        });
    }
  }, []);

  const [value, setValue] = React.useState("");
  React.useEffect(() => {
    if (!_.isEmpty(otpCode)) {
      setValue(otpCode);
      verifyPhoneNumber({
        code: otpCode,
        phoneNumber,
        userDynSub,
      });
    }
  }, [otpCode]);
  const handleChange = (newValue: number) => {
    setValue(newValue);
  };

  const handleComplete = (finalValue: number) => {
    verifyPhoneNumber({
      code: finalValue,
      phoneNumber,
      userDynSub,
    });
    setTimeout(() => {
      setValue("");
    }, 2000);
  };

  const otpFormProps = {
    value,
    handleChange,
    handleComplete,
  };

  return (
    <>
      <StyledDOM.StyledSubHeadingOne>{`Verify Your Mobile Number`}</StyledDOM.StyledSubHeadingOne>
      <StyledDOM.StyledSubHeadingThree>{`The verification code has been sent to`}</StyledDOM.StyledSubHeadingThree>
      <StyledDOM.StyledSubHeadingTwo>
        {phoneNumber}
      </StyledDOM.StyledSubHeadingTwo>
      <StyledDOM.StyledFieldsWrapper>
        <OtpForm {...otpFormProps} />
      </StyledDOM.StyledFieldsWrapper>
      <StyledDOM.StyledTypography style={{ textAlign: "center" }}>
        Didn't get OTP?{" "}
        {timer > 0 ? (
          `Resend OTP in ${timer} seconds.`
        ) : (
          <StyledDOM.StyledButtonLink
            variant="text"
            disabled={timer > 0 ? true : false}
            onClick={() => {
              setTimer(30);
              triggerTimer();
              sendOtp();
            }}
          >
            Resend
          </StyledDOM.StyledButtonLink>
        )}
      </StyledDOM.StyledTypography>
    </>
  );
};

export { VerifyPhoneNumber };
export default VerifyPhoneNumber;
