import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { getUserCategory } from "./api";
import * as type from "../types";

function* fetchUserCategorySaga(action) {
  try {
    const userCategory = yield call(getUserCategory, action);
    yield put({
      type: type.GET_USER_CATEGORY_SUCCESS,
      payload: userCategory,
    });
  } catch (e) {
    yield put({ type: type.GET_USER_CATEGORY_FAILED, message: e.message });
  }
}

function* userCategorySaga() {
  yield takeLatest("GET_USER_CATEGORY", fetchUserCategorySaga);
}

export default userCategorySaga;
