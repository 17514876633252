import React from "react";

const initialStorage = (props) => {
  if (localStorage) {
    const {
      attributes = {},
      signInUserSession = {},
      username = "",
    } = props || {};
    const {
      email = "",
      sub = "",
      //   first_name = "",
      //   middle_name = "",
      //   last_name = "",
      //   telephone = "",
    } = attributes;
    const { accessToken = {} } = signInUserSession;
    const { jwtToken = "", payload = {} } = accessToken;
    const { sub: userSub = "" } = payload;
    localStorage.setItem("subId", userSub ? userSub : sub);
    localStorage.setItem("emailId", email);
    localStorage.setItem("username", username);
    // localStorage.setItem("firstName", first_name);
    // localStorage.setItem("middleName", middle_name);
    // localStorage.setItem("lastName", last_name);
    localStorage.setItem("token", jwtToken);
  }
};

const resetStorage = () => {
  if (localStorage) {
    localStorage.clear();
  }
  if (sessionStorage) {
    sessionStorage.clear();
  }
};

const getStorage = () => {
  return {
    subId: localStorage.getItem("subId"),
    username: localStorage.getItem("username"),
    emailId: localStorage.getItem("emailId"),
    token: `Bearer ${localStorage.getItem("token")}`,
  };
};

export { initialStorage, resetStorage, getStorage };
