import React from "react";
import Footer from "../../Footer";
import Header from "../../Header";
import * as StyledDom from "./style";
import { useNavigate, Link } from "react-router-dom";

const NoPage = () => {
  const navigateTo = useNavigate();
  return (
    <>
      <Header />
      <StyledDom.Styled404Box>
        <StyledDom.StyledTypography variant="h1">
          404
        </StyledDom.StyledTypography>
        <StyledDom.StyledTypography variant="h6">
          The page you’re looking for doesn’t exist.
        </StyledDom.StyledTypography>
        <Link to={"/"}>Go to Home</Link>
      </StyledDom.Styled404Box>
      <Footer />
    </>
  );
};

export { NoPage };
export default NoPage;
