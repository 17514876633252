import React from "react";
import * as ComponentsLib from "@componentsLib";
// import * as IconGallery from "@icongallery";
import * as Utils from "@utils";
import { useNavigate } from "react-router-dom";
import * as StyledDOM from "./styles";
import { useExploreStates } from "./useExploreStates";
import { Grid } from "@mui/material";
import Header from "../../Header";
import Footer from "../../Footer";
import { useDispatch, useSelector } from "react-redux";
import giftPngImg from "../../../../assets/images/explore/giftImage.png";

function Explore(props) {
  const {} = props || {};
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const getListCat = useSelector(
    (state) => state.categoriesList.categoriesList
  );
  const [firstElement = []] = getListCat;
  const { response = [], status = "Loading" } = firstElement;
  const {
    openBackdrop,
    setOpenBackdrop,
    handleBackdropOpen,
    handleBackdropClose,
    openModelPopup,
    setOpenModelPopup,
    handleModelPopupClose,
    handleModelPopupOpen,
    openToast,
    setOpenToast,
    handleOpenToast,
    handleCloseToast,
    vertical,
    setVertical,
    horizontal,
    setHorizontal,
    message,
    setMessage,
    severityNote,
    setSeverityNote,
    openSearchPopup,
    setOpenSearchPopup,
    handleSearchPopupClose,
    handleSearchPopupOpen,
  } = useExploreStates({ navigateTo });

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const backDropProps = {
    openBackdrop,
    setOpenBackdrop,
    handleBackdropClose,
  };
  const categoryListProps = {
    dispatch,
    navigateTo,
    showUrl: true,
    home: false,
  };
  const snackBarProps = {
    openToast,
    handleOpenToast,
    handleCloseToast,
    vertical,
    horizontal,
    message,
    severityNote,
  };
  const headerProps = {
    showLogo: false,
    title: "Explore",
  };

  const searchinputprops = {
    placeholder: "Search for topics and categories...",
    // onPressEnter: onHandleSearch,
    disableSearchBar: true,
    handleSearchPopupClose,
    handleSearchPopupOpen,
  };
  const rectLoaderProps = {
    variant: "rounded",
    height: 100,
    width: "100%",
  };
  // const searchResultProps = {
  //   handleSearchPopupClose,
  //   handleSearchPopupOpen,
  // };
  // const componentPopupProps = {
  //   openModelPopup: openSearchPopup,
  //   handleModelPopupClose,
  //   handleModelPopupOpen,
  //   modalTitle: "",
  //   subTitle: "",
  //   renderComponent: <SearchResult {...searchResultProps} />,
  //   showCloseIcon: false,
  //   dynamicClassName: "searchResult",
  // };
  return (
    <>
      <Header {...headerProps} />
      <ComponentsLib.BackDrop {...backDropProps} />
      <StyledDOM.StyledMainFieldsWrapper>
        <ComponentsLib.Search {...searchinputprops} />
        {/* {openSearchPopup && (
          <ComponentsLib.ComponentPopup {...componentPopupProps} />
        )} */}
        <ComponentsLib.GridListCategory {...categoryListProps} />
        <StyledDOM.StyledGiftOuterGrid>
          {status === "Loading" ? (
            <ComponentsLib.SkeletonLoader {...rectLoaderProps} />
          ) : (
            <StyledDOM.StyledGiftGrid container item xs={12}>
              <Grid item xs={8}>
                <StyledDOM.StyledTitleTypography variant="h4">
                  {Utils.staticText.explore.title}
                </StyledDOM.StyledTitleTypography>
                <StyledDOM.StyledContentTypography>
                  {Utils.staticText.explore.content}
                </StyledDOM.StyledContentTypography>
              </Grid>
              <StyledDOM.StyledIconGrid item xs={4}>
                {/* <Player
                  autoplay={false}
                  loop={false}
                  src={giftGif}
                  style={{ height: "92px", width: "92px" }}
                  controls={false}
                >
                  <Controls
                    visible={false}
                    buttons={["play", "repeat", "frame", "debug"]}
                  />
                </Player> */}
                <img src={giftPngImg} alt="" />
              </StyledDOM.StyledIconGrid>
            </StyledDOM.StyledGiftGrid>
          )}
        </StyledDOM.StyledGiftOuterGrid>
        <ComponentsLib.ToastSnackbar {...snackBarProps} />
      </StyledDOM.StyledMainFieldsWrapper>
      {!openSearchPopup && <Footer />}
    </>
  );
}

export { Explore };
export default Explore;
