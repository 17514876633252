import { all, fork } from "redux-saga/effects";
import userDetailsSaga from "./userDetailsSaga";
import registerUserSaga from "./registerUserSaga";
import categoriesListSaga from "./categoriesListSaga";
import topicsListSaga from "./topicsListSaga";
import userCategorySaga from "./userCategorySaga";
import globalSearchSaga from "./globalSearchSaga";
import notificationsListSaga from "./notificationsListSaga";
import termDetailsSaga from "./termDetailsSaga";
import contributionsSaga from "./contributionsSaga";

export default function* rootSaga() {
  yield all([
    fork(userDetailsSaga),
    fork(registerUserSaga),
    fork(categoriesListSaga),
    fork(topicsListSaga),
    fork(userCategorySaga),
    fork(globalSearchSaga),
    fork(notificationsListSaga),
    fork(termDetailsSaga),
    fork(contributionsSaga),
  ]);
}
