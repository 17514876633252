import React, { useEffect, useState } from "react";

import * as StyledDOM from "./styles";
import * as ComponentsLib from "@componentsLib";
import * as IconGallery from "@icongallery";
import * as Utils from "@utils";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/images/curatorlogo.png";
import myaccounticon from "../../../assets/images/myaccounticon.png";
import _ from "lodash";
import { Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  loginUser,
  getUserDetails,
  clearReduxSession,
} from "../../../redux/actions";
import { SocialShare } from "../../common";

import { Amplify, Auth, Hub } from "aws-amplify";
import awsconfig from "../../../aws-exports";
import { registerUser } from "../../../redux/actions";
import AddToHomeScreenButton from "./AddToHomeScreenButton";

// awsconfig.oauth.redirectSignIn = `${window.location.origin}/`;
// awsconfig.oauth.redirectSignOut = `${window.location.origin}/`;
// console.log("window.location.origin", window.location.origin);
Amplify.configure(awsconfig);

const Header = (props) => {
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  // const history = useHistory();
  const {
    showLogo = true,
    title = "",
    showMenu = true,
    showBookmark = false,
    showShare = false,
    showBackIcon = false,
    floatLeft = false,
    backTo = "/explore",
    from = "",
  } = props || {};
  const [user, setUser] = useState(null);
  const [customState, setCustomState] = useState(null);
  const [openSocialSharePopup, setOpenSocialSharePopup] = React.useState(false);

  const handleSocialSharePopupClose = () => {
    setOpenSocialSharePopup(false);
  };
  const handleSocialSharePopupOpen = () => {
    setOpenSocialSharePopup(true);
  };

  useEffect(() => {
    const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          Utils.initialStorage(data);
          setUser(data);
          dispatch(
            loginUser({
              username: data?.username,
              email: data?.attributes?.email,
              navigateTo,
            })
          );
          dispatch(getUserDetails());
          navigateTo("/");
          break;
        case "signOut":
          Utils.resetStorage();
          dispatch(clearReduxSession());
          navigateTo("/login");
          break;
        case "customOAuthState":
          setCustomState(data);
          break;
        case "signIn_failure":
          console.log("user sign in failed");
          break;
      }
    });
    Auth.currentAuthenticatedUser()
      .then((currentUser) => {
        setUser(currentUser);
      })
      .catch(() => console.log("Not signed in"));

    return unsubscribe;
  }, []);

  const backToPreviousPage = (backTo) => {
    // backTo ? navigateTo(backTo) : navigateTo(-1);
    navigateTo(-1);
  };

  const componentPopupProps = {
    openModelPopup: openSocialSharePopup,
    handleModelPopupClose: handleSocialSharePopupClose,
    handleModelPopupOpen: handleSocialSharePopupOpen,
    modalTitle: "",
    subTitle: "",
    renderComponent: <SocialShare />,
    showCloseIcon: true,
    dynamicClassName: "socialShare",
  };
  return (
    <StyledDOM.StyledHeader className={showBackIcon ? "inner" : "outer"}>
      {showBackIcon && (
        <StyledDOM.StyledSpanIcon
          className={from !== "" ? "absolute" : "relative"}
          onClick={() => backToPreviousPage(backTo)}
        >
          <IconGallery.BackNavigateIcon />
        </StyledDOM.StyledSpanIcon>
      )}
      {showLogo ? (
        <>
          <img
            src={`${logo}`}
            width="150"
            height="40"
            alt="Pheno-wise"
            title="Pheno-wise"
            onClick={() => navigateTo("/")}
          />
          {showMenu && !_.isEmpty(Utils.getStorage().subId) && (
            <StyledDOM.StyledSpanMyAccountAbsolute
              onClick={() => navigateTo("/my-account")}
            >
              <img
                src={myaccounticon}
                alt="Curator By Phenowise"
                width="30"
                height="30"
              />
            </StyledDOM.StyledSpanMyAccountAbsolute>
          )}
        </>
      ) : (
        <>
          {floatLeft ? (
            <StyledDOM.StyledTypographyLeft variant="h4">
              {Utils.contentLimitByCharacter(title, 55)}
              {showMenu && !_.isEmpty(Utils.getStorage().subId) && (
                <StyledDOM.StyledSpanIcon
                  onClick={() => navigateTo("/my-account")}
                >
                  <img src={myaccounticon} width="30" height="30" />
                </StyledDOM.StyledSpanIcon>
              )}
            </StyledDOM.StyledTypographyLeft>
          ) : (
            <StyledDOM.StyledTypography variant="h4">
              {Utils.contentLimitByCharacter(title, 55)}
              {showMenu && !_.isEmpty(Utils.getStorage().subId) && (
                <StyledDOM.StyledSpanMyAccountAbsolute
                  onClick={() => navigateTo("/my-account")}
                >
                  <img src={myaccounticon} width="30" height="30" />
                </StyledDOM.StyledSpanMyAccountAbsolute>
              )}
            </StyledDOM.StyledTypography>
          )}
        </>
      )}

      {showBookmark && <AddToHomeScreenButton />}
      {showShare && (
        <StyledDOM.StyledSpanIcon onClick={handleSocialSharePopupOpen}>
          <IconGallery.ShareIcon />
        </StyledDOM.StyledSpanIcon>
      )}
      {openSocialSharePopup && (
        <ComponentsLib.ComponentPopup {...componentPopupProps} />
      )}
    </StyledDOM.StyledHeader>
  );
};

export { Header };
export default Header;
