import React from "react";
import * as StyledDOM from "./styles";
import * as ComponentsLib from "@componentsLib";
import * as IconGallery from "@icongallery";
import * as Utils from "@utils";
import { Auth } from "aws-amplify";
import Header from "../../../Header";
import Footer from "../../../Footer";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import {
  Forminputprops,
  FormFieldValues,
  validationSchema,
  getValid,
} from "./fixture";
import { useCreatePasswordStates } from "./useCreatePasswordStates";
import { clearReduxSession } from "../../../../../redux/actions";

const renderPopupComponent = () => {
  return <>Confirm popup</>;
};
function CreatePassword(props) {
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  let params = useParams();
  const { emailId = "", verificationId = "" } = params || {};

  let locationUrl = window.location.href.split("/").reverse();
  const [firstElement = ""] = locationUrl;

  const { initialValues } = FormFieldValues();
  const {
    formik,
    openToast,
    setOpenToast,
    vertical,
    setVertical,
    horizontal,
    setHorizontal,
    severityNote,
    setSeverityNote,
    message,
    setMessage,
    handleOpenToast,
    handleCloseToast,
    passwordType,
    setPasswordType,
    confirmPasswordType,
    setConfirmPasswordType,
    showLogin,
    setShowLogin,
    open,
    setOpen,
    isBtnLoading,
    setIsBtnLoading,
    isBtnDisabled,
    setIsBtnDisabled,
    handleOpen,
    handleClose,
  } = useCreatePasswordStates({
    initialValues,
    validationSchema,
    dispatch,
    navigateTo,
    emailId,
    verificationId,
  });
  const { passwordProps, confirmPasswordProps, rememberMeProps } =
    Forminputprops({
      formik,
      passwordType,
      confirmPasswordType,
    });

  const togglePassword = () => {
    passwordType === "password"
      ? setPasswordType("text")
      : setPasswordType("password");
  };

  const toggleConfirmPassword = () => {
    confirmPasswordType === "password"
      ? setConfirmPasswordType("text")
      : setConfirmPasswordType("password");
  };

  const snackBarProps = {
    openToast,
    handleOpenToast,
    handleCloseToast,
    vertical,
    horizontal,
    message,
    severityNote,
  };
  const updatePasswordProps = {
    formik,
    togglePassword,
    toggleConfirmPassword,
    passwordProps,
    confirmPasswordProps,
    rememberMeProps,
    passwordType,
    showLogin,
    confirmPasswordType,
    showInfo: true,
    btnTxt: Utils?.staticText?.updateBtnTxt,
    componentFrom: "myAccount",
  };
  const headerProps = {
    showLogo: false,
    title: firstElement.replaceAll("-", " ")
      ? firstElement.replaceAll("-", " ")
      : "Settings",
    showBackIcon: true,
    backTo: "/my-account",
    showMenu: false,
    from: "editprofile",
  };
  const triggerLogOut = async () => {
    setIsBtnLoading(true);
    setIsBtnDisabled(true);
    try {
      await Auth.signOut();
      setIsBtnLoading(false);
      setIsBtnDisabled(false);
      Utils.resetStorage();
      dispatch(clearReduxSession());
      handleClose();
      navigateTo("/login");
    } catch (error) {
      setIsBtnLoading(false);
      setIsBtnDisabled(false);
    }
  };
  const confirmationPopupProps = {
    open,
    setOpen,
    handleOpen,
    handleClose,
    modalTitle: "Password Changed!",
    subTitle: `You password has updated successfully!`,
    cancelBtn: "Stay login",
    confirmBtn: "Login again",
    triggerConfirmBtn: triggerLogOut,
    triggerCancelBtn: handleClose,
    isBtnLoading,
    isBtnDisabled,
    showIcon: false,
  };
  return (
    <>
      <Header {...headerProps} />
      <StyledDOM.StyledMainFieldsWrapper>
        <StyledDOM.StyledMainHeading>
          {Utils?.staticText?.createPassword?.createPasswordTitle}
        </StyledDOM.StyledMainHeading>
        <StyledDOM.StyledSubHeading>
          {Utils?.staticText?.createPassword?.createPasswordTxt}
        </StyledDOM.StyledSubHeading>
        <ComponentsLib.UpdatePassword {...updatePasswordProps} />
      </StyledDOM.StyledMainFieldsWrapper>
      <ComponentsLib.ToastSnackbar {...snackBarProps} />

      <ComponentsLib.ConfirmationPopup {...confirmationPopupProps} />
      {/* <Footer /> */}
    </>
  );
}

export { CreatePassword };
export default CreatePassword;
