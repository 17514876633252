import { styled } from "@mui/material/styles";
import { Grid, Button, Typography } from "@mui/material";

export const StyledGridItem = styled(Grid)`
  font-family: "Manrope", sans-serif;
  @media only screen and (max-width: 820px) {
    .categoryImage {
      width: auto;
      height: auto;
    }
  }
  @media only screen and (max-width: 420px) {
    .categoryImage {
      width: 42px;
      height: 42px;
    }
  }
`;
export const StyledGrid = styled(Grid)`
  font-family: "Manrope", sans-serif;
  @media only screen and (max-width: 820px) {
    .categoryImage {
      width: auto;
      height: auto;
    }
  }
  @media only screen and (max-width: 420px) {
    .categoryImage {
      width: 42px;
      height: 42px;
    }
  }
`;

export const StyledGridList = styled(Grid)`
  font-family: "Manrope", sans-serif;
  display: block;
  @media only screen and (max-width: 820px) {
    display: block;
  }
 
`;

export const StyledMainHeading = styled("h4")`
  font-family: "Manrope", sans-serif;
  font-size: 20px;
  color: #0f0f0f;
  margin: 0px;
  text-align: left;
  font-weight: 900;
`;
export const StyledSubHeading = styled("h5")`
  font-family: "Manrope", sans-serif;
  font-size: 11px;
  color: #9a999a;
  margin: 0px 0 15px;
  text-align: left;
`;

export const StyledTypography = styled(Typography)`
  font-family: "Manrope", sans-serif;
  font-weight: 900;
  line-height: 1;
  word-break: break-word;
  color: #0f0f0f;
  opacity: 0.8;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 1024px) {
    font-size: 16px;
    height: 50px;
  }

  @media only screen and (max-width: 820px) {
    font-size: 14px;
    height: 50px;
  }
  @media only screen and (max-width: 428px) {
    font-size: 12px;
    height: 30px;
  }
   @media (max-width: 300px) {
   font-size: 9px;
  }
`;
