import React from "react";
import _ from "lodash";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { Auth } from "aws-amplify";
import * as Utils from "@utils";
import * as IconGallery from "@icongallery";

function useForgotPasswordStates(props) {
  const {
    initialValues = {},
    validationSchema = {},
    dispatch = () => "",
    navigateTo = () => "",
  } = props || {};

  // const [showResetPassword, setShowResetPassword] = React.useState(false);
  // const [otpFormValue, setOtpFormValue] = React.useState();

  const [isBtnLoading, setIsBtnLoading] = React.useState(false);
  /* Snackbar functions */
  const [openToast, setOpenToast] = React.useState(false);
  const [vertical, setVertical] = React.useState("top");
  const [horizontal, setHorizontal] = React.useState("right");
  const [severityNote, setSeverityNote] = React.useState("info");
  const [message, setMessage] = React.useState("");

  const [showVerifyEmail, setShowVerifyEmail] = React.useState(false);

  const handleOpenToast = () => {
    setOpenToast(true);
  };

  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
  };
  /*snackbar end */

  const defaultFormik = {
    initialValues,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setIsBtnLoading(true);
      const { email: username = "" } = values || {};
      const user = await Auth.forgotPassword(username)
        .then((data) => {
          const {
            message = "Code sent to email to reset password",
            name = "",
          } = data || {};
          handleOpenToast();
          setSeverityNote("success");
          setMessage(message);
          resetForm({ values: "" });
          setIsBtnLoading(false);
          // setShowResetPassword(true);
          navigateTo(`/reset-password/${username}/`);
        })
        .catch((error) => {
          const {
            message = "Something went wrong. Please try again later",
            name = "",
          } = error || {};
          handleOpenToast();
          setSeverityNote("error");
          setIsBtnLoading(false);
          setMessage(message);
        });
    },
  };

  const formik = useFormik(defaultFormik);

  return {
    formik,
    openToast,
    setOpenToast,
    vertical,
    setVertical,
    horizontal,
    setHorizontal,
    severityNote,
    setSeverityNote,
    message,
    setMessage,
    handleOpenToast,
    handleCloseToast,
    isBtnLoading,
    setIsBtnLoading,
    // showResetPassword,
    // setShowResetPassword,
    // otpFormValue,
    // setOtpFormValue,
  };
}

export { useForgotPasswordStates };
export default useForgotPasswordStates;
