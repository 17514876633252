import React from "react";
import * as StyledDOM from "./styles";
import * as ComponentsLib from "@componentsLib";
import * as IconGallery from "@icongallery";
import * as Utils from "@utils";
import Header from "../../Header";
import Footer from "../../Footer";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import _ from "lodash";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Forminputprops,
  FormFieldValues,
  validationSchema,
  getValid,
} from "./fixture";
import { useForgotPasswordStates } from "./useForgotPasswordStates";

import ResetPassword from "../ResetPassword";

function ForgotPassword(props) {
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const { initialValues } = FormFieldValues();
  const {
    formik,
    openToast,
    setOpenToast,
    vertical,
    setVertical,
    horizontal,
    setHorizontal,
    severityNote,
    setSeverityNote,
    message,
    setMessage,
    handleOpenToast,
    handleCloseToast,
    isBtnLoading,
    setIsBtnLoading,
    // showResetPassword,
    // otpFormValue,
    // setOtpFormValue,
  } = useForgotPasswordStates({
    initialValues,
    validationSchema,
    dispatch,
    navigateTo,
  });
  const { emailProps } = Forminputprops({
    formik,
  });

  const snackBarProps = {
    openToast,
    handleOpenToast,
    handleCloseToast,
    vertical,
    horizontal,
    message,
    severityNote,
  };

  // const resetPasswordProps = {
  //   otpFormValue,
  //   setOtpFormValue,
  // };

  return (
    <>
      <Header />
      <StyledDOM.StyledMainFieldsWrapper>
        {/* {!showResetPassword ? ( */}
        <>
          <StyledDOM.StyledMainHeading>
            {Utils?.staticText?.register?.forgotPassword}
          </StyledDOM.StyledMainHeading>
          <StyledDOM.StyledSubMainWrapperForm onSubmit={formik.handleSubmit}>
            <StyledDOM.StyledGridOuter>
              <StyledDOM.StyledFieldsWrapper>
                <ComponentsLib.TextField {...emailProps} />
              </StyledDOM.StyledFieldsWrapper>
              <StyledDOM.StyledButtonWrapper
                // disabled={!(formik.dirty && formik.isValid)}
                id={"login-button"}
                type={`submit`}
              >
                {isBtnLoading && <CircularProgress disableShrink />} Send Code
              </StyledDOM.StyledButtonWrapper>
            </StyledDOM.StyledGridOuter>
          </StyledDOM.StyledSubMainWrapperForm>
          <StyledDOM.StyledLoginInnerGridLevelTwo>
            Back To <Link to="/login">{Utils?.staticText?.login?.button}</Link>
          </StyledDOM.StyledLoginInnerGridLevelTwo>
        </>
        {/* ) : (
          <>
            <StyledDOM.StyledMainHeadingTwo>
              {Utils?.staticText?.register?.resetPassword}
            </StyledDOM.StyledMainHeadingTwo>
            <ResetPassword {...resetPasswordProps} />
          </>
        )} */}
      </StyledDOM.StyledMainFieldsWrapper>
      <ComponentsLib.ToastSnackbar {...snackBarProps} />
      {/* <Footer /> */}
    </>
  );
}

export { ForgotPassword };
export default ForgotPassword;
