import React from "react";
import { Outlet, Link, useNavigate, useParams } from "react-router-dom";
import * as ComponentsLib from "@componentsLib";
import * as IconGallery from "@icongallery";
import * as Utils from "@utils";
import {
  Forminputprops,
  FormFieldValues,
  validationSchema,
  getValid,
  steps,
} from "./fixture";
import * as StyledDOM from "./styles";
import { useRegisterStates } from "./useRegisterStates";
import { Grid, Box, Step, StepLabel } from "@mui/material";
import Header from "../../Header";
import Footer from "../../Footer";
import RegisterForm from "./stepperForms/registerForm";
import VerifyEmail from "./stepperForms/verifyEmail";
import EnterPhoneNumber from "./stepperForms/enterPhoneNumber";
import VerifyPhoneNumber from "./stepperForms/verifyPhoneNumber";
import SignUpInfo from "./stepperForms/signUpInfo";
import { useDispatch } from "react-redux";
import { registerUser } from "../../../../redux/actions/registerUser";
import { useFormik } from "formik";
import { Auth } from "aws-amplify";
import _ from "lodash";

const Register = (props) => {
  const {} = props || {};
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const { initialValues } = FormFieldValues();
  let params = useParams();
  const { type = "", uuid = "", userSub = "" } = params || {};

  const {
    passwordType,
    setPasswordType,
    phoneNumber,
    setPhoneNumber,
    openToast,
    setOpenToast,
    severityNote,
    setSeverityNote,
    vertical,
    setVertical,
    horizontal,
    setHorizontal,
    message,
    setMessage,
    handleOpenToast,
    handleCloseToast,
    openBackdrop,
    setOpenBackdrop,
    handleBackdropOpen,
    handleBackdropClose,
    emailVerifyText,
    setEmailVerifyText,
    emailVerifySubText,
    setEmailVerifySubText,
    verifyEmailCode,
    resendTrigger,
    disableResendBtn,
    setDisableResendBtn,
    sendOtp,
    verifyPhoneNumber,
    otpCode,
    setOtpCode,
    isBtnLoading,
    setIsBtnLoading,
    activeStep,
    setActiveStep,
    activeStepArray,
    setActiveStepArray,
    formValuesObj,
    setFormValuesObj,
    userDynSub,
    setUserDynSub,
  } = useRegisterStates({
    initialValues,
    validationSchema,
    dispatch,
    navigateTo,
    emailId: "",
    uuid,
    userSub,
    // setActiveStep,
  });

  React.useEffect(() => {
    if (!_.isEmpty(uuid)) {
      setEmailVerifyText("Verifying...");
      setEmailVerifySubText(
        "We are verifying your email and confirmation code. Please wait..."
      );
      // handleBackdropOpen();
      // setActiveStep(1);
      // verifyEmailCode({
      //   emailId: "",
      //   uuid,
      //   userSub,
      //   type,
      //   setEmailVerifyText,
      //   setEmailVerifySubText,
      //   handleBackdropClose,
      //   setDisableResendBtn,
      //   setActiveStep,
      //   navigateTo,
      //   setSeverityNote,
      //   setMessage,
      //   handleOpenToast,
      // });
    }
  }, [uuid]);

  const defaultFormik = {
    initialValues,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      // handleBackdropOpen();
      setIsBtnLoading(true);
      const {
        fullName = "",
        phoneNumber = "",
        password = "",
        email = "",
      } = values || {};
      setFormValuesObj(values);
      try {
        const { user, userConfirmed, userSub } = await Auth.signUp({
          username: email,
          password: password,
          // attributes: {
          //   "custom:first_name": fullName,
          //   "custom:last_name": fullName,
          //   "custom:middle_name": "",
          //   "custom:authorized_by": "",
          //   "custom:telephone": phoneNumber,
          // },
          attributes: {
            email: email,
            given_name: fullName,
            family_name: fullName,
            middle_name: "",
            phone_number: phoneNumber,
          },
          autoSignIn: {
            // optional - enables auto sign in after user is confirmed
            enabled: false,
          },
        });
        if (userSub) {
          setUserDynSub(userSub);
          const usersSignupProps = {
            fullName,
            phoneNumber,
            password,
            email,
            handleOpenToast,
            setSeverityNote,
            setMessage,
            handleNext,
            navigateTo,
            userSub,
            // handleBackdropOpen,
            // handleBackdropClose,
            name: user.getUsername(),
            setActiveStep,
            setIsBtnLoading,
            setActiveStepArray,
          };
          localStorage.setItem("formValues", JSON.stringify(values));
          dispatch(registerUser(usersSignupProps));
          // resetForm({ values: "" });
        }
      } catch (error) {
        const { message = "Something went wrong. Please try again later" } =
          error || {};
        handleBackdropClose();
        handleOpenToast();
        setSeverityNote("error");
        setMessage(message);
        setIsBtnLoading(false);
      }
    },
  };

  const formik = useFormik(defaultFormik);

  const { nameProps, phoneNumberProps, emailProps, passwordProps } =
    Forminputprops({
      formik,
      passwordType,
    });

  const isStepOptional = (step: number) => {
    return step === 1;
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setActiveStepArray(1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const registerFromProps = {
    formik,
    passwordType,
    setPasswordType,
    activeStep,
    setActiveStep,
    handleNext,
    handleBack,
    handleSkip,
    handleReset,
    openToast,
    setOpenToast,
    severityNote,
    setSeverityNote,
    vertical,
    setVertical,
    horizontal,
    setHorizontal,
    message,
    setMessage,
    handleOpenToast,
    handleCloseToast,
    isBtnLoading,
    setIsBtnLoading,
    reLoadForm: activeStepArray == "0" ? true : false,
    formValuesObj,
  };

  const verifyEmailProps = {
    handleNext,
    handleBack,
    openToast,
    setOpenToast,
    severityNote,
    setSeverityNote,
    vertical,
    setVertical,
    horizontal,
    setHorizontal,
    message,
    setMessage,
    handleOpenToast,
    handleCloseToast,
    emailVerifyText,
    setEmailVerifyText,
    emailVerifySubText,
    setEmailVerifySubText,
    resendTrigger,
    disableResendBtn,
    setActiveStep,
    isBtnLoading,
    verifyEmailCode,
    formValuesObj,
    setOpenBackdrop,
    formValuesObj,
  };

  const enterPhoneNumberProps = {
    handleNext,
    handleBack,
    setPhoneNumber,
    openToast,
    setOpenToast,
    severityNote,
    setSeverityNote,
    vertical,
    setVertical,
    horizontal,
    setHorizontal,
    message,
    setMessage,
    handleOpenToast,
    handleCloseToast,
    sendOtp,
    isBtnLoading,
    formValuesObj,
    userDynSub,
  };

  const verifyPhoneNumberProps = {
    handleNext,
    handleBack,
    phoneNumber,
    openToast,
    setOpenToast,
    severityNote,
    setSeverityNote,
    vertical,
    setVertical,
    horizontal,
    setHorizontal,
    message,
    setMessage,
    handleOpenToast,
    handleCloseToast,
    verifyPhoneNumber,
    otpCode,
    setOtpCode,
    sendOtp,
    isBtnLoading,
    formValuesObj,
    userDynSub,
  };

  const snackBarProps = {
    openToast,
    handleOpenToast,
    handleCloseToast,
    vertical,
    horizontal,
    message,
    severityNote,
  };

  const socialLoginProps = {
    dottedText: "Or Register with",
  };

  const backDropProps = {
    openBackdrop,
    setOpenBackdrop,
    handleBackdropClose,
  };

  const signUpInfoProps = {
    navigateTo,
  };

  return (
    <>
      <ComponentsLib.BackDrop {...backDropProps} />
      <Header />
      <StyledDOM.StyledMainFieldsWrapper>
        <StyledDOM.StyledMainHeading>
          {Utils?.staticText?.register?.signUp}
        </StyledDOM.StyledMainHeading>
        <StyledDOM.StyledSubHeadingTwo>
          {Utils?.staticText?.register?.signUpSubText}
        </StyledDOM.StyledSubHeadingTwo>
        <StyledDOM.StyledBox>
          {activeStep === 0 && (
            <ComponentsLib.SocialLogin {...socialLoginProps} />
          )}
          <StyledDOM.StyledStepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode,
              } = {};

              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{""}</StepLabel>
                </Step>
              );
            })}
          </StyledDOM.StyledStepper>
          {activeStep === 0 && (
            <>
              <RegisterForm {...registerFromProps} />
            </>
          )}
          {activeStep === 1 && (
            <>
              <VerifyEmail {...verifyEmailProps} />
            </>
          )}
          {activeStep === 2 && (
            <>
              <EnterPhoneNumber {...enterPhoneNumberProps} />
            </>
          )}
          {activeStep === 3 && (
            <>
              <VerifyPhoneNumber {...verifyPhoneNumberProps} />
            </>
          )}
          {activeStep === 4 && (
            <>
              <SignUpInfo {...signUpInfoProps} />
            </>
          )}
        </StyledDOM.StyledBox>
      </StyledDOM.StyledMainFieldsWrapper>
      <ComponentsLib.ToastSnackbar {...snackBarProps} />
      {/* <Footer /> */}
    </>
  );
};

export { Register };
export default Register;
