import React from "react";
import * as yup from "yup";

export const getValid = (value, optionalFieldItm = "") => {
  const { ...balanceProps } = value;
  let values = Object.keys(balanceProps).map((itm) => {
    let obj = "";
    if (balanceProps[itm].length === 0) {
      obj = itm;
    }
    return obj;
  });

  return values.filter((itm) => itm !== "");
};

export const FormFieldValues = (props) => {
  const initialValues = {
    email: "",
  };
  return {
    initialValues,
  };
};

export const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email Address is required"),
});

export const Forminputprops = (props) => {
  const { formik } = props;

  const emailProps = {
    note: "",
    inputprops: {
      style: {
        height: "20px",
        backgroundPosition: "10px center",
        backgroundRepeat: "no-repeat",
        border: "1px solid #ccc",
        transition: "all 0.2s",
        borderRadius: "4px",
        fontSize: "16px",
        backgroundColor: "#fff",
      },
    },
    className: "cls-textfield cls-email",
    placeholder: "",
    labelname: "Email Address",
    id: "email",
    type: "text",
    name: "email",
    onChange: (e) => {
      formik?.setFieldTouched("email");
      formik?.handleChange(e);
    },
    defaultValue: formik?.values.email,
    helpertext:
      formik?.errors.email &&
      formik?.touched.email &&
      `${formik?.errors.email}`,
    error: formik?.errors.email && formik?.touched.email,
    variant: "filled",
    size: "small",
    formik: formik,
  };

  return {
    emailProps,
  };
};
