import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { getNotificationsList } from "./api";
import * as type from "../types";

function* fetchNotificationsListSaga(action) {
  try {
    const categoryList = yield call(getNotificationsList, action);
    yield put({
      type: type.GET_NOTIFICATION_LIST_SUCCESS,
      payload: categoryList,
    });
  } catch (e) {
    yield put({ type: type.GET_NOTIFICATION_LIST_FAILED, message: e.message });
  }
}

function* notificationsListSaga() {
  yield takeLatest("GET_NOTIFICATION_LIST", fetchNotificationsListSaga);
}

export default notificationsListSaga;
