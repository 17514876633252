import React from "react";
import * as StyledDOM from "./styles";
import * as IconGallery from "@icongallery";
import { useFooterStates } from "./useFooterStates";
import Box, { BoxProps } from "@mui/material/Box";
import { Typography } from "@mui/material";
import _ from "lodash";
import { NavLink } from "react-router-dom";

function Item(props: BoxProps) {
  const { sx, ...other } = props;
  return <StyledDOM.StyledInnerBox {...other} />;
}

const Footer = () => {
  const { menuList, activeMenu, setActiveMenu } = useFooterStates();
  let locationUrl = window.location.href.split("/").reverse();
  const [firstElement = ""] = locationUrl;
  return (
    <StyledDOM.StyledFooter>
      <StyledDOM.StyledOuterBox>
        {!_.isEmpty(menuList) &&
          menuList.map((menuItem, index) => {
            return (
              <Item
                key={index}
                className={activeMenu == menuItem.name ? "active" : ""}
              >
                <NavLink
                  to={menuItem.url}
                  style={({ isActive }) => ({
                    color: isActive ? "#399d94" : "#9a999a",
                    textDecoration: "none",
                  })}
                >
                  {menuItem.icon}
                  <StyledDOM.StyledTypography>
                    {menuItem.name}
                  </StyledDOM.StyledTypography>
                </NavLink>
              </Item>
            );
          })}
      </StyledDOM.StyledOuterBox>
    </StyledDOM.StyledFooter>
  );
};

export { Footer };
export default Footer;
