import React from "react";
import * as yup from "yup";

export const getValid = (value, optionalFieldItm = "") => {
  const { ...balanceProps } = value;
  let values = Object.keys(balanceProps).map((itm) => {
    let obj = "";
    if (balanceProps[itm].length === 0) {
      obj = itm;
    }
    return obj;
  });

  return values.filter((itm) => itm !== "");
};

export const FormFieldValues = (props) => {
  const {
    name = "",
    email = "",
    phone_number = "",
    profile_image = "",
  } = props || {};
  const initialValues = {
    Img: profile_image,
    fullName: name,
    phoneNumber: phone_number,
    email: email,
  };
  return {
    initialValues,
  };
};

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const validationSchema = yup.object().shape({
  fullName: yup.string().required("Full Name is required"),
  phoneNumber: yup.string().required("Phone Number is required"),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email Address is required"),
});

export const Forminputprops = (props) => {
  const {
    formik = () => {},
    passwordType = "password",
    response = {},
    setDisableBtn,
  } = props;

  console.log(props);
  const { name = "" } = response || {};

  const nameProps = {
    note: "",
    inputprops: {
      style: {
        height: "20px",
        backgroundPosition: "10px center",
        backgroundRepeat: "no-repeat",
        border: "1px solid #ccc",
        transition: "all 0.2s",
        borderRadius: "4px",
        fontSize: "16px",
        backgroundColor: "#fff",
      },
    },
    className: "cls-textfield cls-fullName",
    placeholder: "",
    labelname: "Full Name",
    id: "fullName",
    type: "text",
    name: "fullName",
    onChange: (e) => {
      formik?.setFieldTouched("fullName");
      formik?.handleChange(e);
      setDisableBtn(false);
    },
    defaultValue: formik?.values.fullName,
    helpertext:
      formik?.errors.fullName &&
      formik?.touched.fullName &&
      `${formik?.errors.fullName}`,
    error: formik?.errors.fullName && formik?.touched.fullName,
    variant: "filled",
    size: "small",
    formik,
  };

  const phoneNumberProps = {
    note: "",
    disabled: true,
    country: "in",
    inputprops: {
      style: {
        height: "20px",
        backgroundPosition: "10px center",
        backgroundRepeat: "no-repeat",
        border: "1px solid #ccc",
        transition: "all 0.2s",
        borderRadius: "4px",
        fontSize: "16px",
        backgroundColor: "#fff",
      },
    },
    className: "cls-textfield cls-phoneNumber",
    placeholder: "",
    labelName: "Phone Number",
    id: "phoneNumber",
    type: "phone",
    name: "phoneNumber",
    onChange: (value, data, event, formattedValue) => {
      let phoneValue = "+" + value;
      formik.setFieldValue("phoneNumber", phoneValue);
      // formik?.handleChange(event);
    },
    defaultValue: formik?.values.phoneNumber,
    helpertext:
      formik?.errors.phoneNumber &&
      formik?.touched.phoneNumber &&
      `${formik?.errors.phoneNumber}`,
    error: formik?.errors.phoneNumber && formik?.touched.phoneNumber,
    variant: "filled",
    formik,
  };

  const emailProps = {
    note: "",
    disabled: true,
    inputprops: {
      style: {
        height: "20px",
        backgroundPosition: "10px center",
        backgroundRepeat: "no-repeat",
        border: "1px solid #ccc",
        transition: "all 0.2s",
        borderRadius: "4px",
        fontSize: "16px",
        backgroundColor: "#fff",
      },
    },
    className: "cls-textfield cls-email",
    placeholder: "",
    labelname: "Email Address",
    id: "email",
    type: "text",
    name: "email",
    onChange: (e) => {
      formik?.setFieldTouched("email");
      formik?.handleChange(e);
    },
    defaultValue: formik?.values.email,
    helpertext:
      formik?.errors.email &&
      formik?.touched.email &&
      `${formik?.errors.email}`,
    error: formik?.errors.email && formik?.touched.email,
    variant: "filled",
    size: "small",
    formik,
  };

  return {
    nameProps,
    phoneNumberProps,
    emailProps,
  };
};
