import { styled } from "@mui/material/styles";
import { Grid, Button, Typography } from "@mui/material";

export const StyledMainFieldsWrapper = styled(Grid)`
  font-family: "Manrope", sans-serif;
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;

  label {
    font-size: 12px;
  }

  @media only screen and (max-width: 700px) {
    padding: 10px 15px;
  }
`;

export const StyledTitleTypography = styled(Typography)`
  font-family: "Manrope", sans-serif;
  margin: 0px 0px 5px 0px;
  font-size: 16px;
  font-weight: 900;
  color: #0f0f0f;
`;
export const StyledContentTypography = styled(Typography)`
  font-family: "Manrope", sans-serif;
  font-size: 12px;
  font-weight: 700;
  color: #0f0f0f;
`;

export const StyledIconGrid = styled(Grid)`
  font-family: "Manrope", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0f0f0f;
  font-size: 12px;
  font-weight: 700;
`;

export const StyledGiftGrid = styled(Grid)`
  font-family: "Manrope", sans-serif;
  background: #00AB661F;
  border: 1px solid #26867D;
  padding: 15px;
  border-radius: 8px;
}`;
export const StyledGiftOuterGrid = styled(Grid)`
  font-family: "Manrope", sans-serif;
  margin-bottom: 100px;
}`;
