import * as type from "../types";

const initialState = {
  termDetails: [],
};

export default function termDetails(state = initialState, action) {
  switch (action.type) {
    case type.GET_TERM_DETAILS_SUCCESS:
      return {
        ...state,
        termDetails: action.payload.result,
      };
    default:
      return state;
  }
}
