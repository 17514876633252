import { styled } from "@mui/material/styles";
import { Box, Grid, Typography, Button } from "@mui/material";

export const Styled404Box = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 480px;
`;

export const StyledTypography = styled(Typography)`
  color: #000000;
`;
