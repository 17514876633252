import { get, post } from "../../../../helpers/api_helper";
import * as url from "../../../../helpers/url_helper";
import * as Utils from "@utils";

export const registerUserApi = (props) => {
  const { payload = {} } = props || {};
  const {
    email = "",
    userSub = "",
    handleOpenToast = () => "",
    setMessage = () => "",
    setSeverityNote = () => "",
    // logoutCallback = () => "",
    handleBackdropOpen = () => "",
    phoneNumber = "",
    fullName: name = "",
    setActiveStep = () => "",
    setIsBtnLoading = () => "",
    username = "",
    profile_url = "",
    loginFrom = "",
  } = payload || {};
  setIsBtnLoading(true);
  post(url.USER_SIGNUP, {
    email,
    userSub,
    phone_number: phoneNumber,
    name,
    username,
    profile_image: profile_url,
    loginFrom,
  })
    .then((response) => {
      handleBackdropOpen();
      const { data = {} } = response || {};
      const { message = "Verification code sent to registered email." } =
        data || {};
      handleOpenToast();
      setSeverityNote("success");
      setMessage(message);
      // logoutCallback({ isLogout: false });
      setActiveStep(1);
      // handleBackdropClose();
      setIsBtnLoading(false);
    })
    .catch((error) => {
      const { response = {} } = error || {};
      const { data = {} } = response || {};
      const { message = Utils?.staticText?.apiResponseMsg?.error } = data || {};
      handleOpenToast();
      setSeverityNote("error");
      setMessage(message);
      // handleBackdropClose();
      setIsBtnLoading(false);
      // logoutCallback({ isLogout: true });
    });
};

export const verifyEmailApi = (props) => {
  const {
    uuid = "",
    setActiveStep = () => "",
    handleOpenToast = () => "",
    setMessage = () => "",
    setSeverityNote = () => "",
    setEmailVerifyText = () => "",
    setEmailVerifySubText = () => "",
    setDisableResendBtn = () => "",
  } = props || {};
  const verifyUrl = `${url.USERS_EMAIL_VERIFY}?key=${uuid}`;
  get(verifyUrl)
    .then((response) => {
      const { message = "Email verified successfully." } = response || {};
      setEmailVerifyText("Email has been verified.");
      setEmailVerifySubText("");
      handleOpenToast();
      setSeverityNote("success");
      setMessage(message);
      setDisableResendBtn(false);
      setTimeout(() => {
        setActiveStep(2);
      }, 2000);
    })
    .catch((error) => {
      const { response = {} } = error || {};
      const { data = {} } = response || {};
      const { message = Utils?.staticText?.apiResponseMsg?.error } = data || {};
      setEmailVerifyText("Failed to verify email.");
      setEmailVerifySubText("Please try again later.");
      handleOpenToast();
      setSeverityNote("error");
      setMessage(message);
      setDisableResendBtn(false);
    });
};

export const generateOtp = (props) => {
  const {
    phoneNumber = "",
    userSub = "",
    setActiveStep = () => "",
    handleOpenToast = () => "",
    setMessage = () => "",
    setSeverityNote = () => "",
    setOtpCode,
    setIsBtnLoading,
  } = props || {};
  setIsBtnLoading(true);

  post(url.USERS_PHONE_VERIFY, {
    phone_number: phoneNumber,
    username: userSub,
  })
    .then((response) => {
      const {
        message = Utils?.staticText?.apiResponseMsg?.success,
        result = {},
      } = response || {};
      const { message: code = "" } = result;
      setOtpCode(code);
      handleOpenToast();
      setSeverityNote("success");
      setMessage(message);
      setActiveStep(3);
      setIsBtnLoading(false);
    })
    .catch((error) => {
      const { response = {} } = error || {};
      const { data = {} } = response || {};
      const { message = Utils?.staticText?.apiResponseMsg?.error } = data || {};
      handleOpenToast();
      setSeverityNote("error");
      setMessage(message);
      setIsBtnLoading(false);
    });
};

export const verifyOtp = (props) => {
  const {
    code = "",
    phoneNumber = "",
    userSub = "",
    navigateTo = () => "",
    handleOpenToast = () => "",
    setMessage = () => "",
    setSeverityNote = () => "",
    handleBackdropClose = () => "",
    setActiveStep = () => "",
  } = props || {};

  post(url.USERS_PHONE_OTP_VERIFY, {
    phone_number: phoneNumber,
    username: userSub,
    OTP: code,
  })
    .then((response) => {
      const { data = {} } = response || {};
      const { message = Utils?.staticText?.apiResponseMsg?.success } =
        data || {};
      handleOpenToast();
      setSeverityNote("success");
      setMessage(
        "You have successfully signed up for curator app. Please login to continue."
      );
      setActiveStep(4);
      handleBackdropClose();
      localStorage.clear();
      // setTimeout(() => {
      //   navigateTo("/");
      // }, 2000);
    })
    .catch((error) => {
      const { response = {} } = error || {};
      const { data = {} } = response || {};
      const { message = Utils?.staticText?.apiResponseMsg?.error } = data || {};
      handleOpenToast();
      setSeverityNote("error");
      setMessage(message);
      handleBackdropClose();
      // logoutCallback({ isLogout: true });
    });
};
