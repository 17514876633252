import React, { useRef, useEffect } from "react";
import chooseimag from "./chooseimg.png";
import { TextField, Typography } from "@mui/material";
import { Box, AppBar, Toolbar } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import * as StyledDOM from "./styles";
import * as ComponentsLib from "@componentsLib";
import * as Utils from "@utils";
import Header from "../../../Header";
import Footer from "../../../Footer";
import {
  Forminputprops,
  FormFieldValues,
  validationSchema,
  getValid,
} from "./fixture";
import { useEditProfileStates } from "./useEditProfileStates";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails } from "../../../../../redux/actions";
import _ from "lodash";
import { Auth } from "aws-amplify";
import { usersUpdate } from "./api";
import { useFormik } from "formik";
import VerifyPhoneNumber from "../../Register/stepperForms/verifyPhoneNumber";

function EditProfile() {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.userDetails.userDetails);
  const [firstElement = []] = userDetails;
  const { response = [], status: apiStatus = "Loading" } = firstElement;

  const { initialValues } = FormFieldValues(response);

  const [enableReinitializeForm, setEnableReinitializeForm] =
    React.useState(false);

  React.useEffect(() => {
    if (apiStatus == "Loading") {
      setEnableReinitializeForm(!enableReinitializeForm);
    } else {
      setEnableReinitializeForm(!enableReinitializeForm);
    }
  }, [apiStatus]);

  const defaultFormik = {
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      setIsBtnLoading(true);
      setDisableBtn(true);
      const {
        fullName = "",
        email = "",
        phoneNumber = "",
        Img = "",
      } = values || {};
      try {
        // await Storage.put(email, Img, {
        //   contentType: "image/png", // contentType is optional
        // });
        const user = await Auth.currentAuthenticatedUser();
        const { attributes = {} } = user || {};

        let userUpdated = "";
        if (Utils.checkObjectEmptyValueWithKey(attributes)) {
          userUpdated = await Auth.updateUserAttributes(user, {
            "custom:first_name": fullName,
            "custom:last_name": fullName,
            // picture: "",
            // "custom:telephone": phoneNumber,
          });
        } else {
          userUpdated = "SUCCESS";
        }
        if (userUpdated == "SUCCESS") {
          usersUpdate({
            name: fullName,
            id: response?.id,
            profile_image: selectedImage ? selectedImage : Img,
            handleOpenToast,
            setSeverityNote,
            setMessage,
            setIsBtnLoading,
            setDisableBtn,
          });
          Utils.initialStorage(user);
          setTimeout(() => {
            dispatch(getUserDetails());
          }, 1000);
          // resetForm({ values: "" });
        }
      } catch (error) {
        const {
          message = "Something went wrong. Please try again later",
          name = "",
        } = error || {};
        handleOpenToast();
        setSeverityNote("error");
        setMessage(message);
        setIsBtnLoading(false);
        setDisableBtn(false);
      }
    },
  };

  const formik = useFormik(defaultFormik);

  const {
    // formik,
    passwordType,
    setPasswordType,
    openToast,
    setOpenToast,
    handleOpenToast,
    handleCloseToast,
    vertical,
    setVertical,
    horizontal,
    setHorizontal,
    message,
    setMessage,
    severityNote,
    setSeverityNote,
    showVerifyEmail,
    resendTrigger,
    isBtnLoading,
    setIsBtnLoading,
    selectedImage,
    setselectedImage,
    disableBtn,
    setDisableBtn,
    verifyPhoneNumber,
    sendOtp,
    openBackdrop,
    setOpenBackdrop,
    handleBackdropClose,
    showOTPVerifyForm,
    setShowOTPVerifyForm,
    rerenderOnSuccess,
    selectedImageFile,
    setselectedImageFile,
  } = useEditProfileStates({
    initialValues,
    validationSchema,
    response,
    formik,
  });

  const { nameProps, phoneNumberProps, emailProps } = Forminputprops({
    formik,
    response,
    setDisableBtn,
  });

  const backicon = () => {
    window.history.back();
  };

  const filefield = useRef(null);
  const previmageRef = useRef();
  React.useEffect(() => {
    dispatch(getUserDetails());
  }, [rerenderOnSuccess]);

  const {
    id = "",
    isEmailVerified = "",
    isPhoneVerified = "",
    isRoleVerified = "",
    isVerified = "",
    loginFrom = "",
    profile_image = "",
    reputation = "",
    userSub = "",
    username = "",
    name = "",
    email = "",
    phone_number = "",
  } = response || {};

  React.useEffect(() => {
    const { name = "", email = "", phone_number = "" } = response || {};
    const data = {
      fullName: name,
      phoneNumber: phone_number,
      email: email,
      Img: profile_image,
    };

    setselectedImage(profile_image);
    async function setInitialValues() {
      if (response) await formik.setValues(data, false);
    }
    setInitialValues();
  }, [response]);

  React.useEffect(() => {
    previmageRef.current = selectedImage;
  }, [selectedImage]);

  const headerProps = {
    showLogo: false,
    title: "Edit Profile",
    showBackIcon: true,
    backTo: "/my-account",
    showMenu: false,
    from: "editprofile",
  };

  const snackBarProps = {
    openToast,
    handleOpenToast,
    handleCloseToast,
    vertical,
    horizontal,
    message,
    severityNote,
  };
  const imageLoaderProps = {
    variant: "circular",
    height: 110,
  };
  const textLoaderProps = {
    variant: "text",
    height: 60,
    width: "100%",
  };
  const rectLoaderProps = {
    variant: "rectangular",
    width: "100%",
    height: 250,
  };
  const handleSocialSharePopupClose = () => {
    setShowOTPVerifyForm(false);
  };
  const handleSocialSharePopupOpen = () => {
    setShowOTPVerifyForm(true);
  };

  const verifyPhoneNumberProps = {
    verifyPhoneNumber,
    phoneNumber: formik?.values?.phoneNumber,
    setSeverityNote,
    setMessage,
    handleOpenToast,
    handleCloseToast,
    openBackdrop,
    setOpenBackdrop,
    handleBackdropClose,
    handleSocialSharePopupClose,
    userSub: userSub,
  };

  const componentPopupProps = {
    openModelPopup: showOTPVerifyForm,
    handleModelPopupClose: handleSocialSharePopupClose,
    handleModelPopupOpen: handleSocialSharePopupOpen,
    modalTitle: "",
    subTitle: "",
    renderComponent: <VerifyPhoneNumber {...verifyPhoneNumberProps} />,
    showCloseIcon: false,
    dynamicClassName: "verifyOTPForm",
  };
  const backDropProps = {
    openBackdrop,
    setOpenBackdrop,
    handleBackdropClose,
  };
  return (
    <>
      <ComponentsLib.BackDrop {...backDropProps} />
      <Header {...headerProps} />
      <StyledDOM.OuterGrid>
        {apiStatus == "Loading" ? (
          <StyledDOM.StyledPlaceholderGrid>
            <ComponentsLib.SkeletonLoader {...imageLoaderProps} />
          </StyledDOM.StyledPlaceholderGrid>
        ) : (
          <StyledDOM.StyledImg>
            <StyledDOM.StyledAvatar alt={name} src={selectedImage} />
          </StyledDOM.StyledImg>
        )}
        {apiStatus == "Loading" ? (
          <></>
        ) : (
          <StyledDOM.StyledCameraIcon>
            <StyledDOM.StyledImgIcon
              alt=""
              src={chooseimag}
              onClick={() => {
                filefield.current.click();
              }}
            />
          </StyledDOM.StyledCameraIcon>
        )}
        <div className="border"></div>
        {apiStatus == "Loading" ? (
          <StyledDOM.StyledLoaderGrid>
            <ComponentsLib.SkeletonLoader {...textLoaderProps} />
          </StyledDOM.StyledLoaderGrid>
        ) : (
          <StyledDOM.StyledQuotes>
            <p>Share a little bit about your self</p>
          </StyledDOM.StyledQuotes>
        )}
        {apiStatus == "Loading" ? (
          <ComponentsLib.SkeletonLoader {...rectLoaderProps} />
        ) : (
          <StyledDOM.StyledSubMainWrapperForm
            key={enableReinitializeForm}
            onSubmit={formik.handleSubmit}
            enableReinitialize={true}
          >
            <input
              hidden
              name="Img"
              ref={filefield}
              type="file"
              // value={formik.values.Img}
              onBlur={formik.handleBlur}
              onChange={(e) => {
                formik.handleChange(e);
                setDisableBtn(false);
                let reader = new FileReader();
                reader.onload = () => {
                  if (reader.readyState === 2) {
                    setselectedImage(reader.result);
                  }
                };
                reader.readAsDataURL(e.target.files[0]);
                setselectedImageFile(e.target.files[0]);
              }}
              key={response?.name?.length > 0}
            />
            <StyledDOM.StyledFieldsWrapper>
              <ComponentsLib.TextField {...nameProps} />
            </StyledDOM.StyledFieldsWrapper>
            <StyledDOM.StyledFieldsWrapper
              sx={{ marginBottom: !isPhoneVerified ? "20px" : "0px" }}
            >
              <ComponentsLib.TextField {...phoneNumberProps} />
              {!isPhoneVerified && (
                <StyledDOM.StyledResendSpan>
                  Mobile number not Verified?{" "}
                  <span
                    onClick={() => {
                      setOpenBackdrop(true);
                      sendOtp({
                        phoneNumber: formik?.values?.phoneNumber,
                        userSub: userSub,
                        setShowOTPVerifyForm,
                        handleBackdropClose,
                      });
                    }}
                  >
                    Resend OTP
                  </span>
                </StyledDOM.StyledResendSpan>
              )}
            </StyledDOM.StyledFieldsWrapper>
            <StyledDOM.StyledFieldsWrapper>
              <ComponentsLib.TextField {...emailProps} />
            </StyledDOM.StyledFieldsWrapper>
            <StyledDOM.StyledButtonWrapper
              disabled={disableBtn || !(formik.dirty && formik.isValid)}
              id={"login-button"}
              type={`submit`}
            >
              {isBtnLoading && <CircularProgress disableShrink />}{" "}
              {Utils?.staticText?.updateBtnTxt}
            </StyledDOM.StyledButtonWrapper>
          </StyledDOM.StyledSubMainWrapperForm>
        )}
      </StyledDOM.OuterGrid>
      {showOTPVerifyForm && (
        <ComponentsLib.ComponentPopup {...componentPopupProps} />
      )}
      <ComponentsLib.ToastSnackbar {...snackBarProps} />
    </>
  );
}
export { EditProfile };
export default EditProfile;
