import * as type from "../types";

const initialState = {
  register: [],
};

export default function registerUser(state = initialState, action) {
  switch (action.type) {
    case type.REGISTER_USER_SUCCESS:
      return {
        ...state,
        register: action.payload.result,
      };
    default:
      return state;
  }
}
