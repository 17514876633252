import { styled } from "@mui/material/styles";
import { Grid, Typography, IconButton } from "@mui/material";
import sliderBg from "../../../assets/images/categories/bg.png";

export const StyledTopicsSlider = styled(Grid)`
  font-family: "Manrope", sans-serif;
  .sc-dkrFOg.biYdFZ.rec.rec-slider-container {
    overflow: hidden;
    position: relative;
    width: 100%;
    margin: 0 0px;
  }
  @media only screen and (max-width: 820px) {
  }
`;

export const StyledMainHeading = styled("h1")`
  font-family: "Manrope", sans-serif;
  margin: 0px;
  text-align: left;
  font-size: 36px;
  font-weight: 900;

  @media only screen and (max-width: 700px) {
    margin: 0;
  }
`;
export const StyledSubHeadingOne = styled("h2")`
  font-family: "Manrope", sans-serif;
  font-size: 20px;
  color: #0f0f0f;
  margin: 0px;
  text-align: left;
  margin-bottom: 0px;
  font-weight: 900;
  margin-top: 10px;
`;
export const StyledSubHeadingTwo = styled("h3")`
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  color: #000000;
  text-align: left;
  margin: 10px 0 0px;
`;
export const StyledSubHeadingThree = styled("h4")`
  font-family: "Manrope", sans-serif;
  font-size: 11px;
  color: #9a999a;
  margin-top: 0px;
  margin-bottom: 10px;
  text-align: left;
    @media (max-width: 300px) {
   font-size: 8px;
  }
`;
export const StyledGrid = styled(Grid)`
  font-family: "Manrope", sans-serif;
  position: relative;
`;
export const StyledCarousel = styled(Grid)`
  font-family: "Manrope", sans-serif;
  padding: 0;
  margin: 0;
`;
// export const StyledCarouselItem = styled(Grid)`
//   font-family: "Manrope", sans-serif;
//   border: 1px solid #ccc;
//   border-radius: 8px;
//   padding: 15px 10px 10px 10px;
//   margin: 0;
//   box-shadow: 0pt 3pt 7pt #0d332f12;
//   background-image: url(${sliderBg});
// `;
export const StyledItemTitle = styled("h5")`
  font-family: "Manrope", sans-serif;
  margin: 5px 0px 10px 0;
  font-size: 14px;
  font-weight: 900;
  color: #272727;
     @media (max-width: 300px) {
   font-size: 9px;
  }
`;
export const StyledItemContent = styled(Typography)`
  font-family: "Manrope", sans-serif;
  min-height: 35px;
  font-size: 12px;
  font-weight: 900;
  color: #7c797a;
    @media (max-width: 300px) {
   font-size: 10px;
  }
`;
export const StyledArrowIconBtn = styled(IconButton)`
  font-family: "Manrope", sans-serif;
  position: absolute;
  right: 0;
  bottom: 0;
`;
export const StyledHr = styled("hr")`
  font-family: "Manrope", sans-serif;
  border: none;
  border-top: 3px dotted #ccc;
  color: #fff;
  height: 1px;
  width: 100%;
`;
export const StyledInfo = styled(Grid)`
  font-family: "Manrope", sans-serif;
  position: relative;
  height: 10px;
  span.count {
    position: absolute;
    right: 0;
    top: -3px;
    font-size: 12px;
    color: #7c797a;
    font-weight: 900;
  }
`;
export const StyledNoSpan = styled("span")`
  background: #26867d;
  border-radius: 0 20px 20px 0px;
  color: #ffffff;
  font-size: 11px;
  padding: 3px 10px 3px 10px;
  margin-left: -10px;
`;
export const StyledInfoText = styled("span")`
  font-family: "Manrope", sans-serif;
  position: absolute;
  margin-left: 5px;
  font-size: 11px;
  font-weight: 900;
  color: #7c797a;
  width: 80%;
  display: flex;
  align-items: center;
  gap: 3px;
  top: -5px;
  svg {
  }
  span {
    color: #000;
  }
`;
export const StyledCarouselItemDiv = styled(Grid)`
  font-family: "Manrope", sans-serif;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 15px 10px 10px 10px;
  margin: 0px;
  box-shadow: 0pt 3pt 7pt #0d332f12;
  width: 100%;
  background-image: url(${sliderBg});

  // &.listView {
  //   margin-bottom: 10px;
  // }
`;
