import { styled } from "@mui/material/styles";
import { Box, Grid, Typography, Button } from "@mui/material";

export const StyledBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 480px;
  padding: 0 20px;
`;

export const StyledTypography = styled(Typography)`
  color: #000000;
  margin-top: 10px;
  word-break: break-word;
`;
export const StyledButton = styled(Button)`
  margin-top: 20px;
  background: #26867d;
`;
