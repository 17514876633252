import * as type from "../types";

const initialState = {
  userCategory: [],
};

export default function userCategory(state = initialState, action) {
  switch (action.type) {
    case type.GET_USER_CATEGORY_SUCCESS:
      return {
        ...state,
        userCategory: action.payload.result,
      };
    default:
      return state;
  }
}
