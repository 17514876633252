/* Register User */
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAILED = "REGISTER_USER_FAILED";

/* Login User */
export const USERS_LOGIN = "USERS_LOGIN";
export const USERS_LOGIN_SUCCESS = "USERS_LOGIN_SUCCESS";
export const USERS_LOGIN_FAIL = "USERS_LOGIN_FAIL";

/* Get User Details */
export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_FAILED = "GET_USER_DETAILS_FAILED";

/* Trending Topics */
export const GET_TRENDING_TOPICS = "GET_TRENDING_TOPICS";
export const GET_TRENDING_TOPICS_SUCCESS = "GET_TRENDING_TOPICS_SUCCESS";
export const GET_TRENDING_TOPICS_FAILED = "GET_TRENDING_TOPICS_FAILED";

/* Trending Topics By Id*/
export const GET_TRENDING_TOPICS_BY_ID = "GET_TRENDING_TOPICS_BY_ID";
export const GET_TRENDING_TOPICS_BY_ID_SUCCESS =
  "GET_TRENDING_TOPICS_BY_ID_SUCCESS";
export const GET_TRENDING_TOPICS_BY_ID_FAILED =
  "GET_TRENDING_TOPICS_BY_ID_FAILED";

/* Topics Details By Id */
export const GET_TOPICS_BY_ID = "GET_TOPICS_BY_ID";
export const GET_TOPICS_BY_ID_SUCCESS = "GET_TOPICS_BY_ID_SUCCESS";
export const GET_TOPICS_BY_ID_FAILED = "GET_TOPICS_BY_ID_FAILED";

/* Categories List */
export const GET_CATEGORY_LIST = "GET_CATEGORY_LIST";
export const GET_CATEGORY_LIST_SUCCESS = "GET_CATEGORY_LIST_SUCCESS";
export const GET_CATEGORY_LIST_FAILED = "GET_CATEGORY_LIST_FAILED";

/* User Categories List */
export const GET_USER_CATEGORY = "GET_USER_CATEGORY";
export const GET_USER_CATEGORY_SUCCESS = "GET_USER_CATEGORY_SUCCESS";
export const GET_USER_CATEGORY_FAILED = "GET_USER_CATEGORY_FAILED";

/* Global Search */
export const GET_GLOBAL_SEARCH = "GET_GLOBAL_SEARCH";
export const GET_GLOBAL_SEARCH_SUCCESS = "GET_GLOBAL_SEARCH_SUCCESS";
export const GET_GLOBAL_SEARCH_FAILED = "GET_GLOBAL_SEARCH_FAILED";

/* Term Details */
export const GET_TERM_DETAILS = "GET_TERM_DETAILS";
export const GET_TERM_DETAILS_SUCCESS = "GET_TERM_DETAILS_SUCCESS";
export const GET_TERM_DETAILS_FAILED = "GET_TERM_DETAILS_FAILED";

/* Notifications List */
export const GET_NOTIFICATION_LIST = "GET_NOTIFICATION_LIST";
export const GET_NOTIFICATION_LIST_SUCCESS = "GET_NOTIFICATION_LIST_SUCCESS";
export const GET_NOTIFICATION_LIST_FAILED = "GET_NOTIFICATION_LIST_FAILED";

/* Contributions List */
export const GET_COMMENTS_LIST = "GET_COMMENTS_LIST";
export const GET_COMMENTS_LIST_SUCCESS = "GET_COMMENTS_LIST_SUCCESS";
export const GET_COMMENTS_LIST_FAILED = "GET_COMMENTS_LIST_FAILED";
export const GET_COMMENTS_REPLY_LIST = "GET_COMMENTS_REPLY_LIST";
export const GET_COMMENTS_REPLY_LIST_SUCCESS =
  "GET_COMMENTS_REPLY_LIST_SUCCESS";
export const GET_COMMENTS_REPLY_LIST_FAILED = "GET_COMMENTS_REPLY_LIST_FAILED";

// export const GET_ADDED_COMMENTS_LIST = "GET_ADDED_COMMENTS_LIST";

/* Destroy Session */
export const DESTROY_MASTER_SESSION = "DESTROY_MASTER_SESSION";
export const DESTROY_TERMS_SESSION = "DESTROY_TERMS_SESSION";
export const DESTROY_SEARCH_SESSION = "DESTROY_SEARCH_SESSION";
export const DESTROY_CATEGORY_SESSION = "DESTROY_CATEGORY_SESSION";
export const DESTROY_CATEGORY_TRENDING_SESSION =
  "DESTROY_CATEGORY_TRENDING_SESSION";
