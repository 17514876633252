import React from "react";
import * as ComponentsLib from "@componentsLib";
import * as StyledDOM from "./styles";

import awardIcon from "../../../assets/images/home/award.png";
import InfoIcon from "../../../assets/images/infoicon.png";
import _ from "lodash";
import { Alert, Button, Stack } from "@mui/material";
import { Link } from "react-router-dom";

const Banners = (props) => {
  const {
    userDetails = {},
    openBackdrop = "",
    handleBackdropOpen = () => "",
    handleBackdropClose = () => "",
    handleModelPopupOpen = () => "",
  } = props || {};
  const [firstElement = []] = userDetails;
  const { response = [], status = "Loading" } = firstElement;
  const {
    name = "",
    email = "",
    reputation = "",
    awards = "",
    isVerified = "",
    isRoleVerified = "",
    isPhoneVerified = "",
    isEmailVerified = "",
    phone_number = "",
    username = "",
    id = "",
  } = response || {};
  const skeletonLoaderProps = {
    variant: "rounded",
    height: 60,
    width: "100%",
  };
  const errorProps = {
    content: "Banner Details Not found",
    width: "100%",
    height: 60,
  };
  return (
    <>
      {status == "Error" ? (
        <ComponentsLib.ErrorSection {...errorProps} />
      ) : (
        <>
          {!isPhoneVerified && isPhoneVerified !== "" && (
            <Alert severity="error" style={{ marginBottom: "10px" }}>
              Your mobile number is not verified. Please verify it by clicking{" "}
              <Link
                style={{
                  fontWeight: 400,
                  textDecoration: "none",
                }}
                to="/my-account/edit-profile"
              >
                here
              </Link>
            </Alert>
          )}
          {!isRoleVerified && isRoleVerified !== "" ? (
            <Alert severity="warning">
              Your profile category is not verified yet. Please verify it to
              contribute in the terms. To verify click{" "}
              <span
                style={{ color: "#399d94", cursor: "pointer" }}
                onClick={handleModelPopupOpen}
              >
                here
              </span>
            </Alert>
          ) : (
            <>
              {status == "Loading" ? (
                <ComponentsLib.SkeletonLoader {...skeletonLoaderProps} />
              ) : (
                <StyledDOM.StyledBanners container xs={12}>
                  <StyledDOM.StyledGrid item xs={10}>
                    <StyledDOM.StyledTypography>
                      Badges Earned: {awards}
                    </StyledDOM.StyledTypography>
                    <StyledDOM.StyledGrid
                      sx={{
                        float: "left",
                        position: "absolute",
                        bottom: "5px",
                      }}
                    >
                      <StyledDOM.StyledTypography className="marginTop small">
                        <img src={InfoIcon} width="24" height="24" />{" "}
                        <StyledDOM.StyledInfoText>
                          Keep contributing to earn badges!
                        </StyledDOM.StyledInfoText>
                      </StyledDOM.StyledTypography>
                    </StyledDOM.StyledGrid>
                  </StyledDOM.StyledGrid>
                  <StyledDOM.StyledGrid item xs={2}>
                    <img
                      src={awardIcon}
                      width="80"
                      height="80"
                      style={{ marginTop: "-10px", marginLeft: "-7px" }}
                      alt="Keep Contribute"
                    />
                  </StyledDOM.StyledGrid>
                </StyledDOM.StyledBanners>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export { Banners };
export default Banners;
