import React from "react";
import * as StyledDOM from "./styles";
import * as ComponentsLib from "@componentsLib";
import * as IconGallery from "@icongallery";
import * as Utils from "@utils";
import Header from "../../Header";
import Footer from "../../Footer";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import {
  Forminputprops,
  FormFieldValues,
  validationSchema,
  getValid,
} from "./fixture";
import { useResetPasswordStates } from "./useResetPasswordStates";

const ResetPassword = (props) => {
  // const { otpFormValue = "", setOtpFormValue = () => "" } = props || {};
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  let params = useParams();
  const { emailId = "", verificationId = "" } = params || {};
  const { initialValues } = FormFieldValues();
  const {
    formik,
    openToast,
    setOpenToast,
    vertical,
    setVertical,
    horizontal,
    setHorizontal,
    severityNote,
    setSeverityNote,
    message,
    setMessage,
    handleOpenToast,
    handleCloseToast,
    passwordType,
    setPasswordType,
    confirmPasswordType,
    setConfirmPasswordType,
    showLogin,
    setShowLogin,
    otpCode,
    setOtpCode,
    isBtnLoading,
    setIsBtnLoading,
    disableBtn,
    setDisableBtn,
  } = useResetPasswordStates({
    initialValues,
    validationSchema,
    dispatch,
    navigateTo,
    emailId,
    verificationId,
  });
  const { passwordProps, confirmPasswordProps } = Forminputprops({
    formik,
    passwordType,
    confirmPasswordType,
  });

  const togglePassword = () => {
    passwordType === "password"
      ? setPasswordType("text")
      : setPasswordType("password");
  };

  const toggleConfirmPassword = () => {
    confirmPasswordType === "password"
      ? setConfirmPasswordType("text")
      : setConfirmPasswordType("password");
  };

  const snackBarProps = {
    openToast,
    handleOpenToast,
    handleCloseToast,
    vertical,
    horizontal,
    message,
    severityNote,
  };
  const updatePasswordProps = {
    formik,
    togglePassword,
    toggleConfirmPassword,
    passwordProps,
    confirmPasswordProps,
    passwordType,
    showLogin,
    confirmPasswordType,
    btnTxt: Utils?.staticText?.register?.resetPassword,
    otpCode,
    setOtpCode,
    setSeverityNote,
    setMessage,
    handleOpenToast,
    isBtnLoading,
    setIsBtnLoading,
    disableBtn,
    setDisableBtn,
  };
  return (
    <>
      <Header />
      <StyledDOM.StyledMainFieldsWrapper>
        <StyledDOM.StyledMainHeading>
          {Utils?.staticText?.register?.resetPassword}
        </StyledDOM.StyledMainHeading>
        <ComponentsLib.UpdatePassword {...updatePasswordProps} />
      </StyledDOM.StyledMainFieldsWrapper>
      <ComponentsLib.ToastSnackbar {...snackBarProps} />
      {/* <Footer /> */}
    </>
  );
};

export { ResetPassword };
export default ResetPassword;
