import React from "react";
import * as ComponentsLib from "@componentsLib";
import * as IconGallery from "@icongallery";
import * as Utils from "@utils";
import { Link, useNavigate } from "react-router-dom";
import {
  Forminputprops,
  FormFieldValues,
  validationSchema,
  getValid,
} from "./fixture";
import * as StyledDOM from "./styles";
import { useLoginStates } from "./useLoginStates";
import { Grid } from "@mui/material";
import Header from "../../Header";
import Footer from "../../Footer";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { getUserDetails } from "../../../../redux/actions/userDetails";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import { Auth } from "aws-amplify";

const Login = (props) => {
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const { initialValues } = FormFieldValues();
  const {
    formik,
    passwordType,
    setPasswordType,
    openToast,
    setOpenToast,
    handleOpenToast,
    handleCloseToast,
    vertical,
    setVertical,
    horizontal,
    setHorizontal,
    message,
    setMessage,
    severityNote,
    setSeverityNote,
    showVerifyEmail,
    resendTrigger,
    isBtnLoading,
    setIsBtnLoading,
    timer,
    setTimer,
    openBackdrop,
    setOpenBackdrop,
    handleBackdropOpen,
    handleBackdropClose,
  } = useLoginStates({
    initialValues,
    validationSchema,
    dispatch,
    navigateTo,
  });

  React.useEffect(() => {
    // if (!_.isEmpty(Utils.getStorage().subId)) {
    //   navigateTo("/");
    //   dispatch(getUserDetails());
    // }
    localStorage.removeItem("formValues");
    Auth.currentAuthenticatedUser()
      .then((currentUser) => {
        navigateTo("/");
        dispatch(getUserDetails());
      })
      .catch(() => "");
  }, []);

  const { emailProps, passwordProps } = Forminputprops({
    formik,
    passwordType,
  });

  const togglePassword = () => {
    passwordType === "password"
      ? setPasswordType("text")
      : setPasswordType("password");
  };

  const snackBarProps = {
    openToast,
    handleOpenToast,
    handleCloseToast,
    vertical,
    horizontal,
    message,
    severityNote,
  };

  const socialLoginProps = {
    dottedText: Utils?.staticText?.login?.dottedText,
  };
  const headerProps = {
    showMenu: false,
  };

  const backDropProps = {
    openBackdrop,
    handleBackdropClose,
  };

  return (
    <>
      <Header {...headerProps} />
      <ComponentsLib.BackDrop {...backDropProps} />
      <StyledDOM.StyledMainFieldsWrapper>
        <StyledDOM.StyledMainHeading>
          {Utils?.staticText?.login?.welcomeTxt}
        </StyledDOM.StyledMainHeading>
        <StyledDOM.StyledSubHeading>
          {Utils?.staticText?.login?.subHeading}
        </StyledDOM.StyledSubHeading>
        <ComponentsLib.SocialLogin {...socialLoginProps} />
        <StyledDOM.StyledSubMainWrapperForm onSubmit={formik.handleSubmit}>
          <StyledDOM.StyledGridOuter>
            <StyledDOM.StyledFieldsWrapper>
              <ComponentsLib.TextField {...emailProps} />
            </StyledDOM.StyledFieldsWrapper>
            <StyledDOM.StyledFieldsWrapper>
              <ComponentsLib.TextField {...passwordProps} />
              <StyledDOM.StyledViewIcon
                onClick={() => togglePassword()}
                className={!formik.isValid ? `rightMargin` : ``}
              >
                {passwordType === "password" ? (
                  <IconGallery.VisibilityOff />
                ) : (
                  <IconGallery.VisibilityOn />
                )}
              </StyledDOM.StyledViewIcon>
            </StyledDOM.StyledFieldsWrapper>
            <StyledDOM.StyledForgotGrid>
              <Link to="/forgot-password">
                {Utils?.staticText?.register?.forgotPassword}
              </Link>
            </StyledDOM.StyledForgotGrid>
            <StyledDOM.StyledButtonWrapper
              // disabled={!(formik.dirty && formik.isValid)}
              id={"login-button"}
              type={`submit`}
            >
              {isBtnLoading && <CircularProgress disableShrink />}{" "}
              {Utils?.staticText?.login?.button}
            </StyledDOM.StyledButtonWrapper>
            <StyledDOM.StyledRegisterGrid>
              Not a member?{" "}
              <Link to="/register">{Utils?.staticText?.register?.signUp}</Link>
            </StyledDOM.StyledRegisterGrid>
            <StyledDOM.StyledRegisterResendGrid>
              {showVerifyEmail && (
                <>
                  {timer > 0 ? (
                    <span
                      style={{
                        color: "#7c797a",
                        fontSize: "12px",
                      }}
                    >
                      Resend Verification Link in {timer} seconds.
                    </span>
                  ) : (
                    <span
                      style={{
                        color: "#67AAA4",
                      }}
                      onClick={resendTrigger}
                    >
                      {Utils?.staticText?.register?.resendEmail}
                    </span>
                  )}
                </>
              )}
            </StyledDOM.StyledRegisterResendGrid>
          </StyledDOM.StyledGridOuter>
        </StyledDOM.StyledSubMainWrapperForm>
      </StyledDOM.StyledMainFieldsWrapper>
      <ComponentsLib.ToastSnackbar {...snackBarProps} />
    </>
  );
};

export { Login };
export default Login;
