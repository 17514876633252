import React from "react";
import * as ComponentsLib from "@componentsLib";
import * as IconGallery from "@icongallery";
import * as Utils from "@utils";
import { Link, useNavigate } from "react-router-dom";
import * as StyledDOM from "./styles";
import _ from "lodash";
import { useNotificationsStates } from "./useNotificationsStates";
import { Grid } from "@mui/material";
import Header from "../../Header";
import Footer from "../../Footer";
import { useDispatch, useSelector } from "react-redux";
import { getNotificationsList } from "../../../../redux/actions";
import { getUserDetails } from "../../../../redux/actions";

function Explore(props) {
  const {} = props || {};
  const navigateTo = useNavigate();
  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.userDetails.userDetails);
  const [firstUserElement = []] = userDetails;
  const { response: userResponse = [], status: userApiStatus = "Loading" } =
    firstUserElement;

  const { id: userUUID = "" } = userResponse || {};

  const listNotifications = useSelector(
    (state) => state.notificationsList.notificationsList
  );
  const [firstElement = []] = listNotifications;
  const {
    response: notificationResponse = [],
    status: notificationStatus = "Loading",
  } = firstElement;
  const {
    openBackdrop,
    setOpenBackdrop,
    handleBackdropOpen,
    handleBackdropClose,
    openModelPopup,
    setOpenModelPopup,
    handleModelPopupClose,
    handleModelPopupOpen,
    openToast,
    setOpenToast,
    handleOpenToast,
    handleCloseToast,
    vertical,
    setVertical,
    horizontal,
    setHorizontal,
    message,
    setMessage,
    severityNote,
    setSeverityNote,
    tabsList,
    contributionList,
    otherNotificationsList,
  } = useNotificationsStates();

  React.useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getUserDetails());
    onHandleSearch(0);
  }, [dispatch]);

  const backDropProps = {
    openBackdrop,
    setOpenBackdrop,
    handleBackdropClose,
  };
  const snackBarProps = {
    openToast,
    handleOpenToast,
    handleCloseToast,
    vertical,
    horizontal,
    message,
    severityNote,
  };
  const headerProps = {
    showLogo: false,
    title: "Notifications",
  };
  const onHandleSearch = (value) => {
    const notificationEndpoint = value == 0 ? "contribution" : "others";
    dispatch(
      getNotificationsList({ value: notificationEndpoint, id: userUUID })
    );
  };
  const contributionsList =
    !_.isEmpty(notificationResponse) &&
    notificationResponse.filter((item) => item.action === "New Contributions");
  const othersList =
    !_.isEmpty(notificationResponse) &&
    notificationResponse.filter((item) => item.status !== "New Contributions");
  const tabProps = {
    onChange: onHandleSearch,
    tabsList,
    contributionList: contributionsList,
    otherNotificationsList: othersList,
    notificationStatus,
  };
  return (
    <>
      <Header {...headerProps} />
      <ComponentsLib.BackDrop {...backDropProps} />
      <StyledDOM.StyledMainFieldsWrapper>
        <ComponentsLib.NotificationsTab {...tabProps} />
        <ComponentsLib.ToastSnackbar {...snackBarProps} />
      </StyledDOM.StyledMainFieldsWrapper>
      <Footer />
    </>
  );
}

export { Explore };
export default Explore;
