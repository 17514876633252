import * as React from "react";
import { StyledLabel } from "./styles";

function Label(props) {
  const { isrequired = true, Labelname = "" } = props;
  const label = isrequired ? `${Labelname}*` : !isrequired ? Labelname : "";
  return <StyledLabel dangerouslySetInnerHTML={{ __html: label }} />;
}

export { Label };
export default Label;
