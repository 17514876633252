import React from "react";
import _ from "lodash";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { Auth } from "aws-amplify";
import * as Utils from "@utils";
import * as IconGallery from "@icongallery";

function useResetPasswordStates(props) {
  const {
    initialValues = {},
    validationSchema = {},
    dispatch = () => "",
    navigateTo = () => "",
    emailId: username = "",
    verificationId: code = "",
  } = props || {};

  const [isBtnLoading, setIsBtnLoading] = React.useState(false);
  const [disableBtn, setDisableBtn] = React.useState(false);
  const [showLogin, setShowLogin] = React.useState(false);
  const [passwordType, setPasswordType] = React.useState("password");
  const [confirmPasswordType, setConfirmPasswordType] =
    React.useState("password");
  const [otpCode, setOtpCode] = React.useState("");

  /* Snackbar functions */
  const [openToast, setOpenToast] = React.useState(false);
  const [vertical, setVertical] = React.useState("top");
  const [horizontal, setHorizontal] = React.useState("right");
  const [severityNote, setSeverityNote] = React.useState("info");
  const [message, setMessage] = React.useState("");

  const [showVerifyEmail, setShowVerifyEmail] = React.useState(false);

  const handleOpenToast = () => {
    setOpenToast(true);
  };

  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
  };
  /*snackbar end */

  const defaultFormik = {
    initialValues,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setIsBtnLoading(true);
      setDisableBtn(true);
      const { password = "", confirmPassword: new_password = "" } =
        values || {};
      const user = await Auth.forgotPasswordSubmit(
        username,
        otpCode,
        new_password
      )
        .then((data) => {
          const { message = "Password updated successfully", name = "" } =
            data || {};
          handleOpenToast();
          setSeverityNote("success");
          setMessage(message);
          resetForm({ values: "" });
          setShowLogin(true);
          setIsBtnLoading(false);
          setDisableBtn(false);
          setTimeout(() => {
            navigateTo("/login");
          }, 1000);
        })
        .catch((error) => {
          const {
            message = "Something went wrong. Please try again later",
            name = "",
          } = error || {};
          handleOpenToast();
          setSeverityNote("error");
          setMessage(message);
          setIsBtnLoading(false);
          setDisableBtn(false);
        });
    },
  };

  const formik = useFormik(defaultFormik);

  return {
    formik,
    openToast,
    setOpenToast,
    vertical,
    setVertical,
    horizontal,
    setHorizontal,
    severityNote,
    setSeverityNote,
    message,
    setMessage,
    handleOpenToast,
    handleCloseToast,
    passwordType,
    setPasswordType,
    confirmPasswordType,
    setConfirmPasswordType,
    showLogin,
    setShowLogin,
    otpCode,
    setOtpCode,
    isBtnLoading,
    setIsBtnLoading,
    disableBtn,
    setDisableBtn,
  };
}

export { useResetPasswordStates };
export default useResetPasswordStates;
