import { get, post, getSearchResult } from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import * as Utils from "@utils";

const token = Utils.getStorage()?.token.split(" ").reverse();

function getUserDetailsApi(props) {
  const { payload = {} } = props || {};
  return get(url.USERS_DETAILS);
}

function getTrendingTopicsList(props) {
  const { payload = {} } = props || {};
  return get(url.TRENDING_TOPICS);
}

function getTrendingTopicsListById(props) {
  const { payload = {} } = props || {};
  return get(`${url.TRENDING_TOPICS}?category_id=${payload}`);
}

function getTopicsByCatId(props) {
  const { payload = {} } = props || {};
  const { id = "", searchTxt = "" } = payload;
  let searchValue = "";
  if (searchTxt != "Relevance") {
    searchValue = searchTxt;
  }
  return get(`${url.CATEGORY_DETAILS_VIEW}/${id}/${searchValue}`);
}

function getCategoriesList(props) {
  const { payload = {} } = props || {};
  return get(url.CATEGORY_LIST);
}

function getUserCategory(props) {
  const { payload = {} } = props || {};
  return get(url.GET_USER_CATEGORY);
}

function getGlobalSearch(props) {
  const { payload = "" } = props || {};
  const { searchString = "", catName = "all" } = payload || {};
  let queryString = `app=curator&q=${searchString}&cat=${catName}`;
  return getSearchResult(`${url.GET_GLOBAL_SEARCH_BOT}?${queryString}`);
}

function getNotificationsList(props) {
  const { payload = {} } = props || {};
  const { value = "", id = "" } = payload;
  return get(`${url.CONTRIBUTE_NOTIFICATION_LIST}?id=${id}`);
}

function getTermDetails(props) {
  const { payload = "" } = props || {};
  return get(`${url.GET_TERM_DETAILS}/${payload}`);
}

export {
  getUserDetailsApi,
  getTrendingTopicsList,
  getCategoriesList,
  getUserCategory,
  getGlobalSearch,
  getNotificationsList,
  getTermDetails,
  getTopicsByCatId,
  getTrendingTopicsListById,
};
