import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import HealingIcon from "@mui/icons-material/Healing";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import InfoIcon from "@mui/icons-material/Info";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import HomeIcon from "@mui/icons-material/Home";
import SearchIcon from "@mui/icons-material/Search";
import BarChartIcon from "@mui/icons-material/BarChart";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import LogoutIcon from "@mui/icons-material/Logout";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import SendIcon from "@mui/icons-material/Send";
import AddBoxIcon from "@mui/icons-material/AddBox";
import CloseIcon from "@mui/icons-material/Close";

export const VisibilityOff = (props) => <VisibilityOffIcon {...props} />;
export const VisibilityOn = (props) => <VisibilityIcon {...props} />;
export const HealthSafetyIcon = (props) => <HealthAndSafetyIcon {...props} />;
export const HealIcon = (props) => <HealingIcon {...props} />;
export const RightArrowIcon = (props) => <ArrowForwardIosIcon {...props} />;
export const ProfileCircleIcon = (props) => <AccountCircleIcon {...props} />;
export const MenuIcon = (props) => <WidgetsOutlinedIcon {...props} />;
export const MenuHomeIcon = (props) => <HomeIcon {...props} />;
export const MenuSearchIcon = (props) => <SearchIcon {...props} />;
export const MenuLeaderBoardIcon = (props) => <BarChartIcon {...props} />;
export const LogoutIconIcon = (props) => <LogoutIcon {...props} />;
export const ClosePopUpIcon = (props) => <HighlightOffIcon {...props} />;
export const CloseCommentIcon = (props) => <CloseIcon {...props} />;
export const BoxPlaceholderIcon = (props) => (
  <AccountBoxOutlinedIcon {...props} />
);
export const MenuNotificationIcon = (props) => (
  <NotificationsActiveIcon {...props} />
);
export const HeartIcon = (props) => <VolunteerActivismIcon {...props} />;
export const EmailSendIcon = (props) => <SendIcon {...props} />;
export const PlusIcon = (props) => <AddBoxIcon {...props} />;

export const InputSearchIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="13.3333"
        cy="13.3334"
        r="10"
        stroke="#828282"
        strokeWidth="1.8"
      />
      <path
        d="M28 28L20.6667 20.6666"
        stroke="#828282"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const InformationIcon = (props) => {
  return (
    <>
      <svg width={0} height={0}>
        <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
          <stop offset={0} stopColor="rgba(95, 209, 249,0.8)" />
          <stop offset={1} stopColor="rgba(85, 88, 218,1.2)" />
        </linearGradient>
      </svg>
      <InfoIcon {...props} sx={{ fill: "url(#linearColors)" }} />
    </>
  );
};

export const LogoSVG = (props) => {
  const { width = "190", height = "30", color = "white" } = props || {};
  return (
    <>
      <svg
        width={width}
        height={height}
        viewBox="0 0 190 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.292 13.5664C11.5689 13.5664 10.9625 14.1026 10.8692 14.8252H5.0381C4.9448 14.8252 4.8515 14.9184 4.8515 15.0117C4.8515 15.1049 4.92147 15.1981 5.0381 15.1981H10.8692C10.9625 15.8974 11.5689 16.4569 12.292 16.4569C13.085 16.4569 13.7381 15.8042 13.7381 15.0117C13.7147 14.1958 13.085 13.5664 12.292 13.5664Z"
          fill={color}
        />
        <path
          d="M11.359 17.1096C10.7525 17.1096 10.2394 17.5524 10.1461 18.1585H9.58631C9.49302 18.1585 9.39972 18.2284 9.39972 18.345C9.39972 18.4382 9.46969 18.5315 9.58631 18.5315H10.1461C10.2394 19.1142 10.7525 19.5804 11.359 19.5804C12.0354 19.5804 12.5952 19.021 12.5952 18.345C12.5952 17.6457 12.0354 17.1096 11.359 17.1096Z"
          fill={color}
        />
        <path
          d="M11.359 10.5828C10.7525 10.5828 10.2394 11.0257 10.1461 11.6317H9.58631C9.49302 11.6317 9.39972 11.7016 9.39972 11.8182C9.39972 11.9114 9.46969 12.0047 9.58631 12.0047H10.1461C10.2394 12.5874 10.7525 13.0536 11.359 13.0536C12.0354 13.0536 12.5952 12.4942 12.5952 11.8182C12.5952 11.1422 12.0354 10.5828 11.359 10.5828Z"
          fill={color}
        />
        <path
          d="M4.22171 14.8019H3.80188C3.70858 14.1026 3.10215 13.5431 2.37909 13.5431C1.58606 13.5431 0.932983 14.1958 0.932983 14.9883C0.932983 15.7809 1.58606 16.4336 2.37909 16.4336C3.10215 16.4336 3.70858 15.8974 3.80188 15.1748H4.22171C4.31501 15.1748 4.40831 15.0816 4.40831 14.9883C4.40831 14.8951 4.33834 14.8019 4.22171 14.8019Z"
          fill={color}
        />
        <path
          d="M8.79327 11.6317H4.52492C4.43162 11.049 3.91849 10.5828 3.31206 10.5828C2.63565 10.5828 2.07587 11.1422 2.07587 11.8182C2.07587 12.4942 2.63565 13.0536 3.31206 13.0536C3.91849 13.0536 4.43162 12.6107 4.52492 12.0047H8.79327C8.88657 12.0047 8.97987 11.9114 8.97987 11.8182C8.97987 11.7016 8.88657 11.6317 8.79327 11.6317Z"
          fill={color}
        />
        <path
          d="M8.79327 18.1585H4.52492C4.43162 17.5758 3.91849 17.1096 3.31206 17.1096C2.63565 17.1096 2.07587 17.669 2.07587 18.345C2.07587 19.021 2.63565 19.5804 3.31206 19.5804C3.91849 19.5804 4.43162 19.1375 4.52492 18.5315H8.79327C8.88657 18.5315 8.97987 18.4615 8.97987 18.345C8.97987 18.2284 8.88657 18.1585 8.79327 18.1585Z"
          fill={color}
        />
        <path
          d="M9.3997 8.2984C8.83992 8.2984 8.39676 8.69467 8.30346 9.2308H7.39381C7.30051 9.2308 7.20721 9.32404 7.20721 9.41728C7.20721 9.51052 7.27719 9.60376 7.39381 9.60376H8.30346C8.39676 10.1166 8.83992 10.5362 9.3997 10.5362C10.0061 10.5362 10.4959 10.0467 10.4959 9.44059C10.4959 8.78791 10.0061 8.2984 9.3997 8.2984Z"
          fill={color}
        />
        <path
          d="M7.32381 8.78787C7.87772 8.78787 8.32675 8.33911 8.32675 7.78553C8.32675 7.23196 7.87772 6.7832 7.32381 6.7832C6.7699 6.7832 6.32086 7.23196 6.32086 7.78553C6.32086 8.33911 6.7699 8.78787 7.32381 8.78787Z"
          fill={color}
        />
        <path
          d="M7.4171 5.82748H6.11094C6.01764 5.43121 5.6911 5.15149 5.27126 5.15149C4.80478 5.15149 4.40826 5.52445 4.40826 6.01396C4.40826 6.50347 4.78145 6.87643 5.27126 6.87643C5.6911 6.87643 6.01764 6.59671 6.11094 6.20044H7.4171C7.5104 6.20044 7.6037 6.1072 7.6037 6.01396C7.6037 5.89741 7.5104 5.82748 7.4171 5.82748Z"
          fill={color}
        />
        <path
          d="M5.13137 3.91606H4.36166C4.26837 3.56641 3.96515 3.33331 3.61528 3.33331C3.19545 3.33331 2.84558 3.68296 2.84558 4.10254C2.84558 4.52212 3.19545 4.87177 3.61528 4.87177C3.98847 4.87177 4.29169 4.61536 4.36166 4.28902H5.13137C5.22466 4.28902 5.31796 4.19578 5.31796 4.10254C5.31796 4.0093 5.24799 3.91606 5.13137 3.91606Z"
          fill={color}
        />
        <path
          d="M9.70291 1.81818H3.24207C3.17209 1.53846 2.91553 1.32867 2.61231 1.32867C2.26244 1.35198 1.95923 1.6317 1.95923 2.00467C1.95923 2.37763 2.26244 2.65735 2.61231 2.65735C2.91553 2.65735 3.17209 2.44756 3.24207 2.16784H9.67958C9.77288 2.16784 9.86618 2.0746 9.86618 1.98135C9.8895 1.91142 9.7962 1.81818 9.70291 1.81818Z"
          fill={color}
        />
        <path
          d="M6.60075 9.2308H6.27421C6.20424 8.69467 5.73775 8.27509 5.17797 8.27509C4.57154 8.27509 4.08173 8.7646 4.08173 9.37066C4.08173 9.97672 4.57154 10.4662 5.17797 10.4662C5.71443 10.4662 6.15759 10.0933 6.25089 9.58045H6.57743C6.67073 9.58045 6.76402 9.51052 6.76402 9.39397C6.78735 9.30073 6.69405 9.2308 6.60075 9.2308Z"
          fill={color}
        />
        <path
          d="M9.423 5.1748C9.02649 5.1748 8.67662 5.45452 8.58333 5.82749H8.25679C8.16349 5.82749 8.07019 5.89742 8.07019 6.01397C8.07019 6.10721 8.16349 6.20045 8.25679 6.20045H8.58333C8.6533 6.59672 9.00316 6.89975 9.423 6.89975C9.88949 6.89975 10.286 6.52679 10.286 6.03728C10.2627 5.54776 9.88949 5.1748 9.423 5.1748Z"
          fill={color}
        />
        <path
          d="M11.0557 3.35663C10.7059 3.35663 10.4026 3.61304 10.3093 3.93938H5.97102C5.87772 3.93938 5.78442 4.03262 5.78442 4.12586C5.78442 4.2191 5.8544 4.31234 5.97102 4.31234H10.286C10.356 4.66199 10.6825 4.9184 11.0324 4.9184C11.4522 4.9184 11.8021 4.56875 11.8021 4.14917C11.8254 3.70628 11.4756 3.35663 11.0557 3.35663Z"
          fill={color}
        />
        <path
          d="M12.0587 1.35199C11.7554 1.35199 11.4989 1.56178 11.4289 1.8415H10.4726C10.3793 1.8415 10.286 1.93474 10.286 2.02798C10.286 2.12122 10.3793 2.21446 10.4726 2.21446H11.4289C11.4989 2.49418 11.7554 2.70397 12.0587 2.70397C12.4318 2.70397 12.7117 2.40094 12.7117 2.05129C12.7117 1.63171 12.4318 1.35199 12.0587 1.35199Z"
          fill={color}
        />
        <path
          d="M5.29458 21.7016C5.85436 21.7016 6.29753 21.3054 6.39082 20.7692H7.30047C7.39377 20.7692 7.48707 20.676 7.48707 20.5827C7.48707 20.4895 7.41709 20.3963 7.30047 20.3963H6.39082C6.29753 19.8834 5.85436 19.4639 5.29458 19.4639C4.68815 19.4639 4.19834 19.9534 4.19834 20.5594C4.17501 21.1888 4.68815 21.7016 5.29458 21.7016Z"
          fill={color}
        />
        <path
          d="M7.34712 23.2168C7.90104 23.2168 8.35007 22.768 8.35007 22.2145C8.35007 21.6609 7.90104 21.2121 7.34712 21.2121C6.79321 21.2121 6.34418 21.6609 6.34418 22.2145C6.34418 22.768 6.79321 23.2168 7.34712 23.2168Z"
          fill={color}
        />
        <path
          d="M7.25386 24.1725H8.56002C8.65332 24.5688 8.97986 24.8485 9.39969 24.8485C9.86618 24.8485 10.2627 24.4755 10.2627 23.986C10.2627 23.4965 9.88951 23.1235 9.39969 23.1235C8.97986 23.1235 8.65332 23.4033 8.56002 23.7995H7.25386C7.16056 23.7995 7.06726 23.8928 7.06726 23.986C7.06726 24.0792 7.16056 24.1725 7.25386 24.1725Z"
          fill={color}
        />
        <path
          d="M9.53962 26.0606H10.3093C10.4026 26.4103 10.7058 26.6434 11.0557 26.6434C11.4755 26.6434 11.8254 26.2937 11.8254 25.8741C11.8254 25.4545 11.4755 25.1049 11.0557 25.1049C10.6825 25.1049 10.3793 25.3613 10.3093 25.6876H9.53962C9.44633 25.6876 9.35303 25.7809 9.35303 25.8741C9.35303 25.9907 9.44633 26.0606 9.53962 26.0606Z"
          fill={color}
        />
        <path
          d="M4.96809 28.1585H11.4289C11.4989 28.4382 11.7555 28.648 12.0587 28.648C12.4319 28.648 12.7118 28.345 12.7118 27.9953C12.7118 27.6224 12.4086 27.3427 12.0587 27.3427C11.7555 27.3427 11.4989 27.5524 11.4289 27.8322H4.96809C4.87479 27.8322 4.78149 27.9254 4.78149 28.0186C4.80482 28.0886 4.87479 28.1585 4.96809 28.1585Z"
          fill={color}
        />
        <path
          d="M8.07021 20.7692H8.39676C8.46673 21.3053 8.93322 21.7249 9.493 21.7249C10.0994 21.7249 10.5892 21.2354 10.5892 20.6294C10.5892 20.0233 10.0994 19.5338 9.493 19.5338C8.95654 19.5338 8.51338 19.9067 8.42008 20.4196H8.09354C8.00024 20.4196 7.90695 20.4895 7.90695 20.606C7.88362 20.676 7.97692 20.7692 8.07021 20.7692Z"
          fill={color}
        />
        <path
          d="M5.27126 24.8252C5.66778 24.8252 6.01764 24.5454 6.11094 24.1725H6.43748C6.53078 24.1725 6.62408 24.1026 6.62408 23.986C6.62408 23.8928 6.53078 23.7995 6.43748 23.7995H6.11094C6.04097 23.4033 5.6911 23.1002 5.27126 23.1002C4.80478 23.1002 4.40826 23.4732 4.40826 23.9627C4.40826 24.4289 4.78145 24.8252 5.27126 24.8252Z"
          fill={color}
        />
        <path
          d="M3.6386 26.6433C3.98847 26.6433 4.29168 26.3869 4.38498 26.0606H8.72331C8.8166 26.0606 8.9099 25.9674 8.9099 25.8741C8.9099 25.7809 8.83993 25.6876 8.72331 25.6876H4.38498C4.31501 25.338 3.98847 25.0816 3.6386 25.0816C3.21876 25.0816 2.8689 25.4312 2.8689 25.8508C2.84558 26.2937 3.19544 26.6433 3.6386 26.6433Z"
          fill={color}
        />
        <path
          d="M2.61231 28.648C2.91553 28.648 3.17209 28.4382 3.24207 28.1585H4.19836C4.29166 28.1585 4.38496 28.0653 4.38496 27.972C4.38496 27.8788 4.29166 27.7855 4.19836 27.7855H3.24207C3.17209 27.5058 2.91553 27.296 2.61231 27.296C2.23912 27.296 1.95923 27.5991 1.95923 27.9487C1.95923 28.345 2.26244 28.648 2.61231 28.648Z"
          fill={color}
        />
        <path
          d="M25.5868 4.12585C29.1088 4.12585 31.7911 4.73191 33.6803 5.96735C35.5696 7.20278 36.5026 9.23075 36.5026 12.028C36.5026 14.8485 35.5463 16.8997 33.657 18.1585C31.7678 19.4172 29.0388 20.0466 25.4935 20.0466H23.8375V27.9254H18.5195V4.73191C19.6857 4.49881 20.8986 4.33564 22.1815 4.26571C23.4643 4.17247 24.6072 4.12585 25.5868 4.12585ZM25.9133 8.648C25.5168 8.648 25.1436 8.648 24.7705 8.67131C24.3973 8.69462 24.0707 8.71793 23.7908 8.74124V15.5245H25.4469C27.2895 15.5245 28.6656 15.268 29.5986 14.7785C30.5316 14.289 30.998 13.3566 30.998 12.0046C30.998 11.352 30.8814 10.8158 30.6482 10.3729C30.4149 9.95336 30.0651 9.60371 29.6219 9.3473C29.1788 9.09089 28.6423 8.90441 28.0125 8.81117C27.3828 8.69462 26.683 8.648 25.9133 8.648Z"
          fill={color}
        />
        <path
          d="M40.0479 27.9254V2.40093L45.1093 1.58508V10.1399C45.4592 10.0233 45.879 9.93007 46.4155 9.81352C46.9519 9.72028 47.4651 9.65035 47.9549 9.65035C49.401 9.65035 50.6138 9.86014 51.5701 10.2331C52.5264 10.6294 53.2961 11.1888 53.8792 11.9114C54.4623 12.634 54.8589 13.4965 55.0921 14.4988C55.3253 15.5012 55.442 16.5967 55.442 17.8322V27.9254H50.3806V18.4615C50.3806 16.8298 50.1707 15.6876 49.7508 14.9883C49.331 14.3124 48.5613 13.9627 47.4184 13.9627C46.9752 13.9627 46.5321 14.0093 46.1356 14.0793C45.7391 14.1492 45.3892 14.2424 45.0626 14.3357V27.9254H40.0479Z"
          fill={color}
        />
        <path
          d="M59.2205 19.1375C59.2205 17.5524 59.4537 16.1771 59.9435 14.9883C60.4333 13.7995 61.0631 12.8205 61.8561 12.0279C62.6491 11.2354 63.5588 10.6293 64.5851 10.2331C65.6113 9.8368 66.6842 9.62701 67.7572 9.62701C70.2995 9.62701 72.3054 10.3962 73.7748 11.958C75.2443 13.5198 75.9907 15.7809 75.9907 18.8112C75.9907 19.1142 75.9673 19.4172 75.9673 19.7902C75.944 20.1398 75.9207 20.4429 75.8974 20.7226H64.4218C64.5384 21.7715 65.0282 22.5874 65.8912 23.1934C66.7542 23.7995 67.8971 24.1025 69.3665 24.1025C70.2995 24.1025 71.2092 24.0093 72.0955 23.8461C72.9818 23.683 73.7282 23.4732 74.288 23.2168L74.9644 27.3193C74.6845 27.4592 74.3346 27.599 73.8681 27.7156C73.425 27.8555 72.9118 27.972 72.3521 28.0652C71.7923 28.1585 71.2092 28.2517 70.5794 28.3217C69.9497 28.3916 69.3199 28.4149 68.6668 28.4149C67.0574 28.4149 65.658 28.1818 64.4684 27.6923C63.2789 27.2261 62.2993 26.5734 61.5062 25.7342C60.7132 24.9184 60.1534 23.9394 59.7802 22.7972C59.4071 21.6783 59.2205 20.4429 59.2205 19.1375ZM71.1159 17.2028C71.0925 16.7832 71.0226 16.3636 70.906 15.944C70.7893 15.5478 70.5794 15.1748 70.3228 14.8485C70.0663 14.5221 69.7397 14.2657 69.3432 14.0559C68.9467 13.8461 68.4569 13.7529 67.8738 13.7529C67.314 13.7529 66.8242 13.8461 66.4044 14.0326C66.0078 14.2191 65.658 14.4755 65.3781 14.8018C65.0982 15.1282 64.8883 15.4778 64.7483 15.8974C64.6084 16.317 64.4918 16.7366 64.4218 17.1795H71.1159V17.2028Z"
          fill={color}
        />
        <path
          d="M79.9092 10.676C80.7722 10.4196 81.8917 10.1865 83.2446 9.97667C84.5974 9.76688 86.0202 9.65033 87.5362 9.65033C89.0523 9.65033 90.3118 9.86012 91.3148 10.2331C92.3177 10.6294 93.1107 11.1888 93.7172 11.9114C94.3003 12.634 94.7201 13.4965 94.9767 14.4988C95.2333 15.5011 95.3499 16.5967 95.3499 17.8321V27.9254H90.2885V18.4615C90.2885 16.8298 90.0786 15.6876 89.6354 14.9883C89.2156 14.3123 88.3992 13.986 87.233 13.986C86.8598 13.986 86.4866 14.0093 86.0668 14.0326C85.647 14.0559 85.2971 14.1025 84.9706 14.1492V27.9487H79.9092V10.676Z"
          fill={color}
        />
        <path
          d="M116.645 18.9744C116.645 20.373 116.435 21.655 116.039 22.8205C115.642 23.986 115.036 24.9884 114.266 25.8042C113.496 26.6201 112.563 27.2728 111.49 27.7156C110.417 28.1585 109.205 28.3916 107.875 28.3916C106.569 28.3916 105.356 28.1585 104.283 27.7156C103.21 27.2728 102.277 26.6201 101.507 25.8042C100.738 24.9884 100.131 23.986 99.7115 22.8205C99.2917 21.655 99.0584 20.373 99.0584 18.9744C99.0584 17.5758 99.2684 16.2937 99.7115 15.1282C100.155 13.9627 100.761 12.9837 101.554 12.1679C102.347 11.352 103.257 10.7226 104.33 10.2564C105.403 9.81354 106.569 9.58044 107.852 9.58044C109.135 9.58044 110.324 9.81354 111.397 10.2564C112.47 10.6993 113.403 11.352 114.173 12.1679C114.942 12.9837 115.549 13.9627 115.969 15.1282C116.435 16.2937 116.645 17.5758 116.645 18.9744ZM111.49 18.9744C111.49 17.4126 111.187 16.1772 110.557 15.2914C109.928 14.4056 109.041 13.9394 107.898 13.9394C106.755 13.9394 105.846 14.3823 105.216 15.2914C104.586 16.1772 104.26 17.4126 104.26 18.9744C104.26 20.5362 104.586 21.7716 105.216 22.704C105.846 23.6131 106.755 24.0793 107.898 24.0793C109.041 24.0793 109.951 23.6131 110.557 22.704C111.187 21.7716 111.49 20.5362 111.49 18.9744Z"
          fill={color}
        />
        <path
          d="M131.059 17.669C130.616 19.394 130.126 21.0956 129.613 22.7972C129.1 24.4988 128.564 26.2005 128.004 27.9254H124.062C123.642 26.9697 123.199 25.8508 122.733 24.5222C122.266 23.2168 121.753 21.7716 121.24 20.2331C120.727 18.6713 120.214 17.0396 119.677 15.3147C119.141 13.5898 118.628 11.8415 118.138 10.0699H123.479C123.666 10.8858 123.852 11.7716 124.085 12.7273C124.295 13.683 124.529 14.6853 124.762 15.6877C124.995 16.7133 125.252 17.7389 125.508 18.7646C125.765 19.7902 126.045 20.7926 126.301 21.725C126.604 20.7226 126.884 19.7203 127.164 18.6713C127.444 17.6224 127.724 16.5967 127.981 15.5944C128.237 14.5921 128.494 13.6131 128.727 12.6807C128.96 11.7483 129.17 10.8625 129.357 10.0466H133.019C133.205 10.8625 133.415 11.7483 133.625 12.6807C133.858 13.6131 134.092 14.5921 134.348 15.5944C134.605 16.5967 134.861 17.6224 135.141 18.6713C135.421 19.7203 135.701 20.7226 135.981 21.725C136.261 20.7692 136.517 19.7902 136.797 18.7646C137.077 17.7389 137.334 16.7133 137.567 15.6877C137.8 14.662 138.033 13.683 138.243 12.7273C138.453 11.7716 138.663 10.8858 138.85 10.0699H144.121C143.631 11.8415 143.095 13.5898 142.558 15.3147C142.022 17.0396 141.509 18.6947 140.996 20.2331C140.482 21.7949 139.993 23.2168 139.503 24.5222C139.013 25.8275 138.57 26.9697 138.15 27.9254H134.208C133.648 26.2005 133.089 24.4988 132.552 22.7972C132.016 21.0956 131.503 19.394 131.059 17.669Z"
          fill={color}
        />
        <path
          d="M152.331 4.91844C152.331 5.85084 152.028 6.57345 151.422 7.10958C150.815 7.64571 150.115 7.90212 149.299 7.90212C148.483 7.90212 147.783 7.64571 147.177 7.10958C146.57 6.57345 146.267 5.85084 146.267 4.91844C146.267 3.98604 146.57 3.26342 147.177 2.72729C147.783 2.19116 148.483 1.93475 149.299 1.93475C150.115 1.93475 150.815 2.19116 151.422 2.72729C152.028 3.24011 152.331 3.98604 152.331 4.91844ZM151.865 27.9254H146.803V10.07H151.865V27.9254Z"
          fill={color}
        />
        <path
          d="M161.754 24.3124C162.687 24.3124 163.34 24.2191 163.737 24.0327C164.133 23.8462 164.32 23.4965 164.32 22.9837C164.32 22.5874 164.063 22.2145 163.574 21.9115C163.084 21.6084 162.314 21.2588 161.288 20.8858C160.495 20.5828 159.772 20.2797 159.119 19.9767C158.466 19.6504 157.929 19.2774 157.463 18.8345C157.019 18.3916 156.67 17.8555 156.413 17.2494C156.156 16.6434 156.04 15.8975 156.04 15.035C156.04 13.3567 156.67 12.028 157.906 11.0723C159.142 10.0933 160.868 9.60376 163.037 9.60376C164.133 9.60376 165.16 9.697 166.163 9.88348C167.166 10.07 167.959 10.2797 168.542 10.5129L167.655 14.4522C167.072 14.2425 166.419 14.056 165.743 13.9161C165.043 13.7529 164.273 13.683 163.41 13.683C161.824 13.683 161.031 14.1259 161.031 15.0117C161.031 15.2215 161.055 15.408 161.124 15.5478C161.194 15.711 161.334 15.8508 161.521 16.014C161.731 16.1539 162.011 16.317 162.361 16.4802C162.711 16.6434 163.154 16.8299 163.714 17.0397C164.833 17.4592 165.743 17.8555 166.466 18.2518C167.189 18.6481 167.772 19.0909 168.192 19.5571C168.612 20.0233 168.915 20.5362 169.078 21.0956C169.241 21.655 169.335 22.3077 169.335 23.0536C169.335 24.8252 168.682 26.1539 167.352 27.063C166.023 27.9721 164.157 28.4149 161.731 28.4149C160.145 28.4149 158.815 28.2751 157.766 28.0187C156.716 27.739 155.993 27.5292 155.573 27.3427L156.413 23.2401C157.276 23.5898 158.162 23.8462 159.072 24.0327C159.982 24.1958 160.868 24.3124 161.754 24.3124Z"
          fill={color}
        />
        <path
          d="M172.25 19.1375C172.25 17.5524 172.483 16.1771 172.973 14.9883C173.463 13.7995 174.093 12.8205 174.886 12.0279C175.679 11.2354 176.589 10.6293 177.615 10.2331C178.641 9.8368 179.714 9.62701 180.787 9.62701C183.329 9.62701 185.335 10.3962 186.805 11.958C188.274 13.5198 189.02 15.7809 189.02 18.8112C189.02 19.1142 189.02 19.4172 188.997 19.7902C188.974 20.1398 188.95 20.4429 188.927 20.7226H177.452C177.568 21.7715 178.058 22.5874 178.921 23.1934C179.784 23.7995 180.927 24.1025 182.396 24.1025C183.329 24.1025 184.239 24.0093 185.125 23.8461C186.012 23.683 186.758 23.4732 187.318 23.2168L187.994 27.3193C187.714 27.4592 187.364 27.599 186.898 27.7156C186.455 27.8555 185.942 27.972 185.382 28.0652C184.822 28.1585 184.239 28.2517 183.609 28.3217C182.979 28.3916 182.35 28.4149 181.697 28.4149C180.087 28.4149 178.688 28.1818 177.498 27.6923C176.309 27.2261 175.329 26.5734 174.536 25.7342C173.743 24.9184 173.183 23.9394 172.81 22.7972C172.437 21.6783 172.25 20.4429 172.25 19.1375ZM184.146 17.2028C184.122 16.7832 184.052 16.3636 183.936 15.944C183.819 15.5478 183.609 15.1748 183.353 14.8485C183.096 14.5221 182.769 14.2657 182.373 14.0559C181.976 13.8461 181.487 13.7529 180.904 13.7529C180.344 13.7529 179.854 13.8461 179.434 14.0326C179.038 14.2191 178.688 14.4755 178.408 14.8018C178.128 15.1282 177.918 15.4778 177.778 15.8974C177.638 16.317 177.522 16.7366 177.452 17.1795H184.146V17.2028Z"
          fill={color}
        />
      </svg>
    </>
  );
};

export const GoogleIcon = (props) => {
  const { width = "30px", height = "30px" } = props || {};
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.0014 16.3109C30.0014 15.1598 29.9061 14.3198 29.6998 13.4487H16.2871V18.6442H24.1601C24.0014 19.9354 23.1442 21.8798 21.2394 23.1864L21.2127 23.3604L25.4536 26.58L25.7474 26.6087C28.4458 24.1665 30.0014 20.5731 30.0014 16.3109Z"
        fill="#4285F4"
      />
      <path
        d="M16.2863 29.9998C20.1434 29.9998 23.3814 28.7553 25.7466 26.6086L21.2386 23.1863C20.0323 24.0108 18.4132 24.5863 16.2863 24.5863C12.5086 24.5863 9.30225 22.1441 8.15929 18.7686L7.99176 18.7825L3.58208 22.127L3.52441 22.2841C5.87359 26.8574 10.699 29.9998 16.2863 29.9998Z"
        fill="#34A853"
      />
      <path
        d="M8.15964 18.769C7.85806 17.8979 7.68352 16.9645 7.68352 16.0001C7.68352 15.0356 7.85806 14.1023 8.14377 13.2312L8.13578 13.0456L3.67083 9.64746L3.52475 9.71556C2.55654 11.6134 2.00098 13.7445 2.00098 16.0001C2.00098 18.2556 2.55654 20.3867 3.52475 22.2845L8.15964 18.769Z"
        fill="#FBBC05"
      />
      <path
        d="M16.2864 7.4133C18.9689 7.4133 20.7784 8.54885 21.8102 9.4978L25.8419 5.64C23.3658 3.38445 20.1435 2 16.2864 2C10.699 2 5.8736 5.1422 3.52441 9.71549L8.14345 13.2311C9.30229 9.85555 12.5086 7.4133 16.2864 7.4133Z"
        fill="#EB4335"
      />
    </svg>
  );
};

export const HeartRateIcon = (props) => {
  const { width = "30px", height = "30px" } = props || {};
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="heartrate">
        <path
          class="cls-1"
          d="M14.86,13h-.95l-1-1.91-1.9,3.82L9.14,10.11,7.23,13H3.41l-.33-.33A5.4,5.4,0,1,1,10.72,5L12,6.3,13.28,5a5.4,5.4,0,1,1,7.64,7.63l-.33.33L12,21.57,6.07,15.64"
        />

        <line class="cls-1" x1="15.82" y1="12.98" x2="17.73" y2="12.98" />
      </g>
    </svg>
  );
};

export const WoundIcon = (props) => {
  const { width = "30px", height = "30px" } = props || {};
  return (
    <svg
      width={width}
      height={height}
      fill="#000000"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      dataname="Layer 1"
    >
      <path d="M8.82,11.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.41,0,1,1,0,0,0,0-1.42A1,1,0,0,0,8.82,11.29Zm2.47,2.48a1,1,0,0,0,0,1.41,1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.41A1,1,0,0,0,11.29,13.77Zm9-10A6,6,0,0,0,12,3.55a6,6,0,0,0-8.24.2h0A6,6,0,0,0,3.57,12,6,6,0,0,0,8,22a5.92,5.92,0,0,0,4-1.55,6,6,0,0,0,8.25-.2A6,6,0,0,0,20.43,12,6,6,0,0,0,20.25,3.75ZM18.83,5.17A4,4,0,0,1,19,10.56L13.44,5A4.07,4.07,0,0,1,18.83,5.17ZM5.17,18.83A4,4,0,0,1,5,13.44l5.6,5.6A4.08,4.08,0,0,1,5.17,18.83Zm13.66,0a4.08,4.08,0,0,1-5.64,0l-8-8a4,4,0,0,1,0-5.64h0a4,4,0,0,1,5.64,0l8,8A4,4,0,0,1,18.83,18.83Zm-5.06-7.54a1,1,0,0,0,0,1.42,1,1,0,0,0,1.41,0,1,1,0,0,0,0-1.42A1,1,0,0,0,13.77,11.29ZM11.29,8.82a1,1,0,0,0,0,1.41,1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.41A1,1,0,0,0,11.29,8.82Z" />
    </svg>
  );
};

export const BookmarkIcon = (props) => {
  const { width = "28", height = "28" } = props || {};
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 28 28"
    >
      <g
        id="Group_11433"
        dataname="Group 11433"
        transform="translate(-331 -18)"
      >
        <rect
          id="Rectangle_5381"
          dataname="Rectangle 5381"
          width="28"
          height="28"
          transform="translate(331 18)"
          fill="none"
        />
        <path
          id="Icon_awesome-bookmark"
          dataname="Icon awesome-bookmark"
          d="M0,20.17V1.891A1.891,1.891,0,0,1,1.891,0H13.237a1.891,1.891,0,0,1,1.891,1.891V20.17L7.564,15.758Z"
          transform="translate(337.436 21.915)"
          fill="#575757"
        />
      </g>
    </svg>
  );
};

export const ShareIcon = (props) => {
  const { width = "28", height = "28" } = props || {};
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 28 28"
    >
      <g
        id="Group_11326"
        dataname="Group 11326"
        transform="translate(-331 -18)"
      >
        <rect
          id="Rectangle_5381"
          dataname="Rectangle 5381"
          width="28"
          height="28"
          transform="translate(331 18)"
          fill="none"
        />
        <path
          id="Icon_ionic-md-share"
          dataname="Icon ionic-md-share"
          d="M21.464,19.029a3.092,3.092,0,0,0-2.213.848l-8.113-4.69a3.8,3.8,0,0,0,.114-.791,3.8,3.8,0,0,0-.114-.791l8-4.633A3.4,3.4,0,1,0,18.06,6.484a3.777,3.777,0,0,0,.114.791l-8,4.633A3.415,3.415,0,0,0,7.847,11,3.359,3.359,0,0,0,4.5,14.4a3.416,3.416,0,0,0,5.73,2.487l8.057,4.69a2.835,2.835,0,0,0-.114.734,3.291,3.291,0,1,0,3.291-3.277Z"
          transform="translate(330.316 17.661)"
          fill="#575757"
        />
      </g>
    </svg>
  );
};

export const BackNavigateIcon = (props) => {
  const { width = "28", height = "28" } = props || {};
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 28 28"
    >
      <g id="Group_10798" dataname="Group 10798" transform="translate(-16 -18)">
        <rect
          id="Rectangle_5383"
          dataname="Rectangle 5383"
          width="28"
          height="28"
          transform="translate(16 18)"
          fill="none"
        />
        <path
          id="Icon_feather-chevron-left"
          dataname="Icon feather-chevron-left"
          d="M21.5,23l-8-7,8-7"
          transform="translate(12.5 16)"
          fill="none"
          stroke="#575757"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};
export const RightArrow = (props) => {
  const { width = "28", height = "28" } = props || {};
  return (
    <svg
      id="Group_11026"
      data-name="Group 11026"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 28 28"
    >
      <rect
        id="Rectangle_5364"
        data-name="Rectangle 5364"
        width="28"
        height="28"
        fill="none"
        margin-left="1290px"
      />
      <path
        id="Path_417"
        data-name="Path 417"
        d="M104.851,7.693l-6.034,6.02a.982.982,0,1,1-1.39-1.386L102.766,7,97.426,1.673A.982.982,0,0,1,98.816.287l6.034,6.02a.979.979,0,0,1,0,1.386Z"
        transform="translate(-87.138 7)"
        fill="#575757"
      />
    </svg>
  );
};
export const EditIcon = (props) => {
  const { width = "28", height = "28" } = props || {};
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 28 28"
    >
      <g
        id="Group_11506"
        data-name="Group 11506"
        transform="translate(-16 -356)"
      >
        <rect
          id="Rectangle_5428"
          data-name="Rectangle 5428"
          width="28"
          height="28"
          rx="2"
          transform="translate(16 356)"
          fill="#26867d"
          opacity="0.1"
        />
        <g
          id="Icon_feather-edit"
          data-name="Icon feather-edit"
          transform="translate(18.98 359.162)"
        >
          <path
            id="Path_107461"
            data-name="Path 107461"
            d="M10.158,6H4.591A1.591,1.591,0,0,0,3,7.591V18.726a1.591,1.591,0,0,0,1.591,1.591H15.726a1.591,1.591,0,0,0,1.591-1.591V13.158"
            transform="translate(0 -1.459)"
            fill="none"
            stroke="#26867d"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Path_107462"
            data-name="Path 107462"
            d="M20.53,3.323A1.723,1.723,0,0,1,22.967,5.76l-7.717,7.717L12,14.29l.812-3.249Z"
            transform="translate(-4.432 0)"
            fill="none"
            stroke="#26867d"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </g>
    </svg>
  );
};

export const ReferralIcon = (props) => {
  const { width = "28", height = "28" } = props || {};
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 28 28"
    >
      <g
        id="Group_11514"
        data-name="Group 11514"
        transform="translate(-16 -468)"
      >
        <rect
          id="Rectangle_5431"
          data-name="Rectangle 5431"
          width="28"
          height="28"
          rx="2"
          transform="translate(16 468)"
          fill="#00ab66"
          opacity="0.1"
        />
        <path
          id="Icon_ionic-md-share"
          data-name="Icon ionic-md-share"
          d="M17.826,15.848a2.4,2.4,0,0,0-1.738.678L9.714,12.772a3.1,3.1,0,0,0,.089-.633,3.093,3.093,0,0,0-.089-.633L16,7.8a2.655,2.655,0,0,0,1.827.723,2.714,2.714,0,1,0-2.674-2.714,3.077,3.077,0,0,0,.089.633L8.957,10.149A2.642,2.642,0,0,0,4.5,12.139a2.7,2.7,0,0,0,2.674,2.714A2.656,2.656,0,0,0,9,14.129l6.329,3.754a2.308,2.308,0,0,0-.089.588,2.585,2.585,0,1,0,2.585-2.623Z"
          transform="translate(17.5 469.906)"
          fill="#26867d"
        />
      </g>
    </svg>
  );
};

export const LogOutIcon = (props) => {
  const { width = "28", height = "28" } = props || {};
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 28 28"
    >
      <g
        id="Group_11509"
        data-name="Group 11509"
        transform="translate(-16 -628)"
      >
        <rect
          id="Rectangle_5436"
          data-name="Rectangle 5436"
          width="28"
          height="28"
          rx="2"
          transform="translate(16 628)"
          fill="#ff5c3e"
          opacity="0.1"
        />
        <g
          id="Icon_feather-log-out"
          data-name="Icon feather-log-out"
          transform="translate(17.5 629.5)"
        >
          <path
            id="Path_107468"
            data-name="Path 107468"
            d="M9.728,20.5H6.243A1.76,1.76,0,0,1,4.5,18.722V6.278A1.76,1.76,0,0,1,6.243,4.5H9.728"
            fill="none"
            stroke="#ff5c3e"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Path_107469"
            data-name="Path 107469"
            d="M24,19.53l4.357-4.515L24,10.5"
            transform="translate(-7.857 -2.515)"
            fill="none"
            stroke="#ff5c3e"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Path_107470"
            data-name="Path 107470"
            d="M23.956,18H13.5"
            transform="translate(-3.456 -5.5)"
            fill="none"
            stroke="#ff5c3e"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </g>
    </svg>
  );
};
export const FaqIcon = (props) => {
  const { width = "28", height = "28" } = props || {};
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 28 28"
    >
      <g
        id="Group_11507"
        data-name="Group 11507"
        transform="translate(-16 -424)"
      >
        <rect
          id="Rectangle_5429"
          data-name="Rectangle 5429"
          width="28"
          height="28"
          rx="2"
          transform="translate(16 424)"
          fill="#ffd016"
          opacity="0.1"
        />
        <g
          id="Icon_feather-help-circle"
          data-name="Icon feather-help-circle"
          transform="translate(18 426)"
        >
          <path
            id="Path_107463"
            data-name="Path 107463"
            d="M21,12a9,9,0,1,1-9-9,9,9,0,0,1,9,9Z"
            fill="none"
            stroke="#ffd016"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Path_107464"
            data-name="Path 107464"
            d="M13.635,12.31a2.719,2.719,0,0,1,5.284.906c0,1.813-2.719,2.719-2.719,2.719"
            transform="translate(-4.273 -3.011)"
            fill="none"
            stroke="#ffd016"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Path_107465"
            data-name="Path 107465"
            d="M18,25.5h0"
            transform="translate(-6 -9)"
            fill="none"
            stroke="#ffd016"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </g>
    </svg>
  );
};
export const SettingsIcon = (props) => {
  const { width = "28", height = "28" } = props || {};

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 28 28"
    >
      <g
        id="Group_11508"
        data-name="Group 11508"
        transform="translate(-16 -560)"
      >
        <rect
          id="Rectangle_5434"
          data-name="Rectangle 5434"
          width="28"
          height="28"
          rx="2"
          transform="translate(16 560)"
          fill="#05100f"
          opacity="0.1"
        />
        <g
          id="Icon_feather-settings"
          data-name="Icon feather-settings"
          transform="translate(19.5 563.5)"
        >
          <path
            id="Path_107466"
            data-name="Path 107466"
            d="M18.409,15.955A2.455,2.455,0,1,1,15.955,13.5,2.455,2.455,0,0,1,18.409,15.955Z"
            transform="translate(-5.455 -5.455)"
            fill="none"
            stroke="#575757"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Path_107467"
            data-name="Path 107467"
            d="M16.555,12.955a1.35,1.35,0,0,0,.27,1.489l.049.049a1.637,1.637,0,1,1-2.315,2.315l-.049-.049a1.361,1.361,0,0,0-2.307.965v.139a1.636,1.636,0,0,1-3.273,0V17.79a1.35,1.35,0,0,0-.884-1.235,1.35,1.35,0,0,0-1.489.27l-.049.049a1.637,1.637,0,1,1-2.315-2.315l.049-.049A1.361,1.361,0,0,0,3.275,12.2H3.136a1.636,1.636,0,1,1,0-3.273H3.21a1.35,1.35,0,0,0,1.235-.884,1.35,1.35,0,0,0-.27-1.489l-.049-.049A1.637,1.637,0,1,1,6.442,4.192l.049.049a1.35,1.35,0,0,0,1.489.27h.065a1.35,1.35,0,0,0,.818-1.235V3.136a1.636,1.636,0,0,1,3.273,0V3.21a1.361,1.361,0,0,0,2.307.965l.049-.049a1.637,1.637,0,1,1,2.315,2.315l-.049.049a1.35,1.35,0,0,0-.27,1.489v.065a1.35,1.35,0,0,0,1.235.818h.139a1.636,1.636,0,0,1,0,3.273H17.79a1.35,1.35,0,0,0-1.235.818Z"
            fill="none"
            stroke="#575757"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </g>
    </svg>
  );
};

export const SortBy = (props) => {
  const { width = "18", height = "16" } = props || {};
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 18 16"
    >
      <path
        id="Sort_by"
        data-name="Sort by"
        d="M10.687,15.964H8.437a.567.567,0,0,0-.562.571v1.143a.567.567,0,0,0,.563.571h2.25a.567.567,0,0,0,.562-.571V16.536A.567.567,0,0,0,10.687,15.964Zm-4.5-2.286H4.5V2.821a.567.567,0,0,0-.562-.571H2.812a.567.567,0,0,0-.562.571V13.679H.562a.573.573,0,0,0-.4.975l2.812,3.429a.556.556,0,0,0,.8,0l2.812-3.429A.574.574,0,0,0,6.187,13.679Zm9-6.857H8.437a.567.567,0,0,0-.562.571V8.536a.567.567,0,0,0,.563.571h6.75a.567.567,0,0,0,.562-.571V7.393A.567.567,0,0,0,15.187,6.821Zm-2.25,4.571h-4.5a.567.567,0,0,0-.562.571v1.143a.567.567,0,0,0,.563.571h4.5a.567.567,0,0,0,.563-.571V11.964A.567.567,0,0,0,12.937,11.393Zm4.5-9.143h-9a.567.567,0,0,0-.562.571V3.964a.567.567,0,0,0,.563.571h9A.567.567,0,0,0,18,3.964V2.821A.567.567,0,0,0,17.437,2.25Z"
        transform="translate(0 -2.25)"
        fill="#575757"
      />
    </svg>
  );
};

export const SpeechBubble = (props) => {
  const { width = "48px", height = "48px" } = props || {};
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0,0,256,256"
      width={width}
      height={height}
      fillRule="nonzero"
    >
      <g transform="">
        <g
          fill="#575757"
          fillRule="nonzero"
          stroke="none"
          strokeWidth="1"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeMiterlimit="10"
          strokeDasharray=""
          strokeDashoffset="0"
          fontFamily="none"
          fontWeight="none"
          fontSize="none"
          textAnchor="none"
        >
          <g transform="translate(-19.47423,256.18077) rotate(-90) scale(5.33333,5.33333)">
            <path d="M24,4c-11.02771,0 -20,8.97229 -20,20c0,3.27532 0.86271,6.33485 2.26172,9.06445l-2.16797,7.76367c-0.50495,1.8034 1.27818,3.58449 3.08203,3.08008l7.76758,-2.16797c2.72769,1.39712 5.7836,2.25977 9.05664,2.25977c11.02771,0 20,-8.97229 20,-20c0,-11.02771 -8.97229,-20 -20,-20zM24,7c9.40629,0 17,7.59371 17,17c0,9.40629 -7.59371,17 -17,17c-3.00297,0 -5.80774,-0.78172 -8.25586,-2.14648c-0.34566,-0.19287 -0.75354,-0.24131 -1.13477,-0.13477l-7.38672,2.0625l2.0625,-7.38281c0.10655,-0.38122 0.05811,-0.7891 -0.13477,-1.13477c-1.36674,-2.4502 -2.15039,-5.25915 -2.15039,-8.26367c0,-9.40629 7.59371,-17 17,-17z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
