import * as type from "../types";

const initialState = {
  trendingTopicsList: [
    {
      response: [],
      status: "Loading",
    },
  ],
  trendingTopicsListById: [
    {
      response: [],
      status: "Loading",
    },
  ],
  topicsListById: [
    {
      response: [],
      status: "Loading",
    },
  ],
};

export function trendingTopicsList(state = initialState, action) {
  switch (action.type) {
    case type.GET_TRENDING_TOPICS_SUCCESS:
      return {
        ...state,
        trendingTopicsList: [
          {
            response: action.payload.result,
            status: "Success",
          },
        ],
      };
    case type.GET_TRENDING_TOPICS_FAILED:
      return {
        ...state,
        trendingTopicsList: [
          {
            response: [],
            status: "Error",
          },
        ],
      };
    default:
      return state;
  }
}

export function trendingTopicsListById(state = initialState, action) {
  switch (action.type) {
    case type.GET_TRENDING_TOPICS_BY_ID_SUCCESS:
      return {
        ...state,
        trendingTopicsListById: [
          {
            response: action.payload.result,
            status: "Success",
          },
        ],
      };
    case type.GET_TRENDING_TOPICS_BY_ID_FAILED:
      return {
        ...state,
        trendingTopicsListById: [
          {
            response: [],
            status: "Error",
          },
        ],
      };
    default:
      return state;
  }
}

export function topicsListById(state = initialState, action) {
  switch (action.type) {
    case type.GET_TOPICS_BY_ID_SUCCESS:
      return {
        ...state,
        topicsListById: [
          {
            response: action.payload.result,
            status: "Success",
          },
        ],
      };
    case type.GET_TOPICS_BY_ID_FAILED:
      return {
        ...state,
        topicsListById: [
          {
            response: [],
            status: "Error",
          },
        ],
      };
    default:
      return state;
  }
}
