import React from "react";
import _ from "lodash";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { Auth } from "aws-amplify";
import * as Utils from "@utils";
import * as IconGallery from "@icongallery";
import { clearSearchSession } from "../../../../redux/actions";

function useExploreStates(props) {
  const {
    dispatch = () => "",
    navigate = () => "",
    navigateTo = () => "",
  } = props || {};

  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [openModelPopup, setOpenModelPopup] = React.useState(false);
  const [openSearchPopup, setOpenSearchPopup] = React.useState(false);

  const handleBackdropClose = () => {
    setOpenBackdrop(false);
  };
  const handleBackdropOpen = () => {
    setOpenBackdrop(true);
  };

  const handleModelPopupClose = () => {
    setOpenModelPopup(false);
  };
  const handleModelPopupOpen = () => {
    setOpenModelPopup(true);
  };

  const handleSearchPopupClose = () => {
    setOpenSearchPopup(false);
  };
  const handleSearchPopupOpen = () => {
    // setOpenSearchPopup(true);
    navigateTo(`/common/search/all`);
  };

  /* Snackbar functions */
  const [openToast, setOpenToast] = React.useState(false);
  const [vertical, setVertical] = React.useState("top");
  const [horizontal, setHorizontal] = React.useState("right");
  const [severityNote, setSeverityNote] = React.useState("info");
  const [message, setMessage] = React.useState("");

  const [showVerifyEmail, setShowVerifyEmail] = React.useState(false);

  const handleOpenToast = () => {
    setOpenToast(true);
  };

  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
  };
  /*snackbar end */

  return {
    openBackdrop,
    setOpenBackdrop,
    handleBackdropOpen,
    handleBackdropClose,
    openModelPopup,
    setOpenModelPopup,
    handleModelPopupClose,
    handleModelPopupOpen,
    openToast,
    setOpenToast,
    handleOpenToast,
    handleCloseToast,
    vertical,
    setVertical,
    horizontal,
    setHorizontal,
    message,
    setMessage,
    severityNote,
    setSeverityNote,
    openSearchPopup,
    setOpenSearchPopup,
    handleSearchPopupClose,
    handleSearchPopupOpen,
  };
}

export { useExploreStates };
export default useExploreStates;
