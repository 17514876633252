import { styled } from "@mui/material/styles";
import { Box, Grid, Typography, Button } from "@mui/material";

export const StyledBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 200px);
  padding: 0 20px;
  text-align: center;
`;

export const StyledTypographyH5 = styled("h5")`
  color: #333333;
  margin-top: 10px;
  word-break: break-word;
  margin-bottom: 0px;
  font-weight: 900;
  font-size: 16px;
`;
export const StyledTypographyH6 = styled("h6")`
  color: #333333;
  margin-top: 10px;
  word-break: break-word;
  margin-bottom: 0px;
  font-weight: 600;
  font-size: 12px;
`;
export const StyledButton = styled(Button)`
  margin-top: 20px;
  background: #26867d;
`;
