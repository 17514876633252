// import * as React from "react";
// import { Button, Snackbar, IconButton } from "@mui/material";
// import CloseIcon from "@mui/icons-material/Close";

import React, { useState, useEffect } from "react";
import {
  Grid,
  IconButton,
  Button,
  Card,
  CardContent,
  Typography,
  TextField,
  Box,
  Stack,
  Snackbar as SnackbarMUI,
  Alert as MuiAlert,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledSnackbarMUI = styled(SnackbarMUI)`
  .cls-alert-txt {
    font-size: 14px !important;
    box-shadow: 2px 2px 1px #000000bf;
  }
  .cls-alert-txt,
  svg {
    color: #fff !important;
  }
  .cls-alert-txt.cls-warning {
    background: #e2b93b;
  }
  .cls-alert-txt.cls-info {
    background: #007fff;
  }
  .cls-alert-txt.cls-error {
    background: #eb5757;
  }
  .cls-alert-txt.cls-success {
    background: #10bc55;
  }
`;

function ToastSnackbar(props) {
  const {
    openToast = false,
    handleOpenToast = () => "",
    handleCloseToast = () => "",
    vertical = "bottom",
    horizontal = "right",
    message = "Cool",
    severityNote = "info",
  } = props || {};

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <StyledSnackbarMUI
        open={openToast}
        autoHideDuration={6000}
        onClose={handleCloseToast}
        anchorOrigin={{ vertical, horizontal }}
      >
        <MuiAlert
          className={`cls-alert-txt cls-${severityNote}`}
          onClose={handleCloseToast}
          severity={severityNote}
          sx={{ width: "100%" }}
        >
          {message}
        </MuiAlert>
      </StyledSnackbarMUI>
    </Stack>
  );
}

export { ToastSnackbar };
export default ToastSnackbar;

// function ToastSnackbar(props) {
//   const {
//     open,
//     handleClick = () => {},
//     handleClose = () => {},
//     message = "",
//     vertical = "bottom",
//     horizontal = "right",
//     severity=""
//   } = props || {};

//   const action = (
//     <React.Fragment>
//       {/* <Button color="secondary" size="small" onClick={handleClose}>
//         UNDO
//       </Button> */}
//       <IconButton
//         size="small"
//         aria-label="close"
//         color="inherit"
//         onClick={handleClose}
//       >
//         <CloseIcon fontSize="small" />
//       </IconButton>
//     </React.Fragment>
//   );

//   return (
//     <div>
//       <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
//         <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
//           {message}
//         </Alert>
//       </Snackbar>
//       <Snackbar
//         open={open}
//         autoHideDuration={6000}
//         onClose={handleClose}
//         message={message}
//         action={action}
//         anchorOrigin={{ vertical, horizontal }}
//       />
//     </div>
//   );
// }

// export { ToastSnackbar };
// export default ToastSnackbar;
