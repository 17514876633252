import React from "react";
import * as ComponentsLib from "@componentsLib";
import * as IconGallery from "@icongallery";
import * as Utils from "@utils";
import * as StyledDOM from "./styles";
import { Link, useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import SingleForum from "./SingleForum";
import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getComments } from "../../../../../redux/actions";
import { postReplyApi, getReplyApi } from "../api";

function TermForum(props) {
  const {
    screenRerender = "",
    setScreenRerender = () => "",
    isRoleVerified,
    setMessage = () => "",
    setSeverityNote = () => "",
    handleOpenToast = () => "",
    categoryId,
  } = props || {};
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const {
    // categoryId = "",
    categoryName = "",
    termName = "",
    termId = "",
  } = params || {};
  const [showReplyArea, setShowReplyArea] = React.useState(false);
  const [showReplyTo, setShowReplyTo] = React.useState("");
  const [replyComment, setReplyComment] = React.useState("");
  const [replyItemId, setReplyItemId] = React.useState("");
  const [showProgressBar, setShowProgressBar] = React.useState(false);
  const [disableSendBtn, setDisableSendBtn] = React.useState(true);
  const triggerNewComment = () => {
    if (isRoleVerified) {
      navigateTo("./add-comments");
    } else {
      handleOpenToast();
      setSeverityNote("warning");
      setMessage(
        "Your profile category is not verified yet. Please verify it to contribute in the terms."
      );
    }
  };

  const triggerReplyComment = () => {
    setShowProgressBar(true);
    setDisableSendBtn(true);
    const postReplyApiProps = {
      ...props,
      termId,
      comment: replyComment,
      answer_id: replyItemId,
      setReplyComment,
      setShowReplyArea,
      screenRerender,
      setScreenRerender,
    };
    postReplyApi(postReplyApiProps);
  };

  const triggerCommentBox = (e) => {
    setReplyComment(e.target.value);
    setDisableSendBtn(false);
  };

  const triggerReplyTo = (item) => {
    setShowReplyTo(item?.user?.name);
    setReplyItemId(item.id);
    setShowReplyArea(true);
  };
  const singleForumProps = {
    ...props,
    termId,
    categoryId,
    triggerReplyTo,
    isRoleVerified,
  };
  return (
    <>
      <SingleForum {...singleForumProps} />

      <StyledDOM.StyledCommentBoxOuter>
        {/* {isRoleVerified && isRoleVerified !== "" ? ( */}
        <>
          {showReplyArea ? (
            <>
              {showProgressBar && <ComponentsLib.LineProgress />}
              <StyledDOM.StyledReplyText style={{ display: "flex" }}>
                <span>Replying to {showReplyTo}</span>
                <span onClick={() => setShowReplyArea(false)}>
                  <IconGallery.CloseCommentIcon />
                </span>
              </StyledDOM.StyledReplyText>
              <StyledDOM.StyledCommentBox>
                <StyledDOM.StyledTextarea
                  onChange={triggerCommentBox}
                  placeholder="Start writing your comments."
                  value={replyComment}
                ></StyledDOM.StyledTextarea>
                <span
                  className={disableSendBtn ? "inactive" : "active"}
                  onClick={() =>
                    disableSendBtn === false ? triggerReplyComment() : ""
                  }
                >
                  <IconGallery.EmailSendIcon />
                </span>
              </StyledDOM.StyledCommentBox>
            </>
          ) : (
            <StyledDOM.StyledCommentBox onClick={() => triggerNewComment()}>
              <span>Start writing your Contribution here..</span>
              <IconGallery.EmailSendIcon />
            </StyledDOM.StyledCommentBox>
          )}
        </>
        {/* ) : (
          <StyledDOM.StyledCommentBox onClick={() => triggerNewComment()}>
            <span>
              Your profile category is not verified yet. Please verify it to
              contribute in the terms.
            </span>
          </StyledDOM.StyledCommentBox>
        )} */}
      </StyledDOM.StyledCommentBoxOuter>
    </>
  );
}

export { TermForum };
export default TermForum;
