import { Grid, Icon } from "@mui/material";
import _ from "lodash";
import * as React from "react";
import * as StyledDOM from "./styles";
import * as IconGallery from "@icongallery";
import * as Utils from "@utils";
import * as ComponentsLib from "@componentsLib";
import { Link } from "react-router-dom";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <StyledDOM.StyledBox>
          <StyledDOM.StyledTypography>{children}</StyledDOM.StyledTypography>
        </StyledDOM.StyledBox>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function GridListCard(props) {
  const {
    action = "",
    answer_id = "",
    category_id = "",
    id = "",
    message: definition = "",
    name = "",
    notify_name = "",
    notify_to = "",
    question_id = "",
    user_detail = [],
    user_id = "",
    title = "",
    profileUrl = "",
    date = "",
    status = "read",
    isPromote = false,
  } = props || {};
  function stringAvatar(name: string) {
    let splitName = name.split(" ");
    let childrenName =
      splitName.length > 1
        ? `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
        : `${splitName[0][0]}`;
    return {
      // sx: {
      //   bgcolor: stringToColor(name),
      // },
      children: `${childrenName}`,
    };
  }
  if (isPromote) {
    return (
      <>
        <StyledDOM.StyledOuterGrid
          container
          item
          xs={12}
          className={status == "unread" ? "active" : ""}
        >
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {title ? (
              <span
                style={{
                  padding: "10px 5px",
                  textTransform: "capitalize",
                  fontWeight: "900",
                }}
              >
                {title}
              </span>
            ) : (
              ""
            )}
            <Grid
              style={{
                fontSize: "11px",
                color: "#9A999A",
                fontWeight: "700",
              }}
            >
              {Utils.getCurrentTime(":")}
            </Grid>
          </Grid>
          <Grid item xs={2}>
            {profileUrl ? (
              profileUrl
            ) : (
              <StyledDOM.StyledAvatar
                variant="rounded"
                {...stringAvatar(name)}
              />
            )}
          </Grid>
          <Grid
            item
            xs={10}
            dangerouslySetInnerHTML={{
              __html: definition ? definition : "",
            }}
          ></Grid>
          <StyledDOM.StyledLink to="/leaderboard">
            View Leaderboard
          </StyledDOM.StyledLink>
        </StyledDOM.StyledOuterGrid>
        <StyledDOM.StyledHr />
      </>
    );
  } else {
    return (
      <>
        <StyledDOM.StyledOuterGrid
          container
          item
          xs={12}
          className={status == "unread" ? "active" : ""}
        >
          <StyledDOM.StyledTabPanelGrid item xs={12}>
            {title ? <span>{title}</span> : ""}
          </StyledDOM.StyledTabPanelGrid>
          <Grid item xs={2}>
            {profileUrl ? (
              profileUrl
            ) : (
              <StyledDOM.StyledAvatar
                variant="rounded"
                {...stringAvatar(name)}
              />
            )}
          </Grid>
          <Grid item xs={10}>
            <Grid
              dangerouslySetInnerHTML={{
                __html: definition ? definition : "",
              }}
            ></Grid>
            <Grid
              style={{
                paddingTop: "15px",
                fontSize: "11px",
                color: "#9A999A",
                fontWeight: "700",
              }}
            >
              {Utils.getCurrentTime()}
            </Grid>
          </Grid>
        </StyledDOM.StyledOuterGrid>
        <StyledDOM.StyledHr />
      </>
    );
  }
}

function NotificationsTab(props) {
  const {
    onChange = () => "",
    tabsList = [],
    contributionList = [],
    otherNotificationsList = [],
    notificationStatus: status = "Loading",
  } = props || {};
  let today = new Date();
  const objects = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    // onChange(newValue);
    setValue(newValue);
  };

  // .filter((item) => item.created_at === today)
  const filterTodayContributionsList =
    contributionList.length > 0 &&
    contributionList.sort(function (x, y) {
      return y.created_at - x.created_at;
    });

  // .filter((item) => item.created_at > today)
  const filterOlderContributionsList =
    contributionList.length > 0 &&
    contributionList.sort(function (x, y) {
      return y.created_at - x.created_at;
    });

  const filterTodayOtherNotificationsList =
    otherNotificationsList.length > 0 &&
    otherNotificationsList.sort(function (x, y) {
      return y.created_at - x.created_at;
    });

  const filterOlderOtherNotificationsList =
    otherNotificationsList.length > 0 &&
    otherNotificationsList.sort(function (x, y) {
      return y.created_at - x.created_at;
    });

  const rectLoaderProps = {
    variant: "rectangular",
    width: "100%",
    height: 100,
  };
  const contributionErrorProps = {
    content: "Notification(s) Not found",
    bgColor: "#fff",
  };
  const otherErrorProps = {
    content: "Notification(s) Not found",
    bgColor: "#fff",
  };
  return (
    <>
      <StyledDOM.StyledBox sx={{ width: "auto", padding: "0 15px" }}>
        <StyledDOM.StyledBox sx={{ borderBottom: 1, borderColor: "divider" }}>
          <StyledDOM.StyledTabs
            value={value}
            onChange={handleChange}
            variant="fullWidth"
            textColor="primary"
            indicatorColor="primary"
            style={{
              textTransform: "capitalize",
              padding: "0px 15px",
            }}
          >
            {tabsList &&
              tabsList.map((tabItem, idx) => {
                return (
                  <StyledDOM.StyledTab
                    key={`${tabItem.title}_${idx}`}
                    label={
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span>{tabItem.title}</span>
                        {tabItem.count != 0 && (
                          <StyledDOM.StyledCount>
                            {tabItem.count}
                          </StyledDOM.StyledCount>
                        )}
                      </div>
                    }
                    {...a11yProps(idx)}
                  />
                );
              })}
          </StyledDOM.StyledTabs>
        </StyledDOM.StyledBox>
      </StyledDOM.StyledBox>
      <TabPanel value={value} index={0}>
        {status == "Loading" ? (
          <>
            {objects.map(function (object, i) {
              return (
                <StyledDOM.StyledGrid key={i} item xs={4}>
                  <ComponentsLib.SkeletonLoader {...rectLoaderProps} />
                </StyledDOM.StyledGrid>
              );
            })}
          </>
        ) : (
          <>
            {status == "Error" ? (
              <Grid item xs={12} style={{ marginTop: "20px" }}>
                <ComponentsLib.ErrorSection {...contributionErrorProps} />
              </Grid>
            ) : (
              <>
                {/* {!_.isEmpty(filterTodayContributionsList) && (
                  <StyledDOM.StyledTabPanelGrid>
                    <StyledDOM.StyledTabPanelGridSpan>
                      New
                    </StyledDOM.StyledTabPanelGridSpan>
                  </StyledDOM.StyledTabPanelGrid>
                )}
                {!_.isEmpty(filterTodayContributionsList) &&
                  filterTodayContributionsList.map((item, index) => {
                    return <>{GridListCard(item)}</>;
                  })}

                {!_.isEmpty(filterOlderContributionsList) && (
                  <StyledDOM.StyledTabPanelGridSpan>
                    Yesterday
                  </StyledDOM.StyledTabPanelGridSpan>
                )} */}
                {!_.isEmpty(filterOlderContributionsList) &&
                  filterOlderContributionsList.map((item, index) => {
                    return <>{GridListCard(item)}</>;
                  })}
              </>
            )}
          </>
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {status == "Loading" ? (
          <>
            {objects.map(function (object, i) {
              return (
                <StyledDOM.StyledGrid key={i} item xs={4}>
                  <ComponentsLib.SkeletonLoader {...rectLoaderProps} />
                </StyledDOM.StyledGrid>
              );
            })}
          </>
        ) : (
          <>
            {status == "Error" ? (
              <Grid item xs={12} style={{ marginTop: "20px" }}>
                <ComponentsLib.ErrorSection {...otherErrorProps} />
              </Grid>
            ) : (
              <>
                {/* <StyledDOM.StyledTabPanelGrid>
                  {!_.isEmpty(filterTodayOtherNotificationsList) && (
                    <StyledDOM.StyledTabPanelGridSpan>
                      New
                    </StyledDOM.StyledTabPanelGridSpan>
                  )}
                </StyledDOM.StyledTabPanelGrid>
                {!_.isEmpty(filterTodayOtherNotificationsList) &&
                  filterTodayOtherNotificationsList.map((item, index) => {
                    return <>{GridListCard(item)}</>;
                  })}
                {!_.isEmpty(filterOlderOtherNotificationsList) && (
                  <StyledDOM.StyledTabPanelGridSpan>
                    Yesterday
                  </StyledDOM.StyledTabPanelGridSpan>
                )} */}
                {!_.isEmpty(filterOlderOtherNotificationsList) &&
                  filterOlderOtherNotificationsList.map((item, index) => {
                    return <>{GridListCard(item)}</>;
                  })}
              </>
            )}
          </>
        )}
      </TabPanel>
    </>
  );
}

export { NotificationsTab };
export default NotificationsTab;
