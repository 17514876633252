import React, { useEffect, useState } from "react";
import * as StyledDOM from "./styles";
import * as IconGallery from "@icongallery";

const BookmarkButton = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    });
  }, []);
  function handleBookmark() {
    // const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    // const isChrome =
    //   /CriOS/i.test(navigator.userAgent) &&
    //   /iphone|ipod|ipad/i.test(navigator.userAgent);

    // if (isChrome) {
    //   if (deferredPrompt) {
    //     deferredPrompt.prompt();
    //     deferredPrompt.userChoice.then((choiceResult) => {
    //       if (choiceResult.outcome === "accepted") {
    //         console.log("User accepted the A2HS prompt");
    //       } else {
    //         console.log("User dismissed the A2HS prompt");
    //       }
    //       setDeferredPrompt(null);
    //     });
    //   }
    // } else if (isSafari) {
    //   window.alert(
    //     'To add this web app to your home screen, tap the "Share" icon below and then select "Add to Home Screen (curator.phenowise.com -> Share -> Add to Home screen)"'
    //   );
    // } else {
    //   if (deferredPrompt) {
    //     deferredPrompt.prompt();
    //     deferredPrompt.userChoice.then((choiceResult) => {
    //       if (choiceResult.outcome === "accepted") {
    //         console.log("User accepted the A2HS prompt");
    //       } else {
    //         console.log("User dismissed the A2HS prompt");
    //       }
    //       setDeferredPrompt(null);
    //     });
    //   }
    // }
    // window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("/service-worker.js")
      .then((registration) => {
        console.log("Service worker registered:", registration);
      })
      .catch((error) => {
        console.error("Service worker registration failed:", error);
      });
    // });
  }

  return (
    <div>
      <StyledDOM.StyledSpanIcon onClick={handleBookmark}>
        <IconGallery.BookmarkIcon width="50" />
      </StyledDOM.StyledSpanIcon>
    </div>
  );
};

export default BookmarkButton;

export { BookmarkButton };
