const domainUrlTest = {
  mainApiUrl: {
    apiUrl: "https://s84oclvcgk.execute-api.us-east-2.amazonaws.com/dev",
    redirectSignInUrlSSO: "https://curator.phenowise.com",
    redirectSignOutUrlSSO: "https://curator.phenowise.com",
    awsUserPoolsIid: "us-east-1_U5yfLfUZb",
    awsUserPoolsWebClientId: "5fkpn15lmmjg41kc5lcov0ehkh",
    // awsUserPoolsIid: "us-east-1_YPpMRYe3r",
    // awsUserPoolsWebClientId: "4fcv5vnmfjsesq94jqv7neger5",
  },
  qaApiUrl: {
    apiUrl: "https://6d9agu86aa.execute-api.us-east-1.amazonaws.com/dev",
    redirectSignInUrlSSO: "https://curator-dev.phenowise.com",
    redirectSignOutUrlSSO: "https://curator-dev.phenowise.com",
    awsUserPoolsIid: "us-east-1_U5yfLfUZb",
    awsUserPoolsWebClientId: "5fkpn15lmmjg41kc5lcov0ehkh",
    // awsUserPoolsIid: "us-east-1_YPpMRYe3r",
    // awsUserPoolsWebClientId: "4fcv5vnmfjsesq94jqv7neger5",
  },
  localApiUrl: {
    apiUrl: "https://6d9agu86aa.execute-api.us-east-1.amazonaws.com/dev",
    redirectSignInUrlSSO: "http://localhost:3000",
    redirectSignOutUrlSSO: "http://localhost:3000",
    awsUserPoolsIid: "us-east-1_U5yfLfUZb",
    awsUserPoolsWebClientId: "5fkpn15lmmjg41kc5lcov0ehkh",
    // awsUserPoolsIid: "us-east-1_YPpMRYe3r",
    // awsUserPoolsWebClientId: "4fcv5vnmfjsesq94jqv7neger5",
  },
};
const setDynamicUrl = (endpoint) => {
  const hostUrl = window.location.href;
  const parsedUrl = new URL(hostUrl);
  const { hostname = "" } = parsedUrl || {};
  let REACT_APP_API_URL = hostname.includes("curator.phenowise.com")
    ? domainUrlTest["mainApiUrl"][endpoint]
    : hostname.includes("curator-dev.phenowise.com")
    ? domainUrlTest["qaApiUrl"][endpoint]
    : domainUrlTest["localApiUrl"][endpoint];
  return REACT_APP_API_URL;
};

export const assignDomainUrl = () => {
  return setDynamicUrl("apiUrl");
};

export const assignRedirectSignInUrlSSO = () => {
  return setDynamicUrl("redirectSignInUrlSSO");
};

export const assignRedirectSignOutUrlSSO = () => {
  return setDynamicUrl("redirectSignOutUrlSSO");
};

export const awsUserPoolsId = () => {
  return setDynamicUrl("awsUserPoolsIid");
};

export const awsUserPoolsWebId = () => {
  return setDynamicUrl("awsUserPoolsWebClientId");
};
