import React from "react";
import * as ComponentsLib from "@componentsLib";
import * as IconGallery from "@icongallery";
import * as Utils from "@utils";
import * as StyledDOM from "./styles";
import _ from "lodash";
import { Grid, Tooltip } from "@mui/material";
import SortUp from "../../../../../../assets/images/sort-up.png";
import SortDown from "../../../../../../assets/images/sort-down.png";
import Comments from "../../../../../../assets/images/comments.png";
import { postVoteApi } from "../../api";

const objects = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const chatSectionComponent = (
  enableTriggerReplyTo,
  item,
  index,
  triggerPostVote,
  isChild,
  triggerShowSymptom,
  showSymptomIndex,
  userUUID,
  openDownVote,
  setOpenDownVote,
  openUpVote,
  setOpenUpVote,
  showDefinitionText,
  setShowDefinitionText,
  showCommentText,
  setShowCommentText
) => {
  // const { item = [], triggerReplyTo = () => "", index = 0 } = props || {};
  const {
    created_at = "",
    symptom: symptoms = [],
    definition = "",
    user = {},
    answer_accept = 0,
    comment = "",
    reputation = [],
    user_id = "",
  } = item || {};

  const actionReputationArray = reputation.reduce(
    (actionReputationArraySoFar, { action, reputation }) => {
      if (!actionReputationArraySoFar[action])
        actionReputationArraySoFar[action] = [];
      actionReputationArraySoFar[action].push(reputation);
      return actionReputationArraySoFar;
    },
    {}
  );
  let currentUserVoted = reputation.reduce(
    (currentUserVotedSoFar, { action, user_id }) => {
      if (!currentUserVotedSoFar[action]) currentUserVotedSoFar[action] = [];
      currentUserVotedSoFar[action].push(user_id);
      return currentUserVotedSoFar;
    },
    {}
  );

  let currentUserUpVoted =
    currentUserVoted?.up?.length > 0 &&
    currentUserVoted.up.filter((item) => item === userUUID);
  let currentUserDownVoted =
    currentUserVoted?.down?.length > 0 &&
    currentUserVoted.down.filter((item) => item === userUUID);

  let acceptCount = parseInt(
    parseInt(
      actionReputationArray?.up?.length ? actionReputationArray?.up?.length : 0
    ) -
      parseInt(
        actionReputationArray?.down?.length
          ? actionReputationArray?.down?.length
          : 0
      )
  );
  let option1,
    option2 = "";
  !_.isArray(symptoms)
    ? (option1 = symptoms)
    : ([option1 = "", option2 = ""] = symptoms || []);
  const { name = "", profileIcon = "", Items = [] } = user || {};
  const [FirstElement] = Items;
  const { name: replyName = "" } = FirstElement || {};
  const handleTooltipCloseUpVote = () => {
    setOpenUpVote(-1);
  };
  const handleTooltipOpenUpVote = (clickedIndex) => {
    setOpenDownVote(-1);
    setOpenUpVote(clickedIndex);
    setTimeout(() => {
      handleTooltipCloseUpVote();
    }, 2000);
  };
  const handleTooltipCloseDownVote = () => {
    setOpenDownVote(-1);
  };
  const handleTooltipOpenDownVote = (clickedIndex) => {
    setOpenUpVote(-1);
    setOpenDownVote(clickedIndex);
    setTimeout(() => {
      handleTooltipCloseDownVote();
    }, 2000);
  };
  const triggerDefinitionShowMore = (value) => {
    setShowDefinitionText((oldValue) => [...oldValue, value]);
  };
  const triggerDefinitionShowLess = (value) => {
    const newPeople = showDefinitionText.filter((id) => id !== value);
    setShowDefinitionText(newPeople);
  };
  const triggerCommentShowMore = (value) => {
    setShowCommentText((oldValue) => [...oldValue, value]);
  };
  const triggerCommentShowLess = (value) => {
    const newPeople = showCommentText.filter((id) => id !== value);
    setShowCommentText(newPeople);
  };

  return (
    <>
      <StyledDOM.StyledOuterThreadGrid
        container
        item
        xs={12}
        className={isChild ? "child" : ""}
      >
        <StyledDOM.StyledIconGrid item xs={2} sm={0.8}>
          {profileIcon ? (
            <></>
          ) : (
            <IconGallery.ProfileCircleIcon
              sx={{ fontSize: "50px", color: "#ddd" }}
            />
          )}
        </StyledDOM.StyledIconGrid>
        <StyledDOM.StyledContentGrid item xs={10} sm={11.2}>
          <StyledDOM.StyledTypography>
            {isChild ? replyName : name}
          </StyledDOM.StyledTypography>
          <StyledDOM.StyledTypography className="small">
            {Utils?.getTimeStampDateFormat(created_at)}
          </StyledDOM.StyledTypography>
        </StyledDOM.StyledContentGrid>
        <StyledDOM.StyledContentTextGrid item xs={12}>
          <p>
            {isChild ? (
              <>
                {showCommentText.includes(index) ? (
                  <>
                    {comment}{" "}
                    <StyledDOM.StyledSpan
                      onClick={() => triggerCommentShowLess(index)}
                    >
                      Show Less
                    </StyledDOM.StyledSpan>
                  </>
                ) : (
                  <>
                    {Utils.contentLimitByCharacter(comment, 50)}{" "}
                    {comment.length >= 50 && (
                      <StyledDOM.StyledSpan
                        onClick={() => triggerCommentShowMore(index)}
                      >
                        Show More
                      </StyledDOM.StyledSpan>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {showDefinitionText.includes(index) ? (
                  <>
                    {definition}{" "}
                    <StyledDOM.StyledSpan
                      onClick={() => triggerDefinitionShowLess(index)}
                    >
                      Show Less
                    </StyledDOM.StyledSpan>
                  </>
                ) : (
                  <>
                    {Utils.contentLimitByCharacter(definition, 550)}{" "}
                    {definition.length >= 550 && (
                      <StyledDOM.StyledSpan
                        onClick={() => triggerDefinitionShowMore(index)}
                      >
                        Show More
                      </StyledDOM.StyledSpan>
                    )}
                  </>
                )}
              </>
            )}
          </p>
        </StyledDOM.StyledContentTextGrid>
        {symptoms?.length > 0 && (
          <StyledDOM.StyledTagsGrid
            item
            xs={12}
            className={showSymptomIndex === index ? "sympList" : ""}
          >
            <span className="symp">Symptoms:</span>
            {showSymptomIndex !== index ? (
              <>
                <Grid>
                  {option1 && (
                    <StyledDOM.StyledTags
                      label={Utils.replaceSpecialCharactersValue(option1, "")}
                    />
                  )}
                  {option2 && (
                    <StyledDOM.StyledTags
                      label={Utils.replaceSpecialCharactersValue(option2, "")}
                    />
                  )}
                  <span
                    className="symptomsCount"
                    onClick={() => triggerShowSymptom(index)}
                  >
                    {_.isArray(symptoms) && symptoms.length - 2 > 0 && (
                      <>+{symptoms.length - 2} more</>
                    )}
                  </span>
                </Grid>
              </>
            ) : (
              <Grid>
                {symptoms.length > 0 &&
                  symptoms?.map((sympItem, index) => {
                    return (
                      <StyledDOM.StyledTags key={index} label={sympItem} />
                    );
                  })}
              </Grid>
            )}
          </StyledDOM.StyledTagsGrid>
        )}
        {!isChild && (
          <StyledDOM.StyledReplyGrid item xs={12}>
            <StyledDOM.StyledVote>
              {currentUserUpVoted?.length > 0 ? (
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={handleTooltipCloseUpVote}
                  open={openUpVote === index ? true : false}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title="You already up voted for this contribution"
                  arrow
                >
                  <StyledDOM.StyledUpVoteImg
                    src={SortUp}
                    onClick={() => handleTooltipOpenUpVote(index)}
                    alt="up"
                  />
                </Tooltip>
              ) : (
                <StyledDOM.StyledUpVoteImg
                  src={SortUp}
                  alt="up"
                  onClick={() => triggerPostVote(item, "up", currentUserVoted)}
                />
              )}
              {!isNaN(acceptCount) ? acceptCount : 0}
              {currentUserDownVoted?.length > 0 ? (
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={handleTooltipCloseDownVote}
                  open={openDownVote === index ? true : false}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title="You already down voted for this contribution"
                  arrow
                >
                  <StyledDOM.StyledDownVoteImg
                    src={SortDown}
                    onClick={() => handleTooltipOpenDownVote(index)}
                    alt="down"
                  />
                </Tooltip>
              ) : (
                <StyledDOM.StyledDownVoteImg
                  src={SortDown}
                  alt="down"
                  onClick={() =>
                    triggerPostVote(item, "down", currentUserVoted)
                  }
                />
              )}
            </StyledDOM.StyledVote>
            {!isChild && (
              <StyledDOM.StyledComments
                onClick={() => enableTriggerReplyTo(item)}
              >
                <img
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "5px",
                  }}
                  src={Comments}
                  alt="Comments"
                />
                {item?.comments?.length ? item?.comments?.length : 0} Replies
              </StyledDOM.StyledComments>
            )}
          </StyledDOM.StyledReplyGrid>
        )}
      </StyledDOM.StyledOuterThreadGrid>
    </>
  );
};
const rectLoaderProps = {
  variant: "rectangular",
  width: "100%",
  height: 100,
};
const errorProps = {
  content: "Be the first to contribute to this Topic.",
  width: "100%",
  height: 60,
};
function SingleForum(props) {
  let {
    triggerReplyTo = () => "",
    reStructureAnswer = [],
    status,
    screenRerender = "",
    setScreenRerender = () => "",
    userUUID = "",
    isRoleVerified,
    setMessage = () => "",
    setSeverityNote = () => "",
    handleOpenToast = () => "",
  } = props || {};
  const [showSymptomIndex, setShowSymptomIndex] = React.useState(-1);
  const [openDownVote, setOpenDownVote] = React.useState(-1);
  const [openUpVote, setOpenUpVote] = React.useState(-1);
  const [showDefinitionText, setShowDefinitionText] = React.useState([]);
  const [showCommentText, setShowCommentText] = React.useState([]);

  const triggerShowSymptom = (index) => {
    setShowSymptomIndex(index);
  };
  const sendPostVote = (item, voteProps) => {
    const postVoteProps = {
      id: item.id,
      value: voteProps,
      screenRerender,
      setScreenRerender,
    };
    postVoteApi({ ...props, ...postVoteProps });
  };
  const enableTriggerReplyTo = (item) => {
    if (isRoleVerified) {
      triggerReplyTo(item);
    } else {
      handleOpenToast();
      setSeverityNote("warning");
      setMessage(
        "Your profile category is not verified yet. Please verify it to reply in the term's contribution(s)."
      );
    }
  };
  const triggerPostVote = (item, voteProps, userArray) => {
    if (item.user_id === userUUID) {
      handleOpenToast();
      setSeverityNote("info");
      setMessage("You can't up/down vote for your own contribution(s)");
    } else {
      if (isRoleVerified) {
        sendPostVote(item, voteProps);
      } else {
        handleOpenToast();
        setSeverityNote("warning");
        setMessage(
          "Your profile category is not verified yet. Please verify it to up/down vote in the terms."
        );
      }
    }
  };

  let threadLoop = [];
  reStructureAnswer = reStructureAnswer?.sort(function (x, y) {
    return y.created_at - x.created_at;
  });

  threadLoop = reStructureAnswer.map((item, index) => {
    item.comments = item?.comments?.sort(function (x, y) {
      return y.created_at - x.created_at;
    });
    let reOrderValue = {
      ...item,
    };
    return reOrderValue;
  });

  return (
    <>
      {status === "Loading" ? (
        <>
          {objects.map(function (object, i) {
            return (
              <StyledDOM.StyledGrid key={i} item xs={4}>
                <ComponentsLib.SkeletonLoader {...rectLoaderProps} />
              </StyledDOM.StyledGrid>
            );
          })}
        </>
      ) : (
        <>
          {status === "Error" ? (
            <StyledDOM.StyledGrid item xs={12}>
              <ComponentsLib.ErrorSection {...errorProps} />
            </StyledDOM.StyledGrid>
          ) : (
            <>
              {_.isEmpty(threadLoop) && (
                <StyledDOM.StyledGrid item xs={12} p={2}>
                  <ComponentsLib.ErrorSection {...errorProps} />
                </StyledDOM.StyledGrid>
              )}
              {!_.isEmpty(threadLoop) &&
                threadLoop.map((item, index) => {
                  return (
                    <Grid key={index}>
                      {chatSectionComponent(
                        enableTriggerReplyTo,
                        item,
                        index,
                        triggerPostVote,
                        false,
                        triggerShowSymptom,
                        showSymptomIndex,
                        userUUID,
                        openDownVote,
                        setOpenDownVote,
                        openUpVote,
                        setOpenUpVote,
                        showDefinitionText,
                        setShowDefinitionText,
                        showCommentText,
                        setShowCommentText
                      )}
                      {item?.comments?.length > 0 && (
                        <StyledDOM.StyledChildCommentBox>
                          {item?.comments?.map((childItem, childIndex) => {
                            return (
                              <Grid key={childIndex}>
                                {chatSectionComponent(
                                  enableTriggerReplyTo,
                                  childItem,
                                  childIndex,
                                  triggerPostVote,
                                  true,
                                  triggerShowSymptom,
                                  showSymptomIndex,
                                  userUUID,
                                  openDownVote,
                                  setOpenDownVote,
                                  openUpVote,
                                  setOpenUpVote,
                                  showDefinitionText,
                                  setShowDefinitionText,
                                  showCommentText,
                                  setShowCommentText
                                )}
                              </Grid>
                            );
                          })}
                        </StyledDOM.StyledChildCommentBox>
                      )}
                      {index + 1 !== threadLoop.length && (
                        <StyledDOM.StyledHr />
                      )}
                    </Grid>
                  );
                })}
            </>
          )}
        </>
      )}
    </>
  );
}

export { SingleForum };
export default SingleForum;
