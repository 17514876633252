import React from "react";
import * as Utils from "@utils";
import * as StyledDOM from "../styles";
import CircularProgress from "@mui/material/CircularProgress";
import OtpForm from "./otpForm";

const VerifyEmail = (props) => {
  const {
    handleBack = () => "",
    emailVerifyText = "",
    emailVerifySubText = "",
    resendTrigger = () => "",
    disableResendBtn,
    isBtnLoading,
    verifyEmailCode = () => "",
    formValuesObj = {},
  } = props || {};

  const [disableBtn, setDisableBtn] = React.useState(true);
  const [disableBtnLoading, setDisableBtnLoading] = React.useState(true);
  const [timer, setTimer] = React.useState(30);
  const id = React.useRef(null);
  const clear = () => {
    window.clearInterval(id.current);
  };
  React.useEffect(() => {
    id.current = window.setInterval(() => {
      setTimer((time) => time - 1);
    }, 1000);
    return () => clear();
  }, [timer]);

  React.useEffect(() => {
    if (timer === 0) {
      clear();
    }
  }, [timer]);

  const changeEmail = () => {
    handleBack();
  };

  const [value, setValue] = React.useState("");

  const handleComplete = (finalValue: number) => {
    setDisableBtn(false);
    setValue(finalValue);
  };

  const handleChange = (newValue: number) => {
    setValue(newValue);
  };

  const otpFormProps = {
    customClass: "verifyEmail",
    value,
    handleChange,
    handleComplete,
  };

  const submitVerifyBtn = () => {
    verifyEmailCode({
      code: value,
      setDisableBtn,
      setDisableBtnLoading,
      ...props,
    });
  };

  return (
    <>
      <StyledDOM.StyledSubHeadingOne>
        {emailVerifyText}
      </StyledDOM.StyledSubHeadingOne>
      <StyledDOM.StyledSubHeadingTwo>
        {emailVerifySubText}
      </StyledDOM.StyledSubHeadingTwo>
      <StyledDOM.StyledFieldsWrapper>
        <OtpForm {...otpFormProps} />
      </StyledDOM.StyledFieldsWrapper>
      <StyledDOM.StyledTypography>
        {" "}
        {timer > 0 ? (
          `Resend Email in ${timer} seconds.`
        ) : (
          <StyledDOM.StyledVerifySpanWrapper
            onClick={() => {
              setDisableBtn(false);
              setTimer(30);
              resendTrigger({ ...props });
            }}
            // disabled={disableResendBtn || timer > 0 ? true : false}
          >
            {Utils?.staticText?.register?.resendEmail}
          </StyledDOM.StyledVerifySpanWrapper>
        )}
      </StyledDOM.StyledTypography>

      <StyledDOM.StyledVerifyButtonWrapper
        id={"login-button"}
        type={`button`}
        onClick={submitVerifyBtn}
        disabled={disableBtn ? true : false}
      >
        {!disableBtnLoading && <CircularProgress disableShrink />}{" "}
        {Utils?.staticText?.register?.emailVerifyBtn}
      </StyledDOM.StyledVerifyButtonWrapper>
      <StyledDOM.StyledCancelButtonWrapper
        id={"login-button"}
        type={`button`}
        onClick={changeEmail}
        disabled={true}
      >
        {Utils?.staticText?.register?.changeEmail}
      </StyledDOM.StyledCancelButtonWrapper>
    </>
  );
};

export { VerifyEmail };
export default VerifyEmail;
