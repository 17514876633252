import { get, post, put } from "../../../../../helpers/api_helper";
import * as url from "../../../../../helpers/url_helper";
import * as Utils from "@utils";
import { getUserDetails } from "../../../../../redux/actions";

export const usersUpdate = (props) => {
  const {
    id = "",
    name = "",
    profile_image = "",
    handleOpenToast = () => "",
    setSeverityNote = () => "",
    setMessage = () => "",
    setIsBtnLoading = () => "",
    setDisableBtn = () => "",
  } = props || {};

  let payload = {
    id,
    name,
    profile_image,
  };

  put(url.UPDATE_USER_DETAILS, payload)
    .then((response) => {
      const {
        code = "",
        message = "User Details Updated Successfully.",
        result = [],
      } = response || {};
      handleOpenToast();
      setSeverityNote("success");
      setMessage("User Details Updated Successfully.");
      setIsBtnLoading(false);
      setDisableBtn(true);
      return true;
    })
    .catch((error) => {
      const { response = {} } = error || {};
      const { data = {} } = response || {};
      const { code = "", message = Utils?.staticText?.apiResponseMsg?.error } =
        data || {};
      handleOpenToast();
      setSeverityNote("error");
      setMessage(message);
      return false;
    });
};

export const generateOtp = (props) => {
  const {
    phoneNumber = "",
    userSub = "",
    handleOpenToast = () => "",
    setMessage = () => "",
    setSeverityNote = () => "",
    setShowOTPVerifyForm = () => "",
    handleBackdropClose = () => "",
  } = props || {};
  post(url.USERS_PHONE_VERIFY, {
    phone_number: phoneNumber,
    username: userSub,
  })
    .then((response) => {
      // const { data = {} } = response || {};
      const {
        message = Utils?.staticText?.apiResponseMsg?.success,
        result = {},
      } = response || {};
      const { message: code = "", phone_number = "" } = result;
      handleOpenToast();
      setSeverityNote("success");
      setMessage(message);
      setShowOTPVerifyForm(true);
      handleBackdropClose();
    })
    .catch((error) => {
      const { response = {} } = error || {};
      const { data = {} } = response || {};
      const { message = Utils?.staticText?.apiResponseMsg?.error } = data || {};
      handleOpenToast();
      setSeverityNote("error");
      setMessage(message);
      setShowOTPVerifyForm(false);
      handleBackdropClose();
    });
};

export const verifyOtp = (props) => {
  const {
    code = "",
    phoneNumber = "",
    emailId = "",
    userSub = "",
    navigateTo = () => "",
    handleOpenToast = () => "",
    setMessage = () => "",
    setSeverityNote = () => "",
    handleBackdropClose = () => "",
    handleSocialSharePopupClose = () => "",
    setRerenderOnSuccess = () => "",
  } = props || {};

  post(url.USERS_PHONE_OTP_VERIFY, {
    phone_number: phoneNumber,
    username: userSub,
    OTP: code,
  })
    .then((response) => {
      setRerenderOnSuccess(true);
      const { data = {} } = response || {};
      const {
        code = "",
        message = Utils?.staticText?.apiResponseMsg?.success,
      } = data || {};
      handleOpenToast();
      setSeverityNote("success");
      setMessage(message);
      handleBackdropClose();
      handleSocialSharePopupClose();
    })
    .catch((error) => {
      setRerenderOnSuccess(false);
      const { response = {} } = error || {};
      const { data = {} } = response || {};
      const { code = "", message = Utils?.staticText?.apiResponseMsg?.error } =
        data || {};
      handleOpenToast();
      setSeverityNote("error");
      setMessage(message);
      handleBackdropClose();
      handleSocialSharePopupClose();
    });
};
