import moment from "moment";

const replaceStringValue = (url, stringTerm, replaceValue) => {
  if (replaceValue && url.includes(stringTerm)) {
    return url.replace(stringTerm, replaceValue);
  }
};

const capitalizeText = (text) => {
  return text.replace(/\b(\w)/g, (s) => s.toUpperCase());
};

const getTextSpacingRestrict = (e) => {
  let event = e;
  event.target.value =
    event.target.value !== undefined
      ? event.target.value.trim().split(/ +/).join(" ")
      : "";

  return event;
};

const checkObjectEmptyValueWithKey = (object) => {
  const objectRes = Object.keys(object)
    .filter((key) => object[key] !== undefined && object[key] !== "")
    .map((key) => key + "=" + object[key])
    .join("&");

  return objectRes;
};

const contentLimitByCharacter = (text, MAX_LENGTH) => {
  if (text.length > MAX_LENGTH) {
    return `${text.substring(0, MAX_LENGTH)}...`;
  } else {
    return text;
  }
};

const getCurrentDate = (date = new Date(), formate = "DD MMM YYYY") => {
  return moment(date).format(formate);
};

const getCurrentTime = (separator = ":") => {
  let today = new Date();
  let hours = today.getHours();
  let minutes = today.getMinutes();
  let seconds = today.getSeconds();

  return `${hours}${separator}${minutes}${separator}${seconds}`;
};

const getTimeStampDateFormat = (value) => {
  const parsed = moment(new Date(value));

  if (!parsed.isValid()) {
    return value;
  }

  return getTimeAgo(parsed);
};

const getTimeAgo = (date) => {
  return moment(date, "MMM DD YYYY HH:mm:ss GMT Z").fromNow();
};

const replaceSpecialCharactersValue = (url, replaceValue) => {
  //if (replaceValue && url.includes(stringTerm)) {
  return url.replaceAll(/[^a-zA-Z0-9 ]/g, replaceValue);
  //}
};

export {
  replaceStringValue,
  capitalizeText,
  getTextSpacingRestrict,
  checkObjectEmptyValueWithKey,
  contentLimitByCharacter,
  getCurrentDate,
  getCurrentTime,
  getTimeAgo,
  getTimeStampDateFormat,
  replaceSpecialCharactersValue,
};
