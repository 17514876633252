import React from "react";
import * as StyledDOM from "./styles";
import * as IconGallery from "@icongallery";
import * as Utils from "@utils";
import * as ComponentsLib from "@componentsLib";
import _ from "lodash";
// import Carousel from "react-grid-carousel";
import Carousel from "react-elastic-carousel";
import InfoIcon from "../../../assets/images/infoicon.png";
import PlaceholderImg from "../../../assets/images/home/placeholder.png";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";

const MyDot = ({ isActive }) => (
  <span
    style={{
      display: "inline-block",
      height: isActive ? "8px" : "5px",
      width: isActive ? "8px" : "5px",
      background: "#1890ff",
    }}
  ></span>
);

const TrendingTopicsSlider = (props) => {
  const {
    topicsItems = [],
    navigateTo = () => "",
    title = "For You",
    subTitle = "Recommended Topics",
    getListCat,
  } = props || {};

  const [firstElement = []] = getListCat;
  const { response: responseCat = [], status: statusCat = "Loading" } =
    firstElement;

  const { response = [], status = "Loading" } = topicsItems;
  let trendingTopicsArray = [];
  trendingTopicsArray = response.map((item, index) => {
    const {
      id = "",
      definition = "",
      description = "",
      synonym = [],
      category_id = "",
      categories_id = "",
      frequency = "",
      isCompleted = "",
      ontologyId = "",
      onset = "",
      sources = "",
      name = "",
      question = "",
      question_id = "",
      users_contributions = [],
    } = item;
    let synonymArray =
      synonym.length > 0 &&
      synonym.filter((element) => {
        return element.length < 15;
      });
    let reOrderValue = {
      id,
      question: name ? name : question ? question : "No Title Available",
      definition: definition
        ? definition
        : description
        ? description
        : "No Description Available.",
      infoTxt: "Lorem ipsum dolor sit amet.",
      profileList: {
        profileViewIcons: [
          {
            imgUrl: "",
            icon: <IconGallery.ProfileCircleIcon />,
          },
          { imgUrl: PlaceholderImg },
        ],
        profileViewCount: index + 14,
      },
      symptoms: synonym,
      category_id,
      frequency,
      isCompleted,
      ontologyId,
      onset,
      sources,
      categories_id,
      question_id,
      users_contributions,
    };
    return reOrderValue;
  });
  const redirectToCategoryExplore = () => {
    navigateTo("/explore");
  };
  const skeletonLoaderProps = {
    variant: "text",
    width: "30%",
    height: 30,
  };
  const roundLoaderProps = {
    variant: "rounded",
    width: "100%",
    height: 60,
  };
  let trendingTopicsArrayList = trendingTopicsArray;
  // let trendingTopicsArrayList = trendingTopicsArray.sort(
  //   () => Math.random() - 0.5
  // );
  const errorProps = {
    content: "No Trending Topics Available",
    width: "100%",
    height: 60,
  };
  const breakPoints = [
    { width: 1, itemsToShow: 1.1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 2, itemToScroll: 2 },
    { width: 1200, itemsToShow: 2, itemToScroll: 2 },
  ];
  const redirectToTermDetailsUrlTrendingTopics = (item) => {
    let catIdArray =
      responseCat?.length > 0 &&
      responseCat?.filter(
        (catItem) => catItem.id === (item.category_id || item.categories_id)
      );
    const [firstCatItem = []] = catIdArray;
    const { id = "", details = {} } = firstCatItem;
    const { name = "" } = details;
    // if (!_.isEmpty(item.category_id || item.categories_id)) {
    //   navigateTo(
    //     `/explore/${id}/${name
    //       .replaceAll(" ", "-")
    //       .replaceAll("/", "-")
    //       .toLowerCase()}/${item.question_id}/${item.question
    //       .replaceAll(" ", "-")
    //       .replaceAll("/", "-")
    //       .toLowerCase()}`
    //   );
    // }
    navigateTo(
      `/topicdetails/${item?.question_id}/${item?.question
        .replaceAll(" ", "-")
        .replaceAll("/", "-")
        .toLowerCase()}`
    );
  };
  return (
    <StyledDOM.StyledTopicsSlider>
      <StyledDOM.StyledGrid>
        <StyledDOM.StyledSubHeadingOne>
          {status == "Loading" ? (
            <ComponentsLib.SkeletonLoader {...skeletonLoaderProps} />
          ) : (
            <>{title}</>
          )}
        </StyledDOM.StyledSubHeadingOne>
        <StyledDOM.StyledSubHeadingThree>
          {status == "Loading" ? (
            <ComponentsLib.SkeletonLoader {...skeletonLoaderProps} />
          ) : (
            <>{subTitle}</>
          )}
        </StyledDOM.StyledSubHeadingThree>
      </StyledDOM.StyledGrid>
      {status == "Loading" ? (
        <ComponentsLib.SkeletonLoader {...roundLoaderProps} />
      ) : (
        <>
          {status == "Error" ? (
            <ComponentsLib.ErrorSection {...errorProps} />
          ) : (
            // <Carousel
            //   dotColorActive="#795548"
            //   dotColorInactive="#ccc"
            //   showDots={true}
            //   cols={1}
            //   rows={1}
            //   gap={10}
            //   loop
            //   containerStyle={{ margin: "0px 0px 10px -10px" }}
            //   hideArrow={true}
            //   responsiveLayout={[
            //     {
            //       breakpoint: 820,
            //       cols: 2,
            //       rows: 1,
            //       gap: 10,
            //       loop: false,
            //     },
            //     {
            //       breakpoint: 420,
            //       cols: 1,
            //       rows: 1,
            //       gap: 10,
            //       loop: false,
            //     },
            //   ]}
            // >
            <Carousel
              breakPoints={breakPoints}
              showArrows={false}
              isInfiniteLoop={false}
              enableMouseSwipe={true}
              pagination={false}
              itemPadding={[0, 10, 0, 0]}
              style={{ margin: "0px" }}
            >
              {!_.isEmpty(trendingTopicsArrayList) &&
                trendingTopicsArrayList.map((item, i) => {
                  if (i < 3) {
                    return (
                      <StyledDOM.StyledCarouselItemDiv
                        key={i}
                        onClick={() =>
                          redirectToTermDetailsUrlTrendingTopics(item)
                        }
                      >
                        <StyledDOM.StyledNoSpan>#1</StyledDOM.StyledNoSpan>
                        <StyledDOM.StyledItemTitle>
                          {Utils.contentLimitByCharacter(item.question, 30)}
                        </StyledDOM.StyledItemTitle>
                        <StyledDOM.StyledItemContent>
                          {Utils.contentLimitByCharacter(item.definition, 65)}
                        </StyledDOM.StyledItemContent>
                        <StyledDOM.StyledHr />
                        <StyledDOM.StyledInfo>
                          <StyledDOM.StyledInfoText>
                            <IconGallery.SpeechBubble
                              width="20px"
                              height="20px"
                            />
                            <span>
                              {item.users_contributions?.length > 0
                                ? item.users_contributions.length
                                : 0}
                            </span>
                          </StyledDOM.StyledInfoText>
                          {/* <span className="count">
                              {Utils.getCurrentDate(new Date(), "DD MMM YYYY")}
                            </span> */}
                        </StyledDOM.StyledInfo>
                      </StyledDOM.StyledCarouselItemDiv>
                    );
                  }
                })}
            </Carousel>
          )}
        </>
      )}
    </StyledDOM.StyledTopicsSlider>
  );
};

export { TrendingTopicsSlider };
export default TrendingTopicsSlider;
