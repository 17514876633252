import { styled } from "@mui/material/styles";
import { Grid, Button } from "@mui/material";

export const StyledGrid = styled(Grid)`
  font-family: "Manrope", sans-serif;
  text-align: center;
  margin-top: 10px;
`;
export const StyledButton = styled(Button)`
  font-family: "Manrope", sans-serif;
  text-transform: capitalize;
  border: 1px solid #cccccc;
  padding: 10px 20px;
  color: #000;
  font-size: 16px;
`;

export const StyledSpan = styled("span")`
  font-family: "Manrope", sans-serif;
  margin-left: 5px;
  font-size: 14px;
  color: #000;
  font-weight: 700;
`;

export const StyledDottedWrapper = styled("h2")`
  font-family: "Manrope", sans-serif;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  color: #7c797a;
  font-size: 12px;
  margin-top: 30px;
  display: flex;
  .dot {
    flex: 1;
    border-bottom: 1px dotted #7c797a;
    height: 0.6em;
  }
  .text {
    margin: 0 15px;
  }
  // &:before {
  //   content: "--------------";
  //   letter-spacing: 6px;
  //   font-size: 12px;
  //   color: #9cbfdb;
  //   display: inline-block;
  //   vertical-align: 3px;
  //   padding-left: 10px;
  // }
  // &:after {
  //   content: "--------------";
  //   letter-spacing: 6px;
  //   font-size: 12px;
  //   color: #9cbfdb;
  //   display: inline-block;
  //   vertical-align: 3px;
  //   padding-left: 10px;
  // }
`;
