import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { getCategoriesList } from "./api";
import * as type from "../types";

function* fetchCategoryListSaga(action) {
  try {
    const categoryList = yield call(getCategoriesList, action);
    yield put({
      type: type.GET_CATEGORY_LIST_SUCCESS,
      payload: categoryList,
    });
  } catch (e) {
    yield put({ type: type.GET_CATEGORY_LIST_FAILED, message: e.message });
  }
}

function* categoriesListSaga() {
  yield takeLatest("GET_CATEGORY_LIST", fetchCategoryListSaga);
}

export default categoriesListSaga;
