import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { getCommentsListApi } from "../../app/components/Pages/TermDetails/api";
import * as type from "../types";

function* fetchContributionsSaga(action) {
  try {
    const commentsList = yield call(getCommentsListApi, action);
    yield put({
      type: type.GET_COMMENTS_LIST_SUCCESS,
      payload: commentsList,
    });
  } catch (e) {
    yield put({ type: type.GET_COMMENTS_LIST_FAILED, message: e.message });
  }
}

function* contributionsSaga() {
  yield takeLatest("GET_COMMENTS_LIST", fetchContributionsSaga);
}

export default contributionsSaga;
