import { styled } from "@mui/material/styles";
import { Grid, Button, Typography } from "@mui/material";

export const StyledMainFieldsWrapper = styled(Grid)`
  font-family: "Manrope", sans-serif;
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;

  label {
    font-size: 12px;
  }

  @media only screen and (max-width: 700px) {
    padding: 10px 15px;
  }
`;
export const StyledMainDiv = styled("div")`
  @media (max-width: 300px) {
    overflow-x: hidden;
  }
`;
