import React from "react";
import * as ComponentsLib from "@componentsLib";
import * as IconGallery from "@icongallery";
import * as Utils from "@utils";
import { Link, useNavigate } from "react-router-dom";
import * as StyledDOM from "./styles";
import { Auth } from "aws-amplify";
import { useHomeStates } from "./useHomeStates";
import { Grid } from "@mui/material";
import Header from "../../Header";
import Footer from "../../Footer";
import { useDispatch, useSelector } from "react-redux";
import SelectUserCategory from "../SelectUserCategory";
import {
  getUserDetails,
  getTrendingTopicsList,
} from "../../../../redux/actions";
import _ from "lodash";
// import * as StyledDOM from "./styles";

const Home = () => {
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const userDetails = useSelector((state) =>
    state ? state.userDetails.userDetails : ""
  );
  const trendingTopics = useSelector((state) =>
    state ? state.trendingTopicsList.trendingTopicsList : ""
  );
  const [firstElement = []] = trendingTopics;
  const { response = [], status: apiStatus = "Loading" } = firstElement;

  const {
    openBackdrop,
    setOpenBackdrop,
    handleBackdropOpen,
    handleBackdropClose,
    openModelPopup,
    setOpenModelPopup,
    handleModelPopupClose,
    handleModelPopupOpen,
    openToast,
    setOpenToast,
    handleOpenToast,
    handleCloseToast,
    vertical,
    setVertical,
    horizontal,
    setHorizontal,
    message,
    setMessage,
    severityNote,
    setSeverityNote,
  } = useHomeStates();
  React.useEffect(() => {
    // if (_.isEmpty(Utils.getStorage().subId)) {
    //   navigateTo("/login");
    // } else {
    Auth.currentAuthenticatedUser()
      .then((currentUser) => {
        dispatch(getUserDetails());
        dispatch(getTrendingTopicsList());
      })
      .catch(() => navigateTo("/login"));
    // }
  }, []);

  const bannersProps = {
    userDetails,
    openBackdrop,
    handleBackdropOpen,
    handleBackdropClose,
    handleModelPopupOpen,
  };

  const userDetailsProps = {
    userDetails,
    openBackdrop,
    handleBackdropOpen,
    handleBackdropClose,
  };

  const topicsSliderProps = {
    openBackdrop,
    topicsItems: response,
    navigateTo,
    showCarousel: true,
    status: apiStatus,
  };

  const backDropProps = {
    openBackdrop,
    setOpenBackdrop,
    handleBackdropClose,
  };

  const categoryListProps = {
    dispatch,
    navigateTo,
    showUrl: true,
  };
  const selectUserCategoryProps = {
    handleOpenToast,
    setMessage,
    setSeverityNote,
    handleModelPopupClose,
  };
  const componentPopupProps = {
    openModelPopup,
    handleModelPopupClose,
    handleModelPopupOpen,
    modalTitle: "",
    subTitle: "",
    renderComponent: <SelectUserCategory {...selectUserCategoryProps} />,
    dynamicClassName: "verifyOTPForm",
    showCloseIcon: false,
  };

  const snackBarProps = {
    openToast,
    handleOpenToast,
    handleCloseToast,
    vertical,
    horizontal,
    message,
    severityNote,
  };
  const headerProps = {
    showLogo: true,
    showBackIcon: false,
    floatLeft: true,
    showMenu: true,
    showBookmark: false,
    showShare: false,
    backTo: "",
  };
  return (
    <StyledDOM.StyledMainDiv>
      <ComponentsLib.BackDrop {...backDropProps} />
      <Header {...headerProps} />
      <StyledDOM.StyledMainFieldsWrapper>
        <ComponentsLib.UserDetails {...userDetailsProps} />
        <ComponentsLib.Banners {...bannersProps} />
        <ComponentsLib.TopicsSlider {...topicsSliderProps} />
        <ComponentsLib.GridListCategory {...categoryListProps} />
        <ComponentsLib.ComponentPopup {...componentPopupProps} />
        <ComponentsLib.ToastSnackbar {...snackBarProps} />
      </StyledDOM.StyledMainFieldsWrapper>
      <Footer />
    </StyledDOM.StyledMainDiv>
  );
};

export default Home;
export { Home };
