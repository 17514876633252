import React from "react";
export const staticText = {
  apiResponseMsg: {
    error: "Something went wrong. Please try again later.",
    success: "Success",
  },
  login: {
    welcomeTxt: "Welcome Back!",
    button: "Login",
    subHeading: "Start from you left.",
    dottedText: "Or Login with",
  },
  register: {
    signUp: "Register",
    signUpSubText: "Welcome to the new world of lifestyle!",
    resendEmail: "Resend verification Code",
    forgotPassword: "Forgot Password?",
    resetPassword: "Reset Password",
    resetPasswordBtn: "Reset Password",
    changeEmail: "Change Email",
    otp: "Get OTP",
    emailVerifyText: "Email has been verified.",
    emailVerifyBtn: "Verify",
  },
  updateBtnTxt: "Update",
  myAccount: {
    editProfile: "Edit Profile",
    faq: "FAQs",
    referral: "Referral",
    settings: "Settings",
    logout: "Logout",
    policies: "Privacy Policy | T&C ",
  },
  createPassword: {
    createPasswordTitle: "Create New Password",
    createPasswordTxt:
      "Your new password must be different from previously used passwords.",
    importNote: "Important info:",
    conditions: [
      "Must be at least 8 characters.",
      "Both the passwords must not match.",
    ],
  },
  explore: {
    title: "Earn reward points on contributions",
    content:
      "Add contributions & get reward.Let’s see what exciting new contributions you have to share.",
  },
};
