import * as React from "react";
import * as IconGallery from "@icongallery";
import * as StyledDOM from "./styles";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";

function TopicsFilter(props) {
  const {
    triggerTopicsFilter = () => {},
    trendingTopicsArrayList,
    callBackFunc,
  } = props || {};
  const [dropValue, setDropValue] = React.useState("Relevance");

  const handleChange = (event) => {
    setDropValue(event.target.value);

    if (event.target.value.includes("Lowest")) {
      let sortValue = trendingTopicsArrayList.sort(
        (a, b) =>
          parseFloat(a.users_contributions.length) -
          parseFloat(b.users_contributions.length)
      );
      callBackFunc(sortValue);
    } else if (event.target.value.includes("Highest")) {
      let sortValue = trendingTopicsArrayList.sort(
        (a, b) =>
          parseFloat(b.users_contributions.length) -
          parseFloat(a.users_contributions.length)
      );
      callBackFunc(sortValue.reverse());
    } else {
      callBackFunc(trendingTopicsArrayList);
    }
    // triggerTopicsFilter(event.target.value);
  };

  return (
    <StyledDOM.StyledFilterDropDown className="filterDropdown">
      <FormControl>
        <Select
          displayEmpty
          labelId="filter-select-label"
          id="filter-select"
          value={dropValue}
          placeholder="Relevance"
          onChange={handleChange}
          IconComponent={null}
          inputprops={{ "aria-label": "Without label" }}
          input={<OutlinedInput />}
          renderValue={(value) => {
            return (
              <StyledDOM.StyledBox>
                <IconGallery.SortBy width="14" height="12" />
                {value}
              </StyledDOM.StyledBox>
            );
          }}
        >
          <StyledDOM.StyledMenuItem selected value="Relevance">
            Relevance
          </StyledDOM.StyledMenuItem>
          <StyledDOM.StyledMenuItem value="Lowest Contributions">
            Lowest Contributions
          </StyledDOM.StyledMenuItem>
          <StyledDOM.StyledMenuItem value="Highest Contributions">
            Highest Contributions
          </StyledDOM.StyledMenuItem>
        </Select>
      </FormControl>
    </StyledDOM.StyledFilterDropDown>
  );
}

export { TopicsFilter };
export default TopicsFilter;
