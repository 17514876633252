import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { getGlobalSearch } from "./api";
import * as type from "../types";

function* fetchGlobalSearchSaga(action) {
  try {
    const globalSearch = yield call(getGlobalSearch, action);
    yield put({
      type: type.GET_GLOBAL_SEARCH_SUCCESS,
      payload: globalSearch,
    });
  } catch (e) {
    yield put({ type: type.GET_GLOBAL_SEARCH_FAILED, message: e.message });
  }
}

function* globalSearchSaga() {
  yield takeLatest("GET_GLOBAL_SEARCH", fetchGlobalSearchSaga);
}

export default globalSearchSaga;
