import React from "react";
import _ from "lodash";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { loginUser, getUserDetails } from "../../../../redux/actions";
import { Auth } from "aws-amplify";
import * as Utils from "@utils";
import { resendVerificationLink } from "./api";

function useLoginStates(props) {
  const {
    initialValues = {},
    validationSchema = {},
    dispatch = () => "",
    navigateTo = () => "",
  } = props || {};

  const [passwordType, setPasswordType] = React.useState("password");
  const [isBtnLoading, setIsBtnLoading] = React.useState(false);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const handleBackdropClose = () => {
    setOpenBackdrop(false);
  };
  const handleBackdropOpen = () => {
    setOpenBackdrop(true);
  };

  const [timer, setTimer] = React.useState(0);
  const id = React.useRef(null);
  const clear = () => {
    window.clearInterval(id.current);
  };
  const triggerTimer = () => {
    id.current = window.setInterval(() => {
      setTimer((time) => time - 1);
    }, 1000);
  };
  React.useEffect(() => {
    triggerTimer();
    return () => clear();
  }, [timer]);

  React.useEffect(() => {
    if (timer === 0) {
      clear();
    }
  }, [timer]);

  /* Snackbar functions */
  const [openToast, setOpenToast] = React.useState(false);
  const [vertical, setVertical] = React.useState("top");
  const [horizontal, setHorizontal] = React.useState("right");
  const [severityNote, setSeverityNote] = React.useState("info");
  const [message, setMessage] = React.useState("");

  const [showVerifyEmail, setShowVerifyEmail] = React.useState(false);

  const handleOpenToast = () => {
    setOpenToast(true);
  };

  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
  };
  /*snackbar end */

  const logoutCallback = async (props) => {
    const { isLogout = false } = props || {};
    if (isLogout) {
      try {
        await Auth.signOut();
        Utils.resetStorage();
      } catch (error) {
        console.log("error signing out: ", error);
      }
    }
  };

  const defaultFormik = {
    initialValues,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setIsBtnLoading(true);
      const { password = "", email = "" } = values || {};
      try {
        const user = await Auth.signIn(email, password);
        if (user) {
          Utils.initialStorage(user);
          dispatch(
            loginUser({
              username: user?.username,
              email,
              // handleOpenToast,
              // setSeverityNote,
              // setMessage,
              // logoutCallback,
              navigateTo,
            })
          );
          dispatch(getUserDetails());
          // navigateTo("/");
          resetForm({ values: "" });
        }
      } catch (error) {
        const {
          message = "Something went wrong. Please try again later",
          name = "",
        } = error || {};
        if (name === "UserNotConfirmedException") {
          setShowVerifyEmail(true);
          setTimer(5);
        }
        setIsBtnLoading(false);
        handleOpenToast();
        setSeverityNote("error");
        setMessage(message);
      }
    },
  };

  const formik = useFormik(defaultFormik);

  const resendTrigger = async () => {
    handleBackdropOpen();
    const { email = "" } = formik?.values || {};
    try {
      // resendVerificationLink({
      //   email,
      //   handleOpenToast,
      //   setMessage,
      //   setSeverityNote,
      //   handleBackdropClose,
      //   setShowVerifyEmail,
      //   setTimer,
      // });
      const resendVerification = await Auth.resendSignUp(email);
      if (resendVerification) {
        handleOpenToast();
        setSeverityNote("success");
        setMessage("Code resent successfully");
        setTimer(30);
      }
    } catch (error) {
      const {
        message = "Something went wrong. Please try again later",
        name = "",
      } = error || {};
      handleOpenToast();
      handleBackdropClose();
      setSeverityNote("error");
      setMessage(message);
    }
  };

  return {
    formik,
    openToast,
    setOpenToast,
    vertical,
    setVertical,
    horizontal,
    setHorizontal,
    severityNote,
    setSeverityNote,
    message,
    setMessage,
    handleOpenToast,
    handleCloseToast,
    passwordType,
    setPasswordType,
    showVerifyEmail,
    resendTrigger,
    isBtnLoading,
    setIsBtnLoading,
    timer,
    setTimer,
    openBackdrop,
    setOpenBackdrop,
    handleBackdropOpen,
    handleBackdropClose,
  };
}
export { useLoginStates };
export default useLoginStates;
