import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  getTrendingTopicsList,
  getTrendingTopicsListById,
  getTopicsByCatId,
} from "./api";
import * as type from "../types";

function* fetchTrendingTopicsListSaga(action) {
  try {
    const trendingTopicsList = yield call(getTrendingTopicsList, action);
    yield put({
      type: type.GET_TRENDING_TOPICS_SUCCESS,
      payload: trendingTopicsList,
    });
  } catch (e) {
    yield put({ type: type.GET_TRENDING_TOPICS_FAILED, message: e.message });
  }
}

function* fetchTrendingTopicsListByIdSaga(action) {
  try {
    const trendingTopicsListById = yield call(
      getTrendingTopicsListById,
      action
    );
    yield put({
      type: type.GET_TRENDING_TOPICS_BY_ID_SUCCESS,
      payload: trendingTopicsListById,
    });
  } catch (e) {
    yield put({
      type: type.GET_TRENDING_TOPICS_BY_ID_FAILED,
      message: e.message,
    });
  }
}

function* fetchTopicsByCatId(action) {
  try {
    const topicsListById = yield call(getTopicsByCatId, action);
    yield put({
      type: type.GET_TOPICS_BY_ID_SUCCESS,
      payload: topicsListById,
    });
  } catch (e) {
    yield put({ type: type.GET_TOPICS_BY_ID_FAILED, message: e.message });
  }
}

function* topicsListSaga() {
  yield takeLatest("GET_TRENDING_TOPICS", fetchTrendingTopicsListSaga);
  yield takeLatest(
    "GET_TRENDING_TOPICS_BY_ID",
    fetchTrendingTopicsListByIdSaga
  );
  yield takeLatest("GET_TOPICS_BY_ID", fetchTopicsByCatId);
}

export default topicsListSaga;
