import { styled } from "@mui/material/styles";
import { Grid, Button, Typography, Chip, Box } from "@mui/material";

export const StyledCommentBoxOuter = styled(Grid)`
  font-family: "Manrope", sans-serif;
  box-shadow: -1px -1px 8px;
  position: fixed;
  bottom: -1px;
  width: 100%;
  background: #ffffff;
`;
export const StyledTextarea = styled("textarea")`
  font-family: "Manrope", sans-serif;
  width: 84%;
  height: 20px;
  padding: 10px;
  font-family: inherit;
  font-weight: 500;
  background-color: #fbfbfb;
  border-radius: 4px;
  border: 0px;
  color: #0f0f0fcc;
  font-size: 14px;
  &:focus-visible {
    outline: 0px;
  }
`;
export const StyledReplyText = styled(Grid)`
  font-family: "Manrope", sans-serif;
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
  background: #dedede;
  font-size: 12px;
  font-weight: 700;
`;

export const StyledCommentBox = styled(Grid)`
  font-family: "Manrope", sans-serif;
  display: flex;
  padding: 15px;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  span {
    color: #000000;
    opacity: 0.5;
    @media (max-width: 300px) {
      font-size: 14px;
    }
  }

  svg {
    transform: rotate(-45deg);
    color: #000000;
    opacity: 0.6;
  }
  span.active {
    svg {
      transform: rotate(-45deg);
      color: #000000;
      opacity: 0.6;
    }
  }
  span.inactive {
    svg {
      transform: rotate(-45deg);
      color: #000000;
      opacity: 0.2;
    }
  }
`;
