import { styled } from "@mui/material/styles";
import { Grid, Button, Typography } from "@mui/material";
import { style } from "@mui/system";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

export const StyledMainFieldsWrapper = styled(Grid)`
  font-family: "Manrope", sans-serif;
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;

  label {
    font-size: 12px;
  }

  @media only screen and (max-width: 700px) {
    padding: 0px;
  }
`;

export const StyledHeader = styled(Grid)`
  font-family: "Manrope", sans-serif;
  display: flex;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  box-shadow: 0px 3px 3px #ccc;
  padding: 0px 15px 0px 10px;
  justify-content: left;
  align-items: center;
  margin-top: -1px;
  @media only screen and (max-width: 820px) {
  }
`;

export const StyledTypography = styled(Typography)`
  font-family: "Manrope", sans-serif;
  font-size: 18px;
  color: #000;
  margin: 0px;
  width: 100%;
  text-align: center;
  font-weight: 900;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
`;

export const StyledTypographyLeft = styled(Typography)`
  font-family: "Manrope", sans-serif;
  font-size: 20px;
  color: #000;
  margin: 0px;
  width: 100%;
  text-align: center;
  font-weight: 900;
  display: flex;
  align-items: center;
  justify-content: left;
  text-transform: capitalize;
`;

export const StyledSpanIcon = styled("span")`
  margin-top: 10px;
`;

export const StyledSearchResult = styled(Grid)`
  font-family: "Manrope", sans-serif;
`;
export const StyledCategoryList = styled(Grid)`
  font-family: "Manrope", sans-serif;
  padding: 15px;
`;
export const StyledCategoryListTypography = styled(Typography)`
  font-family: "Manrope", sans-serif;
  color: #7c797a;
  font-size: 12px;
  display: flex;
  font-weight: 900;
`;
export const StyledCategoryListUl = styled(List)`
  font-family: "Manrope", sans-serif;
  width: 100%;
  padding-bottom: 0px;
  // margin-bottom: 60px;
`;
export const StyledCategoryListItem = styled(ListItem)`
  font-family: "Manrope", sans-serif;
  padding-left: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  .MuiTypography-root.MuiTypography-body1.MuiListItemText-primary {
    font-family: "Manrope", sans-serif;
    font-size: 14px;
    font-weight: 900;
    color: #0f0f0f;
  }
  .MuiTypography-root.MuiTypography-body2 {
    font-family: "Manrope", sans-serif;
    font-size: 11px;
    color: #7c797a;
    font-weight: 500;
  }
`;
export const StyledCategoryListItemText = styled(ListItemText)`
  font-family: "Manrope", sans-serif;
`;

export const StyledGrid = styled(Grid)`
  font-family: "Manrope", sans-serif;
  margin: 20px 10px;
  @media only screen and (max-width: 820px) {
    .categoryImage {
      width: auto;
      height: auto;
    }
  }
  @media only screen and (max-width: 420px) {
    .categoryImage {
      width: 32px;
      height: 42px;
    }
  }
`;

export const StyledGridNoData = styled(Grid)`
  font-family: "Manrope", sans-serif;
  margin-top: 30px;
  padding: 10px;
  text-align: center;
`;
