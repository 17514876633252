import React from "react";
import _ from "lodash";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { Auth } from "aws-amplify";
import * as Utils from "@utils";
import * as IconGallery from "@icongallery";
import Cookies from "js-cookie";
function useCreatePasswordStates(props) {
  const {
    initialValues = {},
    validationSchema = {},
    dispatch = () => "",
    navigate = () => "",
    emailId: username = "",
    verificationId: code = "",
  } = props || {};

  const [showLogin, setShowLogin] = React.useState(false);
  const [passwordType, setPasswordType] = React.useState("password");
  const [confirmPasswordType, setConfirmPasswordType] =
    React.useState("password");
  const [open, setOpen] = React.useState(false);
  const [isBtnLoading, setIsBtnLoading] = React.useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const onLogout = () => {
    handleOpen();
  };

  /* Snackbar functions */
  const [openToast, setOpenToast] = React.useState(false);
  const [vertical, setVertical] = React.useState("top");
  const [horizontal, setHorizontal] = React.useState("right");
  const [severityNote, setSeverityNote] = React.useState("info");
  const [message, setMessage] = React.useState("");

  const [showVerifyEmail, setShowVerifyEmail] = React.useState(false);

  const handleOpenToast = () => {
    setOpenToast(true);
  };

  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
  };
  /*snackbar end */

  const defaultFormik = {
    initialValues,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const { password = "", confirmPassword: newPassword = "" } = values || {};
      // const user = await Auth.forgotPasswordSubmit(username, code, new_password)
      Auth.currentAuthenticatedUser()
        .then((user) => {
          return Auth.changePassword(user, password, newPassword);
        })
        .then((data) => {
          // const { message = "Password updated successfully", name = "" } =
          //   data || {};
          // handleOpenToast();
          // setSeverityNote("success");
          // setMessage(message);
          if (values.rememberMe) {
            Cookies.set("newpassword", values.confirmPassword, {
              path: "/",
            });
          } else {
            Cookies.remove("newpassword");
          }
          resetForm({ values: "" });
          handleOpen();
        })
        .catch((error) => {
          let {
            message = "Something went wrong. Please try again later",
            name = "",
          } = error || {};
          if (name === "NotAuthorizedException") {
            message = "Current Password is incorrect.";
          }
          handleOpenToast();
          setSeverityNote("error");
          setMessage(message);
        });
    },
  };

  const formik = useFormik(defaultFormik);

  return {
    formik,
    openToast,
    setOpenToast,
    vertical,
    setVertical,
    horizontal,
    setHorizontal,
    severityNote,
    setSeverityNote,
    message,
    setMessage,
    handleOpenToast,
    handleCloseToast,
    passwordType,
    setPasswordType,
    confirmPasswordType,
    setConfirmPasswordType,
    showLogin,
    setShowLogin,
    open,
    setOpen,
    isBtnLoading,
    setIsBtnLoading,
    isBtnDisabled,
    setIsBtnDisabled,
    handleOpen,
    handleClose,
  };
}

export { useCreatePasswordStates };
export default useCreatePasswordStates;
