import { styled } from "@mui/material/styles";
import { Grid, Button, Typography, Chip, Box } from "@mui/material";

export const StyledMainFieldsWrapper = styled(Grid)`
  font-family: "Manrope", sans-serif;
  // padding: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  min-height: 700px;

  label {
    font-size: 12px;
  }

  @media only screen and (max-width: 700px) {
    // padding: 10px 15px;
  }
`;

export const StyledGrid = styled(Grid)`
  font-family: "Manrope", sans-serif;
  background: #ffd0161f;
  padding: 20px 15px 0px 15px;
  position: relative;
`;

export const StyledGridLoader = styled(Grid)`
  padding: 20px 15px 0px 15px;
`;

export const StyledTitle = styled(Typography)`
  font-family: "Manrope", sans-serif;
  font-size: 20px;
  font-weight: 900;
  color: #05100f;
  display: flex;
  justify-content: space-between;
`;

export const StyledSubTitle = styled(Typography)`
  font-family: "Manrope", sans-serif;
  font-size: 11px;
  font-weight: 700;
  color: #9a999a;
`;

export const StyledContentGrid = styled(Grid)`
  font-family: "Manrope", sans-serif;
  font-size: 12px;
  color: #0F0F0F;
  line-height: 18px;
  font-weight: 500;
}
`;

export const StyledTagsGrid = styled(Grid)`
  font-family: "Manrope", sans-serif;
  display: flex;
  align-items: flex-start;
  justify-content: start;
  gap: 5px;
  span.symp {
    font-size: 11px;
    font-weight: 600;
    margin-top: 7px;
  }
  span.symptomsCount {
    font-size: 11px;
    color: #26867d;
    font-weight: 900;
    text-transform: uppercase;
    margin-top: 10px;
  }
  &.sympList {
    gap: 5px;
  }
`;
export const StyledTags = styled(Chip)`
  font-family: "Manrope", sans-serif;
  color: #26867d;
  background: #d3e7e5;
  font-size: 11px;
  font-weight: 900;
  text-transform: uppercase;
  border-radius: 4px;
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  &.MuiChip-root.MuiChip-filled.MuiChip-sizeMedium {
    height: auto;
  }
  span {
    padding: 4px 6px;
    font-size: 10px;
    font-weight: 900;
    white-space: break-spaces;
    border-radius: 4px;
  }
`;

export const StyledBox = styled(Box)`
  font-family: "Manrope", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  // position: absolute;
  width: fit-content;
  background: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 4px;
  box-shadow: 0pt 0pt 1pt;
  padding: 0 10px;
  color: #0f0f0f;
  font-size: 11px;
  height: 36px;
  font-weight: 600;
  margin: 80px auto -17px auto;

  @media only screen and (min-width: 768px) {
    // bottom: -18px;
    // left: 38%;
  }

  @media only screen and (min-width: 280px) {
    // bottom: -20px;
    // left: 27%;
  }

  svg {
    margin: 15px;
  }
  hr {
    margin: 8px 4px;
  }
`;

export const StyledContributionGrid = styled(Grid)`
  font-family: "Manrope", sans-serif;
  padding: 40px 0px 120px 0px;
  position: relative;
`;

export const StyledContributionTitle = styled("h4")`
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  margin: 0;
  font-weight: 900;
  padding: 0 15px 30px 15px;
`;

export const StyledForumGrid = styled(Grid)``;
export const StyledIKnowSomeoneGrid = styled(Grid)`
  font-family: "Manrope", sans-serif;
  position: absolute;
  bottom: 30px;
  margin: 0 auto;
  display: flex;
  align-item: center;
  justify-content: center;
  gap: 20px;
  @media (max-width: 300px) {
    gap: 15px;
  }
  button {
    font-family: "Manrope", sans-serif;
    border: 1px solid;
    color: #000;
    font-size: 11px;
    font-weight: 900;
    @media (max-width: 300px) {
      font-size: 10px;
    }
  }
  @media only screen and (min-width: 767px) {
    width: 96% !important;
  }

  @media only screen and (min-width: 320px) {
    width: 92%;
  }
`;
