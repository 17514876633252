import { styled } from "@mui/material/styles";
import { Grid, Button, Typography, Avatar } from "@mui/material";

export const StyledBanners = styled(Grid)`
  display: flex;
  padding: 8px 0 10px 0px;
  text-align: left;
`;
export const StyledDetails = styled(Grid)``;
export const StyledLogOutButton = styled(Button)``;
export const StyledTypography = styled(Typography)`
  font-family: "Manrope";
  padding: 0px;
  margin: 0px;
  &.large {
    font-size: 16px;
    font-weight: 900;
    overflow-wrap: break-word;
    inline-size: 320px;
    display: block;
    text-transform: capitalize;
  }
  &.small {
    font-size: 12px;
    font-weight: 900;
    color: #7c797a;
  }
  display: flex;
`;

export const StyledAvatar = styled(Avatar)`
  font-family: "Manrope", sans-serif;
  height: 43px;
  width: 43px;
  float: right;
  border: 2px solid #26867d;
`;
