import React from "react";
import * as ComponentsLib from "@componentsLib";
import * as IconGallery from "@icongallery";
import * as Utils from "@utils";
import * as StyledDOM from "./styles";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getGlobalSearch } from "../../../redux/actions";
import { clearSearchSession } from "../../../redux/actions";
import _ from "lodash";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinShareButton,
  LinkedinIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";

function SocialShare(props) {
  const { handleSearchPopupClose = () => "", showCategoryList = true } =
    props || {};
  const navigateTo = useNavigate();
  const dispatch = useDispatch();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <StyledDOM.StyledMainFieldsWrapper>
        <StyledDOM.StyledTypography>Share in</StyledDOM.StyledTypography>
        <StyledDOM.StyledSocialIcons>
          <FacebookShareButton url={window.location.href}>
            <FacebookIcon size={64} round={true} />
          </FacebookShareButton>
          <LinkedinShareButton url={window.location.href}>
            <LinkedinIcon size={64} round={true} />
          </LinkedinShareButton>
          <EmailShareButton url={window.location.href}>
            <EmailIcon size={64} round={true} />
          </EmailShareButton>
        </StyledDOM.StyledSocialIcons>
        {/* <StyledDOM.StyledTypographyCopy>
          {window.location.href}
          <span>Copy</span>
        </StyledDOM.StyledTypographyCopy> */}
      </StyledDOM.StyledMainFieldsWrapper>
    </>
  );
}

export { SocialShare };
export default SocialShare;
