import React from "react";
import * as yup from "yup";

export const getValid = (value, optionalFieldItm = "") => {
  const { ...balanceProps } = value;
  let values = Object.keys(balanceProps).map((itm) => {
    let obj = "";
    if (balanceProps[itm].length === 0) {
      obj = itm;
    }
    return obj;
  });

  return values.filter((itm) => itm !== "");
};

export const FormFieldValues = (props) => {
  const initialValues = {
    userCategory: "",
  };
  return {
    initialValues,
  };
};

export const validationSchema = yup.object().shape({
  userCategory: yup.string().required(),
});

export const Forminputprops = (props) => {
  const { formik, getUserCategoryList = [] } = props;

  let dropDownOptions = [];
  dropDownOptions =
    getUserCategoryList.length > 0 &&
    getUserCategoryList.map((option, index) => {
      const { id = "", category_name = "" } = option;
      let reOrderValue = {
        value: id,
        label: category_name,
      };
      return reOrderValue;
    });

  const dropDownProps = {
    disabled: dropDownOptions?.length > 0 ? false : true,
    note: "",
    options: dropDownOptions,
    inputprops: {
      style: {
        height: "20px",
        backgroundPosition: "10px center",
        backgroundRepeat: "no-repeat",
        border: "1px solid #ccc",
        transition: "all 0.2s",
        borderRadius: "4px",
        fontSize: "16px",
        backgroundColor: "#fff",
      },
    },
    className: "cls-textfield cls-userCategory",
    placeholder: "Select User Category",
    labelname: "Select User Category",
    id: "userCategory",
    type: "textdropdown",
    name: "userCategory",
    onChange: (e, newValue) => {
      if (newValue === null) {
        formik.setFieldValue("userCategory", "");
      } else {
        formik.setFieldValue("userCategory", newValue?.value);
      }
    },
    defaultValue: null,
    helpertext:
      formik?.errors.userCategory &&
      formik?.touched.userCategory &&
      `${formik?.errors.userCategory}`,
    error: formik?.errors.userCategory && formik?.touched.userCategory,
    variant: "filled",
    size: "small",
    formik: formik,
    key: dropDownOptions[0],
  };

  return {
    dropDownProps,
  };
};
